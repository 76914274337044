import React, { useState, useEffect, useMemo } from 'react';
import { 
    Scale, TrendingDown, TrendingUp, Target, Activity, 
    Flame, AlertCircle, ChevronDown, ChevronUp, Pencil, 
    Trash2, Info, AlertTriangle, Plus, Award, Zap, Crown 
} from 'lucide-react';
import { auth, db } from '../../../../firebase';
import { format, differenceInDays } from 'date-fns';
import { calculateBMR, getActivityMultiplier } from '../Fasting/Components/CurrentFastProgress/calculations';
import { doc, collection, query, orderBy, onSnapshot, deleteDoc } from 'firebase/firestore';

// Component imports
import WeightEntryForm from './Components/WeightEntryForm';
import WeightGraph from './Components/WeightGraph';
import { 
    calculateBMI, 
    getBMICategory, 
    calculateTargetWeight 
} from '../../../../utils/healthCalculations';

// Constants
const KG_TO_LBS = 2.20462;
const LBS_TO_KG = 1 / KG_TO_LBS;
const MINIMUM_DAYS_FOR_WEEKLY_AVERAGE = 7;

// Helper Functions
const convertWeight = (weight, fromUnit = 'kg', toUnit = 'lbs') => {
    if (!weight || isNaN(weight)) return 0;
    if (fromUnit === toUnit) return Number(weight);
    if (fromUnit === 'kg' && toUnit === 'lbs') {
        return Number(weight) * KG_TO_LBS;
    }
    if (fromUnit === 'lbs' && toUnit === 'kg') {
        return Number(weight) * LBS_TO_KG;
    }
    return Number(weight);
};

const formatWeight = (weight, measurementPreference = 'metric') => {
    if (!weight || isNaN(weight)) return '0.0';
    const value = measurementPreference === 'imperial' 
        ? convertWeight(weight, 'kg', 'lbs')
        : Number(weight);
    return `${value.toFixed(1)} ${measurementPreference === 'imperial' ? 'lbs' : 'kg'}`;
};

const calculateWeeklyChange = (entries, measurementPreference = 'metric') => {
    if (entries.length < 2) return 0;
    const lastEntry = entries[0];
    const firstEntry = entries[entries.length - 1];
    const totalDays = differenceInDays(lastEntry.date, firstEntry.date);
    if (totalDays < MINIMUM_DAYS_FOR_WEEKLY_AVERAGE) return null;
    const totalWeeks = totalDays / 7;
    const weightDiff = lastEntry.weight - firstEntry.weight;
    const weeklyChange = weightDiff / totalWeeks;
    return measurementPreference === 'imperial' 
        ? convertWeight(weeklyChange, 'kg', 'lbs')
        : weeklyChange;
};
// StatCard Component
const StatCard = ({ 
    icon: Icon, 
    label, 
    value, 
    trend, 
    onClick, 
    className = "", 
    subtext, 
    measurementPreference,
    tooltip,
    variant = "default" 
}) => {
    const displayValue = label === "Weekly Average" 
        ? value === null 
            ? "Insufficient data" 
            : `${Math.abs(value).toFixed(1)} ${measurementPreference === 'imperial' ? 'lbs' : 'kg'}/week`
        : value;

    const isInactiveWeeklyAverage = label === "Weekly Average" && value === null;

    const variants = {
        default: "bg-white",
        gradient: "bg-gradient-to-br from-blue-50 to-indigo-50 border-blue-100",
        success: "bg-gradient-to-br from-green-50 to-emerald-50 border-green-100",
        warning: "bg-gradient-to-br from-yellow-50 to-amber-50 border-amber-100"
    };

    return (
        <div 
            onClick={onClick}
            className={`${variants[variant]} p-4 sm:p-6 rounded-xl border transition-all duration-300 
                ${onClick ? 'cursor-pointer hover:shadow-lg transform hover:scale-[1.02]' : 'hover:shadow-md'} 
                ${isInactiveWeeklyAverage ? 'opacity-75' : ''} ${className}`}
        >
            <div className="flex items-center gap-3 mb-3">
                <div className="p-2.5 bg-white bg-opacity-50 rounded-lg shadow-sm">
                    <Icon className={`w-5 h-5 ${
                        variant === 'gradient' ? 'text-blue-500' :
                        variant === 'success' ? 'text-green-500' :
                        variant === 'warning' ? 'text-amber-500' :
                        'text-blue-500'
                    }`} />
                </div>
                <div className="flex items-center gap-2">
                    <p className="text-sm font-medium text-gray-600">{label}</p>
                    {tooltip && (
                        <div className="relative group">
                            <Info className="w-4 h-4 text-gray-400" />
                            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-2 bg-gray-900 text-white text-xs rounded-lg opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap shadow-lg z-10">
                                {tooltip}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="flex items-end justify-between">
                <div>
                    <p className="text-xl sm:text-2xl font-bold text-gray-900">{displayValue || '0.0'}</p>
                    {subtext && !isInactiveWeeklyAverage && (
                        <div className="text-sm text-gray-500 mt-1">{subtext}</div>
                    )}
                    {isInactiveWeeklyAverage && (
                        <p className="text-sm text-gray-500 mt-1">
                            Need {MINIMUM_DAYS_FOR_WEEKLY_AVERAGE} days of data
                        </p>
                    )}
                </div>
                {trend !== undefined && !isInactiveWeeklyAverage && (
                    <div className={`flex items-center gap-1.5 px-3 py-1.5 rounded-full text-sm font-medium ${
                        trend > 0 
                            ? 'bg-green-50 text-green-600' 
                            : 'bg-red-50 text-red-600'
                    }`}>
                        {trend > 0 ? <TrendingUp className="w-4 h-4" /> : <TrendingDown className="w-4 h-4" />}
                        {trend > 0 ? '+' : ''}{trend}%
                    </div>
                )}
            </div>
        </div>
    );
};

// Alert Component
const Alert = ({ children, variant = "warning", icon: CustomIcon }) => {
    const variants = {
        warning: {
            className: "bg-amber-50 border-amber-500 text-amber-700",
            icon: AlertCircle
        },
        success: {
            className: "bg-green-50 border-green-500 text-green-700",
            icon: Award
        },
        error: {
            className: "bg-red-50 border-red-500 text-red-700",
            icon: AlertTriangle
        }
    };

    const Icon = CustomIcon || variants[variant].icon;

    return (
        <div className={`flex items-center gap-3 border-l-4 p-4 rounded-r ${variants[variant].className}`}>
            <Icon className="w-5 h-5 flex-shrink-0" />
            <div className="flex-1">{children}</div>
        </div>
    );
};
// ProgressCard Component
const ProgressCard = ({ currentWeight, targetWeight, startWeight, measurementPreference }) => {
    const totalToLose = Math.abs(startWeight - targetWeight);
    const lost = Math.abs(startWeight - currentWeight);
    const percentageComplete = Math.min(100, Math.max(0, (lost / totalToLose) * 100));

    return (
        <div className="bg-gradient-to-br from-blue-50 to-indigo-50 p-4 sm:p-6 rounded-xl border border-blue-100">
            <div className="flex items-center gap-3 mb-6">
                <div className="p-2.5 bg-white bg-opacity-50 rounded-lg shadow-sm">
                    <Target className="w-5 h-5 text-blue-500" />
                </div>
                <h3 className="text-lg font-semibold text-gray-900">Progress Overview</h3>
            </div>
            <div className="space-y-6">
                <div className="relative">
                    <div className="flex items-center justify-between mb-2">
                        <div className="flex items-center gap-2">
                            <span className="text-sm font-medium text-blue-700">
                                {percentageComplete.toFixed(1)}% Complete
                            </span>
                            {percentageComplete >= 100 && (
                                <Crown className="w-4 h-4 text-amber-500" />
                            )}
                        </div>
                        <span className="text-sm text-gray-500">Target: {formatWeight(targetWeight, measurementPreference)}</span>
                    </div>
                    <div className="h-3 bg-white rounded-full overflow-hidden shadow-inner">
                        <div 
                            style={{ width: `${percentageComplete}%` }}
                            className="h-full bg-gradient-to-r from-blue-500 to-indigo-500 transform transition-all duration-500 ease-out rounded-full"
                        />
                    </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                    <div className="bg-white bg-opacity-50 p-4 rounded-xl border border-blue-100 transform transition-all duration-300 hover:shadow-md">
                        <div className="flex items-center gap-2 mb-1">
                            <Scale className="w-4 h-4 text-blue-500" />
                            <p className="text-sm text-blue-600">Starting Weight</p>
                        </div>
                        <p className="text-lg font-semibold text-gray-900">
                            {formatWeight(startWeight, measurementPreference)}
                        </p>
                    </div>

                    <div className="bg-white bg-opacity-50 p-4 rounded-xl border border-blue-100 transform transition-all duration-300 hover:shadow-md">
                        <div className="flex items-center gap-2 mb-1">
                            <Activity className="w-4 h-4 text-blue-500" />
                            <p className="text-sm text-blue-600">Current Weight</p>
                        </div>
                        <p className="text-lg font-semibold text-gray-900">
                            {formatWeight(currentWeight, measurementPreference)}
                        </p>
                    </div>

                    <div className="bg-white bg-opacity-50 p-4 rounded-xl border border-blue-100 transform transition-all duration-300 hover:shadow-md">
                        <div className="flex items-center gap-2 mb-1">
                            <Target className="w-4 h-4 text-blue-500" />
                            <p className="text-sm text-blue-600">Target Weight</p>
                        </div>
                        <p className="text-lg font-semibold text-gray-900">
                            {formatWeight(targetWeight, measurementPreference)}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
// CollapsibleWeightHistory Component
const CollapsibleWeightHistory = ({ 
    weightEntries, 
    onEdit, 
    onDelete,  
    measurementPreference 
}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [deleteConfirmation, setDeleteConfirmation] = useState({
        isOpen: false,
        entryToDelete: null
    });

    const handleDeleteClick = (entry, event) => {
        event.stopPropagation();
        setDeleteConfirmation({
            isOpen: true,
            entryToDelete: entry
        });
    };

    const handleEditClick = (entry, event) => {
        event.stopPropagation();
        onEdit(entry);
    };

    const handleDeleteConfirm = () => {
        if (deleteConfirmation.entryToDelete) {
            onDelete(deleteConfirmation.entryToDelete);
            setDeleteConfirmation({ isOpen: false, entryToDelete: null });
        }
    };

    const handleDeleteCancel = () => {
        setDeleteConfirmation({ isOpen: false, entryToDelete: null });
    };

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className="space-y-4">
            <div 
                className={`bg-gradient-to-br from-blue-50 to-indigo-50 rounded-xl border border-blue-100 cursor-pointer
                    transition-all duration-200 hover:shadow-md ${isExpanded ? 'shadow-md' : ''}`}
            >
                <div 
                    className="p-4 sm:p-6"
                    onClick={toggleExpand}
                >
                    <div className="flex items-center justify-between">
                        <div className="flex items-center gap-3">
                            <div className="p-2.5 bg-white bg-opacity-50 rounded-lg shadow-sm">
                                <Scale className="w-5 h-5 text-blue-500" />
                            </div>
                            <div>
                                <h3 className="text-lg font-semibold text-gray-900">Weight History</h3>
                                <p className="text-sm text-gray-600">Track your weight journey over time</p>
                            </div>
                        </div>
                        <div className="p-2.5 bg-white bg-opacity-50 rounded-lg shadow-sm">
                            {isExpanded ? (
                                <ChevronUp className="w-5 h-5 text-blue-500" />
                            ) : (
                                <ChevronDown className="w-5 h-5 text-blue-500" />
                            )}
                        </div>
                    </div>
                </div>

                <div
                    className={`transition-all duration-300 ease-in-out ${
                        isExpanded ? 'max-h-[32rem] opacity-100' : 'max-h-0 opacity-0'
                    } overflow-hidden`}
                >
                    <div className="px-4 sm:px-6 pb-6">
                        <div className="bg-white rounded-lg shadow-sm overflow-hidden">
                            {/* Desktop view */}
                            <div className="hidden md:block overflow-x-auto">
                                <table className="w-full">
                                    <thead>
                                        <tr className="bg-gray-50">
                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Date
                                            </th>
                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Weight
                                            </th>
                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Notes
                                            </th>
                                            <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-100">
                                        {weightEntries.map((entry, index) => (
                                            <tr 
                                                key={entry.id} 
                                                className={`hover:bg-blue-50 transition-colors ${
                                                    index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                                                }`}
                                            >
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                    {format(entry.date, 'MMM d, yyyy')}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                    {formatWeight(entry.weight, measurementPreference)}
                                                </td>
                                                <td className="px-6 py-4 text-sm text-gray-600">
                                                    {entry.notes ? (
                                                        <div className="relative group/notes">
                                                            <div className="line-clamp-1">{entry.notes}</div>
                                                            {entry.notes.length > 50 && (
                                                                <div className="hidden group-hover/notes:block absolute left-0 top-full mt-2 p-3 bg-white rounded-lg shadow-lg border border-gray-200 z-10 w-64">
                                                                    {entry.notes}
                                                                </div>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <span className="text-gray-400 italic">No notes</span>
                                                    )}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-right text-sm">
                                                    <div className="flex items-center justify-end gap-3">
                                                        <button
                                                            onClick={(e) => handleEditClick(entry, e)}
                                                            className="text-blue-600 hover:text-blue-700 transition-colors"
                                                            title="Edit entry"
                                                        >
                                                            <div className="p-1.5 bg-blue-50 rounded-lg hover:bg-blue-100">
                                                                <Pencil className="w-4 h-4" />
                                                            </div>
                                                        </button>
                                                        <button
                                                            onClick={(e) => handleDeleteClick(entry, e)}
                                                            className="text-red-600 hover:text-red-700 transition-colors"
                                                            title="Delete entry"
                                                        >
                                                            <div className="p-1.5 bg-red-50 rounded-lg hover:bg-red-100">
                                                                <Trash2 className="w-4 h-4" />
                                                            </div>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            {/* Mobile view */}
                            <div className="md:hidden">
                                {weightEntries.map((entry, index) => (
                                    <div 
                                        key={entry.id}
                                        className={`p-4 ${index !== 0 ? 'border-t border-gray-100' : ''}`}
                                    >
                                        <div className="flex justify-between items-start mb-2">
                                            <div>
                                                <div className="font-medium text-gray-900">
                                                    {formatWeight(entry.weight, measurementPreference)}
                                                </div>
                                                <div className="text-sm text-gray-500">
                                                    {format(entry.date, 'MMM d, yyyy')}
                                                </div>
                                            </div>
                                            <div className="flex gap-2">
                                                <button
                                                    onClick={(e) => handleEditClick(entry, e)}
                                                    className="p-2 text-blue-600 bg-blue-50 rounded-lg"
                                                >
                                                    <Pencil className="w-4 h-4" />
                                                </button>
                                                <button
                                                    onClick={(e) => handleDeleteClick(entry, e)}
                                                    className="p-2 text-red-600 bg-red-50 rounded-lg"
                                                >
                                                    <Trash2 className="w-4 h-4" />
                                                </button>
                                            </div>
                                        </div>
                                        {entry.notes && (
                                            <div className="text-sm text-gray-600 mt-1">
                                                {entry.notes}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>

                            {weightEntries.length === 0 && (
                                <div className="px-6 py-8 text-center">
                                    <div className="inline-flex items-center justify-center p-2.5 bg-gray-50 rounded-lg mb-4">
                                        <Scale className="w-6 h-6 text-gray-400" />
                                    </div>
                                    <p className="text-gray-600 mb-2">No weight entries yet</p>
                                    <p className="text-sm text-gray-500">
                                        Start tracking your weight by adding your first entry
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/* Mobile-optimized delete confirmation modal */}
            {deleteConfirmation.isOpen && (
                <div className="fixed inset-0 z-[100] flex items-end sm:items-center justify-center sm:p-6">
                    {/* Backdrop */}
                    <div 
                        className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm transition-opacity duration-300" 
                        onClick={handleDeleteCancel}
                    />
                    
                    {/* Mobile-optimized container */}
                    <div 
                        className={`
                            relative
                            w-full
                            sm:w-[440px]
                            bg-white
                            sm:mx-auto
                            sm:rounded-xl
                            rounded-t-2xl
                            shadow-xl
                            transition-all
                            duration-300
                            max-h-[90vh]
                            sm:max-h-[85vh]
                            overflow-y-auto
                            pb-safe
                        `}
                    >
                        {/* Content */}
                        <div className="p-6 space-y-6">
                            {/* Header */}
                            <div className="flex items-start gap-3">
                                <div className="p-2 rounded-full bg-red-50 flex-shrink-0">
                                    <AlertTriangle className="w-6 h-6 text-red-600" />
                                </div>
                                <div>
                                    <h3 className="text-lg font-semibold text-gray-900">
                                        Delete Weight Entry
                                    </h3>
                                    <p className="mt-2 text-gray-600">
                                        Are you sure you want to delete this weight entry? This action cannot be undone.
                                    </p>
                                </div>
                            </div>
                            
                            {/* Buttons */}
                            <div className="flex flex-col-reverse sm:flex-row gap-3 sm:justify-end pt-2">
                                <button
                                    onClick={handleDeleteCancel}
                                    className="w-full sm:w-auto px-4 py-3 text-base font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-lg transition-colors"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleDeleteConfirm}
                                    className="w-full sm:w-auto px-4 py-3 text-base font-medium text-white bg-red-600 hover:bg-red-700 rounded-lg transition-colors"
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
// Main WeightDashboard Component
const WeightDashboard = () => {
    const [showEntryForm, setShowEntryForm] = useState(false);
    const [editingEntry, setEditingEntry] = useState(null);
    const [weightEntries, setWeightEntries] = useState([]);
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Firebase data fetching
    useEffect(() => {
        if (!auth.currentUser) return;

        const userRef = doc(db, 'users', auth.currentUser.uid);
        const weightHistoryRef = collection(db, 'users', auth.currentUser.uid, 'weightHistory');
        const weightQuery = query(weightHistoryRef, orderBy('date', 'desc'));

        const unsubscribeUser = onSnapshot(userRef, (userDoc) => {
            if (userDoc.exists()) {
                setUserData(userDoc.data());
            } else {
                setError('User data not found');
            }
        }, (error) => {
            console.error('Error fetching user data:', error);
            setError('Failed to load user data');
        });

        const unsubscribeWeight = onSnapshot(weightQuery, (snapshot) => {
            const entries = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
                date: doc.data().date.toDate(),
                weight: Number(doc.data().weight)
            }));
            setWeightEntries(entries);
            setLoading(false);
        }, (error) => {
            console.error('Error fetching weight entries:', error);
            setError('Failed to load weight data');
            setLoading(false);
        });

        return () => {
            unsubscribeUser();
            unsubscribeWeight();
        };
    }, []);

    const handleDeleteEntry = async (entry) => {
        try {
            const entryRef = doc(db, 'users', auth.currentUser.uid, 'weightHistory', entry.id);
            await deleteDoc(entryRef);
        } catch (error) {
            console.error('Error deleting weight entry:', error);
            setError('Failed to delete weight entry');
        }
    };

    // Calculate metrics
    const {
        currentWeight,
        startWeight,
        averageWeeklyChange,
        lastEntryDays,
        bmi,
        bmiCategory,
        totalWeightChange,
        weightChangePercentage,
        hasEnoughDataForWeeklyAverage,
        dailyCalories
    } = useMemo(() => {
        const current = weightEntries[0]?.weight || userData?.weight || 0;
        const start = weightEntries[weightEntries.length - 1]?.weight || current;
        const weeklyChange = calculateWeeklyChange(weightEntries, userData?.measurementPreference);
        const lastEntryDiff = weightEntries[0] ? differenceInDays(new Date(), weightEntries[0].date) : null;
        const totalDays = weightEntries.length >= 2 ? differenceInDays(weightEntries[0].date, weightEntries[weightEntries.length - 1].date) : 0;
        
        const bmiValue = calculateBMI(userData?.height, current);
        const bmiCat = getBMICategory(bmiValue);
        const totalChange = current - start;
        const changePercent = start > 0 ? (totalChange / start) * 100 : 0;

        const bmr = calculateBMR(
            current,
            userData?.height || 170,
            userData?.age || 30,
            userData?.gender || 'male',
            userData?.measurementPreference
        );
        const activityMultiplier = getActivityMultiplier(userData?.activityLevel || 'sedentary');
        const tdee = Math.round(bmr * activityMultiplier);

        return {
            currentWeight: current,
            startWeight: start,
            averageWeeklyChange: weeklyChange,
            lastEntryDays: lastEntryDiff,
            bmi: bmiValue,
            bmiCategory: bmiCat,
            totalWeightChange: totalChange,
            weightChangePercentage: changePercent,
            hasEnoughDataForWeeklyAverage: totalDays >= MINIMUM_DAYS_FOR_WEEKLY_AVERAGE,
            dailyCalories: tdee
        };
    }, [weightEntries, userData]);

    const targetWeight = useMemo(() => 
        calculateTargetWeight(userData?.height), [userData?.height]
    );

    if (loading) {
        return (
            <div className="min-h-screen bg-gradient-to-br from-gray-50 to-blue-50 p-6 flex items-center justify-center">
                <div className="bg-white p-6 rounded-xl shadow-md flex items-center gap-3">
                    <div className="animate-spin rounded-full h-5 w-5 border-2 border-blue-500 border-t-transparent"></div>
                    <span className="text-gray-600 font-medium">Loading weight data...</span>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="min-h-screen bg-gradient-to-br from-gray-50 to-blue-50 p-6 flex items-center justify-center">
                <Alert variant="error">{error}</Alert>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gradient-to-br from-gray-50 to-blue-50 p-4 sm:p-6">
            <div className="max-w-7xl mx-auto space-y-4 sm:space-y-6">
                {/* Header */}
                <div className="bg-white rounded-xl border border-gray-200 p-4 sm:p-6">
                    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
                        <div>
                            <h1 className="text-xl sm:text-2xl font-bold text-gray-800 flex items-center gap-2">
                                <Scale className="w-6 h-6 text-blue-500" />
                                Weight Management
                            </h1>
                            <p className="text-gray-500 mt-1">Track and monitor your weight journey</p>
                        </div>
                        <button
                            onClick={() => {
                                setEditingEntry(null);
                                setShowEntryForm(true);
                            }}
                            className="w-full sm:w-auto group relative inline-flex items-center justify-center gap-2 px-4 py-2.5 bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white rounded-lg transition-all duration-300 shadow-sm hover:shadow-md active:shadow-inner"
                        >
                            <div className="relative flex items-center justify-center">
                                <Scale className="w-4 h-4" />
                                <Plus className="w-3 h-3 absolute -top-1 -right-1 text-blue-200 animate-pulse" />
                            </div>
                            <span className="font-medium text-sm">
                                Add Today's Weight
                            </span>
                        </button>
                    </div>
                </div>

                {/* Alert */}
                {lastEntryDays > 2 && (
                    <Alert 
                        variant="warning"
                        icon={AlertCircle}
                    >
                        <div className="flex flex-col">
                            <span className="font-medium">Time for an update!</span>
                            <span>It's been {lastEntryDays} days since your last weight entry. Regular tracking helps maintain progress!</span>
                        </div>
                    </Alert>
                )}

                {/* Statistics Grid */}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3 sm:gap-4">
                    <StatCard 
                        icon={Scale}
                        label="Current Weight"
                        value={formatWeight(currentWeight, userData?.measurementPreference)}
                        subtext={`BMI: ${bmi.toFixed(1)} (${bmiCategory.category})`}
                        measurementPreference={userData?.measurementPreference}
                        variant="gradient"
                    />
                    <StatCard 
                        icon={totalWeightChange < 0 ? TrendingDown : TrendingUp}
                        label="Total Change"
                        value={formatWeight(Math.abs(totalWeightChange), userData?.measurementPreference)}
                        trend={weightChangePercentage.toFixed(1)}
                        subtext={weightEntries.length > 0 ? 
                            `Since ${format(weightEntries[weightEntries.length - 1]?.date || new Date(), 'MMM d, yyyy')}` :
                            'No previous entries'}
                        measurementPreference={userData?.measurementPreference}
                        variant={totalWeightChange < 0 ? 'success' : 'warning'}
                    />
                    <StatCard 
                        icon={Flame}
                        label="Weekly Average"
                        value={hasEnoughDataForWeeklyAverage ? averageWeeklyChange : null}
                        subtext={hasEnoughDataForWeeklyAverage ? 
                            (averageWeeklyChange < 0 ? 'Weight loss' : 'Weight gain') : 
                            null}
                        measurementPreference={userData?.measurementPreference}
                        variant="gradient"
                    />
                    <StatCard 
                        icon={Activity}
                        label="Daily Energy Expenditure"
                        value={`${dailyCalories.toLocaleString()} kcal`}
                        tooltip="Your total daily calorie burn based on activity level"
                        subtext={userData?.activityLevel ? `Activity level: ${userData.activityLevel}` : 'Update activity level in profile'}
                        onClick={() => window.location.href = '/settings/profile'}
                        variant="gradient"
                    />
                </div>

                {/* Progress Overview */}
                <ProgressCard
                    currentWeight={currentWeight}
                    targetWeight={targetWeight}
                    startWeight={startWeight}
                    measurementPreference={userData?.measurementPreference}
                />

                {/* Weight Graph */}
                {weightEntries.length > 0 && (
                    <div className="bg-white rounded-xl border border-gray-200 p-4 sm:p-6">
                        <WeightGraph 
                            weightEntries={weightEntries}
                            targetWeight={targetWeight}
                            measurementPreference={userData?.measurementPreference}
                        />
                    </div>
                )}

                {/* Weight History */}
                <CollapsibleWeightHistory 
                    weightEntries={weightEntries}
                    onEdit={(entry) => {
                        setEditingEntry(entry);
                        setShowEntryForm(true);
                    }}
                    onDelete={handleDeleteEntry}
                    measurementPreference={userData?.measurementPreference}
                />

{/* Weight Entry Form Modal */}
                {showEntryForm && (
                    <div className="fixed inset-0 z-50">
                        {/* Backdrop */}
                        <div 
                            className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm transition-opacity duration-300" 
                            onClick={() => {
                                setShowEntryForm(false);
                                setEditingEntry(null);
                            }}
                        />
                        
                        {/* Mobile-optimized container */}
                        <div className="relative min-h-screen sm:flex sm:items-center sm:p-4">
                            <div 
                                className={`
                                    w-full sm:w-[480px] 
                                    mx-auto
                                    transition-all duration-300
                                `}
                            >
                                <div className="bg-white shadow-xl overflow-hidden sm:rounded-xl">
                                    <div className="max-h-screen sm:max-h-[85vh] overflow-y-auto">
                                        <WeightEntryForm
                                            initialData={editingEntry}
                                            onSuccess={() => {
                                                setShowEntryForm(false);
                                                setEditingEntry(null);
                                            }}
                                            onCancel={() => {
                                                setShowEntryForm(false);
                                                setEditingEntry(null);
                                            }}
                                            measurementPreference={userData?.measurementPreference}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default WeightDashboard;