// src/firebase.js
import { initializeApp } from 'firebase/app';
import { initializeFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getStorage, connectStorageEmulator, ref, getDownloadURL } from 'firebase/storage';

// Validate required environment variables
const validateEnvVariables = () => {
    const requiredVars = [
        'REACT_APP_FIREBASE_API_KEY',
        'REACT_APP_FIREBASE_AUTH_DOMAIN',
        'REACT_APP_FIREBASE_PROJECT_ID',
        'REACT_APP_FIREBASE_STORAGE_BUCKET',
        'REACT_APP_FIREBASE_MESSAGING_SENDER_ID',
        'REACT_APP_FIREBASE_APP_ID'
    ];

    const missingVars = requiredVars.filter(varName => !process.env[varName]);
    
    if (missingVars.length > 0) {
        throw new Error(
            `Missing required environment variables: ${missingVars.join(', ')}\n` +
            'Make sure you have created a .env file with all required variables.'
        );
    }
};

// Run validation
validateEnvVariables();

// Initialize Firebase config with explicit storage bucket format
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: 'bioguard-b9ace.firebasestorage.app',
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services with custom settings
const auth = getAuth(app);
const storage = getStorage(app);
const db = initializeFirestore(app, {
    experimentalForceLongPolling: true,
    useFetchStreams: false
});

// Development environment setup with emulators
if (window.location.hostname === 'localhost') {
    try {
        // Connect to Auth emulator
        connectAuthEmulator(auth, 'http://127.0.0.1:9099', { disableWarnings: true });
        console.log('✅ Connected to Auth emulator');

        // Connect to Firestore emulator
        connectFirestoreEmulator(db, '127.0.0.1', 8080);
        console.log('✅ Connected to Firestore emulator');

        // Connect to Storage emulator
        connectStorageEmulator(storage, '127.0.0.1', 9199);
        console.log('✅ Connected to Storage emulator');

        console.log('🔥 Using Firebase emulators in development mode');
    } catch (error) {
        console.error('❌ Error connecting to Firebase emulators:', error);
        console.error('Make sure your emulators are running with: firebase emulators:start');
    }
} else {
    console.log('🚀 Using Firebase production services');
}

// Custom error handler for Firebase operations
const handleFirebaseError = (error) => {
    console.error('Firebase operation failed:', error);
    
    const errorMessages = {
        'storage/unauthorized': 'You are not authorized to access this resource.',
        'storage/canceled': 'Upload was cancelled.',
        'storage/unknown': 'An unknown error occurred.',
        'storage/object-not-found': 'File does not exist.',
        'storage/bucket-not-found': 'Storage bucket not found.',
        'storage/project-not-found': 'Project not found.',
        'storage/quota-exceeded': 'Quota exceeded.',
        'storage/unauthenticated': 'User is not authenticated.',
        'storage/invalid-checksum': 'File on the client does not match the checksum of the file received by the server.',
        'storage/retry-limit-exceeded': 'The maximum time limit on an operation has been exceeded.',
        'storage/invalid-event-name': 'Invalid event name provided.',
        'storage/invalid-url': 'Invalid URL provided.',
        'storage/invalid-argument': 'Invalid argument provided.',
        'storage/no-default-bucket': 'No default bucket has been set.',
        'storage/cannot-slice-blob': 'Cannot slice blob for upload.',
        'storage/server-file-wrong-size': 'Server recorded incorrect upload file size.'
    };

    return {
        code: error.code,
        message: errorMessages[error.code] || error.message
    };
};

// Storage helper functions
const getStorageErrorMessage = (error) => {
    return handleFirebaseError(error).message;
};

const getStorageDownloadURL = async (path) => {
    try {
        const storageRef = ref(storage, path);
        return await getDownloadURL(storageRef);
    } catch (error) {
        throw handleFirebaseError(error);
    }
};

// Export initialized Firebase instances and helper functions
export {
    app,
    db,
    auth,
    storage,
    handleFirebaseError,
    getStorageErrorMessage,
    getStorageDownloadURL
};