// src/components/comments/CommentSection.js
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useComments } from './hooks/useComments';
import CommentHeader from './components/CommentHeader';
import CommentInput from './components/CommentInput';
import VirtualizedCommentList from './components/VirtualizedCommentList';
import NotificationToast from './components/NotificationToast';
import EmptyComments from './components/EmptyComments';

const CommentSection = ({ 
  post = null,
  comments = [],
  currentUser,
  className = ''
}) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const {
    localComments,
    replyingTo,
    setReplyingTo,
    editingComment,
    setEditingComment,
    commentText,
    setCommentText,
    showReplies,
    setShowReplies,
    sortBy,
    setSortBy,
    commentFilter,
    setCommentFilter,
    isSubmitting,
    showNotification,
    notificationMessage,
    sortedAndFilteredComments,
    handleSubmitComment,
    handleDeleteComment,
    handleLikeComment,
    handleDislikeComment,
    handleReportComment,
    handleInputChange
  } = useComments({
    initialComments: comments,
    postId: post?.id || '',
    currentUser,
    onSuccess: (message) => {
      console.log('Comment action success:', message);
    },
    onError: (error) => {
      console.error('Comment action error:', error);
    }
  });

  const handleCommentAction = useCallback((actionType, commentId, data) => {
    switch (actionType) {
      case 'delete':
        handleDeleteComment(commentId);
        break;
      case 'like':
        handleLikeComment(commentId);
        break;
      case 'dislike':
        handleDislikeComment(commentId);
        break;
      case 'reply':
        setReplyingTo(commentId);
        const commentInput = document.querySelector('#comment-input-main');
        if (commentInput) {
          commentInput.focus();
          commentInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        break;
      case 'report':
        handleReportComment(commentId, data);
        break;
      default:
        console.warn('Unknown comment action:', actionType);
    }
  }, [handleDeleteComment, handleLikeComment, handleDislikeComment, handleReportComment, setReplyingTo]);

  if (!post) {
    return null;
  }

  return (
    <section className={`space-y-6 ${className}`}>
      <CommentHeader
        commentCount={localComments.length}
        commentFilter={commentFilter}
        setCommentFilter={setCommentFilter}
        sortBy={sortBy}
        setSortBy={setSortBy}
      />

      <CommentInput
        commentText={commentText}
        handleInputChange={handleInputChange}
        handleSubmitComment={handleSubmitComment}
        isSubmitting={isSubmitting}
        currentUser={currentUser}
        replyingTo={replyingTo ? localComments.find(c => c.id === replyingTo) : null}
        setReplyingTo={setReplyingTo}
        showEmojiPicker={showEmojiPicker}
        setShowEmojiPicker={setShowEmojiPicker}
        placeholder={replyingTo ? "Write a reply..." : "Write a comment..."}
      />

      {sortedAndFilteredComments.length > 0 ? (
        <VirtualizedCommentList
          comments={sortedAndFilteredComments}
          currentUser={currentUser}
          isAuthor={post.authorId === currentUser?.uid}
          editingComment={editingComment}
          setEditingComment={setEditingComment}
          showReplies={showReplies}
          setShowReplies={setShowReplies}
          onCommentAction={handleCommentAction}
        />
      ) : (
        <EmptyComments filter={commentFilter} />
      )}

      <NotificationToast
        message={notificationMessage}
        show={showNotification}
      />
    </section>
  );
};

export default CommentSection;