import React, { useState, useEffect } from 'react';
import { PostsService } from '../PostsService';
import { useAuth } from '../../../../contexts/AuthContext';

const PostEditor = ({ postId }) => {
  const { currentUser } = useAuth();
  const [post, setPost] = useState(null);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    content: '',
    category: ''
  });

  useEffect(() => {
    loadPost();
  }, [postId]);

  const loadPost = async () => {
    try {
      if (postId) {
        const postData = await PostsService.getPostById(postId);
        setPost(postData);
        setFormData({
          title: postData.title,
          content: postData.content,
          category: postData.category
        });
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const handleUpdatePost = async (e) => {
    e.preventDefault();
    try {
      if (!currentUser) {
        throw new Error('Must be signed in to update posts');
      }

      await PostsService.updatePost(postId, formData, currentUser.uid);
      await loadPost(); // Reload post
      setIsEditing(false);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleLike = async () => {
    try {
      if (!currentUser) {
        throw new Error('Must be signed in to like posts');
      }

      await PostsService.toggleLike(postId, currentUser.uid);
      await loadPost(); // Reload post to get updated likes
    } catch (err) {
      setError(err.message);
    }
  };

  const handleComment = async (text) => {
    try {
      if (!currentUser) {
        throw new Error('Must be signed in to comment');
      }

      await PostsService.addComment(postId, currentUser.uid, currentUser.email, text);
      await loadPost(); // Reload post to get updated comments
    } catch (err) {
      setError(err.message);
    }
  };

  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <div className="max-w-4xl mx-auto p-6">
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {error}
        </div>
      )}

      {isEditing ? (
        <form onSubmit={handleUpdatePost} className="space-y-4">
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
            placeholder="Title"
          />
          <textarea
            name="content"
            value={formData.content}
            onChange={handleInputChange}
            className="w-full p-2 border rounded min-h-[200px]"
            placeholder="Content"
          />
          <select
            name="category"
            value={formData.category}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
          >
            <option value="">Select Category</option>
            <option value="Health Tips">Health Tips</option>
            <option value="Medical Research">Medical Research</option>
            <option value="Wellness">Wellness</option>
            {/* Add more categories as needed */}
          </select>
          <div className="flex gap-2">
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Save
            </button>
            <button
              type="button"
              onClick={() => setIsEditing(false)}
              className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
            >
              Cancel
            </button>
          </div>
        </form>
      ) : (
        <div className="space-y-4">
          <h1 className="text-3xl font-bold">{post.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: post.content }} className="prose max-w-none" />
          
          <div className="flex items-center gap-4 pt-4">
            <button
              onClick={handleLike}
              className={`flex items-center gap-2 px-4 py-2 rounded ${
                post.likedBy?.includes(currentUser?.uid)
                  ? 'bg-blue-100 text-blue-600'
                  : 'bg-gray-100 hover:bg-gray-200'
              }`}
            >
              Like ({post.likes || 0})
            </button>
            
            {post.authorId === currentUser?.uid && (
              <button
                onClick={() => setIsEditing(true)}
                className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
              >
                Edit Post
              </button>
            )}
          </div>

          {/* Comments Section */}
          <div className="mt-8">
            <h2 className="text-2xl font-bold mb-4">Comments</h2>
            <div className="space-y-4">
              {post.comments?.map((comment, index) => (
                <div key={index} className="bg-gray-50 p-4 rounded">
                  <p className="text-gray-600 text-sm">{comment.authorEmail}</p>
                  <p>{comment.text}</p>
                </div>
              ))}
              
              {currentUser && (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    const text = e.target.comment.value;
                    if (text) {
                      handleComment(text);
                      e.target.comment.value = '';
                    }
                  }}
                  className="mt-4"
                >
                  <textarea
                    name="comment"
                    className="w-full p-2 border rounded"
                    placeholder="Write a comment..."
                  />
                  <button
                    type="submit"
                    className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                  >
                    Add Comment
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PostEditor;