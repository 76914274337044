import React, { useState, useMemo, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ChevronDown, Search, Pencil, ChevronRight, X, ArrowUp,
  List, Grid, TrendingUp, Clock, Filter, SortAsc, Tag
} from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import PostCard from './PostCard';
import { processContent } from './contentProcessor';
import ResponsiveCategoryNav from './components/ResponsiveCategoryNav';

// Category Navigation Component
const CategoryNav = ({
  categories,
  selectedCategory,
  onSelectCategory,
  onCreatePost,
  postCounts = {}
}) => {
  const [showMoreModal, setShowMoreModal] = useState(false);
  const [visibleCategories, setVisibleCategories] = useState([]);
  const [overflowCategories, setOverflowCategories] = useState([]);
  const containerRef = useRef(null);
  const tabsRef = useRef(new Map());

  useEffect(() => {
    const allCategories = [
      { 
        key: 'all', 
        label: 'All Topics', 
        count: Object.values(postCounts).reduce((a, b) => a + b, 0) 
      },
      ...Object.entries(categories).map(([key, { label, icon: Icon }]) => ({
        key,
        label,
        Icon,
        count: postCounts[key] || 0
      }))
    ];

    const updateVisibleCategories = () => {
      const width = window.innerWidth;
      let visibleCount;
      
      if (width < 640) { // sm
        visibleCount = 4;
      } else if (width < 768) { // md
        visibleCount = 6;
      } else if (width < 1024) { // lg
        visibleCount = 8;
      } else {
        visibleCount = 10;
      }

      setVisibleCategories(allCategories.slice(0, visibleCount));
      setOverflowCategories(allCategories.slice(visibleCount));
    };

    updateVisibleCategories();
    window.addEventListener('resize', updateVisibleCategories);
    return () => window.removeEventListener('resize', updateVisibleCategories);
  }, [categories, postCounts]);

  return (
    <div className="bg-white border-b border-gray-100">
      <div className="max-w-screen-2xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        {/* Header */}
        <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4 mb-6">
          <div>
            <h1 className="text-2xl sm:text-3xl font-bold text-gray-900">Community Insights</h1>
            <p className="text-sm sm:text-base text-gray-600 mt-1">
              Join the conversation with healthcare professionals
            </p>
          </div>
          
          <button
            onClick={onCreatePost}
            className="inline-flex items-center justify-center gap-2 px-6 py-2.5
              bg-blue-600 hover:bg-blue-700 text-white rounded-full
              transition-all duration-200 text-sm font-medium
              shadow-sm hover:shadow-md w-full sm:w-auto"
          >
            <Pencil className="w-4 h-4" />
            Write a Post
          </button>
        </div>

        {/* Category Grid */}
        <div className="relative" ref={containerRef}>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2">
            {visibleCategories.map(({ key, label, Icon, count }) => (
              <button
                key={key}
                ref={el => tabsRef.current.set(key, el)}
                onClick={() => onSelectCategory(key)}
                className={`
                  relative px-3 py-2 text-sm rounded-lg transition-all
                  hover:bg-gray-50 flex items-center gap-2
                  ${selectedCategory === key
                    ? 'text-blue-600 bg-blue-50 font-medium'
                    : 'text-gray-600'
                  }
                `}
              >
                {Icon && <Icon className="w-4 h-4 flex-shrink-0" />}
                <span className="truncate">{label}</span>
                {count > 0 && (
                  <span className={`
                    text-xs px-1.5 py-0.5 rounded-full ml-auto flex-shrink-0
                    ${selectedCategory === key
                      ? 'bg-blue-100 text-blue-600'
                      : 'bg-gray-100 text-gray-600'
                    }
                  `}>
                    {count > 999 ? '999+' : count}
                  </span>
                )}
              </button>
            ))}

            {overflowCategories.length > 0 && (
              <button
                onClick={() => setShowMoreModal(true)}
                className="px-3 py-2 text-sm text-gray-600 hover:text-gray-900
                  hover:bg-gray-50 rounded-lg flex items-center gap-2"
              >
                More Categories
                <ChevronRight className="w-4 h-4" />
              </button>
            )}
          </div>
        </div>

        {/* Categories Modal */}
        <AnimatePresence>
          {showMoreModal && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black/50 z-50 flex items-end sm:items-center justify-center p-4"
              onClick={() => setShowMoreModal(false)}
            >
              <motion.div
                initial={{ y: 50 }}
                animate={{ y: 0 }}
                exit={{ y: 50 }}
                onClick={e => e.stopPropagation()}
                className="bg-white rounded-t-2xl sm:rounded-2xl shadow-xl w-full max-w-lg max-h-[80vh] overflow-hidden"
              >
                <div className="p-4 border-b border-gray-100 flex items-center justify-between">
                  <h3 className="font-semibold text-gray-900">All Categories</h3>
                  <button
                    onClick={() => setShowMoreModal(false)}
                    className="p-1 hover:bg-gray-100 rounded-lg transition-colors"
                  >
                    <X className="w-5 h-5" />
                  </button>
                </div>
                <div className="p-4 overflow-y-auto max-h-[60vh]">
                  <div className="grid grid-cols-2 gap-2">
                    {[...visibleCategories, ...overflowCategories].map(({ key, label, Icon, count }) => (
                      <button
                        key={key}
                        onClick={() => {
                          onSelectCategory(key);
                          setShowMoreModal(false);
                        }}
                        className={`
                          px-3 py-2 text-sm rounded-lg transition-colors
                          flex items-center gap-2
                          ${selectedCategory === key
                            ? 'bg-blue-50 text-blue-600 font-medium'
                            : 'text-gray-600 hover:bg-gray-50'
                          }
                        `}
                      >
                        {Icon && <Icon className="w-4 h-4" />}
                        <span className="truncate">{label}</span>
                        {count > 0 && (
                          <span className={`
                            text-xs px-1.5 py-0.5 rounded-full ml-auto
                            ${selectedCategory === key
                              ? 'bg-blue-100 text-blue-600'
                              : 'bg-gray-100 text-gray-600'
                            }
                          `}>
                            {count > 999 ? '999+' : count}
                          </span>
                        )}
                      </button>
                    ))}
                  </div>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

// Advanced Search Component
const AdvancedSearch = ({ 
  searchQuery, 
  onSearchChange,
  selectedTags,
  onTagSelect,
  availableTags = []
}) => {
  const [showTagsDropdown, setShowTagsDropdown] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowTagsDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="relative flex-1">
      <div className="flex items-center gap-2">
        <div className="relative flex-1">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => onSearchChange(e.target.value)}
            placeholder="Search posts..."
            className="w-full px-4 py-2 pl-10 bg-gray-50 border border-gray-200 
              rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
          <Search className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
        </div>

        <div className="relative" ref={dropdownRef}>
          <button
            onClick={() => setShowTagsDropdown(!showTagsDropdown)}
            className="px-4 py-2 bg-white border border-gray-200 rounded-lg
              text-sm hover:bg-gray-50 transition-colors flex items-center gap-2"
          >
            <Tag className="w-4 h-4" />
            Tags
            <ChevronDown className={`w-4 h-4 transition-transform ${
              showTagsDropdown ? 'rotate-180' : ''
            }`} />
          </button>

          <AnimatePresence>
            {showTagsDropdown && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 10 }}
                className="absolute right-0 mt-2 w-64 bg-white rounded-lg shadow-lg 
                  border border-gray-200 py-2 z-10"
              >
                <div className="px-3 py-2 border-b border-gray-100">
                  <input
                    type="text"
                    placeholder="Search tags..."
                    className="w-full px-3 py-1.5 bg-gray-50 border border-gray-200 
                      rounded text-sm focus:ring-2 focus:ring-blue-500 
                      focus:border-transparent"
                  />
                </div>
                <div className="max-h-64 overflow-y-auto py-2">
                  {availableTags.map(tag => (
                    <button
                      key={tag}
                      onClick={() => onTagSelect(tag)}
                      className={`w-full px-3 py-2 text-left text-sm hover:bg-gray-50 
                        flex items-center gap-2 ${
                          selectedTags.includes(tag)
                            ? 'text-blue-600 bg-blue-50'
                            : 'text-gray-700'
                        }`}
                    >
                      <span className="flex-1">{tag}</span>
                      {selectedTags.includes(tag) && (
                        <span className="w-4 h-4 rounded-full bg-blue-100 flex items-center 
                          justify-center text-blue-600 text-xs">
                          ✓
                        </span>
                      )}
                    </button>
                  ))}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>

      {/* Selected Tags */}
      {selectedTags.length > 0 && (
        <div className="flex flex-wrap gap-2 mt-3">
          {selectedTags.map(tag => (
            <span
              key={tag}
              className="px-2 py-1 bg-blue-50 text-blue-600 rounded-full text-sm 
                flex items-center gap-1"
            >
              {tag}
              <button
                onClick={() => onTagSelect(tag)}
                className="w-4 h-4 rounded-full hover:bg-blue-100 
                  flex items-center justify-center"
              >
                ×
              </button>
            </span>
          ))}
          <button
            onClick={() => onTagSelect([])}
            className="px-2 py-1 text-sm text-gray-500 hover:text-gray-700"
          >
            Clear all
          </button>
        </div>
      )}
    </div>
  );
};

// View Mode Toggle Component
const ViewModeToggle = ({ viewMode, onViewModeChange }) => (
  <div className="flex items-center bg-gray-100 rounded-lg p-1">
    <button
      onClick={() => onViewModeChange('list')}
      className={`p-1.5 rounded ${
        viewMode === 'list'
          ? 'bg-white text-gray-900 shadow-sm'
          : 'text-gray-600 hover:text-gray-900'
      } transition-colors`}
      aria-label="List view"
    >
      <List className="w-5 h-5" />
    </button>
    <button
      onClick={() => onViewModeChange('grid')}
      className={`p-1.5 rounded ${
        viewMode === 'grid'
          ? 'bg-white text-gray-900 shadow-sm'
          : 'text-gray-600 hover:text-gray-900'
      } transition-colors`}
      aria-label="Grid view"
    >
      <Grid className="w-5 h-5" />
    </button>
  </div>
);

// Sort Options Component
const SortOptions = ({ value, onChange }) => (
  <select
    value={value}
    onChange={(e) => onChange(e.target.value)}
    className="px-4 py-2 bg-white border border-gray-200 rounded-lg text-sm
      focus:ring-2 focus:ring-blue-500 focus:border-transparent 
      appearance-none cursor-pointer"
  >
    <option value="latest">Latest</option>
    <option value="trending">Trending</option>
    <option value="discussed">Most Discussed</option>
    <option value="liked">Most Liked</option>
  </select>
);

// Empty State Component
const EmptyState = ({ onCreatePost, searchQuery }) => (
  <div className="text-center py-12">
    <div className="w-12 h-12 bg-blue-50 rounded-full flex items-center justify-center mx-auto mb-4">
      <Search className="w-6 h-6 text-blue-600" />
      </div>
    <h3 className="text-lg font-semibold text-gray-900 mb-2">
      {searchQuery ? 'No matching posts found' : 'No posts yet'}
    </h3>
    <p className="text-gray-500 max-w-md mx-auto mb-6">
      {searchQuery 
        ? "We couldn't find any posts matching your criteria. Try adjusting your filters or create a new post."
        : "Be the first to share your insights with the community!"
      }
    </p>
    <button
      onClick={onCreatePost}
      className="inline-flex items-center gap-2 px-6 py-3 
        bg-blue-600 hover:bg-blue-700 text-white rounded-full 
        transition-all duration-200 shadow-sm hover:shadow-md"
    >
      <Pencil className="w-5 h-5" />
      Create First Post
    </button>
  </div>
);

const PostList = ({ 
  posts = [], 
  categories = {},
  currentUser, 
  onLike,
  onBookmark,
  onCreatePost,
  onEdit,
  onDelete,
  onReport,
  onFilter
}) => {
  const navigate = useNavigate();
  const [viewMode, setViewMode] = useState('list');
  const [sortBy, setSortBy] = useState('latest');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [selectedTags, setSelectedTags] = useState([]);
  const [visiblePosts, setVisiblePosts] = useState(12);
  const [isLoading, setIsLoading] = useState(false);
  const [showScrollTop, setShowScrollTop] = useState(false);

  // Get unique tags from all posts
  const availableTags = useMemo(() => {
    const tagSet = new Set();
    posts.forEach(post => {
      post.tags?.forEach(tag => tagSet.add(tag));
    });
    return Array.from(tagSet).sort();
  }, [posts]);

  // Calculate post counts by category
  const postCounts = useMemo(() => {
    const counts = {};
    posts.forEach(post => {
      if (post.category) {
        counts[post.category] = (counts[post.category] || 0) + 1;
      }
    });
    return counts;
  }, [posts]);

  // Handle scroll to top visibility
  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.pageYOffset > 800);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Filter and sort posts
  const filteredAndSortedPosts = useMemo(() => {
    return posts
      .filter(post => {
        // Category filter
        if (selectedCategory !== 'all' && post.category !== selectedCategory) {
          return false;
        }

        // Tags filter
        if (selectedTags.length > 0 && 
            !selectedTags.every(tag => post.tags?.includes(tag))) {
          return false;
        }

        // Search query filter
        if (searchQuery) {
          const searchLower = searchQuery.toLowerCase();
          const contentText = processContent(post.content || '');
          return (
            post.title?.toLowerCase().includes(searchLower) ||
            post.subtitle?.toLowerCase().includes(searchLower) ||
            contentText.toLowerCase().includes(searchLower) ||
            post.tags?.some(tag => tag.toLowerCase().includes(searchLower)) ||
            post.authorEmail?.toLowerCase().includes(searchLower)
          );
        }

        return true;
      })
      .sort((a, b) => {
        switch (sortBy) {
          case 'trending':
            const aScore = (a.likes || 0) * 2 + (a.views || 0);
            const bScore = (b.likes || 0) * 2 + (b.views || 0);
            return bScore - aScore;
          case 'discussed':
            return (b.comments?.length || 0) - (a.comments?.length || 0);
          case 'liked':
            return (b.likes || 0) - (a.likes || 0);
          case 'latest':
          default:
            return new Date(b.createdAt) - new Date(a.createdAt);
        }
      });
  }, [posts, searchQuery, selectedCategory, selectedTags, sortBy]);

  const handleLoadMore = () => {
    setIsLoading(true);
    setTimeout(() => {
      setVisiblePosts(prev => prev + 12);
      setIsLoading(false);
    }, 500);
  };

  const handleTagSelect = (tag) => {
    setSelectedTags(prev => {
      if (Array.isArray(tag)) return []; // Clear all
      if (prev.includes(tag)) {
        return prev.filter(t => t !== tag);
      }
      return [...prev, tag];
    });
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Category Navigation */}
      <ResponsiveCategoryNav 
        categories={categories}
        selectedCategory={selectedCategory}
        onSelectCategory={setSelectedCategory}
        onCreatePost={onCreatePost}
        postCounts={postCounts}
      />
      

      {/* Main Content */}
      <div className="max-w-screen-2xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        {/* Search and View Controls */}
        <div className="bg-white rounded-xl shadow-sm mb-6">
          <div className="p-4 flex flex-col sm:flex-row gap-4 items-stretch sm:items-center">
            <AdvancedSearch 
              searchQuery={searchQuery}
              onSearchChange={setSearchQuery}
              selectedTags={selectedTags}
              onTagSelect={handleTagSelect}
              availableTags={availableTags}
            />

            <div className="flex items-center gap-3">
              <ViewModeToggle 
                viewMode={viewMode}
                onViewModeChange={setViewMode}
              />

              <SortOptions
                value={sortBy}
                onChange={setSortBy}
              />
            </div>
          </div>

          {/* Active Filters */}
          {(selectedCategory !== 'all' || selectedTags.length > 0 || searchQuery) && (
            <div className="px-4 py-3 border-t border-gray-100 flex flex-wrap gap-2 items-center">
              <span className="text-sm text-gray-500">Active filters:</span>
              
              {selectedCategory !== 'all' && (
                <span className="px-2 py-1 bg-blue-50 text-blue-600 rounded-full text-sm 
                  flex items-center gap-1">
                  {categories[selectedCategory]?.label}
                  <button
                    onClick={() => setSelectedCategory('all')}
                    className="w-4 h-4 rounded-full hover:bg-blue-100 
                      flex items-center justify-center"
                  >
                    ×
                  </button>
                </span>
              )}

              {selectedTags.map(tag => (
                <span
                  key={tag}
                  className="px-2 py-1 bg-blue-50 text-blue-600 rounded-full text-sm 
                    flex items-center gap-1"
                >
                  {tag}
                  <button
                    onClick={() => handleTagSelect(tag)}
                    className="w-4 h-4 rounded-full hover:bg-blue-100 
                      flex items-center justify-center"
                  >
                    ×
                  </button>
                </span>
              ))}

              {searchQuery && (
                <span className="px-2 py-1 bg-blue-50 text-blue-600 rounded-full text-sm 
                  flex items-center gap-1">
                  "{searchQuery}"
                  <button
                    onClick={() => setSearchQuery('')}
                    className="w-4 h-4 rounded-full hover:bg-blue-100 
                      flex items-center justify-center"
                  >
                    ×
                  </button>
                </span>
              )}

              <button
                onClick={() => {
                  setSelectedCategory('all');
                  setSelectedTags([]);
                  setSearchQuery('');
                }}
                className="px-2 py-1 text-sm text-gray-500 hover:text-gray-700"
              >
                Clear all filters
              </button>
            </div>
          )}
        </div>

        {/* Posts Grid/List */}
        {filteredAndSortedPosts.length > 0 ? (
          <>
            <div className={`
              ${viewMode === 'grid'
                ? 'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6'
                : 'space-y-6'
              }
            `}>
              {filteredAndSortedPosts
                .slice(0, visiblePosts)
                .map(post => (
                  <PostCard 
                    key={post.id}
                    post={post}
                    currentUser={currentUser}
                    onLike={onLike}
                    onBookmark={onBookmark}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    onReport={onReport}
                  />
                ))}
            </div>

            {/* Load More */}
            {filteredAndSortedPosts.length > visiblePosts && (
              <div className="flex justify-center mt-12">
                <button
                  onClick={handleLoadMore}
                  disabled={isLoading}
                  className="group px-6 py-3 bg-white rounded-full border border-gray-200
                    text-sm text-gray-600 hover:text-gray-900 shadow-sm hover:shadow-md
                    transition-all duration-200 flex items-center gap-2 disabled:opacity-50"
                >
                  {isLoading ? (
                    <>
                      <div className="w-4 h-4 border-2 border-gray-300 border-t-blue-600 
                        rounded-full animate-spin" />
                      <span>Loading more posts...</span>
                    </>
                  ) : (
                    <>
                      <span>Show more posts</span>
                      <ChevronDown className="w-4 h-4 group-hover:translate-y-0.5 
                        transition-transform" />
                    </>
                  )}
                </button>
              </div>
            )}
          </>
        ) : (
          <EmptyState 
            onCreatePost={onCreatePost}
            searchQuery={searchQuery}
          />
        )}
      </div>

      {/* Scroll to Top Button */}
      <AnimatePresence>
        {showScrollTop && (
          <motion.button
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            onClick={handleScrollToTop}
            className="fixed bottom-6 right-6 p-3 bg-white rounded-full shadow-lg 
              hover:shadow-xl border border-gray-200 text-gray-600 hover:text-gray-900 
              transition-all duration-200 hover:scale-110 z-50"
          >
            <ArrowUp className="w-5 h-5" />
          </motion.button>
        )}
      </AnimatePresence>
    </div>
  );
};

export default PostList;