import React, { useState, useEffect } from 'react';
import { Timer, Flame, Sparkles } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import StartFast from '../Components/StartFast/StartFast';
import CurrentFastProgress from '../Components/CurrentFastProgress/CurrentFastProgress';
import FastHistory from '../Components/FastHistory/FastHistory';

const TimerSection = ({ 
  activeFast,
  elapsedTime,
  targetHours,
  remainingTime,
  isLoading,
  onStartFast,
  onStopFast,
  onCustomDuration,
  onDeleteFast,
  onEditFast,
  onAdjustTime,
  userWeight,
  measurementSystem = 'metric',
  fastingHistory = []
}) => {
  const [isInitialized, setIsInitialized] = useState(false);

  // Array of motivational messages for active fasts
  const motivationalMessages = [
    {
      title: "Stay Strong, Keep Going!",
      description: "Every hour brings you closer to your wellness goals"
    },
    {
      title: "You're Making Progress!",
      description: "Your dedication to fasting is transforming your health"
    },
    {
      title: "Keep Up the Great Work!",
      description: "Your body is thanking you for this healing time"
    },
    {
      title: "Embrace the Journey!",
      description: "Each moment of fasting is a step towards better health"
    },
    {
      title: "You've Got This!",
      description: "Your commitment to wellness is inspiring"
    }
  ];

  // Get a random message but change it only when fast status changes
  const getMessage = () => {
    if (!activeFast) {
      if (!fastingHistory.length) {
        return {
          title: "Welcome to Your Fasting Journey",
          description: "Start your first fast to begin tracking your wellness progress"
        };
      }
      return {
        title: "Ready for Your Next Fast?",
        description: "Choose your fasting duration and continue your wellness journey"
      };
    }

    // Use elapsed time to pick a message (changes every few hours)
    const messageIndex = Math.floor((elapsedTime / (1000 * 60 * 60 * 4)) % motivationalMessages.length);
    return motivationalMessages[messageIndex];
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsInitialized(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  if (!isInitialized) {
    return (
      <div className="space-y-8">
        <div className="animate-pulse">
          <div className="h-32 bg-gray-100 rounded-xl mb-8" />
          <div className="h-64 bg-gray-100 rounded-xl" />
        </div>
      </div>
    );
  }

  const currentMessage = getMessage();

  return (
    <div className="space-y-8">
      <AnimatePresence mode="wait">
        <motion.div
          key={currentMessage.title} // This ensures animation triggers when message changes
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.2 }}
        >
          <div className={`border rounded-xl p-4 flex items-start gap-3 ${
            activeFast 
              ? 'bg-blue-50 border-blue-100'
              : fastingHistory.length 
                ? 'bg-purple-50 border-purple-100'
                : 'bg-green-50 border-green-100'
          }`}>
            {activeFast ? (
              <Flame className="w-6 h-6 text-orange-500 flex-shrink-0 mt-1" />
            ) : fastingHistory.length ? (
              <Sparkles className="w-6 h-6 text-purple-500 flex-shrink-0 mt-1" />
            ) : (
              <Timer className="w-6 h-6 text-green-500 flex-shrink-0 mt-1" />
            )}
            <div>
              <h3 className={`font-medium ${
                activeFast 
                  ? 'text-blue-900'
                  : fastingHistory.length 
                    ? 'text-purple-900'
                    : 'text-green-900'
              }`}>
                {currentMessage.title}
              </h3>
              <p className={`text-sm mt-1 ${
                activeFast 
                  ? 'text-blue-700'
                  : fastingHistory.length 
                    ? 'text-purple-700'
                    : 'text-green-700'
              }`}>
                {currentMessage.description}
              </p>
            </div>
          </div>
        </motion.div>
      </AnimatePresence>

      {/* Main Timer Display */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        {activeFast ? (
          <CurrentFastProgress
            activeFast={activeFast}
            elapsedTime={elapsedTime}
            targetHours={targetHours}
            remainingTime={remainingTime}
            onStopFast={onStopFast}
            onAdjustTime={onAdjustTime}
            userWeight={userWeight}
            measurementSystem={measurementSystem}
          />
        ) : (
          <StartFast
            onStartFast={onStartFast}
            isLoading={isLoading}
          />
        )}
      </motion.div>

      {/* Fasting History */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3, delay: 0.1 }}
      >
        <FastHistory
          fasts={fastingHistory}
          measurementSystem={measurementSystem}
          onDeleteFast={onDeleteFast}
          onEditFast={onEditFast}
        />
      </motion.div>
    </div>
  );
};

export default TimerSection;