import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../../../../firebase';
import {
  Stethoscope,
  FlaskConical,
  Scan,
  Pill,
  Syringe,
  AlertTriangle,
  ChevronDown,
  Clock,
  Calendar,
  Loader2,
  CheckSquare,
  Tag,
  Target
} from 'lucide-react';
import { processUserRecommendations } from './Components/RecommendationsLogic';

const RecommendationDashboard = () => {
  // States
  const [recommendations, setRecommendations] = useState([]);
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedRecs, setExpandedRecs] = useState(new Set());

  // Get auth instance
  const auth = getAuth();

  // Constants
  const actionTypeIcons = {
    physician_visit: Stethoscope,
    lab_test: FlaskConical,
    imaging: Scan,
    vaccination: Syringe,
    supplement: Pill
  };

  // Fetch user profile
  const fetchUserProfile = async (userId) => {
    try {
      const userDocRef = doc(db, 'users', userId);
      const userDocSnap = await getDoc(userDocRef);
      
      if (userDocSnap.exists()) {
        return userDocSnap.data();
      } else {
        throw new Error('User profile not found');
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
      throw error;
    }
  };

  const loadRecommendations = async () => {
    try {
      setLoading(true);
      setError(null);

      // First fetch user profile
      const profile = await fetchUserProfile(auth.currentUser.uid);
      setUserProfile(profile);

      // Then process recommendations with the profile
      const { matchingRecommendations } = await processUserRecommendations(
        auth.currentUser.uid,
        profile
      );

      setRecommendations(matchingRecommendations);

    } catch (err) {
      console.error('Error loading recommendations:', err);
      setError(err.message || 'Failed to load recommendations');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (auth.currentUser) {
      loadRecommendations();
    }
  }, [auth.currentUser]);

  const calculateDueIn = (recommendation) => {
    if (!recommendation.frequency) return null;
    
    const [number, period] = recommendation.frequency.split(' ');
    const today = new Date();
    const assignedDate = recommendation.assignedAt?.toDate() || today;
    
    const daysInPeriod = {
      day: 1,
      days: 1,
      week: 7,
      weeks: 7,
      month: 30,
      months: 30,
      year: 365,
      years: 365
    };

    const daysPerPeriod = daysInPeriod[period.toLowerCase()] || 30;
    const totalDays = Number(number) * daysPerPeriod;
    const daysPassed = Math.floor((today - assignedDate) / (1000 * 60 * 60 * 24));
    
    return totalDays - daysPassed;
  };

  const toggleExpand = (recId) => {
    setExpandedRecs(prev => {
      const newSet = new Set(prev);
      if (newSet.has(recId)) {
        newSet.delete(recId);
      } else {
        newSet.add(recId);
      }
      return newSet;
    });
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <Loader2 className="w-8 h-8 animate-spin mx-auto text-blue-500" />
          <p className="mt-2 text-gray-600">Loading your recommendations...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-6">
      {/* Header */}
      <div className="mb-6">
        <h1 className="text-2xl font-bold text-gray-900">Health Recommendations</h1>
        <p className="mt-1 text-sm text-gray-500">Your personalized health action items</p>
      </div>

      {error && (
        <div className="mb-6 bg-red-50 border-l-4 border-red-400 p-4 rounded-lg">
          <div className="flex">
            <AlertTriangle className="w-5 h-5 text-red-400" />
            <div className="ml-3">
              <p className="text-sm text-red-600">{error}</p>
            </div>
          </div>
        </div>
      )}

      {/* Recommendations List */}
      <div className="space-y-4">
        {recommendations.length === 0 ? (
          <div className="text-center py-8 bg-gray-50 rounded-lg">
            <div className="w-12 h-12 bg-gray-100 rounded-full flex items-center justify-center mx-auto mb-3">
              <CheckSquare className="w-6 h-6 text-gray-400" />
            </div>
            <h3 className="text-base font-medium text-gray-900">No Active Recommendations</h3>
            <p className="mt-1 text-sm text-gray-500">You're all caught up! Check back later for new recommendations.</p>
          </div>
        ) : (
          recommendations.map((rec) => {
            const Icon = actionTypeIcons[rec.actionType] || Stethoscope;
            const isExpanded = expandedRecs.has(rec.id);
            const dueIn = calculateDueIn(rec);
            
            return (
              <div
                key={rec.id}
                className="bg-white rounded-xl border border-gray-200 overflow-hidden"
              >
                <div className="p-4">
                  {/* Header */}
                  <div 
                    className="flex items-start space-x-3 cursor-pointer"
                    onClick={() => toggleExpand(rec.id)}
                  >
                    <div className={`p-2 rounded-lg ${
                      dueIn < 0 ? 'bg-red-50' : 'bg-blue-50'
                    }`}>
                      <Icon className={`w-5 h-5 ${
                        dueIn < 0 ? 'text-red-500' : 'text-blue-500'
                      }`} />
                    </div>
                    <div className="flex-1 min-w-0">
                      <div className="flex flex-wrap items-center gap-2 mb-1">
                        <h3 className="text-base font-semibold text-gray-900 break-words">
                          {rec.actionDetails}
                        </h3>
                        <span className={`px-2 py-0.5 rounded-full text-xs font-medium ${
                          rec.priority >= 4 ? 'bg-red-100 text-red-800' :
                          rec.priority >= 2 ? 'bg-yellow-100 text-yellow-800' :
                          'bg-green-100 text-green-800'
                        }`}>
                          Priority {rec.priority}
                        </span>
                      </div>
                      <p className="text-sm text-gray-600">{rec.instruction}</p>
                      
                      {/* Tags */}
                      <div className="flex flex-wrap gap-2 mt-2">
                        {rec.selectedRiskFactors?.map(factor => (
                          <span key={factor} className="inline-flex items-center px-2 py-0.5 rounded-md bg-gray-100 text-xs text-gray-600">
                            <Tag className="w-3 h-3 mr-1" />
                            {factor}
                          </span>
                        ))}
                        {rec.selectedHealthGoals?.map(goal => (
                          <span key={goal} className="inline-flex items-center px-2 py-0.5 rounded-md bg-purple-100 text-xs text-purple-600">
                            <Target className="w-3 h-3 mr-1" />
                            {goal}
                          </span>
                        ))}
                      </div>
                    </div>
                    <ChevronDown 
                      className={`w-5 h-5 text-gray-400 transform transition-transform flex-shrink-0 ${isExpanded ? 'rotate-180' : ''}`}
                    />
                  </div>

                  {/* Expanded Content */}
                  {isExpanded && (
                    <div className="mt-4 pl-10">
                      <div className="bg-gray-50 rounded-lg p-4">
                        <h4 className="text-sm font-medium text-gray-900 mb-2">
                          Why is this important?
                        </h4>
                        <p className="text-sm text-gray-600">{rec.explanation}</p>
                        
                        <div className="mt-4 space-y-2">
                          <div className="flex items-center">
                            <Clock className="w-4 h-4 text-gray-400 mr-2" />
                            <span className="text-sm text-gray-600">
                              Frequency: Every {rec.frequency}
                            </span>
                          </div>
                          <div className="flex items-center">
                            <Calendar className="w-4 h-4 text-gray-400 mr-2" />
                            <span className="text-sm text-gray-600">
                              Due in: {dueIn ? `${dueIn} days` : 'Not specified'}
                            </span>
                          </div>
                        </div>

                        <div className="mt-4">
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              // Handle completion
                            }}
                            className="w-full inline-flex items-center justify-center px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors duration-200"
                          >
                            <CheckSquare className="w-4 h-4 mr-2" />
                            Mark as Completed
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })
        )}
      </div>

      {/* Disclaimer */}
      <div className="mt-6 bg-yellow-50 border-l-4 border-yellow-400 p-4 rounded-lg">
        <div className="flex">
          <AlertTriangle className="w-5 h-5 text-yellow-400" />
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">Important Note</h3>
            <p className="text-sm text-yellow-700">
              These recommendations are personalized based on your health profile and goals. 
              Always consult with your healthcare provider before starting any new health routine.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecommendationDashboard;