import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { 
  Clock, Trash2, Target, Activity,
  Award, TrendingUp, HeartPulse, Timer,
  AlertCircle, Edit2, Filter, Search,
  BarChart3, Info, Scale, Flame,
  Plus, ChevronRight, X, ChevronDown,
  ChevronUp, ArrowDown, Calendar,
  Check
} from 'lucide-react';
import { format, differenceInDays, isWithinInterval, startOfWeek, endOfWeek } from 'date-fns';
import { motion, AnimatePresence } from 'framer-motion';

// Utility functions
const getColor = (value) => {
  const intensity = Math.floor((value / 100) * 255);
  return `rgb(${255 - intensity}, ${255 - intensity}, 255)`;
};

const getFastingPhase = (hours) => {
  if (hours < 12) return { name: 'Glycogen Depletion', color: 'blue', description: 'Initial fasting stage' };
  if (hours < 18) return { name: 'Fat Burning', color: 'green', description: 'Enhanced fat oxidation' };
  if (hours < 24) return { name: 'Ketosis', color: 'purple', description: 'Deep ketosis state' };
  if (hours < 48) return { name: 'Autophagy', color: 'indigo', description: 'Cellular repair active' };
  return { name: 'Deep Healing', color: 'orange', description: 'Maximum autophagy' };
};

const getHealthBenefits = (hours) => {
  return [
    { threshold: 12, text: 'HGH ↑200%', icon: TrendingUp, active: hours >= 12 },
    { threshold: 16, text: 'Ketones ↑', icon: Activity, active: hours >= 16 },
    { threshold: 18, text: 'Insulin ↓', icon: ArrowDown, active: hours >= 18 },
    { threshold: 24, text: 'Autophagy ↑', icon: HeartPulse, active: hours >= 24 },
    { threshold: 48, text: 'Stem Cells ↑', icon: Target, active: hours >= 48 },
  ];
};
// Modal Component
const Modal = ({ isOpen, onClose, title, children }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      // Scroll to top when modal opens
      window.scrollTo(0, 0);
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 z-[9999] bg-black/60 backdrop-blur-sm overflow-y-auto"
      onClick={(e) => {
        if (e.target === e.currentTarget) onClose();
      }}
    >
      <div className="min-h-screen flex items-start justify-center p-4">
        <div 
          className="relative bg-white rounded-2xl shadow-xl w-full max-w-lg mt-8 mb-8"
          onClick={(e) => e.stopPropagation()}
        >
          {/* Modal Header */}
          <div className="flex justify-between items-center p-4 border-b border-gray-100">
            <h3 className="text-lg font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
              {title}
            </h3>
            <button
              onClick={onClose}
              className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            >
              <X className="w-5 h-5 text-gray-500" />
            </button>
          </div>
          
          {/* Modal Body */}
          <div className="p-4">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};



// Action Button Component
const ActionButton = ({ 
  icon: Icon, 
  label, 
  onClick, 
  variant = 'primary', 
  size = 'md', 
  className = '', 
  disabled = false 
}) => {
  const baseClasses = "flex items-center justify-center gap-2 rounded-lg font-medium transition-all duration-200";
  
  const variants = {
    primary: `bg-gradient-to-r from-blue-500 to-purple-500 text-white hover:from-blue-600 hover:to-purple-600 
              ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`,
    secondary: "bg-gray-100 text-gray-600 hover:bg-gray-200",
    danger: "bg-red-50 text-red-600 hover:bg-red-100",
    ghost: "bg-transparent hover:bg-gray-100",
    success: "bg-green-50 text-green-600 hover:bg-green-100"
  };
  
  const sizes = {
    sm: "px-2 py-1 text-sm",
    md: "px-3 py-2 md:px-4 md:py-2",
    lg: "px-4 py-2 md:px-6 md:py-3 text-lg"
  };

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`${baseClasses} ${variants[variant]} ${sizes[size]} ${className}`}
    >
      <Icon className={size === 'sm' ? "w-3 h-3" : "w-4 h-4"} />
      {label && <span className="hidden md:inline">{label}</span>}
    </button>
  );
};

// Quick Stats Badge Component
const StatBadge = ({ icon: Icon, label, value, trend, className = '' }) => {
  return (
    <div className={`flex items-center gap-2 px-3 py-1.5 bg-gray-50 rounded-lg ${className}`}>
      <Icon className="w-4 h-4 text-gray-500" />
      <div className="flex items-baseline gap-1">
        <span className="text-xs md:text-sm font-medium text-gray-600">{label}:</span>
        <span className="text-xs md:text-sm font-bold text-gray-900">{value}</span>
      </div>
      {trend && (
        <span className={`text-xs font-medium ${
          trend > 0 ? 'text-green-600' : 'text-red-600'
        }`}>
          {trend > 0 ? '+' : ''}{trend}%
        </span>
      )}
    </div>
  );
};
// History Card Component
const HistoryCard = ({ fast, measurementSystem, onDeleteFast, onEditFast }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editedEndTime, setEditedEndTime] = useState(fast.endTime?.slice(0, 16) || '');
  const [validationError, setValidationError] = useState('');

  const startTime = new Date(fast.startTime);
  const endTime = new Date(fast.endTime);
  const duration = (endTime - startTime) / (1000 * 60 * 60);
  const calories = Math.round(duration * (fast.userWeight * 0.4));
  const fatLoss = ((calories / 3500) * (measurementSystem === 'metric' ? 0.453592 : 1)).toFixed(2);
  const isSuccessful = duration >= fast.targetHours;
  
  const phase = getFastingPhase(duration);
  const healthBenefits = getHealthBenefits(duration);

  const handleEditSubmit = () => {
    const newEndTime = new Date(editedEndTime);
    const startTimeDate = new Date(fast.startTime);

    if (newEndTime <= startTimeDate) {
      setValidationError('End time must be after start time');
      return;
    }

    if (newEndTime > new Date()) {
      setValidationError('End time cannot be in the future');
      return;
    }

    onEditFast(fast.id, {
      ...fast,
      endTime: newEndTime.toISOString()
    });
    setShowEditModal(false);
    setValidationError('');
  };

  const resetEditModal = () => {
    setEditedEndTime(fast.endTime?.slice(0, 16) || '');
    setValidationError('');
    setShowEditModal(false);
  };

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-white rounded-xl shadow-lg hover:shadow-xl transition-all overflow-hidden"
    >
      {/* Card Header */}
      <div className="p-4 cursor-pointer" onClick={() => setIsExpanded(!isExpanded)}>
        <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-3">
          <div className="flex items-center gap-3">
            <div className="p-2 rounded-lg bg-blue-50">
              <Timer className="w-5 h-5 text-blue-500" />
            </div>
            <div>
              <h4 className="text-base md:text-lg font-semibold text-gray-900">
                {format(startTime, 'MMM d, yyyy')}
              </h4>
              <div className="flex items-center gap-2 mt-1">
                <Clock className="w-4 h-4 text-gray-400" />
                <p className="text-xs md:text-sm text-gray-500">
                  {format(startTime, 'h:mm a')} - {format(endTime, 'h:mm a')}
                </p>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between md:justify-end gap-2">
            <div className={`px-3 py-1.5 rounded-lg text-sm font-semibold ${
              isSuccessful 
                ? 'bg-green-50 text-green-600' 
                : 'bg-blue-50 text-blue-600'
            }`}>
              {duration.toFixed(1)}h
            </div>
            <div className="flex gap-1 md:gap-2">
              <ActionButton
                icon={Edit2}
                variant="ghost"
                size="sm"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowEditModal(true);
                }}
              />
              <ActionButton
                icon={Trash2}
                variant="ghost"
                size="sm"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDeleteModal(true);
                }}
              />
              <ActionButton
                icon={isExpanded ? ChevronUp : ChevronDown}
                variant="ghost"
                size="sm"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Expanded Content */}
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <div className="px-4 pb-4 space-y-4">
              <div className="h-px bg-gray-100" />

              {/* Metrics Grid */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <motion.div
                  whileHover={{ scale: 1.02 }}
                  className="p-4 bg-gradient-to-br from-orange-50 to-orange-100 rounded-xl"
                >
                  <div className="flex items-center gap-2 mb-2">
                    <Flame className="w-5 h-5 text-orange-500" />
                    <span className="text-sm font-medium text-orange-700">
                      Calories Burned
                    </span>
                  </div>
                  <div className="text-xl md:text-2xl font-bold text-orange-900">{calories}</div>
                </motion.div>

                <motion.div
                  whileHover={{ scale: 1.02 }}
                  className="p-4 bg-gradient-to-br from-green-50 to-green-100 rounded-xl"
                >
                  <div className="flex items-center gap-2 mb-2">
                    <Scale className="w-5 h-5 text-green-500" />
                    <span className="text-sm font-medium text-green-700">
                      Fat Loss
                    </span>
                  </div>
                  <div className="text-xl md:text-2xl font-bold text-green-900">
                    {fatLoss} {measurementSystem === 'metric' ? 'kg' : 'lbs'}
                  </div>
                </motion.div>
              </div>

              {/* Health Benefits */}
              <div className="space-y-2">
                <h5 className="text-sm font-medium text-gray-600">Health Benefits</h5>
                <div className="flex flex-wrap gap-2">
                  {healthBenefits.map((benefit, index) => (
                    <div
                      key={index}
                      className={`px-3 py-1.5 rounded-full flex items-center gap-1.5
                                ${benefit.active 
                                  ? 'bg-purple-50 text-purple-700' 
                                  : 'bg-gray-50 text-gray-400'}`}
                    >
                      <benefit.icon className="w-4 h-4" />
                      <span className="text-xs md:text-sm font-medium">
                        {benefit.text}
                      </span>
                    </div>
                  ))}
                </div>
              </div>

              {/* Phase Information */}
              <div className="bg-gray-50 rounded-lg p-4">
                <div className="flex items-center gap-2 mb-2">
                  <Info className="w-5 h-5 text-gray-500" />
                  <span className="font-medium text-gray-700">Current Phase</span>
                </div>
                <p className="text-xs md:text-sm text-gray-600">{phase.description}</p>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

{/* Edit Modal */}
<Modal
  isOpen={showEditModal}
  onClose={() => setShowEditModal(false)}
  title="Edit Fast End Time"
>
  <div className="space-y-4">
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-1">
        Start Time (Read-only)
      </label>
      <div className="flex items-center gap-2 bg-gray-50 px-3 py-2 rounded-lg text-gray-500">
        <Clock className="w-5 h-5" />
        <span className="text-sm">{format(startTime, 'MMM d, yyyy h:mm a')}</span>
      </div>
    </div>

    <div>
      <label className="block text-sm font-medium text-gray-700 mb-1">
        End Time
      </label>
      <input
        type="datetime-local"
        value={editedEndTime}
        onChange={(e) => setEditedEndTime(e.target.value)}
        className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
      />
    </div>

    {validationError && (
      <div className="flex items-start gap-2 text-red-600 bg-red-50 p-3 rounded-lg">
        <AlertCircle className="w-5 h-5 mt-0.5" />
        <span className="text-sm">{validationError}</span>
      </div>
    )}

    <div className="flex justify-end gap-3">
      <button
        onClick={() => setShowEditModal(false)}
        className="px-4 py-2 text-sm font-medium text-gray-600 bg-gray-100 rounded-lg hover:bg-gray-200"
      >
        Cancel
      </button>
      <button
        onClick={handleEditSubmit}
        className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-lg hover:bg-blue-600"
      >
        Save Changes
      </button>
    </div>
  </div>
</Modal>


      {/* Delete Modal */}
<Modal
  isOpen={showDeleteModal}
  onClose={() => setShowDeleteModal(false)}
  title="Delete Fast"
>
  <div className="space-y-4">
    <div className="bg-red-50 rounded-xl p-4">
      <div className="flex gap-3">
        <AlertCircle className="w-6 h-6 text-red-500 shrink-0" />
        <div>
          <h4 className="font-medium text-red-700">Delete this fast?</h4>
          <p className="text-sm text-red-600 mt-1">
            This action cannot be undone.
          </p>
        </div>
      </div>
    </div>

    <div className="flex justify-end gap-3">
      <button
        onClick={() => setShowDeleteModal(false)}
        className="px-4 py-2 text-sm font-medium text-gray-600 bg-gray-100 rounded-lg hover:bg-gray-200"
      >
        Cancel
      </button>
      <button
        onClick={() => {
          onDeleteFast(fast.id);
          setShowDeleteModal(false);
        }}
        className="px-4 py-2 text-sm font-medium text-white bg-red-500 rounded-lg hover:bg-red-600"
      >
        Delete
      </button>
    </div>
  </div>
</Modal>
    </motion.div>
  );
};
// Analytics Panel Component
const AnalyticsPanel = ({ fasts, isExpanded }) => {
  const stats = useMemo(() => {
    if (!fasts.length) return null;

    const now = new Date();
    const thisWeek = {
      start: startOfWeek(now),
      end: endOfWeek(now)
    };

    return fasts.reduce((acc, fast) => {
      const startTime = new Date(fast.startTime);
      const endTime = new Date(fast.endTime);
      const duration = (endTime - startTime) / (1000 * 60 * 60);
      const isSuccessful = duration >= fast.targetHours;
      const isThisWeek = isWithinInterval(startTime, thisWeek);

      return {
        totalFasts: acc.totalFasts + 1,
        successfulFasts: acc.successfulFasts + (isSuccessful ? 1 : 0),
        totalHours: acc.totalHours + duration,
        longestFast: Math.max(acc.longestFast, duration),
        averageHours: ((acc.totalHours + duration) / (acc.totalFasts + 1)).toFixed(2),
        thisWeekFasts: acc.thisWeekFasts + (isThisWeek ? 1 : 0),
        thisWeekHours: acc.thisWeekHours + (isThisWeek ? duration : 0),
        streakCount: isSuccessful ? acc.streakCount + 1 : 0,
      };
    }, {
      totalFasts: 0,
      successfulFasts: 0,
      totalHours: 0,
      longestFast: 0,
      averageHours: 0,
      thisWeekFasts: 0,
      thisWeekHours: 0,
      streakCount: 0,
    });
  }, [fasts]);

  if (!stats) return null;

  const metrics = [
    {
      label: 'Success Rate',
      value: `${Math.round((stats.successfulFasts / stats.totalFasts) * 100)}%`,
      icon: Award,
      color: 'green',
      description: 'Completed vs target'
    },
    {
      label: 'Longest Fast',
      value: `${stats.longestFast.toFixed(1)}h`,
      icon: Timer,
      color: 'blue',
      description: 'Personal record'
    },
    {
      label: 'Average',
      value: `${stats.averageHours}h`,
      icon: Clock,
      color: 'purple',
      description: 'Per fast'
    },
    {
      label: 'Streak',
      value: stats.streakCount,
      icon: Flame,
      color: 'orange',
      description: 'Current streak'
    }
  ];

  return (
    <div className={`grid grid-cols-2 md:grid-cols-4 gap-3 md:gap-4 mb-6`}>
      {metrics.map((metric, index) => (
        <motion.div
          key={metric.label}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: index * 0.1 }}
          className="bg-white rounded-xl p-3 md:p-6 shadow-lg hover:shadow-xl transition-all group"
        >
          <div className="w-10 h-10 md:w-12 md:h-12 rounded-xl bg-gray-50 
                        flex items-center justify-center mb-3 
                        group-hover:scale-110 transition-transform">
            <metric.icon className="w-5 h-5 md:w-6 md:h-6 text-gray-500" />
          </div>
          <div className="space-y-1">
            <p className="text-xs md:text-sm font-medium text-gray-500">{metric.label}</p>
            <p className="text-lg md:text-2xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
              {metric.value}
            </p>
            <p className="text-xs text-gray-400 hidden md:block">{metric.description}</p>
          </div>
        </motion.div>
      ))}
    </div>
  );
};

// Fast Filters Component
const FastFilters = ({ filterCriteria, setFilterCriteria }) => {
  const handleClearFilters = () => {
    setFilterCriteria({
      dateRange: 'all',
      duration: 'all',
      success: 'all',
      searchTerm: '',
    });
  };

  const hasActiveFilters = filterCriteria.dateRange !== 'all' || 
                          filterCriteria.duration !== 'all' || 
                          filterCriteria.success !== 'all' ||
                          filterCriteria.searchTerm !== '';

  return (
    <div className="bg-white rounded-xl p-3 md:p-4 shadow-lg mb-6 space-y-3 md:space-y-4">
      {/* Search and Filters Grid */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-3 md:gap-4">
        {/* Search Input - Full width on mobile */}
        <div className="relative col-span-1 md:col-span-2">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
          <input
            type="text"
            placeholder="Search by date..."
            value={filterCriteria.searchTerm}
            onChange={(e) => setFilterCriteria(prev => ({
              ...prev,
              searchTerm: e.target.value
            }))}
            className="w-full pl-10 pr-4 py-2.5 rounded-xl border border-gray-200 
                     focus:ring-2 focus:ring-blue-500 focus:border-blue-500
                     text-sm md:text-base"
          />
        </div>

        {/* Filters - Stack on mobile */}
        <select
          value={filterCriteria.dateRange}
          onChange={(e) => setFilterCriteria(prev => ({
            ...prev,
            dateRange: e.target.value
          }))}
          className="w-full pl-4 pr-10 py-2.5 rounded-xl border border-gray-200 
                   focus:ring-2 focus:ring-blue-500 focus:border-blue-500
                   appearance-none cursor-pointer text-sm md:text-base"
        >
          <option value="all">All Time</option>
          <option value="week">This Week</option>
          <option value="month">This Month</option>
        </select>

        <select
          value={filterCriteria.duration}
          onChange={(e) => setFilterCriteria(prev => ({
            ...prev,
            duration: e.target.value
          }))}
          className="w-full pl-4 pr-10 py-2.5 rounded-xl border border-gray-200 
                   focus:ring-2 focus:ring-blue-500 focus:border-blue-500
                   appearance-none cursor-pointer text-sm md:text-base"
        >
          <option value="all">All Durations</option>
          <option value="short">Short (≤16h)</option>
          <option value="medium">Medium (16-24h)</option>
          <option value="long">Long (>24h)</option>
        </select>

        <select
          value={filterCriteria.success}
          onChange={(e) => setFilterCriteria(prev => ({
            ...prev,
            success: e.target.value
          }))}
          className="w-full pl-4 pr-10 py-2.5 rounded-xl border border-gray-200 
                   focus:ring-2 focus:ring-blue-500 focus:border-blue-500
                   appearance-none cursor-pointer text-sm md:text-base"
        >
          <option value="all">All Status</option>
          <option value="successful">Successful</option>
          <option value="incomplete">Incomplete</option>
        </select>
      </div>

      {/* Clear Filters Button */}
      {hasActiveFilters && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="flex justify-end"
        >
          <ActionButton
            icon={Filter}
            label="Clear filters"
            variant="ghost"
            onClick={handleClearFilters}
            className="w-full md:w-auto justify-center"
          />
        </motion.div>
      )}
    </div>
  );
};
// Main FastHistory Component
const FastHistory = ({ 
  fasts = [], // Add default value here
  measurementSystem, 
  onDeleteFast, 
  onEditFast 
}) => {
  const [expanded, setExpanded] = useState(false);
  const [filterCriteria, setFilterCriteria] = useState({
    dateRange: 'all',
    duration: 'all',
    success: 'all',
    searchTerm: '',
  });

  // Mobile viewport detection
  const [isMobile, setIsMobile] = useState(typeof window !== 'undefined' && window.innerWidth < 768);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Filter fasts based on criteria
  const filteredFasts = useMemo(() => {
    return fasts.filter(fast => {
      const startTime = new Date(fast.startTime);
      const endTime = new Date(fast.endTime);
      const duration = (endTime - startTime) / (1000 * 60 * 60);
      const isSuccessful = duration >= fast.targetHours;

      // Date range filter
      if (filterCriteria.dateRange !== 'all') {
        const now = new Date();
        const daysAgo = {
          'week': 7,
          'month': 30,
        }[filterCriteria.dateRange];

        if (daysAgo && differenceInDays(now, startTime) > daysAgo) {
          return false;
        }
      }

      // Duration filter
      if (filterCriteria.duration !== 'all') {
        const durationRanges = {
          'short': { min: 0, max: 16 },
          'medium': { min: 16, max: 24 },
          'long': { min: 24, max: Infinity }
        }[filterCriteria.duration];

        if (durationRanges && (duration < durationRanges.min || duration >= durationRanges.max)) {
          return false;
        }
      }

      // Success filter
      if (filterCriteria.success !== 'all') {
        if (filterCriteria.success === 'successful' && !isSuccessful) return false;
        if (filterCriteria.success === 'incomplete' && isSuccessful) return false;
      }

      // Search term filter
      if (filterCriteria.searchTerm) {
        const searchDate = format(startTime, 'MMMM d, yyyy').toLowerCase();
        if (!searchDate.includes(filterCriteria.searchTerm.toLowerCase())) {
          return false;
        }
      }

      return true;
    });
  }, [fasts, filterCriteria]);

  const hasActiveFilters = filterCriteria.dateRange !== 'all' || 
                          filterCriteria.duration !== 'all' || 
                          filterCriteria.success !== 'all' ||
                          filterCriteria.searchTerm !== '';

  return (
    <div className="mt-6 px-4 md:px-0">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="bg-gradient-to-br from-gray-50 via-blue-50 to-purple-50 rounded-3xl shadow-xl 
                  p-4 md:p-6 transition-all duration-200"
      >
        {/* Header - Always visible */}
        <div 
          className="flex flex-col md:flex-row md:items-center justify-between gap-4 cursor-pointer"
          onClick={() => setExpanded(!expanded)}
        >
          <div className="flex items-center gap-4">
            <div className="p-3 bg-gradient-to-r from-blue-500 to-purple-500 rounded-2xl shadow-lg">
              <Calendar className="w-6 h-6 text-white" />
            </div>
            <div>
              <h2 className="text-xl md:text-2xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
                Fasting History
              </h2>
              <div className="flex items-center gap-3 mt-1">
                <span className="text-sm md:text-base text-gray-600">
                  {filteredFasts.length} {filteredFasts.length === 1 ? 'fast' : 'fasts'} recorded
                </span>
              </div>
            </div>
          </div>
          <ActionButton
            icon={expanded ? ChevronUp : ChevronDown}
            variant="ghost"
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              setExpanded(!expanded);
            }}
          />
        </div>

        {/* Collapsible Content */}
        <AnimatePresence>
          {expanded && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="mt-6"
            >
              {/* Analytics Panel */}
              <AnalyticsPanel fasts={fasts} isExpanded={expanded} />

              {/* Filters */}
              <FastFilters 
                filterCriteria={filterCriteria} 
                setFilterCriteria={setFilterCriteria}
              />

              {/* Fasts List */}
              <div className="space-y-4">
                {filteredFasts.length === 0 ? (
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="text-center py-8 md:py-12 bg-white rounded-xl shadow-lg"
                  >
                    <div className="w-16 h-16 md:w-20 md:h-20 mx-auto mb-4 md:mb-6 rounded-full 
                                bg-gradient-to-br from-blue-50 to-purple-50 
                                flex items-center justify-center">
                      {hasActiveFilters ? (
                        <Filter className="w-8 h-8 md:w-10 md:h-10 text-blue-500" />
                      ) : (
                        <Timer className="w-8 h-8 md:w-10 md:h-10 text-blue-500" />
                      )}
                    </div>
                    <h3 className="text-lg md:text-xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 
                               bg-clip-text text-transparent mb-2">
                      {hasActiveFilters ? 'No matching fasts found' : 'Begin Your Fasting Journey'}
                    </h3>
                    <p className="text-sm md:text-base text-gray-600 max-w-md mx-auto px-4">
                      {hasActiveFilters ? 
                        'Try adjusting your filters to see more results' : 
                        'Start your first fast to begin tracking your progress!'
                      }
                    </p>
                  </motion.div>
                ) : (
                  <>
                    {filteredFasts.map((fast) => (
                      <HistoryCard
                        key={fast.id}
                        fast={fast}
                        measurementSystem={measurementSystem}
                        onDeleteFast={onDeleteFast}
                        onEditFast={onEditFast}
                      />
                    ))}
                    
                    {/* Quick Stats Footer */}
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      className="mt-6 pt-6 border-t border-gray-200"
                    >
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-6">
                        <StatBadge
                          icon={Clock}
                          label="Average Duration"
                          value={`${(filteredFasts.reduce((acc, fast) => {
                            const duration = (new Date(fast.endTime) - new Date(fast.startTime)) / (1000 * 60 * 60);
                            return acc + duration;
                          }, 0) / filteredFasts.length).toFixed(1)}h`}
                        />
                        <StatBadge
                          icon={Target}
                          label="Completion Rate"
                          value={`${Math.round((filteredFasts.filter(fast => {
                            const duration = (new Date(fast.endTime) - new Date(fast.startTime)) / (1000 * 60 * 60);
                            return duration >= fast.targetHours;
                          }).length / filteredFasts.length) * 100)}%`}
                        />
                        <StatBadge
                          icon={Activity}
                          label="Total Hours"
                          value={`${(filteredFasts.reduce((acc, fast) => {
                            const duration = (new Date(fast.endTime) - new Date(fast.startTime)) / (1000 * 60 * 60);
                            return acc + duration;
                          }, 0)).toFixed(1)}h`}
                          className="hidden md:flex"
                        />
                      </div>
                    </motion.div>
                  </>
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </div>
  );
};

// PropTypes
FastHistory.propTypes = {
  fasts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      startTime: PropTypes.string.isRequired,
      endTime: PropTypes.string.isRequired,
      targetHours: PropTypes.number.isRequired,
      userWeight: PropTypes.number.isRequired,
    })
  ),
  measurementSystem: PropTypes.oneOf(['metric', 'imperial']).isRequired,
  onDeleteFast: PropTypes.func.isRequired,
  onEditFast: PropTypes.func.isRequired,
};


export default FastHistory;