// ElectrolyteData.js
export const ELECTROLYTE_DATA = {
    sodium: {
      min: 500,
      max: 2300,
      unit: 'mg',
      description: 'Essential for nerve conduction, muscle contraction, and fluid balance',
      deficiencySigns: [
        'Headache',
        'Fatigue',
        'Muscle weakness',
        'Irritability',
        'Nausea',
        'Poor concentration'
      ],
      excessSigns: [
        'Increased thirst',
        'Fluid retention',
        'High blood pressure',
        'Stomach upset'
      ],
      foodSources: [
        { name: 'Pink Himalayan salt', amount: '1/4 tsp', content: '500mg' },
        { name: 'Sea salt', amount: '1/4 tsp', content: '560mg' },
        { name: 'Bone broth', amount: '1 cup', content: '860mg' }
      ],
      supplements: [
        {
          name: 'Salt water mixture',
          dosage: '1/4 tsp in 500ml water',
          timing: 'Sip throughout the day',
          notes: 'Add a squeeze of lemon for taste if desired'
        }
      ],
      safetyNotes: [
        'Monitor blood pressure when supplementing',
        'Divide intake throughout the day',
        'Reduce intake if experiencing bloating'
      ]
    },
    potassium: {
      min: 2000,
      max: 3500,
      unit: 'mg',
      description: 'Critical for heart function, muscle contractions, and cellular operations',
      deficiencySigns: [
        'Muscle cramps',
        'Heart palpitations',
        'Fatigue',
        'Muscle weakness',
        'Tingling or numbness'
      ],
      excessSigns: [
        'Stomach upset',
        'Irregular heartbeat',
        'Tingling in hands and feet',
        'Weakness'
      ],
      foodSources: [
        { name: 'Sugar-free electrolyte water', amount: '500ml', content: '200mg' },
        { name: 'Bone broth', amount: '1 cup', content: '600mg' }
      ],
      supplements: [
        {
          name: 'Potassium chloride',
          dosage: 'As directed by healthcare provider',
          timing: 'Divided doses with water',
          notes: 'Only supplement under medical supervision'
        }
      ],
      safetyNotes: [
        'Never supplement without medical guidance',
        'High-dose supplementation can be dangerous',
        'Monitor heart rhythm if known cardiac issues'
      ]
    },
    magnesium: {
      min: 200,
      max: 400,
      unit: 'mg',
      description: 'Vital for energy production, muscle function, and nervous system health',
      deficiencySigns: [
        'Muscle twitches',
        'Mental fog',
        'Fatigue',
        'Headaches',
        'Difficulty sleeping'
      ],
      excessSigns: [
        'Diarrhea',
        'Nausea',
        'Stomach cramps',
        'Low blood pressure'
      ],
      foodSources: [
        { name: 'Magnesium water', amount: '500ml', content: '80mg' },
        { name: 'Sugar-free electrolyte drink', amount: '500ml', content: '120mg' }
      ],
      supplements: [
        {
          name: 'Magnesium citrate',
          dosage: '100-200mg',
          timing: 'Evening, with water',
          notes: 'Most bioavailable form'
        },
        {
          name: 'Magnesium glycinate',
          dosage: '100-200mg',
          timing: 'Evening, with water',
          notes: 'Gentle on stomach'
        }
      ],
      safetyNotes: [
        'Start with low doses',
        'May have laxative effect',
        'Avoid if kidney problems'
      ]
    }
  };
  
  export const SUPPLEMENTATION_PROTOCOLS = {
    electrolytes: {
      sodium: {
        phases: {
          preparation: {
            timing: 'Pre-fast',
            recommendation: 'Regular dietary intake',
            dosage: 'Normal diet',
            notes: 'No additional supplementation needed'
          },
          early: {
            timing: 'As needed',
            recommendation: 'Light salt intake if exercising',
            dosage: '1/4 tsp salt in water if needed',
            notes: 'Only if experiencing symptoms or exercising'
          },
          moderate: {
            timing: '2-3 times daily',
            recommendation: 'Pink salt or sea salt in water',
            dosage: {
              minimal: '1/8 tsp in water',
              standard: '1/4 tsp in water',
              active: '1/2 tsp in water'
            },
            schedule: [
              { time: 'Morning', amount: '1/4 tsp in 500ml water' },
              { time: 'Afternoon', amount: '1/8 tsp in 500ml water' },
              { time: 'Evening', amount: '1/8 tsp in 500ml water' }
            ]
          },
          extended: {
            timing: '3-4 times daily',
            recommendation: 'Structured salt supplementation',
            dosage: {
              minimal: '1/4 tsp total daily',
              standard: '1/2 tsp total daily',
              active: '3/4 tsp total daily'
            },
            schedule: [
              { time: 'Early morning', amount: '1/4 tsp in 500ml water' },
              { time: 'Midday', amount: '1/4 tsp in 500ml water' },
              { time: 'Evening', amount: '1/4 tsp in 500ml water' }
            ]
          }
        },
        contraindications: [
          'High blood pressure',
          'Heart failure',
          'Kidney disease',
          'Edema'
        ]
      },
      magnesium: {
        phases: {
          preparation: {
            timing: 'Pre-fast',
            recommendation: 'Regular dietary intake',
            dosage: 'Normal diet',
            notes: 'Consider starting supplementation'
          },
          early: {
            timing: 'Evening',
            recommendation: 'Magnesium citrate or glycinate',
            dosage: {
              minimal: '100mg',
              standard: '200mg',
              active: '300mg'
            },
            schedule: [
              { time: 'Evening', amount: '200mg with water' }
            ]
          },
          moderate: {
            timing: 'Twice daily',
            recommendation: 'Divided doses',
            dosage: {
              minimal: '200mg daily',
              standard: '300mg daily',
              active: '400mg daily'
            },
            schedule: [
              { time: 'Morning', amount: '150mg with water' },
              { time: 'Evening', amount: '150mg with water' }
            ]
          },
          extended: {
            timing: 'Twice daily',
            recommendation: 'Regular supplementation',
            dosage: {
              minimal: '300mg daily',
              standard: '400mg daily',
              active: '500mg daily'
            },
            schedule: [
              { time: 'Morning', amount: '200mg with water' },
              { time: 'Evening', amount: '200mg with water' }
            ]
          }
        },
        contraindications: [
          'Kidney problems',
          'Severe digestive issues',
          'Certain medications'
        ]
      }
    },
    supplementTiming: {
      spacing: [
        {
          supplements: ['magnesium', 'calcium'],
          minimumGap: 2,
          note: 'Take these minerals separately for better absorption'
        },
        {
          supplements: ['salt', 'magnesium'],
          minimumGap: 1,
          note: 'Space out electrolyte supplements'
        }
      ],
      optimalTimes: {
        sodium: 'Morning and early afternoon',
        potassium: 'Under medical supervision only',
        magnesium: 'Evening, before bed',
        calcium: 'Afternoon'
      }
    }
  };
  
  export const SAFETY_THRESHOLDS = {
    minimal: {
      hours: 24,
      status: 'safe',
      message: 'Regular hydration and mineral intake recommended'
    },
    moderate: {
      hours: 48,
      status: 'monitor',
      message: 'Begin electrolyte supplementation as recommended'
    },
    high: {
      hours: 72,
      status: 'warning',
      message: 'Careful electrolyte monitoring required'
    },
    extreme: {
      hours: 96,
      status: 'danger',
      message: 'Medical supervision recommended'
    }
  };