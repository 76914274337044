import React, { createContext, useContext, useState, useEffect } from 'react';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { differenceInYears } from 'date-fns';

const RecommendationsContext = createContext();

export const RecommendationsProvider = ({ children }) => {
    const [recommendations, setRecommendations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filters, setFilters] = useState({
        status: 'all', // 'all', 'pending', 'completed'
        type: 'all',   // 'all', 'physician_visit', 'lab_test', etc.
    });

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                loadUserRecommendations();
            } else {
                setRecommendations([]);
                setLoading(false);
            }
        });

        return () => unsubscribe();
    }, []);

    const loadUserRecommendations = async () => {
        try {
            setLoading(true);
            setError(null);
            const user = auth.currentUser;
            if (!user) return;

            // Get user's profile data
            const userDocRef = doc(db, 'users', user.uid);
            const userDocSnap = await getDoc(userDocRef);
            
            if (!userDocSnap.exists()) {
                setError('User profile not found');
                return;
            }

            const userData = userDocSnap.data();
            
            // Calculate user's age
            const age = differenceInYears(
                new Date(),
                new Date(userData.dateOfBirth)
            );
            
            // Get all recommendations
            const recommendationsSnapshot = await getDocs(collection(db, 'recommendations'));
            const allRecommendations = recommendationsSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            
            // Get user's recommendation status
            const userRecsSnapshot = await getDocs(query(
                collection(db, 'userRecommendations'),
                where('userId', '==', user.uid)
            ));

            const userRecStatus = {};
            userRecsSnapshot.docs.forEach(doc => {
                userRecStatus[doc.data().recommendationId] = {
                    status: doc.data().status,
                    completedAt: doc.data().completedAt?.toDate(),
                    id: doc.id
                };
            });
            
            // Filter recommendations for user
            const matchingRecommendations = allRecommendations.filter(rec => {
                const matchesGender = rec.gender === 'all' || rec.gender === userData.gender;
                const matchesAge = age >= parseInt(rec.minAge) && age <= parseInt(rec.maxAge);
                const hasMatchingRiskFactor = rec.selectedRiskFactors.some(
                    factor => userData.selectedRiskFactors.includes(factor)
                );
                const hasMatchingGoal = rec.selectedHealthGoals.some(
                    goal => userData.selectedHealthGoals.includes(goal)
                );
                
                return matchesGender && matchesAge && 
                       (hasMatchingRiskFactor || hasMatchingGoal);
            });
            
            // Combine recommendations with status
            const recommendationsWithStatus = matchingRecommendations.map(rec => ({
                ...rec,
                status: userRecStatus[rec.id]?.status || 'pending',
                completedAt: userRecStatus[rec.id]?.completedAt,
                userRecId: userRecStatus[rec.id]?.id
            }));
            
            setRecommendations(recommendationsWithStatus);
        } catch (err) {
            console.error('Error loading recommendations:', err);
            setError('Failed to load recommendations');
        } finally {
            setLoading(false);
        }
    };

    const getFilteredRecommendations = () => {
        return recommendations.filter(rec => {
            if (filters.status !== 'all' && rec.status !== filters.status) return false;
            if (filters.type !== 'all' && rec.actionType !== filters.type) return false;
            return true;
        });
    };

    const value = {
        recommendations: getFilteredRecommendations(),
        allRecommendations: recommendations,
        loading,
        error,
        filters,
        setFilters,
        refreshRecommendations: loadUserRecommendations
    };

    return (
        <RecommendationsContext.Provider value={value}>
            {children}
        </RecommendationsContext.Provider>
    );
};

export const useRecommendations = () => {
    const context = useContext(RecommendationsContext);
    if (!context) {
        throw new Error('useRecommendations must be used within a RecommendationsProvider');
    }
    return context;
};