import React, { useCallback, useEffect, useState, forwardRef } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import { MenuBar } from './MenuBar';
import { FloatingFormatBar, InsertMenu, TableMenu } from './FloatingMenus';
import { editorExtensions } from './extensions';
import { Camera, X, Save, Undo, Redo, AlertTriangle } from 'lucide-react';
import debounce from 'lodash/debounce';

// Custom Alert Component
const Alert = ({ children, onClose }) => (
  <div className="fixed bottom-4 left-4 bg-amber-50 border border-amber-200 rounded-lg p-4 shadow-lg max-w-md">
    <div className="flex items-start gap-3">
      <AlertTriangle className="w-5 h-5 text-amber-600 flex-shrink-0 mt-0.5" />
      <div className="flex-1 text-sm text-amber-800">
        {children}
      </div>
      {onClose && (
        <button
          onClick={onClose}
          className="p-1 hover:bg-amber-100 rounded-full text-amber-600"
        >
          <X className="w-4 h-4" />
        </button>
      )}
    </div>
  </div>
);

const RichTextEditor = forwardRef(({
  content,
  onChange,
  placeholder = 'Start writing...',
  readOnly = false,
  onImageUpload,
  autoSave = true
}, ref) => {
  const [imageUploadProgress, setImageUploadProgress] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [wordCount, setWordCount] = useState(0);
  const [lastSaved, setLastSaved] = useState(null);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [showUnsavedWarning, setShowUnsavedWarning] = useState(false);

  // Initialize editor with extended configuration
  const editor = useEditor({
    extensions: editorExtensions(placeholder),
    content,
    editable: !readOnly,
    autofocus: 'end',
    editorProps: {
      attributes: {
        class: 'prose prose-lg max-w-none focus:outline-none min-h-[400px] px-8 py-4',
        spellcheck: 'true',
      },
    },
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      handleContentChange(html);
      
      // Update word count
      const text = editor.state.doc.textContent;
      const words = text.trim().split(/\s+/).filter(word => word.length > 0);
      setWordCount(words.length);
      
      setUnsavedChanges(true);
      setShowUnsavedWarning(true);
    },
  });

  // Debounced content change handler
  const handleContentChange = useCallback(
    debounce((html) => {
      onChange(html);
      if (autoSave) {
        setIsSaving(true);
        // Simulate auto-save delay
        setTimeout(() => {
          setIsSaving(false);
          setLastSaved(new Date());
          setUnsavedChanges(false);
          setShowUnsavedWarning(false);
        }, 800);
      }
    }, 500),
    [onChange, autoSave]
  );

  // Image upload handling
  const handleImageUpload = useCallback(
    async (file) => {
      if (!onImageUpload || !editor) return;

      try {
        setImageUploadProgress(0);
        const url = await onImageUpload(file, (progress) => {
          setImageUploadProgress(progress);
        });
        
        if (url) {
          editor.chain()
            .focus()
            .setImage({ 
              src: url,
              alt: file.name,
              title: file.name,
            })
            .run();
        }
      } catch (error) {
        console.error('Failed to upload image:', error);
      } finally {
        setImageUploadProgress(null);
      }
    },
    [editor, onImageUpload]
  );

  // Drag and drop handling
  const handleDragEnter = useCallback((e) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    setIsDragging(false);
  }, []);

  const handleDrop = useCallback(
    (e) => {
      e.preventDefault();
      setIsDragging(false);
      
      const files = Array.from(e.dataTransfer?.files || []);
      const images = files.filter(file => file.type.startsWith('image/'));
      
      images.forEach(handleImageUpload);
    },
    [handleImageUpload]
  );

  // Save warning on page leave
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (unsavedChanges) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [unsavedChanges]);

  if (!editor) {
    return null;
  }

  return (
    <div 
      className="relative border border-gray-200 rounded-lg overflow-hidden bg-white shadow-sm"
      ref={ref}
    >
      <MenuBar editor={editor} />
      
      {/* Editor Area */}
      <div 
        onDragEnter={handleDragEnter}
        onDragOver={(e) => e.preventDefault()}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        className="relative"
      >
        <EditorContent editor={editor} />
        <FloatingFormatBar editor={editor} />
        <InsertMenu editor={editor} />
        <TableMenu editor={editor} />

        {/* Drag Overlay */}
        {isDragging && (
          <div className="absolute inset-0 bg-blue-50/90 border-2 border-dashed border-blue-500 rounded-lg flex items-center justify-center">
            <div className="text-blue-600 text-lg font-medium">
              Drop images here
            </div>
          </div>
        )}
      </div>

      {/* Status Bar */}
      <div className="border-t border-gray-200 px-4 py-2 flex items-center justify-between text-sm text-gray-500">
        <div className="flex items-center gap-4">
          <span>{wordCount} words</span>
          <div className="w-px h-4 bg-gray-300" />
          <div className="flex items-center gap-2">
            <button
              onClick={() => editor.chain().focus().undo().run()}
              disabled={!editor.can().undo()}
              className="p-1 hover:bg-gray-100 rounded disabled:opacity-50"
            >
              <Undo className="w-4 h-4" />
            </button>
            <button
              onClick={() => editor.chain().focus().redo().run()}
              disabled={!editor.can().redo()}
              className="p-1 hover:bg-gray-100 rounded disabled:opacity-50"
            >
              <Redo className="w-4 h-4" />
            </button>
          </div>
        </div>
        
        <div className="flex items-center gap-2">
          {isSaving && (
            <span className="text-blue-600 flex items-center gap-1">
              <Save className="w-4 h-4 animate-spin" />
              Saving...
            </span>
          )}
          {lastSaved && !isSaving && !unsavedChanges && (
            <span className="text-green-600">
              Saved {new Intl.RelativeTimeFormat().format(
                Math.round((lastSaved - new Date()) / 1000 / 60),
                'minute'
              )}
            </span>
          )}
          {unsavedChanges && !isSaving && (
            <span className="text-amber-600 flex items-center gap-1">
              <AlertTriangle className="w-4 h-4" />
              Unsaved changes
            </span>
          )}
        </div>
      </div>

      {/* Upload Progress */}
      {imageUploadProgress !== null && (
        <div className="fixed bottom-4 right-4 bg-white rounded-lg shadow-lg border border-gray-200 p-3 transition-all duration-300 transform translate-y-0 opacity-100">
          <div className="flex items-center gap-3">
            <Camera className="w-4 h-4 text-blue-600 animate-pulse" />
            <div className="flex-1">
              <div className="w-48 h-1.5 bg-gray-200 rounded-full overflow-hidden">
                <div 
                  className="h-full bg-blue-600 transition-all duration-300"
                  style={{ width: `${imageUploadProgress}%` }}
                />
              </div>
            </div>
            <span className="text-sm text-gray-600 tabular-nums">
              {Math.round(imageUploadProgress)}%
            </span>
            <button 
              onClick={() => setImageUploadProgress(null)}
              className="p-1 hover:bg-gray-100 rounded-full text-gray-400 hover:text-gray-600"
            >
              <X className="w-4 h-4" />
            </button>
          </div>
        </div>
      )}

      {/* Custom Unsaved Changes Warning */}
      {showUnsavedWarning && (
        <Alert onClose={() => setShowUnsavedWarning(false)}>
          You have unsaved changes. These changes will be lost if you leave the page.
        </Alert>
      )}
    </div>
  );
});

RichTextEditor.displayName = 'RichTextEditor';

export default RichTextEditor;