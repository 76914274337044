// UserRoutes.jsx
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import RegisteredDashboardLayout from '../components/user/StructureDashboard/RegisteredDashboardLayout';
import NonRegisteredDashboardLayout from '../components/user/StructureDashboard/NonRegisteredDashboardLayout';
import AuthPage from '../components/user/Authorization/AuthPage';
import ProfileCompletion from '../components/user/Authorization/ProfileCompletion';
import UpdateProfile from '../components/user/Authorization/UpdateProfile';
import Community from '../components/user/Posts/Community';
import PostView from '../components/user/Posts/components/PostView/PostView';
import PostEditor from '../components/user/Posts/components/PostEditor';
import WeightDashboard from '../components/user/GoalsDashboard/WeightManagement/WeightDashboard';
import Fasting from '../components/user/GoalsDashboard/Fasting/Fasting';
import RecommendationDashboard from '../components/user/GoalsDashboard/RecommendationSystem/RecommendationDashboard';
import FeatureRequests from '../components/user/FeatureRequests/FeatureRequests';
import ProtectedRoute from './ProtectedRoute';

const ROUTES = {
  AUTH: '/auth',
  PROFILE_COMPLETION: '/profile-completion',
  DASHBOARD: '/dashboard',
  PREVIEW: '/preview',
  SETTINGS: '/settings'
};

// Shared layout wrapper that handles both authenticated and non-authenticated states
const LayoutWrapper = ({ isPreview = false }) => (
  <ProtectedRoute allowPublic={true}>
    {(status) => {
      const Layout = status?.isRegistered ? RegisteredDashboardLayout : NonRegisteredDashboardLayout;
      return <Layout profileStatus={status} isPreview={isPreview} />;
    }}
  </ProtectedRoute>
);

const UserRoutes = () => {
  return (
    <Routes>
      {/* Public Auth Route */}
      <Route path={ROUTES.AUTH} element={<AuthPage />} />

      {/* Profile Completion - Protected */}
      <Route
        path={ROUTES.PROFILE_COMPLETION}
        element={
          <ProtectedRoute>
            <ProfileCompletion />
          </ProtectedRoute>
        }
      />

      {/* Preview Routes */}
      <Route path={ROUTES.PREVIEW} element={<LayoutWrapper isPreview={true} />}>
        <Route index element={<Navigate to="community" replace />} />
        
        <Route path="community">
          <Route index element={<Community isPreview={true} />} />
          <Route path="post/:postId" element={<PostView isPreview={true} />} />
        </Route>

        <Route
          path="weight"
          element={
            <ProtectedRoute>
              <WeightDashboard isPreview={true} />
            </ProtectedRoute>
          }
        />
        <Route
          path="fasting"
          element={
            <ProtectedRoute>
              <Fasting isPreview={true} />
            </ProtectedRoute>
          }
        />
        <Route
          path="recommendations"
          element={
            <ProtectedRoute>
              <RecommendationDashboard isPreview={true} />
            </ProtectedRoute>
          }
        />

        <Route path="*" element={<Navigate to="community" replace />} />
      </Route>

      {/* Main Dashboard Routes */}
      <Route path={ROUTES.DASHBOARD} element={<LayoutWrapper />}>
        <Route index element={<Navigate to="community" replace />} />

        <Route path="community">
          <Route index element={<Community />} />
          <Route path="post/:postId" element={<PostView />} />
          <Route
            path="new"
            element={
              <ProtectedRoute>
                <PostEditor />
              </ProtectedRoute>
            }
          />
          <Route
            path="edit/:postId"
            element={
              <ProtectedRoute>
                <PostEditor />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route path="weight" element={<WeightDashboard />} />
        <Route path="fasting" element={<Fasting />} />
        <Route path="recommendations" element={<RecommendationDashboard />} />
        <Route path="feature-requests" element={<FeatureRequests />} />

        <Route path="*" element={<Navigate to="community" replace />} />
      </Route>

      {/* Settings Routes */}
      <Route path={ROUTES.SETTINGS} element={<LayoutWrapper />}>
        <Route path="profile" element={<UpdateProfile />} />
      </Route>

      {/* Root Route - Conditional Redirect */}
      <Route
        path="/"
        element={
          <ProtectedRoute allowPublic={true}>
            {(status) => (
              <Navigate
                to={status.isRegistered ? ROUTES.DASHBOARD : ROUTES.PREVIEW}
                replace
              />
            )}
          </ProtectedRoute>
        }
      />

      {/* Global Catch-all */}
      <Route
        path="*"
        element={
          <ProtectedRoute allowPublic={true}>
            {(status) => (
              <Navigate
                to={status.isRegistered ? ROUTES.DASHBOARD : ROUTES.PREVIEW}
                replace
              />
            )}
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default UserRoutes;