// src/components/user/GoalsDashboard/Fasting/Components/CurrentFastProgress/TimePickerModal.js
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Check } from 'lucide-react';
import { format, isAfter, differenceInHours } from 'date-fns';

export const TimePickerModal = ({ isOpen, onClose, onSave, currentTime }) => {
  const [selectedDateTime, setSelectedDateTime] = useState(
    currentTime ? new Date(currentTime) : new Date()
  );
  const [error, setError] = useState('');

  const handleTimeChange = (e) => {
    try {
      const [hours, minutes] = e.target.value.split(':');
      const newDateTime = new Date(selectedDateTime);
      newDateTime.setHours(parseInt(hours), parseInt(minutes));
      setSelectedDateTime(newDateTime);
      setError('');
    } catch (err) {
      setError('Invalid time format');
    }
  };

  const handleSave = () => {
    const now = new Date();
    if (isAfter(selectedDateTime, now)) {
      setError('Start time cannot be in the future');
      return;
    }
    
    const elapsedHours = differenceInHours(now, selectedDateTime);
    onSave(selectedDateTime.toISOString(), elapsedHours);
    onClose();
  };

  const getLastSevenDays = () => {
    const dates = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date();
      date.setDate(date.getDate() - i);
      dates.push({
        label: i === 0 ? 'Today' : i === 1 ? 'Yesterday' : format(date, 'EEE, MMM d'),
        time: date
      });
    }
    return dates;
  };

  const quickDates = getLastSevenDays();
  const isDateSelected = (date) => format(date, 'yyyy-MM-dd') === format(selectedDateTime, 'yyyy-MM-dd');

  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-start justify-center p-4 bg-black/50 backdrop-blur-sm">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="w-full max-w-md bg-white rounded-xl shadow-xl mt-20"
          >
            <div className="p-6">
              <div className="flex items-center justify-between mb-6">
                <h3 className="text-xl font-semibold">Adjust Start Time</h3>
                <button 
                  onClick={onClose}
                  className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                >
                  <X className="w-5 h-5" />
                </button>
              </div>

              {error && (
                <div className="mb-4 p-3 bg-red-50 text-red-600 rounded-lg text-sm">
                  {error}
                </div>
              )}

              <div className="space-y-6">
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Quick Select Date
                  </label>
                  <div className="space-y-2">
                    {quickDates.map(({ label, time }) => {
                      const selected = isDateSelected(time);
                      return (
                        <motion.button
                          key={label}
                          onClick={() => {
                            const newDate = new Date(time);
                            const current = new Date(selectedDateTime);
                            newDate.setHours(current.getHours(), current.getMinutes());
                            setSelectedDateTime(newDate);
                          }}
                          className={`w-full p-3 text-sm rounded-lg transition-all
                                    flex items-center justify-between
                                    ${selected 
                                      ? 'bg-blue-500 text-white font-medium shadow-md' 
                                      : 'bg-blue-50 text-blue-600 hover:bg-blue-100'
                                    }`}
                          whileHover={{ scale: 1.01 }}
                          whileTap={{ scale: 0.99 }}
                        >
                          <span>{label}</span>
                          {selected && (
                            <motion.div
                              initial={{ scale: 0 }}
                              animate={{ scale: 1 }}
                              transition={{ type: "spring", stiffness: 400, damping: 25 }}
                            >
                              <Check className="w-5 h-5" />
                            </motion.div>
                          )}
                        </motion.button>
                      );
                    })}
                  </div>
                </div>

                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Custom Time
                    </label>
                    <input
                      type="time"
                      value={format(selectedDateTime, 'HH:mm')}
                      onChange={handleTimeChange}
                      className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                    />
                  </div>
                </div>

                <div className="flex justify-end gap-3 pt-4">
                  <button
                    onClick={onClose}
                    className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
                  >
                    Cancel
                  </button>
                  <motion.button
                    onClick={handleSave}
                    className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600
                             flex items-center gap-2 transition-colors"
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    <Check className="w-5 h-5" />
                    Set Time
                  </motion.button>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

TimePickerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  currentTime: PropTypes.string
};

export default TimePickerModal;