import { collection, getDocs } from 'firebase/firestore';
import { db, auth } from '../../../../../firebase';

export const matchesUserCriteria = (recommendation, userProfile) => {
  if (!userProfile) return false;
  
  try {
    const birthDate = new Date(userProfile.dateOfBirth);
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();

    const matchesGender = !recommendation.gender || recommendation.gender === userProfile.gender;
    const matchesMinAge = !recommendation.minAge || age >= parseInt(recommendation.minAge);
    const matchesMaxAge = !recommendation.maxAge || age <= parseInt(recommendation.maxAge);

    // Updated risk factors matching logic
    const hasRiskFactorsMatch = (() => {
      const userHasNoFactors = !userProfile.selectedRiskFactors || userProfile.selectedRiskFactors.length === 0;
      const recHasNoFactors = !recommendation.selectedRiskFactors || recommendation.selectedRiskFactors.length === 0;
      
      // Both have no risk factors - match
      if (userHasNoFactors && recHasNoFactors) return true;
      
      // One has factors but other doesn't - no match
      if (userHasNoFactors || recHasNoFactors) return false;
      
      // Both have factors - check for overlap
      return recommendation.selectedRiskFactors.some(factor => 
        userProfile.selectedRiskFactors.includes(factor)
      );
    })();

    // Updated health goals matching logic
    const hasHealthGoalsMatch = (() => {
      const userHasNoGoals = !userProfile.selectedHealthGoals || userProfile.selectedHealthGoals.length === 0;
      const recHasNoGoals = !recommendation.selectedHealthGoals || recommendation.selectedHealthGoals.length === 0;
      
      // Both have no goals - match
      if (userHasNoGoals && recHasNoGoals) return true;
      
      // One has goals but other doesn't - no match
      if (userHasNoGoals || recHasNoGoals) return false;
      
      // Both have goals - check for overlap
      return recommendation.selectedHealthGoals.some(goal => 
        userProfile.selectedHealthGoals.includes(goal)
      );
    })();

    return matchesGender && matchesMinAge && matchesMaxAge && 
           hasRiskFactorsMatch && hasHealthGoalsMatch;
  } catch (error) {
    console.error('Error matching criteria:', error);
    return false;
  }
};

export const fetchAllRecommendations = async () => {
  try {
    const recommendationsRef = collection(db, 'recommendations');
    const snapshot = await getDocs(recommendationsRef);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('Error fetching recommendations:', error);
    throw new Error('Failed to fetch recommendations');
  }
};

export const createUserRecommendations = (userId, matchingRecommendations) => {
  return matchingRecommendations.map(rec => ({
    userId,
    recommendationId: rec.id,
    status: 'pending',
    priority: rec.priority || 1,
    actionType: rec.actionType,
    actionDetails: rec.actionDetails,
    instruction: rec.instruction,
    explanation: rec.explanation,
    frequency: rec.frequency,
    createdAt: new Date(),
    lastCompletedAt: null,
    nextDueDate: null,
    assignedAt: new Date(),
    ...(rec.selectedRiskFactors && { selectedRiskFactors: rec.selectedRiskFactors }),
    ...(rec.selectedHealthGoals && { selectedHealthGoals: rec.selectedHealthGoals })
  }));
};

export const processUserRecommendations = async (userId, userProfile) => {
  try {
    if (!userId) throw new Error('userId is required');
    if (!userProfile) throw new Error('userProfile is required');

    const allRecommendations = await fetchAllRecommendations();
    
    const matchingRecommendations = allRecommendations.filter(rec => 
      matchesUserCriteria(rec, userProfile)
    );
    
    const userRecommendations = createUserRecommendations(
      userId, 
      matchingRecommendations
    );

    return {
      matchingRecommendations,
      userRecommendations
    };
  } catch (error) {
    console.error('Error processing recommendations:', error);
    throw error;
  }
};