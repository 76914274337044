/**
 * Calculate BMI from height (cm) and weight (kg)
 * @param {number} height - Height in centimeters
 * @param {number} weight - Weight in kilograms
 * @returns {number} BMI value with one decimal place
 */
export const calculateBMI = (height = 0, weight = 0) => {
    if (height <= 0 || weight <= 0) return 0;
    const heightInMeters = height / 100;
    return Number((weight / (heightInMeters * heightInMeters)).toFixed(1));
};

/**
 * Format weight based on measurement preference
 * @param {number} weight - Weight in kilograms
 * @param {string} measurementPreference - 'metric' or 'imperial'
 * @returns {string} Formatted weight string with unit
 */
export const formatWeight = (weight, measurementPreference = 'metric') => {
    // Check if weight is null, undefined, or not a number
    if (weight === null || weight === undefined || isNaN(Number(weight))) {
        return measurementPreference === 'imperial' ? '0.0 lbs' : '0.0 kg';
    }

    // Convert weight to number to ensure we can use toFixed
    const numWeight = Number(weight);
    
    try {
        if (measurementPreference === 'imperial') {
            const lbs = (numWeight * 2.20462).toFixed(1);
            return `${lbs} lbs`;
        }
        return `${numWeight.toFixed(1)} kg`;
    } catch (error) {
        console.error('Error formatting weight:', error);
        return measurementPreference === 'imperial' ? '0.0 lbs' : '0.0 kg';
    }
};

/**
 * Calculate target weight based on height using BMI range midpoint
 * @param {number} height - Height in centimeters
 * @returns {number} Target weight in kilograms
 */
export const calculateTargetWeight = (height = 0, bias = 0.9) => {
    if (height <= 0) return 0;
    try {
        // Ensure bias is between 0 and 1
        const normalizedBias = Math.max(0, Math.min(1, bias));
        
        const heightInMeters = height / 100;
        const minWeight = 18.5 * heightInMeters * heightInMeters;
        const maxWeight = 24.9 * heightInMeters * heightInMeters;
        
        // Use bias to calculate weighted average
        const targetWeight = minWeight + (maxWeight - minWeight) * normalizedBias;
        
        return Number(targetWeight.toFixed(1));
    } catch (error) {
        console.error('Error calculating target weight:', error);
        return 0;
    }
};

/**
 * Calculate age from date of birth
 * @param {string|Date} dateOfBirth - Date of birth
 * @returns {number} Age in years
 */
export const calculateAge = (dateOfBirth) => {
    if (!dateOfBirth) return 0;
    try {
        const birthDate = new Date(dateOfBirth);
        if (isNaN(birthDate.getTime())) return 0;

        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    } catch (error) {
        console.error('Error calculating age:', error);
        return 0;
    }
};

/**
 * Get BMI category and associated styling
 * @param {number} bmi - BMI value
 * @returns {Object} Category info with styling
 */
export const getBMICategory = (bmi) => {
    if (bmi < 18.5) return { category: "Underweight", color: "text-blue-500", bgColor: "bg-blue-50" };
    if (bmi < 25) return { category: "Normal", color: "text-green-500", bgColor: "bg-green-50" };
    if (bmi < 30) return { category: "Overweight", color: "text-yellow-500", bgColor: "bg-yellow-50" };
    return { category: "Obese", color: "text-red-500", bgColor: "bg-red-50" };
};