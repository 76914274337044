/* --- Begin src/routes/AdminRoutes.js --- */
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import AdminAuth from '../components/admin/AdminAuth';
import AdminDashboard from '../components/admin/AdminDashboard';
import RiskFactors from '../components/admin/RiskFactors';
import HealthGoals from '../components/admin/HealthGoals';
import RecommendationForm from '../components/admin/RecommendationForm';
import ProtectedRoute from './ProtectedRoute';

const AdminRoutes = () => {
  return (
    <Routes>
      {/* Public admin login route */}
      <Route path="/admin" element={<AdminAuth />} />

      {/* Protected admin dashboard routes */}
      <Route
        path="/*"
        element={
          <ProtectedRoute requireAdmin={true}>
            <AdminDashboard />
          </ProtectedRoute>
        }
      >
        {/* Dashboard sub-routes */}
        <Route index element={<Navigate to="risk-factors" replace />} />
        <Route path="risk-factors" element={<RiskFactors />} />
        <Route path="health-goals" element={<HealthGoals />} />
        <Route path="recommendations" element={<RecommendationForm />} />
      </Route>
    </Routes>
  );
};

export default AdminRoutes;