import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth } from '../firebase';

const AuthContext = createContext();

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        try {
            const unsubscribe = auth.onAuthStateChanged((user) => {
                setCurrentUser(user);
                setLoading(false);
            }, (error) => {
                console.error("Auth state change error:", error);
                setError(error);
                setLoading(false);
            });

            return unsubscribe;
        } catch (error) {
            console.error("Auth setup error:", error);
            setError(error);
            setLoading(false);
        }
    }, []);

    const value = {
        currentUser,
        loading,
        error
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth() {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
}