// TermsOfService.js
import React from 'react';
import LegalLayout from './LegalLayout';

const TermsOfService = () => {
  return (
    <LegalLayout title="Terms of Service">
      <div className="prose max-w-none">
        <div className="space-y-12">
          {/* Agreement Section */}
          <section className="rounded-2xl bg-gray-50 p-6 hover:bg-gray-100 transition-colors" id="agreement">
            <h2 className="text-2xl font-semibold mb-4 text-gray-900">1. Agreement to Terms</h2>
            <p className="text-gray-600 mb-4">
              By accessing or using WatchMy.health, you agree to be bound by these Terms of Service 
              and our Privacy Policy. If you disagree with any part of these terms, you may not 
              access our service.
            </p>
          </section>

          {/* Services Section */}
          <section className="rounded-2xl bg-gray-50 p-6 hover:bg-gray-100 transition-colors" id="services">
            <h2 className="text-2xl font-semibold mb-4 text-gray-900">2. Services Description</h2>
            <p className="text-gray-600 mb-4">
              WatchMy.health provides health tracking and monitoring services, including:
            </p>
            <ul className="list-disc pl-6 mb-4 text-gray-600 space-y-2">
              <li>Weight management and tracking tools</li>
              <li>Fasting schedule monitoring</li>
              <li>Health analytics and insights</li>
              <li>Personalized health recommendations</li>
              <li>Progress tracking and reporting</li>
            </ul>
          </section>

          {/* Continue with other sections following the same pattern... */}
          
          <div className="mt-12 pt-8 border-t border-gray-200">
            <p className="text-sm text-gray-500">
              These Terms of Service were last updated on November 5, 2024. By continuing to use our services, 
              you agree to be bound by these terms.
            </p>
          </div>
        </div>
      </div>
    </LegalLayout>
  );
};

export default TermsOfService;
