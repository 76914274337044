import { useState, useRef, useCallback, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../../../contexts/AuthContext';
import PostsService from '../../../PostsService';

export const usePostView = () => {
  const { postId } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  // Refs
  const articleRef = useRef(null);
  const headerRef = useRef(null);
  const startTimeRef = useRef(Date.now());
  const unsubscribeRef = useRef(null);

  // State
  const [state, setState] = useState({
    post: null,
    loading: true,
    error: null,
    isEditing: false,
    readingProgress: 0,
    isBookmarked: false,
    showShareMenu: false,
    showOptionsMenu: false,
    showConfirmDialog: false,
    confirmMessage: '',
    confirmAction: null,
    toast: { show: false, message: '', type: 'info' },
    showScrollTop: false
  });

  // State update helper
  const updateState = useCallback((updates) => {
    setState(prev => ({ ...prev, ...updates }));
  }, []);

  // Toast helper
  const showToast = useCallback((message, type = 'info') => {
    updateState({ toast: { show: true, message, type } });
    setTimeout(() => {
      updateState({ toast: { show: false, message: '', type: 'info' } });
    }, 3000);
  }, [updateState]);

  // Handlers
  const handlers = {
    handleBack: useCallback(() => {
      if (state.isEditing) {
        updateState({
          showConfirmDialog: true,
          confirmMessage: 'Discard changes?',
          confirmAction: () => {
            updateState({ isEditing: false });
            navigate(-1);
          }
        });
      } else {
        navigate(-1);
      }
    }, [state.isEditing, navigate, updateState]),

    handleLike: async () => {
      if (!currentUser) {
        showToast('Please sign in to like posts', 'warning');
        return;
      }
      try {
        await PostsService.toggleLike(postId, currentUser.uid);
      } catch (error) {
        showToast(error.message, 'error');
      }
    },

    handleBookmark: async () => {
      if (!currentUser) {
        showToast('Please sign in to bookmark posts', 'warning');
        return;
      }
      try {
        await PostsService.toggleBookmark(postId, currentUser.uid);
        updateState({ isBookmarked: !state.isBookmarked });
        showToast(
          state.isBookmarked ? 'Post removed from bookmarks' : 'Post added to bookmarks',
          'success'
        );
      } catch (error) {
        showToast(error.message, 'error');
      }
    },

    handleShare: async (platform = 'copy') => {
      try {
        const postUrl = window.location.href;
        const title = state.post?.title || 'Check out this post';
        
        switch (platform) {
          case 'twitter':
            window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=${encodeURIComponent(postUrl)}`, '_blank');
            break;
          case 'facebook':
            window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(postUrl)}`, '_blank');
            break;
          case 'linkedin':
            window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(postUrl)}`, '_blank');
            break;
          case 'copy':
            await navigator.clipboard.writeText(postUrl);
            showToast('Link copied to clipboard!', 'success');
            break;
        }
        
        updateState({ showShareMenu: false });
        if (platform !== 'copy') {
          showToast('Opening share window...', 'success');
        }
      } catch (error) {
        showToast('Failed to share post', 'error');
      }
    },

    handleDelete: () => {
      updateState({
        showConfirmDialog: true,
        confirmMessage: 'Are you sure you want to delete this post? This action cannot be undone.',
        confirmAction: async () => {
          try {
            await PostsService.deletePost(postId, currentUser.uid);
            showToast('Post deleted successfully', 'success');
            navigate('/dashboard/community');
          } catch (error) {
            showToast(error.message, 'error');
          }
        }
      });
    },

    handleReport: async (reason) => {
      if (!currentUser) {
        showToast('Please sign in to report posts', 'warning');
        return;
      }
      try {
        await PostsService.reportPost(postId, {
          reporterId: currentUser.uid,
          reason,
          createdAt: new Date().toISOString()
        });
        showToast('Post reported successfully', 'success');
        updateState({ showOptionsMenu: false });
      } catch (error) {
        showToast(error.message, 'error');
      }
    },

    handleSave: async (updatedPost) => {
      try {
        await PostsService.updatePost(postId, updatedPost, currentUser.uid);
        updateState({ isEditing: false });
        showToast('Post updated successfully!', 'success');
      } catch (error) {
        showToast(error.message, 'error');
      }
    },

    handleAddComment: async (commentText) => {
      if (!currentUser) {
        showToast('Please sign in to comment', 'warning');
        return;
      }
      try {
        const newComment = {
          text: commentText,
          authorId: currentUser.uid,
          authorEmail: currentUser.email,
          createdAt: new Date().toISOString(),
          likes: [],
          dislikes: []
        };
        await PostsService.addComment(postId, newComment);
        showToast('Comment added successfully!', 'success');
      } catch (error) {
        showToast(error.message, 'error');
      }
    },

    handleEditComment: async (commentId, newText) => {
      if (!currentUser) {
        showToast('Please sign in to edit comments', 'warning');
        return;
      }
      try {
        await PostsService.updateComment(postId, commentId, { text: newText });
        showToast('Comment updated successfully!', 'success');
      } catch (error) {
        showToast(error.message, 'error');
      }
    },

    handleDeleteComment: async (commentId) => {
      try {
        await PostsService.deleteComment(postId, commentId);
        showToast('Comment deleted successfully!', 'success');
      } catch (error) {
        showToast(error.message, 'error');
      }
    },

    handleReportComment: async (commentId, reason) => {
      if (!currentUser) {
        showToast('Please sign in to report comments', 'warning');
        return;
      }
      try {
        await PostsService.reportComment(postId, commentId, {
          reporterId: currentUser.uid,
          reason,
          createdAt: new Date().toISOString()
        });
        showToast('Comment reported successfully', 'success');
      } catch (error) {
        showToast(error.message, 'error');
      }
    },

    handleLikeComment: async (commentId) => {
      if (!currentUser) {
        showToast('Please sign in to like comments', 'warning');
        return;
      }
      try {
        await PostsService.toggleCommentLike(postId, commentId, currentUser.uid);
      } catch (error) {
        showToast(error.message, 'error');
      }
    },

    handleDislikeComment: async (commentId) => {
      if (!currentUser) {
        showToast('Please sign in to dislike comments', 'warning');
        return;
      }
      try {
        await PostsService.toggleCommentDislike(postId, commentId, currentUser.uid);
      } catch (error) {
        showToast(error.message, 'error');
      }
    },

    scrollToTop: () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  // Effects
  useEffect(() => {
    const handleScroll = () => {
      if (!articleRef.current || !headerRef.current) return;

      const totalHeight = articleRef.current.clientHeight;
      const windowHeight = window.innerHeight;
      const scrolled = window.scrollY;
      const headerHeight = headerRef.current.clientHeight;

      const adjustedScroll = Math.max(0, scrolled - headerHeight);
      const adjustedTotal = totalHeight - windowHeight;
      
      const progress = (adjustedScroll / adjustedTotal) * 100;
      updateState({
        readingProgress: Math.min(Math.max(progress, 0), 100),
        showScrollTop: window.scrollY > 500
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [updateState]);

  useEffect(() => {
    if (!postId) return;

    const unsubscribe = PostsService.subscribeToPost(postId, (updatedPost, error) => {
      if (error) {
        updateState({
          error: error.message,
          loading: false
        });
        return;
      }

      if (updatedPost) {
        updateState({
          post: updatedPost,
          isBookmarked: updatedPost.bookmarkedBy?.includes(currentUser?.uid),
          loading: false,
          error: null
        });
      }
    });

    unsubscribeRef.current = unsubscribe;

    return () => {
      if (unsubscribeRef.current) {
        unsubscribeRef.current();
      }
      if (state.post?.id) {
        const timeSpent = Math.floor((Date.now() - startTimeRef.current) / 1000);
        PostsService.updatePostAnalytics(state.post.id, { timeSpent });
      }
    };
  }, [postId, currentUser?.uid, updateState]);

  return {
    state,
    updateState,
    showToast,
    handlers,
    refs: {
      articleRef,
      headerRef,
      startTimeRef,
      unsubscribeRef
    },
    post: state.post,
    currentUser,
    isAuthor: currentUser?.uid === state.post?.authorId
  };
};