import { 
    collection, 
    addDoc, 
    deleteDoc, 
    updateDoc, 
    getDoc, 
    getDocs, 
    doc, 
    query, 
    where, 
    orderBy, 
    limit, 
    startAfter,
    increment,
    arrayUnion,
    arrayRemove,
    serverTimestamp,
    onSnapshot,
    Timestamp
  } from 'firebase/firestore';
  import { db } from '../../../firebase';
  
  export class PostsService {
    /**
     * Helper method to safely convert Firestore timestamp to Date
     */
    static convertTimestamp(timestamp) {
      try {
        if (!timestamp) return null;
        return timestamp?.toDate?.() || new Date(timestamp);
      } catch (error) {
        console.error('Error converting timestamp:', error);
        return null;
      }
    }
  
    /**
     * Helper method to format post data
     */
    static formatPostData(doc) {
      try {
        if (!doc.exists()) return null;
        
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          createdAt: this.convertTimestamp(data.createdAt),
          updatedAt: this.convertTimestamp(data.updatedAt),
          comments: (data.comments || []).map(comment => ({
            ...comment,
            createdAt: this.convertTimestamp(comment.createdAt)
          }))
        };
      } catch (error) {
        console.error('Error formatting post data:', error);
        return null;
      }
    }
  
    /**
     * Get a single post by ID with error handling
     */
    static async getPostById(postId) {
      try {
        if (!postId) throw new Error('Post ID is required');
  
        const postRef = doc(db, 'posts', postId);
        const postDoc = await getDoc(postRef);
        
        if (!postDoc.exists()) {
          throw new Error('Post not found');
        }
  
        const formattedPost = this.formatPostData(postDoc);
        if (!formattedPost) {
          throw new Error('Error formatting post data');
        }
  
        return formattedPost;
      } catch (error) {
        console.error('Error getting post:', error);
        throw error;
      }
    }
  
    /**
     * Subscribe to real-time post updates with enhanced error handling
     */
    static subscribeToPost(postId, callback) {
      if (!postId) {
        callback(null, new Error('Post ID is required'));
        return () => {};
      }
  
      try {
        return onSnapshot(
          doc(db, 'posts', postId),
          (doc) => {
            if (doc.exists()) {
              const formattedPost = this.formatPostData(doc);
              if (formattedPost) {
                callback(formattedPost);
              } else {
                callback(null, new Error('Error formatting post data'));
              }
            } else {
              callback(null, new Error('Post not found'));
            }
          },
          (error) => {
            console.error('Error subscribing to post:', error);
            callback(null, error);
          }
        );
      } catch (error) {
        console.error('Error setting up post subscription:', error);
        callback(null, error);
        return () => {};
      }
    }
  
    /**
     * Create a new post with enhanced validation
     */
    static async createPost(postData, userId, userEmail) {
      try {
        if (!userId || !userEmail) {
          throw new Error('Authentication required to create posts');
        }
  
        // Enhanced validation
        const validationErrors = [];
        if (!postData.title?.trim()) validationErrors.push('Title is required');
        if (!postData.content?.trim()) validationErrors.push('Content is required');
        if (!postData.category) validationErrors.push('Category is required');
  
        if (validationErrors.length > 0) {
          throw new Error(`Validation failed: ${validationErrors.join(', ')}`);
        }
  
        const post = {
          title: postData.title.trim(),
          content: postData.content.trim(),
          category: postData.category,
          authorId: userId,
          authorEmail: userEmail,
          createdAt: Timestamp.now(),
          updatedAt: Timestamp.now(),
          
          subtitle: (postData.subtitle || '').trim(),
          coverImage: postData.coverImage || null,
          tags: Array.isArray(postData.tags) 
            ? postData.tags.filter(tag => tag && tag.trim())
            : [],
          readTime: postData.readTime || '3 min read',
          publishStatus: postData.publishStatus || 'draft',
          
          likes: 0,
          likedBy: [],
          comments: [],
          views: 0,
          bookmarkedBy: [],
          reports: [],
          uniqueViewers: [],
          
          analytics: {
            totalViews: 0,
            uniqueViews: 0,
            engagementRate: 0,
            shareCount: 0,
            timeSpent: 0,
            lastUpdated: Timestamp.now()
          }
        };
  
        const docRef = await addDoc(collection(db, 'posts'), post);
        return docRef.id;
      } catch (error) {
        console.error('Error creating post:', error);
        throw error;
      }
    }
  
    /**
     * Add comment to a post with optimistic updates
     */
    static async addComment(postId, userId, userEmail, text) {
      try {
        if (!userId || !userEmail) {
          throw new Error('Authentication required to comment');
        }
  
        if (!text?.trim()) {
          throw new Error('Comment text is required');
        }
  
        const comment = {
          id: crypto.randomUUID(),
          text: text.trim(),
          authorId: userId,
          authorEmail: userEmail,
          createdAt: Timestamp.now(),
          likes: 0,
          likedBy: []
        };
  
        const postRef = doc(db, 'posts', postId);
        const postDoc = await getDoc(postRef);
        
        if (!postDoc.exists()) {
          throw new Error('Post not found');
        }
  
        // Update with optimistic concurrency
        const currentComments = postDoc.data().comments || [];
        await updateDoc(postRef, {
          comments: [...currentComments, comment],
          'analytics.engagementRate': increment(0.2),
          'analytics.lastUpdated': serverTimestamp()
        });
  
        return comment;
      } catch (error) {
        console.error('Error adding comment:', error);
        throw error;
      }
    }
  
    /**
     * Delete comment from a post with proper validation
     */
    static async deleteComment(postId, commentId, userId) {
      try {
        if (!userId) {
          throw new Error('Authentication required to delete comments');
        }
  
        if (!postId || !commentId) {
          throw new Error('Post ID and Comment ID are required');
        }
  
        const postRef = doc(db, 'posts', postId);
        const postDoc = await getDoc(postRef);
        
        if (!postDoc.exists()) {
          throw new Error('Post not found');
        }
  
        const post = postDoc.data();
        const commentIndex = post.comments.findIndex(c => c.id === commentId);
        
        if (commentIndex === -1) {
          throw new Error('Comment not found');
        }
  
        const comment = post.comments[commentIndex];
        
        if (comment.authorId !== userId && post.authorId !== userId) {
          throw new Error('Permission denied: You can only delete your own comments');
        }
  
        const updatedComments = [...post.comments];
        updatedComments.splice(commentIndex, 1);
  
        await updateDoc(postRef, {
          comments: updatedComments,
          'analytics.engagementRate': increment(-0.1),
          'analytics.lastUpdated': serverTimestamp()
        });
  
        return true;
      } catch (error) {
        console.error('Error deleting comment:', error);
        throw error;
      }
    }

    // PostsService.js has most of the code correct already, we just need to add comment interactions:

static async toggleCommentLike(postId, commentId, userId) {
  try {
    if (!userId) {
      throw new Error('Authentication required to like comments');
    }

    const postRef = doc(db, 'posts', postId);
    const post = await getDoc(postRef);
    
    if (!post.exists()) {
      throw new Error('Post not found');
    }

    const comments = post.data().comments || [];
    const commentIndex = comments.findIndex(c => c.id === commentId);
    
    if (commentIndex === -1) {
      throw new Error('Comment not found');
    }

    const comment = comments[commentIndex];
    const likedBy = comment.likedBy || [];
    const dislikedBy = comment.dislikedBy || [];

    // Remove from disliked if exists
    const newDislikedBy = dislikedBy.filter(id => id !== userId);
    
    // Toggle like
    const newLikedBy = likedBy.includes(userId)
      ? likedBy.filter(id => id !== userId)
      : [...likedBy, userId];

    comments[commentIndex] = {
      ...comment,
      likedBy: newLikedBy,
      dislikedBy: newDislikedBy,
      likes: newLikedBy.length,
      dislikes: newDislikedBy.length
    };

    await updateDoc(postRef, { 
      comments,
      'analytics.engagementRate': increment(0.1),
      'analytics.lastUpdated': serverTimestamp()
    });
    
    return true;
  } catch (error) {
    console.error('Error toggling comment like:', error);
    throw error;
  }
}

static async toggleCommentDislike(postId, commentId, userId) {
  try {
    if (!userId) {
      throw new Error('Authentication required to dislike comments');
    }

    const postRef = doc(db, 'posts', postId);
    const post = await getDoc(postRef);
    
    if (!post.exists()) {
      throw new Error('Post not found');
    }

    const comments = post.data().comments || [];
    const commentIndex = comments.findIndex(c => c.id === commentId);
    
    if (commentIndex === -1) {
      throw new Error('Comment not found');
    }

    const comment = comments[commentIndex];
    const dislikedBy = comment.dislikedBy || [];
    const likedBy = comment.likedBy || [];

    // Remove from liked if exists
    const newLikedBy = likedBy.filter(id => id !== userId);
    
    // Toggle dislike
    const newDislikedBy = dislikedBy.includes(userId)
      ? dislikedBy.filter(id => id !== userId)
      : [...dislikedBy, userId];

    comments[commentIndex] = {
      ...comment,
      dislikedBy: newDislikedBy,
      likedBy: newLikedBy,
      dislikes: newDislikedBy.length,
      likes: newLikedBy.length
    };

    await updateDoc(postRef, { 
      comments,
      'analytics.engagementRate': increment(-0.1),
      'analytics.lastUpdated': serverTimestamp()
    });
    
    return true;
  } catch (error) {
    console.error('Error toggling comment dislike:', error);
    throw error;
  }
}

static async reportComment(postId, commentId, userId, reason) {
  try {
    if (!userId) {
      throw new Error('Authentication required to report comments');
    }

    if (!reason?.trim()) {
      throw new Error('Reason is required to report a comment');
    }

    const report = {
      id: crypto.randomUUID(),
      userId,
      reason: reason.trim(),
      timestamp: Timestamp.now(),
      status: 'pending'
    };

    const postRef = doc(db, 'posts', postId);
    const post = await getDoc(postRef);
    
    if (!post.exists()) {
      throw new Error('Post not found');
    }

    const comments = post.data().comments || [];
    const commentIndex = comments.findIndex(c => c.id === commentId);
    
    if (commentIndex === -1) {
      throw new Error('Comment not found');
    }

    comments[commentIndex] = {
      ...comments[commentIndex],
      reports: [...(comments[commentIndex].reports || []), report]
    };

    await updateDoc(postRef, { 
      comments,
      'analytics.lastUpdated': serverTimestamp()
    });
    
    return true;
  } catch (error) {
    console.error('Error reporting comment:', error);
    throw error;
  }
}
  
    /**
     * Toggle like status for a post with optimistic updates
     */
    static async toggleLike(postId, userId) {
      try {
        if (!userId) {
          throw new Error('Authentication required to like posts');
        }
  
        const postRef = doc(db, 'posts', postId);
        const postDoc = await getDoc(postRef);
        
        if (!postDoc.exists()) {
          throw new Error('Post not found');
        }
  
        const post = postDoc.data();
        const isLiked = post.likedBy?.includes(userId) || false;
        
        await updateDoc(postRef, {
          likes: increment(isLiked ? -1 : 1),
          likedBy: isLiked ? arrayRemove(userId) : arrayUnion(userId),
          'analytics.engagementRate': increment(isLiked ? -0.1 : 0.1),
          'analytics.lastUpdated': serverTimestamp()
        });
  
        return !isLiked;
      } catch (error) {
        console.error('Error toggling like:', error);
        throw error;
      }
    }
  
    /**
     * Toggle bookmark status for a post
     */
    static async toggleBookmark(postId, userId) {
      try {
        if (!userId) {
          throw new Error('Authentication required to bookmark posts');
        }
  
        const postRef = doc(db, 'posts', postId);
        const postDoc = await getDoc(postRef);
        
        if (!postDoc.exists()) {
          throw new Error('Post not found');
        }
  
        const post = postDoc.data();
        const isBookmarked = post.bookmarkedBy?.includes(userId) || false;
  
        await updateDoc(postRef, {
          bookmarkedBy: isBookmarked ? arrayRemove(userId) : arrayUnion(userId),
          'analytics.lastUpdated': serverTimestamp()
        });
  
        return !isBookmarked;
      } catch (error) {
        console.error('Error toggling bookmark:', error);
        throw error;
      }
    }
  
    /**
     * Update post with enhanced validation and optimistic updates
     */
    static async updatePost(postId, updateData, userId) {
      try {
        if (!userId) {
          throw new Error('Authentication required to update posts');
        }
  
        const postRef = doc(db, 'posts', postId);
        const postDoc = await getDoc(postRef);
        
        if (!postDoc.exists()) {
          throw new Error('Post not found');
        }
  
        const existingPost = postDoc.data();
        const isInteractionUpdate = this.isInteractionFields(updateData);
        const isAuthor = existingPost.authorId === userId;
        const isAdmin = await this.isUserAdmin(userId);
  
        if (!isInteractionUpdate && !isAuthor && !isAdmin) {
          throw new Error('Permission denied: You can only edit your own posts');
        }
  
        if (updateData.authorId && updateData.authorId !== existingPost.authorId) {
          throw new Error('Cannot change post author');
        }
  
        const sanitizedData = this.sanitizeUpdateData(updateData);
        
        // Validation for required fields
        if (sanitizedData.title === '') {
          throw new Error('Title cannot be empty');
        }
  
        if (sanitizedData.content === '') {
          throw new Error('Content cannot be empty');
        }
  
        const finalUpdateData = {
          ...sanitizedData,
          updatedAt: Timestamp.now()
        };
  
        await updateDoc(postRef, finalUpdateData);
  
        // Return updated post data
        const updatedDoc = await getDoc(postRef);
        return this.formatPostData(updatedDoc);
      } catch (error) {
        console.error('Error updating post:', error);
        throw error;
      }
    }
  
    /**
     * Delete post with cascade deletion
     */
    static async deletePost(postId, userId) {
      try {
        if (!userId) {
          throw new Error('Authentication required to delete posts');
        }
  
        const postRef = doc(db, 'posts', postId);
        const postDoc = await getDoc(postRef);
        
        if (!postDoc.exists()) {
          throw new Error('Post not found');
        }
  
        const post = postDoc.data();
        const isAdmin = await this.isUserAdmin(userId);
  
        if (post.authorId !== userId && !isAdmin) {
          throw new Error('Permission denied: You can only delete your own posts');
        }
  
        // Could add cascade deletion here if needed
        // e.g., delete associated comments, likes, etc.
  
        await deleteDoc(postRef);
        return true;
      } catch (error) {
        console.error('Error deleting post:', error);
        throw error;
      }
    }

  /**
   * Get posts with filtering and pagination
   */
  static async getPosts(options = {}) {
    try {
      const {
        category,
        searchTerm = '',
        authorId,
        lastVisible,
        limit: queryLimit = 10,
        status,
        sortBy = 'latest',
        filterBy = 'all',
        userId = null,
        tags = []
      } = options;

      const queryConstraints = [];

      // Base filters
      if (category) {
        queryConstraints.push(where('category', '==', category));
      }

      if (authorId) {
        queryConstraints.push(where('authorId', '==', authorId));
      }

      if (status) {
        queryConstraints.push(where('publishStatus', '==', status));
      }

      if (tags.length > 0) {
        queryConstraints.push(where('tags', 'array-contains-any', tags));
      }

      // Special filters
      switch (filterBy) {
        case 'bookmarked':
          if (!userId) throw new Error('User ID required for bookmarked posts');
          queryConstraints.push(where('bookmarkedBy', 'array-contains', userId));
          break;
        case 'liked':
          if (!userId) throw new Error('User ID required for liked posts');
          queryConstraints.push(where('likedBy', 'array-contains', userId));
          break;
      }

      // Sort order
      switch (sortBy) {
        case 'latest':
          queryConstraints.push(orderBy('createdAt', 'desc'));
          break;
        case 'popular':
          queryConstraints.push(orderBy('likes', 'desc'));
          break;
        case 'views':
          queryConstraints.push(orderBy('views', 'desc'));
          break;
      }

      if (lastVisible) {
        queryConstraints.push(startAfter(lastVisible));
      }
      
      queryConstraints.push(limit(queryLimit));

      const postsQuery = query(collection(db, 'posts'), ...queryConstraints);
      const querySnapshot = await getDocs(postsQuery);
      
      const posts = querySnapshot.docs
        .map(doc => this.formatPostData(doc))
        .filter(post => post !== null);

      const filteredPosts = searchTerm
        ? posts.filter(post =>
            post.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            post.content?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            post.tags?.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase()))
          )
        : posts;

      return {
        posts: filteredPosts,
        lastVisible: querySnapshot.docs[querySnapshot.docs.length - 1],
        hasMore: querySnapshot.docs.length === queryLimit
      };
    } catch (error) {
      console.error('Error fetching posts:', error);
      throw error;
    }
  }
/**
   * Increment view count for a post
   */
static async incrementViewCount(postId, userId = null) {
    try {
      const postRef = doc(db, 'posts', postId);
      const postDoc = await getDoc(postRef);

      if (!postDoc.exists()) {
        throw new Error('Post not found');
      }

      const postData = postDoc.data();
      const updateData = {
        views: increment(1),
        'analytics.totalViews': increment(1),
        'analytics.lastUpdated': serverTimestamp()
      };

      if (userId && !postData.uniqueViewers?.includes(userId)) {
        updateData.uniqueViewers = arrayUnion(userId);
        updateData['analytics.uniqueViews'] = increment(1);
      }

      await updateDoc(postRef, updateData);
      return {
        success: true,
        views: (postData.views || 0) + 1
      };
    } catch (error) {
      console.error('Error incrementing view count:', error);
      throw error;
    }
  }

  /**
   * Report a post
   */
  static async reportPost(postId, userId, reason) {
    try {
      if (!userId) {
        throw new Error('Authentication required to report posts');
      }

      const report = {
        id: crypto.randomUUID(),
        userId,
        reason: reason?.trim(),
        timestamp: Timestamp.now(),
        status: 'pending'
      };

      await updateDoc(doc(db, 'posts', postId), {
        reports: arrayUnion(report),
        'analytics.lastUpdated': serverTimestamp()
      });

      return true;
    } catch (error) {
      console.error('Error reporting post:', error);
      throw error;
    }
  }

  /**
   * Get user's draft posts
   */
  static async getDraftPosts(userId, limit = 10) {
    try {
      if (!userId) {
        throw new Error('User ID is required');
      }

      const postsQuery = query(
        collection(db, 'posts'),
        where('authorId', '==', userId),
        where('publishStatus', '==', 'draft'),
        orderBy('updatedAt', 'desc'),
        limit(limit)
      );

      const querySnapshot = await getDocs(postsQuery);
      return querySnapshot.docs
        .map(doc => this.formatPostData(doc))
        .filter(post => post !== null);
    } catch (error) {
      console.error('Error getting draft posts:', error);
      throw error;
    }
  }

  /**
   * Helper method to check if update fields are interaction-only
   */
  static isInteractionFields(updateData) {
    const interactionFields = [
      'likes',
      'likedBy',
      'views',
      'comments',
      'analytics',
      'bookmarkedBy',
      'uniqueViewers',
      'reports'
    ];

    return Object.keys(updateData).every(key => {
      if (key === 'analytics') {
        const analyticsFields = [
          'totalViews',
          'uniqueViews',
          'engagementRate',
          'shareCount',
          'timeSpent',
          'lastUpdated'
        ];
        return Object.keys(updateData.analytics).every(analyticsKey =>
          analyticsFields.includes(analyticsKey)
        );
      }
      return interactionFields.includes(key);
    });
  }

  /**
   * Helper method to sanitize update data
   */
  static sanitizeUpdateData(data) {
    if (!data || typeof data !== 'object') {
      return {};
    }

    const sanitized = { ...data };
    
    // Trim string fields
    const stringFields = ['title', 'subtitle', 'content', 'category'];
    stringFields.forEach(field => {
      if (typeof sanitized[field] === 'string') {
        sanitized[field] = sanitized[field].trim();
      }
    });

    // Filter empty tags
    if (Array.isArray(sanitized.tags)) {
      sanitized.tags = sanitized.tags.filter(tag => tag && tag.trim());
    }

    // Ensure arrays exist and are arrays
    const arrayFields = ['likedBy', 'bookmarkedBy', 'comments', 'reports', 'uniqueViewers', 'tags'];
    arrayFields.forEach(field => {
      if (sanitized[field] && !Array.isArray(sanitized[field])) {
        sanitized[field] = [];
      }
    });

    // Ensure analytics object structure
    if (sanitized.analytics && typeof sanitized.analytics === 'object') {
      sanitized.analytics = {
        totalViews: sanitized.analytics.totalViews || 0,
        uniqueViews: sanitized.analytics.uniqueViews || 0,
        engagementRate: sanitized.analytics.engagementRate || 0,
        shareCount: sanitized.analytics.shareCount || 0,
        timeSpent: sanitized.analytics.timeSpent || 0,
        lastUpdated: Timestamp.now()
      };
    }

    // Remove any undefined or null values
    Object.keys(sanitized).forEach(key => {
      if (sanitized[key] === undefined || sanitized[key] === null) {
        delete sanitized[key];
      }
    });

    return sanitized;
  }

  /**
   * Helper method to check admin status
   */
  static async isUserAdmin(userId) {
    try {
      if (!userId) return false;
      const userDoc = await getDoc(doc(db, 'admins', userId));
      return userDoc.exists();
    } catch {
      return false;
    }
  }

  /**
   * Helper method to batch get posts
   */
  static async batchGetPosts(postIds) {
    try {
      if (!Array.isArray(postIds) || postIds.length === 0) {
        return [];
      }

      const posts = [];
      const chunks = [];

      // Split into chunks of 10 due to Firestore limitations
      for (let i = 0; i < postIds.length; i += 10) {
        chunks.push(postIds.slice(i, i + 10));
      }

      for (const chunk of chunks) {
        const batchPromises = chunk.map(postId => 
          getDoc(doc(db, 'posts', postId))
        );
        
        const batchResults = await Promise.all(batchPromises);
        
        batchResults.forEach(doc => {
          const formattedPost = this.formatPostData(doc);
          if (formattedPost) {
            posts.push(formattedPost);
          }
        });
      }

      return posts;
    } catch (error) {
      console.error('Error batch getting posts:', error);
      throw error;
    }
  }
}

export default PostsService;