import {
    Coffee,
    Gauge,
    Battery,
    Activity,
    Flame,
    Zap,
    TrendingUp,
    Sparkles,
    ShieldCheck,
    Heart
} from 'lucide-react';

export const fastingStages = [
    {
        hour: 0,
        label: 'Fed State',
        benefit: 'Digestive phase active',
        detailedBenefits: [
            'Blood glucose and insulin levels elevated',
            'Digestive processes active',
            'Nutrient absorption and storage'
        ],
        icon: Coffee,
        color: 'bg-gray-50 text-gray-500'
    },
    {
        hour: 4,
        label: 'Post-Absorptive State',
        benefit: 'Transition to stored energy',
        detailedBenefits: [
            'Blood glucose returning to baseline',
            'Insulin levels declining',
            'Beginning glycogen utilization'
        ],
        icon: Gauge,
        color: 'bg-blue-50 text-blue-500'
    },
    {
        hour: 12,
        label: 'Glycogen Depletion',
        benefit: 'Liver glycogen decreasing',
        detailedBenefits: [
            'Glycogen stores diminishing',
            'Glucagon levels rising',
            'Increased fat oxidation beginning'
        ],
        icon: Battery,
        color: 'bg-purple-50 text-purple-500'
    },
    {
        hour: 16,
        label: 'Early Ketosis',
        benefit: 'Metabolic switch initiating',
        detailedBenefits: [
            'Ketone production starting',
            'Fat oxidation increasing',
            'Growth hormone rising'
        ],
        icon: Activity,
        color: 'bg-indigo-50 text-indigo-500'
    },
    {
        hour: 24,
        label: 'Ketosis',
        benefit: 'Fat-adapted state',
        detailedBenefits: [
            'Significant ketone production',
            'Enhanced fat oxidation',
            'Decreased inflammation markers'
        ],
        icon: Flame,
        color: 'bg-orange-50 text-orange-500'
    },
    {
        hour: 36,
        label: 'Autophagy',
        benefit: 'Cellular cleanup begins',
        detailedBenefits: [
            'Autophagy pathways activated',
            'Cellular repair increasing',
            'Protein recycling enhanced'
        ],
        icon: Sparkles,
        color: 'bg-green-50 text-green-500'
    },
    {
        hour: 48,
        label: 'Enhanced Autophagy',
        benefit: 'Deep cellular renewal',
        detailedBenefits: [
            'Maximum autophagy',
            'Increased stem cell activation',
            'Significant immune system changes'
        ],
        icon: Heart,
        color: 'bg-pink-50 text-pink-500'
    }
];