import { useState, useCallback } from 'react';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { app } from '../firebase';
const storage = getStorage(app);
const auth = getAuth(app);

export const useImageUpload = (userId) => {
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);

  const uploadImage = useCallback(async (file) => {
    if (!file || !userId) {
      throw new Error('File and user ID are required');
    }

    try {
      setIsUploading(true);
      setError(null);

      // Get fresh auth token
      const token = await auth.currentUser?.getIdToken(true);
      if (!token) {
        throw new Error('Authentication required');
      }

      // Validate file
      if (!file.type.startsWith('image/')) {
        throw new Error('File must be an image');
      }

      if (file.size > 5 * 1024 * 1024) {
        throw new Error('Image must be smaller than 5MB');
      }

      // Create file path
      const timestamp = Date.now();
      const extension = file.name.split('.').pop().toLowerCase();
      const filename = `${timestamp}-${Math.random().toString(36).substr(2, 9)}.${extension}`;
      const path = `posts/${userId}/${filename}`;

      // Create storage reference
      const storageRef = ref(storage, path);

      // Prepare metadata
      const metadata = {
        contentType: file.type,
        customMetadata: {
          userId,
          createdAt: new Date().toISOString(),
          originalName: file.name,
          'access-control-allow-origin': '*',
          'access-control-allow-methods': 'GET, POST, PUT, DELETE, OPTIONS',
          'access-control-expose-headers': 'Content-Length',
          'access-control-allow-headers': 'Content-Type, Authorization'
        },
        cacheControl: 'public,max-age=3600'
      };

      // Start upload
      const uploadTask = uploadBytesResumable(storageRef, file, metadata);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress(progress);
          },
          (error) => {
            console.error('Upload error:', error);
            setIsUploading(false);
            setError(error.message);
            reject(error);
          },
          async () => {
            try {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              setIsUploading(false);
              setProgress(100);
              resolve({
                url: downloadURL,
                path,
                filename,
                metadata
              });
            } catch (error) {
              console.error('Download URL error:', error);
              setIsUploading(false);
              setError(error.message);
              reject(error);
            }
          }
        );
      });
    } catch (error) {
      console.error('Pre-upload error:', error);
      setIsUploading(false);
      setError(error.message);
      throw error;
    }
  }, [userId]);

  const resetUploadState = useCallback(() => {
    setIsUploading(false);
    setProgress(0);
    setError(null);
  }, []);

  return {
    uploadImage,
    isUploading,
    progress,
    error,
    resetUploadState
  };
};

export default useImageUpload;