import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, collection, addDoc, getDocs, Timestamp } from 'firebase/firestore';
import { auth, db } from '../../../firebase';
import { 
    Calendar, 
    User, 
    Scale, 
    Ruler, 
    Activity,
    Heart,
    Target,
    AlertCircle,
    Check,
    ChevronRight,
    ChevronLeft,
    ArrowRight
} from 'lucide-react';

const CONSTANTS = {
    MEASUREMENT_SYSTEMS: {
        METRIC: 'metric',
        IMPERIAL: 'imperial'
    },
    GENDERS: {
        MALE: 'male',
        FEMALE: 'female'
    },
    ACTIVITY_LEVELS: {
        SEDENTARY: { 
            title: 'Sedentary',
            description: 'Little or no exercise',
            examples: 'Office work, driving',
            multiplier: 1.2
        },
        LIGHTLY_ACTIVE: {
            title: 'Lightly Active',
            description: '1-3 days/week',
            examples: 'Walking, light housework',
            multiplier: 1.375
        },
        MODERATELY_ACTIVE: {
            title: 'Moderately Active',
            description: '3-5 days/week',
            examples: 'Jogging, cycling',
            multiplier: 1.55
        },
        VERY_ACTIVE: {
            title: 'Very Active',
            description: '6-7 days/week',
            examples: 'Hard exercise daily',
            multiplier: 1.725
        },
        EXTREMELY_ACTIVE: {
            title: 'Extremely Active',
            description: 'Very hard exercise & physical job',
            examples: 'Athletic training',
            multiplier: 1.9
        }
    }
};

// Helper Components
const ProgressSteps = ({ currentStep, steps }) => (
    <div className="mb-8">
        <div className="relative">
            <div className="overflow-hidden h-1.5 flex rounded-full bg-gray-100">
                <div 
                    className="transition-all duration-500 h-full bg-gradient-to-r from-blue-600 to-indigo-600 rounded-full"
                    style={{ width: `${(currentStep / steps.length) * 100}%` }}
                />
            </div>
            <div className="mt-4 grid grid-cols-4 gap-2">
                {steps.map((step) => (
                    <div key={step.number} className="text-center">
                        <div className={`
                            w-8 h-8 mx-auto rounded-full flex items-center justify-center
                            transition-all duration-200 mb-2
                            ${currentStep >= step.number 
                                ? 'bg-blue-600 text-white' 
                                : 'bg-gray-100 text-gray-400'}
                        `}>
                            {currentStep > step.number ? (
                                <Check className="w-5 h-5" />
                            ) : (
                                step.number
                            )}
                        </div>
                        <div className={`text-xs font-medium ${
                            currentStep >= step.number ? 'text-gray-900' : 'text-gray-400'
                        }`}>
                            {step.title}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div>
);

const FormField = ({ label, error, children }) => (
    <div className="space-y-1">
        <label className="block text-sm font-medium text-gray-700">
            {label}
        </label>
        {children}
        {error && (
            <p className="text-sm text-red-600 flex items-center gap-1">
                <AlertCircle className="w-4 h-4" />
                {error}
            </p>
        )}
    </div>
);

const OnboardingPage = () => {
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(1);
    
    const [state, setState] = useState({
        loading: true,
        submitting: false,
        error: '',
        errors: {},
        riskFactors: [],
        healthGoals: []
    });

    const [formData, setFormData] = useState({
        name: '',
        dateOfBirth: '',
        measurementSystem: CONSTANTS.MEASUREMENT_SYSTEMS.METRIC,
        height: { feet: '', inches: '', cm: '' },
        weight: { lb: '', kg: '' },
        selectedRiskFactors: [],
        selectedHealthGoals: [],
        gender: '',
        activityLevel: ''
    });

    const steps = [
        { number: 1, title: 'Basic Info' },
        { number: 2, title: 'Measurements' },
        { number: 3, title: 'Activity' },
        { number: 4, title: 'Health Goals' }
    ];

    // Fetch data from Firebase
    const fetchData = useCallback(async () => {
        try {
            const [riskFactorsSnap, healthGoalsSnap] = await Promise.all([
                getDocs(collection(db, 'riskFactors')),
                getDocs(collection(db, 'healthGoals'))
            ]);

            setState(prev => ({
                ...prev,
                riskFactors: riskFactorsSnap.docs.map(doc => ({ id: doc.id, ...doc.data() })),
                healthGoals: healthGoalsSnap.docs.map(doc => ({ id: doc.id, ...doc.data() })),
                loading: false
            }));
        } catch (err) {
            console.error('Error fetching data:', err);
            setState(prev => ({
                ...prev,
                error: 'Failed to load necessary data',
                loading: false
            }));
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    // Input handlers
    const handleNumberInput = (value, type, field) => {
        if (value === '') {
            setFormData(prev => ({
                ...prev,
                [type]: { ...prev[type], [field]: '' }
            }));
            return;
        }

        const newValue = value.replace(/[^\d.]/g, '');
        setFormData(prev => ({
            ...prev,
            [type]: { ...prev[type], [field]: newValue }
        }));
    };

    // Validation
    const validateStep = (step) => {
        const errors = {};
        
        switch (step) {
            case 1:
                if (!formData.name.trim()) errors.name = 'Name is required';
                if (!formData.dateOfBirth) errors.dateOfBirth = 'Date of birth is required';
                if (!formData.gender) errors.gender = 'Please select your gender';
                break;
            case 2:
                if (formData.measurementSystem === CONSTANTS.MEASUREMENT_SYSTEMS.METRIC) {
                    if (!formData.height.cm) errors.height = 'Height is required';
                    if (!formData.weight.kg) errors.weight = 'Weight is required';
                } else {
                    if (!formData.height.feet) errors.height = 'Height is required';
                    if (!formData.weight.lb) errors.weight = 'Weight is required';
                }
                break;
            case 3:
                if (!formData.activityLevel) errors.activityLevel = 'Please select your activity level';
                break;
            default:
                break;
        }

        return errors;
    };

    // Navigation handlers
    const handleNext = () => {
        const errors = validateStep(currentStep);
        if (Object.keys(errors).length === 0) {
            setCurrentStep(prev => Math.min(prev + 1, 4));
            setState(prev => ({ ...prev, errors: {} }));
        } else {
            setState(prev => ({ ...prev, errors }));
        }
    };

    const handleBack = () => {
        setCurrentStep(prev => Math.max(prev - 1, 1));
        setState(prev => ({ ...prev, errors: {} }));
    };
// Form submission handler
const handleSubmit = async (e) => {
    e.preventDefault();
    setState(prev => ({ ...prev, submitting: true }));

    try {
        const currentUser = auth.currentUser;
        if (!currentUser) throw new Error('No authenticated user found');

        const now = Timestamp.now();
        
        const profileData = {
            name: formData.name.trim(),
            dateOfBirth: formData.dateOfBirth,
            gender: formData.gender,
            height: formData.measurementSystem === CONSTANTS.MEASUREMENT_SYSTEMS.METRIC 
                ? parseFloat(formData.height.cm)
                : (parseInt(formData.height.feet) * 30.48 + parseInt(formData.height.inches || 0) * 2.54),
            weight: formData.measurementSystem === CONSTANTS.MEASUREMENT_SYSTEMS.METRIC
                ? parseFloat(formData.weight.kg)
                : (parseFloat(formData.weight.lb) * 0.453592),
            activityLevel: formData.activityLevel,
            selectedRiskFactors: formData.selectedRiskFactors,
            selectedHealthGoals: formData.selectedHealthGoals,
            measurementPreference: formData.measurementSystem,
            profileCompleted: true,
            updatedAt: now
        };

        await Promise.all([
            updateDoc(doc(db, 'users', currentUser.uid), profileData),
            addDoc(collection(db, `users/${currentUser.uid}/weightHistory`), {
                weight: profileData.weight,
                date: now,
                notes: 'Initial weight entry',
                createdAt: now,
                updatedAt: now
            })
        ]);

        navigate('/dashboard');
    } catch (err) {
        console.error('Profile completion error:', err);
        setState(prev => ({
            ...prev,
            error: err.message || 'Failed to complete profile',
            submitting: false
        }));
    }
};

// Loading state
if (state.loading) {
    return (
        <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-gray-50 to-blue-50">
            <div className="text-center space-y-3">
                <div className="w-16 h-16 mx-auto border-4 border-blue-200 border-t-blue-600 rounded-full animate-spin" />
                <p className="text-gray-600">Loading your profile...</p>
            </div>
        </div>
    );
}

// Main render
return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-blue-50 py-8 px-4">
        <div className="max-w-2xl mx-auto">
            {/* Header */}
            <div className="text-center mb-8">
                <h1 className="text-3xl font-bold text-gray-900 mb-2">
                    {currentStep === 4 ? "Final Step!" : "Complete Your Profile"}
                </h1>
                <p className="text-gray-600">
                    {currentStep === 1 && "Let's start with some basic information"}
                    {currentStep === 2 && "Now, let's get your body measurements"}
                    {currentStep === 3 && "Tell us about your activity level"}
                    {currentStep === 4 && "Choose your health goals"}
                </p>
            </div>

            {/* Progress Steps */}
            <ProgressSteps currentStep={currentStep} steps={steps} />

            {/* Error Banner */}
            {state.error && (
                <div className="mb-6 p-4 bg-red-50 border-l-4 border-red-500 rounded-r">
                    <div className="flex items-center space-x-3">
                        <AlertCircle className="w-5 h-5 text-red-500" />
                        <p className="text-red-700">{state.error}</p>
                    </div>
                </div>
            )}

            {/* Form */}
            <div className="bg-white rounded-2xl shadow-sm border border-gray-100">
                <form className="p-6 space-y-6">
                    {/* Step 1: Basic Information */}
                    {currentStep === 1 && (
                        <div className="space-y-6">
                            <FormField label="Full Name" error={state.errors.name}>
                                <div className="relative">
                                    <input
                                        type="text"
                                        value={formData.name}
                                        onChange={(e) => setFormData(prev => ({...prev, name: e.target.value}))}
                                        className="w-full pl-10 pr-4 py-2.5 rounded-lg border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                        placeholder="Enter your full name"
                                    />
                                    <User className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
                                </div>
                            </FormField>

                            <FormField label="Date of Birth" error={state.errors.dateOfBirth}>
                                <div className="relative">
                                    <input
                                        type="date"
                                        value={formData.dateOfBirth}
                                        onChange={(e) => setFormData(prev => ({...prev, dateOfBirth: e.target.value}))}
                                        className="w-full pl-10 pr-4 py-2.5 rounded-lg border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                        max={new Date().toISOString().split("T")[0]}
                                    />
                                    <Calendar className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
                                </div>
                            </FormField>

                            <FormField label="Gender" error={state.errors.gender}>
                                <div className="grid grid-cols-2 gap-4">
                                    {Object.values(CONSTANTS.GENDERS).map((gender) => (
                                        <label
                                            key={gender}
                                            className={`
                                                relative flex items-center justify-center p-3 rounded-lg border-2
                                                cursor-pointer transition-all duration-200
                                                ${formData.gender === gender
                                                    ? "border-blue-500 bg-blue-50 text-blue-700"
                                                    : "border-gray-200 hover:border-blue-200"}
                                            `}
                                        >
                                            <input
                                                type="radio"
                                                name="gender"
                                                value={gender}
                                                checked={formData.gender === gender}
                                                onChange={(e) => setFormData(prev => ({
                                                    ...prev,
                                                    gender: e.target.value
                                                }))}
                                                className="sr-only"
                                            />
                                            <span className="capitalize font-medium">{gender}</span>
                                        </label>
                                    ))}
                                </div>
                            </FormField>
                        </div>
                    )}

                    {/* Step 2: Measurements */}
                    {currentStep === 2 && (
                        <div className="space-y-6">
                            <div className="flex justify-center mb-6">
                                <div className="inline-flex rounded-lg border border-gray-200 p-1">
                                    {Object.entries(CONSTANTS.MEASUREMENT_SYSTEMS).map(([key, value]) => (
                                        <button
                                            key={key}
                                            type="button"
                                            onClick={() => setFormData(prev => ({
                                                ...prev,
                                                measurementSystem: value,
                                                height: { feet: "", inches: "", cm: "" },
                                                weight: { lb: "", kg: "" }
                                            }))}
                                            className={`
                                                px-4 py-2 rounded-md text-sm font-medium
                                                ${formData.measurementSystem === value
                                                    ? "bg-blue-600 text-white"
                                                    : "text-gray-600 hover:bg-gray-50"}
                                            `}
                                        >
                                            {key === "METRIC" ? "Metric (cm/kg)" : "Imperial (ft/lbs)"}
                                        </button>
                                    ))}
                                </div>
                            </div>

                            {formData.measurementSystem === CONSTANTS.MEASUREMENT_SYSTEMS.METRIC ? (
                                <>
                                    <FormField label="Height (cm)" error={state.errors.height}>
                                        <div className="relative">
                                            <input
                                                type="text"
                                                value={formData.height.cm}
                                                onChange={(e) => handleNumberInput(e.target.value, "height", "cm")}
                                                className="w-full pl-10 pr-12 py-2.5 rounded-lg border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                                placeholder="Enter height"
                                            />
                                            <Ruler className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
                                            <span className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500">cm</span>
                                        </div>
                                    </FormField>

                                    <FormField label="Weight (kg)" error={state.errors.weight}>
                                        <div className="relative">
                                            <input
                                                type="text"
                                                value={formData.weight.kg}
                                                onChange={(e) => handleNumberInput(e.target.value, "weight", "kg")}
                                                className="w-full pl-10 pr-12 py-2.5 rounded-lg border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                                placeholder="Enter weight"
                                            />
                                            <Scale className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
                                            <span className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500">kg</span>
                                        </div>
                                    </FormField>
                                </>
                            ) : (
                                <>
                                    <FormField label="Height" error={state.errors.height}>
                                        <div className="grid grid-cols-2 gap-4">
                                            <div className="relative">
                                                <input
                                                    type="text"
                                                    value={formData.height.feet}
                                                    onChange={(e) => handleNumberInput(e.target.value, "height", "feet")}
                                                    className="w-full pl-10 pr-12 py-2.5 rounded-lg border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                                    placeholder="Feet"
                                                />
                                                <Ruler className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
                                                <span className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500">ft</span>
                                            </div>
                                            <div className="relative">
                                                <input
                                                    type="text"
                                                    value={formData.height.inches}
                                                    onChange={(e) => handleNumberInput(e.target.value, "height", "inches")}
                                                    className="w-full pl-3 pr-12 py-2.5 rounded-lg border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                                    placeholder="Inches"
                                                />
                                                <span className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500">in</span>
                                            </div>
                                        </div>
                                    </FormField>

                                    <FormField label="Weight (lbs)" error={state.errors.weight}>
                                        <div className="relative">
                                            <input
                                                type="text"
                                                value={formData.weight.lb}
                                                onChange={(e) => handleNumberInput(e.target.value, "weight", "lb")}
                                                className="w-full pl-10 pr-12 py-2.5 rounded-lg border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                                placeholder="Enter weight"
                                            />
                                            <Scale className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
                                            <span className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500">lbs</span>
                                        </div>
                                    </FormField>
                                </>
                            )}
                        </div>
                    )}

                    {/* Step 3: Activity Level */}
                    {currentStep === 3 && (
                        <div className="space-y-4">
                            {Object.entries(CONSTANTS.ACTIVITY_LEVELS).map(([level, details]) => (
                                <label
                                    key={level}
                                    className={`
                                        block relative rounded-lg border-2 p-4
                                        cursor-pointer transition-all duration-200
                                        ${formData.activityLevel === level
                                            ? "border-blue-500 bg-blue-50"
                                            : "border-gray-200 hover:bg-gray-50"}
                                    `}
                                >
                                    <input
                                        type="radio"
                                        name="activityLevel"
                                        value={level}
                                        checked={formData.activityLevel === level}
                                        onChange={(e) => setFormData(prev => ({
                                            ...prev,
                                            activityLevel: e.target.value
                                        }))}
                                        className="sr-only"
                                    />
                                    <div className="flex items-center justify-between">
                                        <div>
                                            <h4 className="text-base font-medium text-gray-900">{details.title}</h4>
                                            <p className="text-sm text-gray-500 mt-1">{details.description}</p>
                                            <p className="text-xs text-gray-400 mt-1">Example: {details.examples}</p>
                                        </div>
                                        {formData.activityLevel === level && (
                                            <Check className="w-5 h-5 text-blue-500" />
                                        )}
                                    </div>
                                </label>
                            ))}
                        </div>
                    )}

                    {/* Step 4: Health Goals and Risk Factors */}
                    {currentStep === 4 && (
                            <div className="space-y-8">
                                {/* Health Goals */}
                                <div>
                                    <h3 className="text-lg font-medium text-gray-900 mb-4">Select Your Health Goals</h3>
                                    <div className="grid grid-cols-2 gap-4">
                                        {state.healthGoals.map((goal) => (
                                            <label
                                                key={goal.id}
                                                className={`
                                                    relative flex items-center p-4 rounded-lg border-2
                                                    cursor-pointer transition-all duration-200
                                                    ${formData.selectedHealthGoals.includes(goal.id)
                                                        ? "border-green-500 bg-green-50"
                                                        : "border-gray-200 hover:bg-gray-50"}
                                                `}
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked={formData.selectedHealthGoals.includes(goal.id)}
                                                    onChange={(e) => {
                                                        const newGoals = e.target.checked
                                                            ? [...formData.selectedHealthGoals, goal.id]
                                                            : formData.selectedHealthGoals.filter(id => id !== goal.id);
                                                        setFormData(prev => ({...prev, selectedHealthGoals: newGoals}));
                                                    }}
                                                    className="sr-only"
                                                />
                                                <div className="flex items-center space-x-3">
                                                    <div className={`
                                                        w-5 h-5 border-2 rounded flex items-center justify-center
                                                        ${formData.selectedHealthGoals.includes(goal.id)
                                                            ? "bg-green-500 border-green-500"
                                                            : "border-gray-300"}
                                                    `}>
                                                        {formData.selectedHealthGoals.includes(goal.id) && (
                                                            <Check className="w-3 h-3 text-white" />
                                                        )}
                                                    </div>
                                                    <span className="text-sm font-medium">{goal.name}</span>
                                                </div>
                                            </label>
                                        ))}
                                    </div>
                                </div>

                                {/* Risk Factors */}
                                <div>
                                    <h3 className="text-lg font-medium text-gray-900 mb-2">Health Risk Factors</h3>
                                    <p className="text-sm text-gray-500 mb-4">Select any that apply to you</p>
                                    <div className="grid grid-cols-2 gap-4">
                                        {state.riskFactors.map((factor) => (
                                            <label
                                                key={factor.id}
                                                className={`
                                                    relative flex items-center p-4 rounded-lg border-2
                                                    cursor-pointer transition-all duration-200
                                                    ${formData.selectedRiskFactors.includes(factor.id)
                                                        ? "border-yellow-500 bg-yellow-50"
                                                        : "border-gray-200 hover:bg-gray-50"}
                                                `}
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked={formData.selectedRiskFactors.includes(factor.id)}
                                                    onChange={(e) => {
                                                        const newFactors = e.target.checked
                                                            ? [...formData.selectedRiskFactors, factor.id]
                                                            : formData.selectedRiskFactors.filter(id => id !== factor.id);
                                                        setFormData(prev => ({...prev, selectedRiskFactors: newFactors}));
                                                    }}
                                                    className="sr-only"
                                                />
                                                <div className="flex items-center space-x-3">
                                                    <div className={`
                                                        w-5 h-5 border-2 rounded flex items-center justify-center
                                                        ${formData.selectedRiskFactors.includes(factor.id)
                                                            ? "bg-yellow-500 border-yellow-500"
                                                            : "border-gray-300"}
                                                    `}>
                                                        {formData.selectedRiskFactors.includes(factor.id) && (
                                                            <Check className="w-3 h-3 text-white" />
                                                        )}
                                                    </div>
                                                    <span className="text-sm font-medium">{factor.name}</span>
                                                </div>
                                            </label>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Navigation Buttons */}
                        <div className="flex justify-between pt-6">
                            {currentStep > 1 && (
                                <button
                                    type="button"
                                    onClick={handleBack}
                                    className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900"
                                >
                                    <ChevronLeft className="w-5 h-5 mr-1" />
                                    Back
                                </button>
                            )}
                            
                            <button
                                type="button"
                                onClick={currentStep === 4 ? handleSubmit : handleNext}
                                disabled={state.submitting}
                                className={`
                                    ml-auto inline-flex items-center px-6 py-2.5 rounded-lg
                                    text-white font-medium text-sm
                                    transition-all duration-200
                                    ${state.submitting
                                        ? "bg-gray-400 cursor-not-allowed"
                                        : "bg-gradient-to-r from-blue-600 to-indigo-600 hover:from-blue-700 hover:to-indigo-700"}
                                `}
                            >
                                {state.submitting ? (
                                    <div className="flex items-center">
                                        <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin mr-2" />
                                        Processing...
                                    </div>
                                ) : (
                                    <>
                                        <span>{currentStep === 4 ? "Complete Profile" : "Continue"}</span>
                                        <ArrowRight className="w-5 h-5 ml-2" />
                                    </>
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default OnboardingPage;