import React, { useState, useEffect } from 'react';
import { ThumbsUp, MessageSquare, Trash2, X } from 'lucide-react';

const DeleteDialog = ({ isOpen, onClose, onConfirm }) => {
  useEffect(() => {
    if (isOpen) {
      // Prevent background scrolling when dialog is open
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto" onClick={(e) => {
      if (e.target === e.currentTarget) onClose();
    }}>
      <div className="fixed inset-0 bg-black/50" aria-hidden="true" />
      <div className="flex min-h-full items-end sm:items-center justify-center p-0">
        <div className="relative w-full sm:max-w-lg transform rounded-t-xl sm:rounded-xl bg-white p-6 shadow-xl transition-all">
          <div className="flex justify-between items-start mb-4">
            <h3 className="text-lg font-semibold text-gray-900">Delete Suggestion</h3>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500 p-2"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
          <p className="text-gray-600 mb-6">
            Are you sure you want to delete this suggestion? This action cannot be undone.
          </p>
          <div className="flex flex-col-reverse sm:flex-row sm:justify-end gap-3">
            <button
              onClick={onClose}
              className="w-full sm:w-auto px-4 py-2.5 text-sm font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-lg"
            >
              Cancel
            </button>
            <button
              onClick={onConfirm}
              className="w-full sm:w-auto px-4 py-2.5 text-sm font-medium text-white bg-red-600 hover:bg-red-700 rounded-lg"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const SuggestionCard = ({ 
  suggestion, 
  currentUserId,
  newComment,
  onNewCommentChange,
  onVote, 
  onAddComment,
  onDelete,
  onToggleComments, 
  isSelected 
}) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  
  const getFormattedDate = (timestamp) => {
    if (!timestamp) return 'Unknown date';
    
    const date = timestamp?.seconds ? 
      new Date(timestamp.seconds * 1000) : 
      new Date(timestamp);
    
    return date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const handleDelete = async () => {
    await onDelete(suggestion.id);
    setShowDeleteDialog(false);
  };

  const isAuthor = suggestion.userId === currentUserId;

  return (
    <div className="bg-white hover:bg-gray-50 transition-colors rounded-lg border border-slate-200 shadow-sm overflow-hidden">
      <div className="p-4">
        {/* Main content - Responsive layout */}
        <div className="flex flex-col sm:flex-row sm:items-start justify-between gap-4">
          <div className="flex-1 min-w-0">
            <div className="flex items-start justify-between gap-2">
              <h3 className="font-medium text-gray-900 break-all whitespace-pre-wrap flex-1">
                {suggestion.title}
              </h3>
              {isAuthor && (
                <button
                  onClick={() => setShowDeleteDialog(true)}
                  className="flex-shrink-0 p-2 text-gray-400 hover:text-red-500 rounded-lg hover:bg-gray-100 transition-colors"
                  aria-label="Delete suggestion"
                >
                  <Trash2 className="w-4 h-4" />
                </button>
              )}
            </div>
            <div className="text-sm text-gray-500 mt-1">
              By {suggestion.userName} • {getFormattedDate(suggestion.createdAt)}
            </div>
            {suggestion.status && suggestion.status !== 'new' && (
              <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mt-2
                ${suggestion.status === 'inProgress' ? 'bg-blue-100 text-blue-800' : 
                  suggestion.status === 'completed' ? 'bg-green-100 text-green-800' : 
                  'bg-gray-100 text-gray-800'}`
              }>
                {suggestion.status.charAt(0).toUpperCase() + suggestion.status.slice(1)}
              </span>
            )}
          </div>

          {/* Action buttons - Stack on mobile */}
          <div className="flex items-center gap-2 sm:flex-shrink-0">
            <button
              onClick={() => onVote(suggestion.id)}
              className={`flex-1 sm:flex-none inline-flex items-center justify-center gap-1 px-3 py-2 rounded-lg border border-slate-200 text-sm font-medium transition-colors
                ${suggestion.votes?.includes(currentUserId)
                  ? 'bg-blue-50 text-blue-600 border-blue-200 hover:bg-blue-100'
                  : 'hover:bg-gray-50'
                }`}
            >
              <ThumbsUp className="w-4 h-4" />
              <span>{suggestion.votes?.length || 0}</span>
            </button>
            <button
              onClick={() => onToggleComments(suggestion.id)}
              className={`flex-1 sm:flex-none inline-flex items-center justify-center gap-1 px-3 py-2 rounded-lg border border-slate-200 text-sm font-medium transition-colors
                ${isSelected ? 'bg-gray-100' : 'hover:bg-gray-50'}`}
            >
              <MessageSquare className="w-4 h-4" />
              <span>{suggestion.comments?.length || 0}</span>
            </button>
          </div>
        </div>

        {/* Comments section */}
        {isSelected && (
          <div className="mt-4 space-y-4">
            {suggestion.comments?.map((comment, index) => (
              <div key={index} className="bg-gray-50 p-3 rounded-lg">
                <p className="text-sm text-gray-800 break-words whitespace-pre-wrap">{comment.text}</p>
                <div className="text-xs text-gray-500 mt-1">
                  {comment.userName} • {getFormattedDate(comment.createdAt)}
                </div>
              </div>
            ))}
            
            {/* Comment input - Full width on mobile */}
            <div className="flex flex-col sm:flex-row gap-2">
              <textarea
                value={newComment}
                onChange={(e) => onNewCommentChange(e.target.value)}
                placeholder="Add a comment..."
                className="flex-1 min-h-[80px] resize-none rounded-lg border border-slate-200 p-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
              <button
                onClick={() => onAddComment(suggestion.id)}
                disabled={!newComment?.trim()}
                className="w-full sm:w-auto px-4 py-2 bg-blue-600 text-white rounded-lg text-sm font-medium hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Comment
              </button>
            </div>
          </div>
        )}
      </div>

      {/* Delete Dialog */}
      <DeleteDialog
        isOpen={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        onConfirm={handleDelete}
      />
    </div>
  );
};

export default SuggestionCard;