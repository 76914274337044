import React, { useMemo } from 'react';
import { motion } from 'framer-motion';
import { Clock, Target } from 'lucide-react';

export const TimerRing = ({ 
  progress = 0, 
  size = 300, 
  strokeWidth = 20,
  targetHours = 0,
  elapsedHours = 0,
  remainingHours = 0 
}) => {
  // Validate and normalize progress
  const normalizedProgress = Math.min(Math.max(0, progress), 100);
  
  // Memoize calculated values
  const dimensions = useMemo(() => {
    const radius = (size - strokeWidth) / 2;
    const circumference = 2 * Math.PI * radius;
    const center = size / 2;
    const strokeDashoffset = circumference - (normalizedProgress / 100) * circumference;
    
    return { radius, circumference, center, strokeDashoffset };
  }, [size, strokeWidth, normalizedProgress]);

  const { radius, circumference, center, strokeDashoffset } = dimensions;

  // Memoize tick marks generation
  const tickMarks = useMemo(() => {
    const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
      const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
      return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians)
      };
    };

    const ticks = [];
    const tickCount = 48; // One tick every 30 minutes for 24 hours

    for (let i = 0; i < tickCount; i++) {
      const angle = (i * 360) / tickCount;
      const isHour = i % 2 === 0;
      const tickLength = isHour ? 10 : 5;
      const tickWidth = isHour ? 2 : 1;
      const tickRadius = radius + strokeWidth / 2;

      const startPoint = polarToCartesian(center, center, tickRadius - tickLength, angle);
      const endPoint = polarToCartesian(center, center, tickRadius, angle);

      ticks.push(
        <line
          key={i}
          x1={startPoint.x}
          y1={startPoint.y}
          x2={endPoint.x}
          y2={endPoint.y}
          stroke={isHour ? "#6366F1" : "#A5B4FC"}
          strokeWidth={tickWidth}
          strokeLinecap="round"
          opacity="0.3"
        />
      );
    }
    return ticks;
  }, [radius, strokeWidth, center]);

  // Calculate target position
  const targetPosition = useMemo(() => {
    if (!targetHours) return null;
    return {
      x: center + radius * Math.cos(-Math.PI / 2 + (2 * Math.PI * (targetHours / 24))),
      y: center + radius * Math.sin(-Math.PI / 2 + (2 * Math.PI * (targetHours / 24)))
    };
  }, [center, radius, targetHours]);

  return (
    <div className="relative flex items-center justify-center" style={{ width: size, height: size }}>
      {/* Background blur effect */}
      <div 
        className="absolute inset-0 bg-gradient-to-br from-blue-500/10 to-purple-500/10 rounded-full"
        style={{ 
          filter: 'blur(20px)',
          transform: 'scale(1.1)'
        }}
      />

      <svg 
        width={size} 
        height={size} 
        className="transform -rotate-90"
        style={{ filter: 'drop-shadow(0 0 10px rgba(99, 102, 241, 0.2))' }}
      >
        <defs>
          {/* Improved gradient definition */}
          <linearGradient id="ringGradient" gradientUnits="userSpaceOnUse">
            <stop offset="0%" stopColor="#3B82F6" />
            <stop offset="50%" stopColor="#6366F1" />
            <stop offset="100%" stopColor="#8B5CF6" />
          </linearGradient>

          {/* Enhanced glow effect */}
          <filter id="glow" x="-20%" y="-20%" width="140%" height="140%">
            <feGaussianBlur stdDeviation="4" result="coloredBlur" />
            <feFlood floodColor="#6366F1" floodOpacity="0.3" result="glowColor" />
            <feComposite in="glowColor" in2="coloredBlur" operator="in" result="softGlow" />
            <feMerge>
              <feMergeNode in="softGlow" />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>

        {/* Base ring with tick marks */}
        <g>
          {tickMarks}
          <circle
            cx={center}
            cy={center}
            r={radius}
            fill="none"
            stroke="#E2E8F0"
            strokeWidth={strokeWidth}
            opacity="0.3"
          />
        </g>

        {/* Target indicator */}
        {targetPosition && (
          <circle
            cx={targetPosition.x}
            cy={targetPosition.y}
            r={strokeWidth / 3}
            fill="#3B82F6"
            opacity="0.5"
          />
        )}

        {/* Progress ring with smooth animation */}
        <motion.circle
          cx={center}
          cy={center}
          r={radius}
          fill="none"
          stroke="url(#ringGradient)"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeDasharray={circumference}
          initial={{ strokeDashoffset: circumference }}
          animate={{ strokeDashoffset }}
          transition={{ 
            duration: 1.5,
            ease: [0.4, 0, 0.2, 1]
          }}
          filter="url(#glow)"
        />

        {/* Progress indicator dot */}
        <motion.circle
          cx={center + radius}
          cy={center}
          r={strokeWidth / 2.5}
          fill="#8B5CF6"
          initial={false}
          animate={{
            cx: center + radius * Math.cos(-Math.PI / 2 + (2 * Math.PI * normalizedProgress / 100)),
            cy: center + radius * Math.sin(-Math.PI / 2 + (2 * Math.PI * normalizedProgress / 100))
          }}
          transition={{ 
            type: "spring",
            stiffness: 200,
            damping: 20
          }}
          style={{
            filter: 'drop-shadow(0 0 4px rgba(139, 92, 246, 0.5))'
          }}
        />
      </svg>

      {/* Center content with time display */}
      {elapsedHours !== undefined && (
        <div className="absolute inset-0 flex flex-col items-center justify-center">
          <motion.div 
            className="text-4xl font-bold"
            style={{
              background: 'linear-gradient(135deg, #3B82F6 0%, #8B5CF6 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent'
            }}
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            {Math.floor(elapsedHours)}h {Math.floor((elapsedHours % 1) * 60)}m
          </motion.div>

          {remainingHours >= 0 && (
            <div className="flex items-center gap-4 mt-3">
              <div className="flex items-center gap-2 text-sm text-gray-500">
                <Clock className="w-4 h-4" />
                <span>{Math.max(0, remainingHours).toFixed(1)}h left</span>
              </div>
              <div className="w-1 h-1 bg-gray-300 rounded-full" />
              <div className="flex items-center gap-2 text-sm text-gray-500">
                <Target className="w-4 h-4" />
                <span>{targetHours}h target</span>
              </div>
            </div>
          )}

          <motion.div
            className="absolute bottom-12 text-sm font-medium text-blue-600"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            {normalizedProgress.toFixed(1)}% complete
          </motion.div>
        </div>
      )}
    </div>
  );
};

export default TimerRing;
