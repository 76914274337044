import React, { forwardRef } from 'react';
import { MessageCircle } from 'lucide-react';
import { motion } from 'framer-motion';

const EmptyComments = forwardRef(({ filter }, ref) => (
  <motion.div
    ref={ref}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    className="text-center py-12"
  >
    <div className="w-16 h-16 bg-gray-100 rounded-full flex items-center 
      justify-center mx-auto mb-4">
      <MessageCircle className="w-8 h-8 text-gray-400" />
    </div>
    <h3 className="text-lg font-semibold text-gray-900 mb-2">
      No comments yet
    </h3>
    <p className="text-gray-500 max-w-md mx-auto">
      {filter === 'all' 
        ? 'Be the first to share your thoughts!'
        : 'No comments match your current filter.'}
    </p>
    
    {filter === 'all' && (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        className="mt-6"
      >
        <p className="text-sm text-gray-400 mb-4">
          Start the discussion by leaving the first comment
        </p>
        <div className="flex justify-center gap-4">
          <button
            onClick={() => {
              const commentInput = document.querySelector('#comment-input-main');
              if (commentInput) {
                commentInput.focus();
                commentInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
              }
            }}
            className="px-6 py-2 bg-blue-600 text-white rounded-full text-sm font-medium
              hover:bg-blue-700 transition-colors transform hover:translate-y-[-1px]
              hover:shadow-md active:translate-y-0"
          >
            Write a comment
          </button>
        </div>
      </motion.div>
    )}

    {filter !== 'all' && (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        className="mt-6"
      >
        <button
          onClick={() => window.dispatchEvent(new CustomEvent('resetCommentFilter'))}
          className="px-6 py-2 bg-gray-100 text-gray-600 rounded-full text-sm font-medium
            hover:bg-gray-200 transition-colors"
        >
          Show all comments
        </button>
      </motion.div>
    )}
  </motion.div>
));

EmptyComments.displayName = 'EmptyComments';

export default EmptyComments;