import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Heart, MessageSquare, Share2, MoreHorizontal, ExternalLink,
  Edit3, Trash2, Flag, Copy, PencilLine, Bookmark,
  Clock, Eye, Tag, ChevronDown, Link as LinkIcon,
  Twitter, Facebook, Linkedin, ImageOff
} from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';
import { motion, AnimatePresence } from 'framer-motion';
import { processContent, extractPreviewText, processImageUrl } from './contentProcessor';

const PostCard = ({ 
  post, 
  currentUser, 
  onLike, 
  onBookmark, 
  onEdit,
  onDelete,
  onReport,
  isPreview = false
}) => {
  const navigate = useNavigate();
  const basePath = isPreview ? '/preview' : '/dashboard';
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showShareMenu, setShowShareMenu] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [processedContent, setProcessedContent] = useState('');
  const [imageUrl, setImageUrl] = useState(null);
  const [imageError, setImageError] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);
  
  const dropdownRef = useRef(null);
  const contentRef = useRef(null);

  const isAuthor = currentUser?.uid === post.authorId;

  // Process content
  useEffect(() => {
    if (post?.content) {
      const processed = isExpanded ? 
        processContent(post.content) : 
        extractPreviewText(post.content);
      setProcessedContent(processed);
    }
  }, [post?.content, isExpanded]);

  // Process image
  useEffect(() => {
    let isMounted = true;

    const loadImage = async () => {
      if (!post?.coverImage) {
        if (isMounted) {
          setImageUrl(null);
          setImageLoading(false);
        }
        return;
      }

      try {
        const url = await processImageUrl(post.coverImage);
        if (isMounted) {
          setImageUrl(url);
          setImageError(false);
          setImageLoading(false);
        }
      } catch (error) {
        console.error('Error loading image:', error);
        if (isMounted) {
          setImageError(true);
          setImageLoading(false);
        }
      }
    };

    setImageLoading(true);
    loadImage();

    return () => {
      isMounted = false;
    };
  }, [post?.coverImage]);

  // Handle click outside dropdowns
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
        setShowShareMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Update bookmark state
  useEffect(() => {
    setIsBookmarked(post?.bookmarkedBy?.includes(currentUser?.uid) || false);
  }, [post?.bookmarkedBy, currentUser?.uid]);

  const handlePostClick = (e) => {
    if (e.target.closest('button') || e.target.closest('a') || 
        e.target.closest('.interactive-element')) {
      return;
    }
    // Allow viewing posts without authentication
    navigate(`${basePath}/community/post/${post.id}`);
  };

  const handleShare = async (platform = 'copy') => {
    const postUrl = `${window.location.origin}${basePath}/community/post/${post.id}`;
    const title = post.title || 'Check out this post';

    try {
      switch (platform) {
        case 'twitter':
          window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(postUrl)}&text=${encodeURIComponent(title)}`);
          break;
        case 'linkedin':
          window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(postUrl)}`);
          break;
        case 'facebook':
          window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(postUrl)}`);
          break;
        case 'copy':
        default:
          await navigator.clipboard.writeText(postUrl);
          setShowTooltip(true);
          setTimeout(() => setShowTooltip(false), 2000);
      }
      setShowShareMenu(false);
    } catch (error) {
      console.error('Share failed:', error);
    }
  };

  return (
    <motion.article 
      layout
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      whileHover={{ scale: 1.01 }}
      transition={{ duration: 0.2 }}
      onClick={handlePostClick}
      className="bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-200 overflow-hidden cursor-pointer"
    >
      {/* Cover Image */}
      {post.coverImage && (
        <div className="relative w-full aspect-[16/9] overflow-hidden">
          {imageLoading ? (
            <div className="w-full h-full bg-gray-100 animate-pulse flex items-center justify-center">
              <div className="w-8 h-8 border-4 border-blue-600 border-t-transparent rounded-full animate-spin" />
            </div>
          ) : imageUrl && !imageError ? (
            <img
              src={imageUrl}
              alt={post.title || 'Post cover'}
              className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
              loading="lazy"
              onError={() => setImageError(true)}
            />
          ) : (
            <div className="w-full h-full bg-gray-100 flex items-center justify-center">
              <ImageOff className="w-8 h-8 text-gray-400" />
            </div>
          )}
        </div>
      )}

      <div className="p-6">
        {/* Author Info */}
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-3">
            <div className="w-10 h-10 rounded-full bg-gradient-to-br from-blue-500 to-blue-700 
              flex items-center justify-center text-white font-medium shadow-sm">
              {post.authorEmail?.[0]?.toUpperCase() || '?'}
            </div>
            <div>
              <div className="font-medium text-gray-900">
                {post.authorEmail?.split('@')[0] || 'Anonymous'}
              </div>
              <div className="flex items-center gap-1.5 text-sm text-gray-500">
                <time dateTime={post.createdAt}>
                  {formatDistanceToNow(new Date(post.createdAt), { addSuffix: true })}
                </time>
                <span>·</span>
                <span className="flex items-center gap-1">
                  <Clock className="w-4 h-4" />
                  {post.readTime || '5 min read'}
                </span>
                <span>·</span>
                <span className="flex items-center gap-1">
                  <Eye className="w-4 h-4" />
                  {post.views || 0} views
                </span>
              </div>
            </div>
          </div>

          {/* Post Actions Dropdown */}
          {currentUser && (isAuthor || currentUser.isAdmin) && (
            <div className="relative" ref={dropdownRef}>
              <button 
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDropdown(!showDropdown);
                }}
                className="p-2 text-gray-500 hover:text-gray-700 hover:bg-gray-100 
                  rounded-full transition-colors"
              >
                <MoreHorizontal className="w-5 h-5" />
              </button>
              
              <AnimatePresence>
                {showDropdown && (
                  <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 10 }}
                    className="absolute right-0 mt-2 w-56 bg-white rounded-lg shadow-lg 
                      border border-gray-200 py-1 z-10"
                  >
                    {isAuthor && (
                      <>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            onEdit(post);
                            setShowDropdown(false);
                          }}
                          className="w-full px-4 py-2 text-left text-sm hover:bg-gray-50 
                            flex items-center gap-2 text-blue-600"
                        >
                          <Edit3 className="w-4 h-4" />
                          Edit post
                        </button>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            onDelete(post.id);
                            setShowDropdown(false);
                          }}
                          className="w-full px-4 py-2 text-left text-sm hover:bg-gray-50 
                            flex items-center gap-2 text-red-600"
                        >
                          <Trash2 className="w-4 h-4" />
                          Delete post
                        </button>
                      </>
                    )}
                    {!isAuthor && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          onReport(post.id);
                          setShowDropdown(false);
                        }}
                        className="w-full px-4 py-2 text-left text-sm hover:bg-gray-50 
                          flex items-center gap-2 text-amber-600"
                      >
                        <Flag className="w-4 h-4" />
                        Report post
                      </button>
                    )}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          )}
        </div>

        {/* Post Content */}
        <div className="space-y-4">
          <h2 className="text-xl sm:text-2xl font-bold text-gray-900 leading-tight 
            group-hover:text-blue-600 transition-colors line-clamp-2">
            {post.title || 'Untitled Post'}
          </h2>
          
          {post.subtitle && (
            <p className="text-gray-600 text-lg line-clamp-2">{post.subtitle}</p>
          )}

          <div 
            ref={contentRef}
            className={`prose prose-sm sm:prose-base max-w-none ${
              !isExpanded ? 'line-clamp-3' : ''
            }`}
            dangerouslySetInnerHTML={{ __html: processedContent }}
          />
        </div>

        {/* Post Footer */}
        <div className="flex items-center justify-between mt-6 pt-4 border-t border-gray-100">
          <div className="flex items-center gap-4">
            <button
              onClick={(e) => {
                e.stopPropagation();
                if (currentUser) {
                  onLike(post.id);
                } else {
                  navigate(`/auth?mode=login&returnUrl=${encodeURIComponent(basePath + '/community')}`);
                }
              }}
              className={`flex items-center gap-1.5 text-sm ${
                currentUser && post.likedBy?.includes(currentUser?.uid)
                  ? 'text-pink-600'
                  : 'text-gray-600 hover:text-pink-600'
              } transition-colors`}
            >
              <Heart className={`w-5 h-5 ${
                currentUser && post.likedBy?.includes(currentUser?.uid) ? 'fill-current' : ''
              }`} />
              <span>{post.likes || 0}</span>
            </button>

            <button
              onClick={(e) => {
                e.stopPropagation();
                navigate(`${basePath}/community/post/${post.id}#comments`);
              }}
              className="flex items-center gap-1.5 text-sm text-gray-600 hover:text-gray-900 
                transition-colors"
            >
              <MessageSquare className="w-5 h-5" />
              <span>{post.comments?.length || 0}</span>
            </button>
          </div>

          <div className="flex items-center gap-3">
            {currentUser && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onBookmark(post.id);
                }}
                className={`p-1.5 rounded-full ${
                  isBookmarked
                    ? 'text-blue-600'
                    : 'text-gray-600 hover:text-blue-600'
                } transition-colors`}
              >
                <Bookmark className={`w-5 h-5 ${isBookmarked ? 'fill-current' : ''}`} />
              </button>
            )}

            <div className="relative">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setShowShareMenu(!showShareMenu);
                }}
                className="p-1.5 rounded-full text-gray-600 hover:text-gray-900 
                  transition-colors"
              >
                <Share2 className="w-5 h-5" />
              </button>

              <AnimatePresence>
                {showShareMenu && (
                  <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 10 }}
                    className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg 
                      border border-gray-200 py-1 z-10"
                  >
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleShare('twitter');
                      }}
                      className="w-full px-4 py-2 text-left text-sm hover:bg-gray-50 
                        flex items-center gap-3"
                    >
                      <Twitter className="w-4 h-4 text-[#1DA1F2]" />
                      Share on Twitter
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleShare('linkedin');
                      }}
                      className="w-full px-4 py-2 text-left text-sm hover:bg-gray-50 
                        flex items-center gap-3"
                    >
                      <Linkedin className="w-4 h-4 text-[#0A66C2]" />
                      Share on LinkedIn
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleShare('facebook');
                      }}
                      className="w-full px-4 py-2 text-left text-sm hover:bg-gray-50 
                        flex items-center gap-3"
                    >
                      <Facebook className="w-4 h-4 text-[#1877F2]" />
                      Share on Facebook
                    </button>
                    <hr className="my-1 border-gray-200" />
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleShare('copy');
                      }}
                      className="w-full px-4 py-2 text-left text-sm hover:bg-gray-50 
                        flex items-center gap-3"
                    >
                      <LinkIcon className="w-4 h-4" />
                      Copy link
                    </button>
                  </motion.div>
                )}
              </AnimatePresence>

              <AnimatePresence>
                {showTooltip && (
                  <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 10 }}
                    className="absolute bottom-full right-0 mb-2 px-3 py-1 bg-gray-900 
                      text-white text-xs rounded shadow-lg whitespace-nowrap"
                  >
                    Link copied!
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </div>

        {/* Tags */}
        {post.tags && post.tags.length > 0 && (
          <div className="flex flex-wrap gap-2 mt-4">
            {post.tags.map(tag => (
              <span
                key={tag}
                className="px-3 py-1 bg-gray-100 text-gray-600 rounded-full text-sm 
                  hover:bg-gray-200 transition-colors cursor-pointer flex items-center gap-1"
                onClick={(e) => e.stopPropagation()}
              >
                <Tag className="w-3 h-3" />
                {tag}
              </span>
            ))}
          </div>
        )}
      </div>
    </motion.article>
  );
};

export default PostCard;