// src/components/user/GoalsDashboard/Fasting/Components/CurrentFastProgress/Electrolytes/ElectrolyteTracker.js
import React, { useState } from 'react';
import { 
  AlertTriangle, 
  Shield, 
  Info, 
  ChevronDown,
  ChevronUp,
  Clock
} from 'lucide-react';
import { calculateElectrolyteNeeds, getSafetyStatus } from './electrolyteTracking';
import { SupplementationGuide } from './SupplementationGuide';



export const ElectrolyteTracker = ({ 
  fastingHours,
  activityLevel = 'moderate',
  onLog
}) => {
  const [activeView, setActiveView] = useState('overview'); // 'overview' | 'supplements' | 'monitoring'
  const electrolytes = calculateElectrolyteNeeds(fastingHours, activityLevel);
  const safetyStatus = getSafetyStatus(fastingHours);

  const NavigationTabs = () => (
    <div className="flex space-x-2 mb-4">
      {[
        { id: 'overview', label: 'Overview' },
        { id: 'supplements', label: 'Supplementation' },
        { id: 'monitoring', label: 'Monitoring' }
      ].map(tab => (
        <button
          key={tab.id}
          onClick={() => setActiveView(tab.id)}
          className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors
            ${activeView === tab.id 
              ? 'bg-blue-100 text-blue-800' 
              : 'bg-gray-100 text-gray-600 hover:bg-gray-200'}`}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );

  return (
    <div className="space-y-6">
      {/* Safety Status */}
      <div className={`p-4 rounded-lg ${safetyStatus.color} flex items-center gap-3`}>
        <AlertTriangle className="w-5 h-5" />
        <p className="text-sm font-medium">{safetyStatus.message}</p>
      </div>

      <NavigationTabs />

      {activeView === 'overview' && (
        <div className="space-y-4">
          {/* Quick status cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {Object.entries(electrolytes).map(([name, data]) => (
              <div key={name} className="bg-white rounded-xl p-4 shadow-sm">
                <div className="flex justify-between items-center mb-2">
                  <h3 className="font-medium capitalize">{name}</h3>
                  <span className={`px-2 py-1 rounded-full text-xs font-medium
                    ${data.riskLevel > 2 ? 'bg-red-100 text-red-800' :
                      data.riskLevel > 1 ? 'bg-yellow-100 text-yellow-800' :
                      'bg-green-100 text-green-800'}`}>
                    {data.phase.charAt(0).toUpperCase() + data.phase.slice(1)}
                  </span>
                </div>
                <p className="text-sm text-gray-600">{data.recommendation}</p>
              </div>
            ))}
          </div>
        </div>
      )}

      {activeView === 'supplements' && (
        <SupplementationGuide 
          fastingHours={fastingHours}
          activityLevel={activityLevel}
          onLog={onLog}
        />
      )}

      {activeView === 'monitoring' && (
        <div className="space-y-4">
          {Object.entries(electrolytes).map(([name, data]) => (
            <div key={name} className="bg-white rounded-xl p-4 shadow-sm">
              <h3 className="font-medium capitalize mb-3">{name}</h3>
              {data.details.deficiencySigns && (
                <div className="space-y-2">
                  <h4 className="text-sm font-medium text-gray-700">Monitor for:</h4>
                  <ul className="grid grid-cols-2 gap-2">
                    {data.details.deficiencySigns.map((sign, idx) => (
                      <li key={idx} className="text-sm text-gray-600 flex items-center gap-2">
                        <div className="w-1.5 h-1.5 rounded-full bg-blue-400" />
                        {sign}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};