import React, { useState, useEffect } from 'react';
import { collection, addDoc, updateDoc, deleteDoc, doc, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import {
  ShieldAlert,
  Plus,
  Edit2,
  Trash2,
  AlertCircle,
  X,
  Save,
  Search
} from 'lucide-react';

const RiskFactors = () => {
  const [riskFactors, setRiskFactors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentFactor, setCurrentFactor] = useState({
    name: '',
    description: ''
  });

  useEffect(() => {
    loadRiskFactors();
  }, []);

  const loadRiskFactors = async () => {
    try {
      setLoading(true);
      const querySnapshot = await getDocs(collection(db, 'riskFactors'));
      const factors = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setRiskFactors(factors);
    } catch (err) {
      setError('Failed to load risk factors');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editMode && currentFactor.id) {
        await updateDoc(doc(db, 'riskFactors', currentFactor.id), {
          name: currentFactor.name,
          description: currentFactor.description,
          updatedAt: new Date()
        });
      } else {
        await addDoc(collection(db, 'riskFactors'), {
          name: currentFactor.name,
          description: currentFactor.description,
          createdAt: new Date()
        });
      }
      clearForm();
      loadRiskFactors();
    } catch (err) {
      setError('Failed to save risk factor');
      console.error(err);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this risk factor?')) {
      try {
        await deleteDoc(doc(db, 'riskFactors', id));
        loadRiskFactors();
      } catch (err) {
        setError('Failed to delete risk factor');
        console.error(err);
      }
    }
  };

  const clearForm = () => {
    setCurrentFactor({ name: '', description: '' });
    setEditMode(false);
    setShowForm(false);
    setError('');
  };

  const filteredFactors = riskFactors.filter(factor =>
    factor.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    factor.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
          <p className="mt-4 text-gray-600">Loading risk factors...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Header Section */}
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-2xl font-bold text-gray-800 flex items-center gap-2">
            <ShieldAlert className="w-6 h-6 text-blue-500" />
            Risk Factors
          </h2>
          <p className="text-gray-600 mt-1">Manage health risk factors for user assessment</p>
        </div>
        <button
          onClick={() => setShowForm(true)}
          className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-all"
        >
          <Plus className="w-4 h-4" />
          Add Risk Factor
        </button>
      </div>

      {/* Error Alert */}
      {error && (
        <div className="flex items-center gap-2 bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-lg">
          <AlertCircle className="w-5 h-5" />
          {error}
        </div>
      )}

      {/* Search Bar */}
      <div className="relative">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
        <input
          type="text"
          placeholder="Search risk factors..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      {/* Risk Factors Grid */}
      <div className="grid grid-cols-1 gap-4">
        {filteredFactors.map((factor) => (
          <div 
            key={factor.id} 
            className="bg-white rounded-lg border border-gray-200 hover:border-blue-200 transition-all p-6"
          >
            <div className="flex justify-between items-start">
              <div className="space-y-2">
                <h3 className="text-lg font-semibold text-gray-900">{factor.name}</h3>
                <p className="text-gray-600">{factor.description}</p>
                {factor.updatedAt && (
                  <p className="text-sm text-gray-400">
                    Last updated: {factor.updatedAt.toDate().toLocaleDateString()}
                  </p>
                )}
              </div>
              <div className="flex items-center gap-2">
                <button
                  onClick={() => {
                    setCurrentFactor(factor);
                    setEditMode(true);
                    setShowForm(true);
                  }}
                  className="p-2 text-blue-600 hover:bg-blue-50 rounded-full transition-all"
                >
                  <Edit2 className="w-4 h-4" />
                </button>
                <button
                  onClick={() => handleDelete(factor.id)}
                  className="p-2 text-red-600 hover:bg-red-50 rounded-full transition-all"
                >
                  <Trash2 className="w-4 h-4" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Form Modal */}
      {showForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-xl shadow-xl max-w-md w-full max-h-[90vh] overflow-y-auto">
            <div className="p-6">
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-xl font-bold text-gray-800">
                  {editMode ? 'Edit Risk Factor' : 'Add Risk Factor'}
                </h3>
                <button
                  onClick={clearForm}
                  className="text-gray-400 hover:text-gray-600"
                >
                  <X className="w-5 h-5" />
                </button>
              </div>

              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Risk Factor Name
                  </label>
                  <input
                    type="text"
                    value={currentFactor.name}
                    onChange={(e) => setCurrentFactor({...currentFactor, name: e.target.value})}
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Description
                  </label>
                  <textarea
                    value={currentFactor.description}
                    onChange={(e) => setCurrentFactor({...currentFactor, description: e.target.value})}
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    rows="4"
                    required
                  />
                </div>

                <div className="flex justify-end gap-3 pt-4">
                  <button
                    type="button"
                    onClick={clearForm}
                    className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-all"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-all"
                  >
                    <Save className="w-4 h-4" />
                    {editMode ? 'Update' : 'Save'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RiskFactors;