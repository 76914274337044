// src/utils/auth.js
import { 
    doc, 
    setDoc, 
    collection, 
    Timestamp 
} from 'firebase/firestore';
import { db } from '../firebase';

export const initializeUserData = async (userId, email) => {
    if (!userId || !email) {
        throw new Error('Missing required parameters: userId and email must be provided');
    }

    try {
        const now = Timestamp.now();
        
        // Create user document with validated data structure
        const userRef = doc(db, 'users', userId);
        const userData = {
            email,
            createdAt: now,
            updatedAt: now,
            profileCompleted: false,
            measurementPreference: 'metric',
            selectedRiskFactors: [],
            selectedHealthGoals: [],
            dateOfBirth: null,
            gender: '',
            userId: userId
        };

        console.log('Creating user document:', { userId });
        await setDoc(userRef, userData);
        console.log('Successfully created user document');

        // Initialize fasting settings
        const fastingSettingsRef = doc(collection(db, 'users', userId, 'fastingSettings'), 'default');
        const fastingSettings = {
            defaultDuration: 16,
            notifications: {
                startReminder: true,
                progressUpdates: true,
                endReminder: true
            },
            createdAt: now,
            updatedAt: now
        };

        console.log('Creating fasting settings');
        await setDoc(fastingSettingsRef, fastingSettings);
        console.log('Successfully created fasting settings');

        // Create initial health tracking entry
        const healthTrackingRef = doc(db, 'healthTracking', `${userId}_initial`);
        const healthData = {
            userId: userId,
            createdAt: now,
            updatedAt: now,
            metrics: {},
            notes: 'Initial health tracking entry'
        };

        console.log('Creating initial health tracking');
        await setDoc(healthTrackingRef, healthData);
        console.log('Successfully created health tracking');

        return true;
    } catch (error) {
        console.error('Error initializing user data:', {
            error,
            errorCode: error.code,
            errorMessage: error.message,
            userId
        });
        
        if (error.code === 'permission-denied') {
            throw new Error('Permission denied: Data validation failed or security rules prevented the operation');
        } else if (error.code === 'not-found') {
            throw new Error('Database path not found. Please check your Firebase configuration.');
        } else if (error.code === 'failed-precondition') {
            throw new Error('Operation failed. Database may not be properly initialized.');
        } else {
            throw new Error(`Failed to initialize user data: ${error.message}`);
        }
    }
};