// electrolyteTracking.js
import { 
    ELECTROLYTE_DATA, 
    SUPPLEMENTATION_PROTOCOLS,
    SAFETY_THRESHOLDS 
  } from './ElectrolyteData';
  
  const getBaseMultiplier = (hours) => {
    if (hours <= 24) return 1;
    if (hours <= 48) return 1.2;
    return 1.3;
  };
  
  const getActivityMultiplier = (activity) => {
    const multipliers = {
      sedentary: 0.9,
      light: 1.0,
      moderate: 1.1,
      high: 1.2
    };
    return multipliers[activity] || 1.0;
  };
  
  const calculateTarget = (phaseData, baseMultiplier, activityMultiplier) => {
    if (typeof phaseData.dosage === 'object') {
      return {
        minimal: phaseData.dosage.minimal,
        standard: phaseData.dosage.standard,
        active: phaseData.dosage.active
      };
    }
    return phaseData.dosage;
  };
  
  const assessRiskLevel = (hours, electrolyte) => {
    const baseRisk = hours > 72 ? 3 : hours > 48 ? 2 : hours > 24 ? 1 : 0;
    const electrolyteRiskFactors = {
      sodium: 1.2,
      potassium: 1.5,
      magnesium: 1.0,
      calcium: 0.8
    };
    return baseRisk * (electrolyteRiskFactors[electrolyte] || 1);
  };
  
  export const calculateElectrolyteNeeds = (fastingHours, activityLevel = 'moderate') => {
    const baseMultiplier = getBaseMultiplier(fastingHours);
    const activityMultiplier = getActivityMultiplier(activityLevel);
    
    return Object.entries(SUPPLEMENTATION_PROTOCOLS.electrolytes).reduce((acc, [electrolyte, data]) => {
      const phase = getPhase(fastingHours);
      const phaseData = data.phases[phase];
      
      acc[electrolyte] = {
        ...phaseData,
        target: calculateTarget(phaseData, baseMultiplier, activityMultiplier),
        riskLevel: assessRiskLevel(fastingHours, electrolyte),
        details: ELECTROLYTE_DATA[electrolyte],
        phase
      };
      return acc;
    }, {});
  };
  
  export const getPhase = (hours) => {
    if (hours <= 24) return 'early';
    if (hours <= 48) return 'moderate';
    return 'extended';
  };
  
  export const getSafetyStatus = (fastingHours) => {
    if (fastingHours < SAFETY_THRESHOLDS.minimal.hours) {
      return {
        level: 'safe',
        message: SAFETY_THRESHOLDS.minimal.message,
        color: 'text-green-700 bg-green-50'
      };
    }
    if (fastingHours < SAFETY_THRESHOLDS.moderate.hours) {
      return {
        level: 'monitor',
        message: SAFETY_THRESHOLDS.moderate.message,
        color: 'text-blue-700 bg-blue-50'
      };
    }
    if (fastingHours < SAFETY_THRESHOLDS.high.hours) {
      return {
        level: 'warning',
        message: SAFETY_THRESHOLDS.high.message,
        color: 'text-yellow-700 bg-yellow-50'
      };
    }
    return {
      level: 'alert',
      message: SAFETY_THRESHOLDS.extreme.message,
      color: 'text-red-700 bg-red-50'
    };
  };