// ProtectedRoute.js
import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { auth, db } from '../firebase';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { LoadingSpinner } from '../App';

const ProtectedRoute = ({ children, requireAdmin = false, allowPublic = false }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [profileStatus, setProfileStatus] = useState(null);
  const [authChecked, setAuthChecked] = useState(false);
  const location = useLocation();

  useEffect(() => {
    let unsubscribeUser = null;

    const checkUserStatus = async (user) => {
      try {
        if (!user) {
          setProfileStatus(null);
          setLoading(false);
          setAuthChecked(true);
          return;
        }

        // Check admin status
        const adminDoc = await getDoc(doc(db, 'admins', user.uid));
        const isUserAdmin = adminDoc.exists();
        setIsAdmin(isUserAdmin);

        // Always subscribe to profile updates when user is authenticated
        unsubscribeUser = onSnapshot(
          doc(db, 'users', user.uid),
          (doc) => {
            if (doc.exists()) {
              setProfileStatus({
                exists: true,
                completed: doc.data().profileCompleted || false,
                data: doc.data(),
                isRegistered: true,
                user: user  // Include auth user data
              });
            } else {
              setProfileStatus({
                exists: false,
                completed: false,
                data: null,
                isRegistered: false,
                user: user  // Include auth user data
              });
            }
            setLoading(false);
            setAuthChecked(true);
          },
          (error) => {
            console.error('Error in profile snapshot:', error);
            setLoading(false);
            setAuthChecked(true);
          }
        );
      } catch (error) {
        console.error('Error checking user status:', error);
        setLoading(false);
        setAuthChecked(true);
      }
    };

    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      checkUserStatus(user);
    });

    return () => {
      unsubscribeAuth();
      if (unsubscribeUser) {
        unsubscribeUser();
      }
    };
  }, []);

  // Check if it's a preview path or community path
  const isPreviewPath = location.pathname.startsWith('/preview');
  const isCommunityPath = location.pathname.includes('/community') || 
                         location.pathname.includes('/preview/community');

  // Show loading state until both auth and profile checks are complete
  if (loading || !authChecked) {
    return <LoadingSpinner />;
  }

  // For preview and community paths, pass user data if available
  if (isPreviewPath || isCommunityPath) {
    // If user is authenticated, wrap with RegisteredDashboardLayout
    if (auth.currentUser) {
      return typeof children === 'function'
        ? children({ 
            isReadOnly: false,
            isRegistered: Boolean(profileStatus?.completed),
            isPreview: isPreviewPath,
            ...profileStatus  // Pass all profile data
          })
        : React.cloneElement(children, { 
            isReadOnly: false,
            isRegistered: Boolean(profileStatus?.completed),
            isPreview: isPreviewPath,
            ...profileStatus  // Pass all profile data
          });
    }
    // For non-authenticated users, render with limited access
    return typeof children === 'function'
      ? children({ 
          isReadOnly: true, 
          isRegistered: false,
          isPreview: isPreviewPath,
          data: null
        })
      : React.cloneElement(children, { 
          isReadOnly: true, 
          isRegistered: false,
          isPreview: isPreviewPath,
          data: null
        });
  }

  // Handle public access
  if (allowPublic && !auth.currentUser) {
    return typeof children === 'function'
      ? children({ isReadOnly: true, isRegistered: false, data: null })
      : React.cloneElement(children, { isReadOnly: true, isRegistered: false, data: null });
  }

  // Handle unauthenticated users for protected routes
  if (!auth.currentUser && !allowPublic) {
    const redirectPath = location.pathname + location.search + location.hash;
    return <Navigate to={requireAdmin ? "/admin" : `/auth?redirect=${encodeURIComponent(redirectPath)}`} />;
  }

  // Handle admin routes
  if (requireAdmin && !isAdmin) {
    return <Navigate to="/auth" />;
  }

  if (isAdmin && !requireAdmin) {
    return <Navigate to="/admin/risk-factors" />;
  }

  // Handle profile completion routing for regular users
  if (!requireAdmin && profileStatus && !isAdmin) {
    const currentPath = location.pathname;

    // If profile doesn't exist or isn't completed
    if (!profileStatus.completed) {
      // Allow access to profile completion page
      if (currentPath === '/profile-completion') {
        return typeof children === 'function' 
          ? children({ ...profileStatus, isReadOnly: false }) 
          : children;
      }
      // Redirect to profile completion if trying to access other pages
      return <Navigate to="/profile-completion" />;
    }

    // Redirect completed profiles away from profile completion page
    if (profileStatus.completed && currentPath === '/profile-completion') {
      return <Navigate to="/dashboard" />;
    }
  }

  // Render the protected content with profile status
  return typeof children === 'function' 
    ? children({ 
        ...profileStatus, 
        isReadOnly: false,
        isRegistered: Boolean(profileStatus?.completed)
      }) 
    : React.cloneElement(children, { 
        ...profileStatus,
        isReadOnly: false,
        isRegistered: Boolean(profileStatus?.completed)
      });
};

export default ProtectedRoute;