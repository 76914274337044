import React, { useState, useEffect } from 'react';
import { 
    LineChart, 
    Line, 
    XAxis, 
    YAxis, 
    CartesianGrid, 
    Tooltip, 
    ResponsiveContainer,
    ReferenceLine,
    Area,
    ComposedChart,
    Legend,
    Brush
} from 'recharts';
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import { db, auth } from '../../../../../firebase';
import { format, subDays, addDays, differenceInDays } from 'date-fns';
import { Scale, Calendar, TrendingUp, TrendingDown, Target } from 'lucide-react';

// Custom tooltip component optimized for mobile
const CustomTooltip = ({ active, payload, label, measurementPreference }) => {
    if (active && payload && payload.length) {
        const data = payload[0].payload;
        const weightChange = data.previousWeight ? (data.weight - data.previousWeight).toFixed(1) : null;
        const unit = measurementPreference === 'imperial' ? 'lbs' : 'kg';
        
        return (
            <div className="bg-white p-2 sm:p-4 border rounded-lg shadow-lg max-w-[200px] sm:max-w-[300px]">
                <p className="font-medium text-gray-900 text-sm sm:text-base mb-1 sm:mb-2">
                    {format(new Date(label), 'MMMM d, yyyy')}
                </p>
                <div className="space-y-1 sm:space-y-2">
                    <div className="flex items-center gap-1 sm:gap-2">
                        <Scale className="w-3 h-3 sm:w-4 sm:h-4 text-blue-500" />
                        <span className="text-blue-600 font-medium text-sm sm:text-base">
                            {data.weight?.toFixed(1) || data.projectedWeight?.toFixed(1)} {unit}
                        </span>
                    </div>
                    
                    {weightChange && (
                        <div className="flex items-center gap-1 sm:gap-2">
                            {parseFloat(weightChange) < 0 ? (
                                <TrendingDown className="w-3 h-3 sm:w-4 sm:h-4 text-green-500" />
                            ) : (
                                <TrendingUp className="w-3 h-3 sm:w-4 sm:h-4 text-red-500" />
                            )}
                            <span className={`text-sm sm:text-base font-medium ${
                                parseFloat(weightChange) < 0 ? 'text-green-600' : 'text-red-600'
                            }`}>
                                {weightChange > 0 ? '+' : ''}{weightChange} {unit}
                            </span>
                        </div>
                    )}

                    {data.movingAverage && (
                        <div className="flex items-center gap-1 sm:gap-2">
                            <Calendar className="w-3 h-3 sm:w-4 sm:h-4 text-purple-500" />
                            <span className="text-purple-600 font-medium text-sm sm:text-base">
                                7-day avg: {data.movingAverage.toFixed(1)} {unit}
                            </span>
                        </div>
                    )}
                </div>
                
                {data.notes && (
                    <div className="mt-1 sm:mt-2 pt-1 sm:pt-2 border-t border-gray-200">
                        <p className="text-xs sm:text-sm text-gray-600">
                            Note: {data.notes}
                        </p>
                    </div>
                )}
            </div>
        );
    }
    return null;
};
// Mobile-optimized stat card component
const StatCard = ({ title, value, unit, icon: Icon, bgColor, textColor, iconColor, children }) => (
    <div className={`${bgColor} p-2 sm:p-4 rounded-lg`}>
        <div className="flex items-center gap-1 sm:gap-2 mb-1 sm:mb-2">
            <Icon className={`w-4 h-4 sm:w-5 sm:h-5 ${iconColor}`} />
            <h4 className={`text-sm sm:text-base font-medium ${textColor}`}>{title}</h4>
        </div>
        <div className="space-y-1">
            <div className={`text-lg sm:text-xl font-semibold ${textColor}`}>
                {value} {unit}
            </div>
            {children}
        </div>
    </div>
);

// Compact time range selection button
const TimeRangeButton = ({ range, active, onClick }) => (
    <button
        onClick={() => onClick(range)}
        className={`px-2 sm:px-3 py-1 rounded text-xs sm:text-sm font-medium transition-colors ${
            active
                ? 'bg-blue-100 text-blue-700'
                : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
        }`}
    >
        {range}
    </button>
);

// Calculate moving average helper function
const calculateMovingAverage = (data, windowSize = 7) => {
    return data.map((entry, index) => {
        const window = data.slice(Math.max(0, index - windowSize + 1), index + 1);
        const sum = window.reduce((acc, curr) => acc + curr.weight, 0);
        return {
            ...entry,
            movingAverage: sum / window.length,
            previousWeight: index > 0 ? data[index - 1].weight : null
        };
    });
};

// Calculate weight projection helper function
const calculateProjection = (data, targetWeight) => {
    if (data.length < 2) {
        return { projectedData: [], daysToTarget: null, dailyRate: 0 };
    }

    const sortedData = [...data].sort((a, b) => a.date - b.date);
    const firstEntry = sortedData[0];
    const lastEntry = sortedData[sortedData.length - 1];
    
    const totalChange = lastEntry.weight - firstEntry.weight;
    const daysDiff = differenceInDays(lastEntry.date, firstEntry.date) || 1;
    const dailyRate = totalChange / daysDiff;
    
    const latestWeight = lastEntry.weight;
    const weightToLose = latestWeight - targetWeight;
    const isLosingWeight = targetWeight < latestWeight;
    
    if (Math.abs(latestWeight - targetWeight) < 0.1) {
        return { projectedData: [], daysToTarget: 0, dailyRate: 0 };
    }

    let effectiveRate;
    if (isLosingWeight) {
        effectiveRate = Math.min(dailyRate, -0.1);
        if (dailyRate >= 0) effectiveRate = -0.1;
    } else {
        effectiveRate = Math.max(dailyRate, 0.1);
        if (dailyRate <= 0) effectiveRate = 0.1;
    }

    const daysToTarget = Math.abs(Math.round(weightToLose / effectiveRate));
    const projectionDays = Math.min(daysToTarget || 90, 90);
    
    const projectedData = [];
    let currentDate = lastEntry.date;
    let currentWeight = latestWeight;
    let reachedTarget = false;

    for (let i = 1; i <= projectionDays && !reachedTarget; i++) {
        currentDate = addDays(currentDate, 1);
        currentWeight = Number((latestWeight + (effectiveRate * i)).toFixed(1));

        if (isLosingWeight && currentWeight <= targetWeight) {
            currentWeight = targetWeight;
            reachedTarget = true;
        } else if (!isLosingWeight && currentWeight >= targetWeight) {
            currentWeight = targetWeight;
            reachedTarget = true;
        }

        projectedData.push({
            date: currentDate,
            projectedWeight: currentWeight
        });

        if (reachedTarget) break;
    }

    return { 
        projectedData, 
        daysToTarget: projectedData.length,
        dailyRate: effectiveRate 
    };
};
// Mobile-optimized projection info component
const ProjectionInfo = ({ daysToTarget, dailyRate, measurementPreference, dataPoints = 0 }) => {
    const unit = measurementPreference === 'imperial' ? 'lbs' : 'kg';
    const ratePerWeek = (dailyRate * 7).toFixed(2);
    const isGaining = dailyRate > 0;
    
    return (
        <div className="bg-yellow-50 p-2 sm:p-4 rounded-lg">
            <div className="flex items-center gap-1 sm:gap-2 mb-1 sm:mb-2">
                <Target className="w-4 h-4 sm:w-5 sm:h-5 text-yellow-600" />
                <h4 className="text-sm sm:text-base font-medium text-yellow-900">Weight Projection</h4>
            </div>
            <div className="space-y-0.5 sm:space-y-1 text-xs sm:text-sm">
                <p className="text-yellow-800">
                    Current rate: {Math.abs(ratePerWeek)} {unit}/week
                    {isGaining ? ' gain' : ' loss'}
                </p>
                {daysToTarget && Math.abs(dailyRate) > 0 && (
                    <>
                        <p className="text-yellow-800">
                            Estimated target date: {format(addDays(new Date(), daysToTarget), 'MMM d, yyyy')}
                        </p>
                        <p className="text-yellow-800">
                            Days to target: {daysToTarget}
                        </p>
                    </>
                )}
                {(!daysToTarget || Math.abs(dailyRate) === 0) && (
                    <p className="text-yellow-800">
                        {isGaining ? 'Currently gaining weight' : 'Currently losing weight'}
                    </p>
                )}
                <p className="text-yellow-600 text-xs mt-1 sm:mt-2">
                    *Initial projection based on {dataPoints} data points
                </p>
            </div>
        </div>
    );
};

const WeightGraph = ({ targetWeight, measurementPreference = 'metric' }) => {
    const [timeRange, setTimeRange] = useState('1M');
    const [weightEntries, setWeightEntries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showMovingAverage, setShowMovingAverage] = useState(true);
    const [showMinMax, setShowMinMax] = useState(true);
    const [showProjection, setShowProjection] = useState(true);

    useEffect(() => {
        const user = auth.currentUser;
        if (!user) return;

        const weightRef = collection(db, 'users', user.uid, 'weightHistory');
        const weightQuery = query(weightRef, orderBy('date', 'desc'));

        const unsubscribe = onSnapshot(
            weightQuery,
            (snapshot) => {
                const entries = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                    date: doc.data().date.toDate(),
                    weight: doc.data().weight,
                    notes: doc.data().notes || ''
                }));
                
                const processedData = calculateMovingAverage(entries.sort((a, b) => a.date - b.date));
                setWeightEntries(processedData);
                setLoading(false);
            },
            (error) => {
                console.error('Error fetching weight entries:', error);
                setError('Failed to load weight data');
                setLoading(false);
            }
        );

        return () => unsubscribe();
    }, []);

    const getFilteredData = () => {
        if (!weightEntries?.length) return [];

        const now = new Date();
        let filterDate = new Date();

        switch (timeRange) {
            case '1W':
                filterDate = subDays(now, 7);
                break;
            case '1M':
                filterDate = subDays(now, 30);
                break;
            case '3M':
                filterDate = subDays(now, 90);
                break;
            case '6M':
                filterDate = subDays(now, 180);
                break;
            case '1Y':
                filterDate = subDays(now, 365);
                break;
            default:
                return weightEntries;
        }

        return weightEntries.filter(entry => entry.date >= filterDate);
    };
    if (loading) {
        return (
            <div className="bg-white rounded-xl shadow-sm p-3 sm:p-6">
                <div className="h-[200px] sm:h-[320px] flex items-center justify-center">
                    <div className="animate-pulse text-gray-400 text-sm sm:text-base">Loading weight data...</div>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="bg-white rounded-xl shadow-sm p-3 sm:p-6">
                <div className="h-[200px] sm:h-[320px] flex items-center justify-center text-red-500 text-sm sm:text-base">{error}</div>
            </div>
        );
    }

    const data = getFilteredData();
    const { projectedData, daysToTarget, dailyRate } = calculateProjection(data, targetWeight);
    const combinedData = [...data, ...projectedData];
    
    const weights = data.map(d => d.weight);
    const minWeight = Math.min(...weights, targetWeight) - 2;
    const maxWeight = Math.max(...weights, targetWeight) + 2;

    const latestWeight = weights[weights.length - 1] || 0;
    const totalChange = latestWeight - weights[0] || 0;
    const averageWeight = weights.reduce((a, b) => a + b, 0) / weights.length || 0;

    return (
        <div className="bg-white rounded-xl shadow-sm p-3 sm:p-6">
            <div className="flex flex-col gap-3 sm:gap-4">
                {/* Header and Controls */}
                <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2 sm:gap-4">
                    <h3 className="text-base sm:text-lg font-semibold text-gray-900 flex items-center gap-2">
                        <Scale className="w-4 h-4 sm:w-5 sm:h-5 text-blue-500" />
                        Weight Progress
                    </h3>
                    <div className="flex flex-wrap gap-1 sm:gap-2">
                        {['1W', '1M', '3M', '6M', '1Y'].map((range) => (
                            <TimeRangeButton
                                key={range}
                                range={range}
                                active={timeRange === range}
                                onClick={setTimeRange}
                            />
                        ))}
                    </div>
                </div>

                {/* Options */}
                <div className="flex flex-wrap gap-2 sm:gap-4 text-xs sm:text-sm">
                    <label className="flex items-center gap-1 sm:gap-2">
                        <input
                            type="checkbox"
                            checked={showMovingAverage}
                            onChange={(e) => setShowMovingAverage(e.target.checked)}
                            className="rounded text-blue-500 w-3 h-3 sm:w-4 sm:h-4"
                        />
                        <span className="text-gray-600">Show 7-day average</span>
                    </label>
                    <label className="flex items-center gap-1 sm:gap-2">
                        <input
                            type="checkbox"
                            checked={showMinMax}
                            onChange={(e) => setShowMinMax(e.target.checked)}
                            className="rounded text-blue-500 w-3 h-3 sm:w-4 sm:h-4"
                        />
                        <span className="text-gray-600">Show min/max range</span>
                    </label>
                    <label className="flex items-center gap-1 sm:gap-2">
                        <input
                            type="checkbox"
                            checked={showProjection}
                            onChange={(e) => setShowProjection(e.target.checked)}
                            className="rounded text-yellow-500 w-3 h-3 sm:w-4 sm:h-4"
                        />
                        <span className="text-gray-600">Show projection</span>
                    </label>
                </div>

                {/* Statistics Cards */}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-2 sm:gap-4">
                    <StatCard
                        title="Latest Weight"
                        value={latestWeight.toFixed(1)}
                        unit={measurementPreference === 'imperial' ? 'lbs' : 'kg'}
                        icon={Scale}
                        bgColor="bg-blue-50"
                        textColor="text-blue-900"
                        iconColor="text-blue-600"
                    />
                    <StatCard
                        title="Total Change"
                        value={`${totalChange > 0 ? '+' : ''}${totalChange.toFixed(1)}`}
                        unit={measurementPreference === 'imperial' ? 'lbs' : 'kg'}
                        icon={TrendingUp}
                        bgColor="bg-green-50"
                        textColor="text-green-900"
                        iconColor="text-green-600"
                    />
                    <StatCard
                        title="Average Weight"
                        value={averageWeight.toFixed(1)}
                        unit={measurementPreference === 'imperial' ? 'lbs' : 'kg'}
                        icon={Calendar}
                        bgColor="bg-purple-50"
                        textColor="text-purple-900"
                        iconColor="text-purple-600"
                    />
                    {showProjection && (
                        <ProjectionInfo 
                            daysToTarget={daysToTarget}
                            dailyRate={dailyRate}
                            measurementPreference={measurementPreference}
                            dataPoints={data.length}
                        />
                    )}
                </div>

                {/* Chart */}
                <div className="h-[200px] sm:h-[320px]">
                    {data.length > 0 ? (
                        <ResponsiveContainer width="100%" height="100%">
                            <ComposedChart
                                data={combinedData}
                                margin={{ 
                                    top: 5,
                                    right: 5,
                                    left: 5,
                                    bottom: 5 
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
                                <XAxis 
                                    dataKey="date"
                                    tickFormatter={(date) => format(new Date(date), 'MMM d')}
                                    stroke="#6B7280"
                                    tick={{ fontSize: 10 }}
                                    minTickGap={20}
                                />
                                <YAxis 
                                    domain={[minWeight, maxWeight]}
                                    stroke="#6B7280"
                                    tickFormatter={(value) => `${value.toFixed(1)}`}
                                    tick={{ fontSize: 10 }}
                                    width={35}
                                />
                                <Tooltip 
                                    content={<CustomTooltip measurementPreference={measurementPreference} />}
                                />
                                <Legend wrapperStyle={{ fontSize: '10px' }} />
                                
                                <ReferenceLine 
                                    y={targetWeight} 
                                    stroke="#3B82F6" 
                                    strokeDasharray="3 3"
                                    label={{ 
                                        value: 'Target',
                                        fill: '#3B82F6',
                                        fontSize: 10
                                    }}
                                />
                                
                                {showMinMax && (
                                    <Area
                                        type="monotone"
                                        dataKey="weight"
                                        fill="#E0F2FE"
                                        stroke="none"
                                        opacity={0.3}
                                    />
                                )}
                                
                                {showMovingAverage && (
                                    <Line
                                        type="monotone"
                                        dataKey="movingAverage"
                                        stroke="#8B5CF6"
                                        strokeWidth={1}
                                        dot={false}
                                        name="7-day avg"
                                    />
                                )}
                                
                                {showProjection && projectedData.length > 0 && (
                                    <Line
                                        type="monotone"
                                        dataKey="projectedWeight"
                                        stroke="#EAB308"
                                        strokeWidth={1}
                                        strokeDasharray="5 5"
                                        name="Projection"
                                        dot={false}
                                    />
                                )}
                                
                                <Line
                                    type="monotone"
                                    dataKey="weight"
                                    stroke="#10B981"
                                    strokeWidth={1.5}
                                    name="Weight"
                                    dot={{ 
                                        stroke: '#059669',
                                        strokeWidth: 1,
                                        r: 2,
                                        fill: '#fff'
                                    }}
                                    activeDot={{ 
                                        stroke: '#059669',
                                        strokeWidth: 1,
                                        r: 4,
                                        fill: '#fff'
                                    }}
                                />
                            </ComposedChart>
                        </ResponsiveContainer>
                    ) : (
                        <div className="h-full flex items-center justify-center text-gray-500 text-sm">
                            No weight entries available
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default WeightGraph;