import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { auth, db } from '../../../firebase';
import { 
  collection, 
  addDoc, 
  getDocs, 
  doc, 
  updateDoc,
  deleteDoc,
  Timestamp, 
  orderBy, 
  query
} from 'firebase/firestore';
import { 
  Loader2, 
  ThumbsUp, 
  MessageCircle, 
  Plus,
  Search,
  Filter,
  SortAsc,
  Tag
} from 'lucide-react';
import SuggestionCard from './Components/SuggestionCard';

const FeatureRequests = () => {
  const { userData } = useOutletContext();
  const [suggestions, setSuggestions] = useState([]);
  const [newSuggestion, setNewSuggestion] = useState('');
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [newComments, setNewComments] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [sortBy, setSortBy] = useState('latest');

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        await fetchSuggestions();
      } else {
        setSuggestions([]);
        setIsLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchSuggestions = async () => {
    if (!auth.currentUser) {
      setError('Please sign in to view suggestions');
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      setError(null);
      
      const suggestionsRef = collection(db, 'suggestions');
      const q = query(suggestionsRef, orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(q);
      
      const suggestionsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      setSuggestions(suggestionsData);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      setError('Failed to fetch suggestions');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitSuggestion = async (e) => {
    e.preventDefault();
    if (!auth.currentUser) {
      setError('Please sign in to submit suggestions');
      return;
    }

    if (!newSuggestion.trim() || newSuggestion.length < 10) {
      setError('Suggestion must be at least 10 characters long');
      return;
    }
    
    try {
      setIsLoading(true);
      setError(null);
      
      const suggestionData = {
        title: newSuggestion.trim(),
        userId: auth.currentUser.uid,
        userName: userData?.displayName || 'Anonymous',
        votes: [],
        comments: [],
        status: 'new',
        createdAt: Timestamp.now()
      };
  
      const suggestionsRef = collection(db, 'suggestions');
      await addDoc(suggestionsRef, suggestionData);
      
      setNewSuggestion('');
      await fetchSuggestions();
    } catch (error) {
      console.error('Error submitting suggestion:', error);
      setError('Failed to submit suggestion');
    } finally {
      setIsLoading(false);
    }
  };

  const handleVote = async (suggestionId) => {
    if (!auth.currentUser) {
      setError('Please sign in to vote');
      return;
    }

    try {
      const userId = auth.currentUser.uid;
      const suggestionRef = doc(db, 'suggestions', suggestionId);
      const suggestion = suggestions.find(s => s.id === suggestionId);
      
      const newVotes = suggestion.votes?.includes(userId)
        ? suggestion.votes.filter(id => id !== userId)
        : [...(suggestion.votes || []), userId];
      
      await updateDoc(suggestionRef, { votes: newVotes });
      await fetchSuggestions();
    } catch (error) {
      console.error('Error voting:', error);
      setError('Failed to register vote');
    }
  };

  const handleAddComment = async (suggestionId) => {
    if (!auth.currentUser) {
      setError('Please sign in to comment');
      return;
    }

    const commentText = newComments[suggestionId];
    if (!commentText?.trim()) return;

    try {
      const suggestion = suggestions.find(s => s.id === suggestionId);
      const newComment = {
        text: commentText.trim(),
        userId: auth.currentUser.uid,
        userName: userData?.displayName || 'Anonymous',
        createdAt: Timestamp.now()
      };
      
      const suggestionRef = doc(db, 'suggestions', suggestionId);
      await updateDoc(suggestionRef, {
        comments: [...(suggestion.comments || []), newComment]
      });
      
      setNewComments(prev => ({
        ...prev,
        [suggestionId]: ''
      }));
      
      await fetchSuggestions();
    } catch (error) {
      console.error('Error adding comment:', error);
      setError('Failed to add comment');
    }
  };

  const handleDeleteSuggestion = async (suggestionId) => {
    if (!auth.currentUser) {
      setError('Please sign in to delete suggestions');
      return;
    }

    try {
      setIsLoading(true);
      setError(null);
      
      const suggestionRef = doc(db, 'suggestions', suggestionId);
      await deleteDoc(suggestionRef);
      
      // Close comments if the deleted suggestion was selected
      if (selectedSuggestion === suggestionId) {
        setSelectedSuggestion(null);
      }
      
      await fetchSuggestions();
    } catch (error) {
      console.error('Error deleting suggestion:', error);
      setError('Failed to delete suggestion');
    } finally {
      setIsLoading(false);
    }
  };

  const filteredSuggestions = suggestions
    .filter(suggestion => {
      const matchesSearch = suggestion.title.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesFilter = filterStatus === 'all' || suggestion.status === filterStatus;
      return matchesSearch && matchesFilter;
    })
    .sort((a, b) => {
      if (sortBy === 'latest') return b.createdAt?.seconds - a.createdAt?.seconds;
      if (sortBy === 'votes') return (b.votes?.length || 0) - (a.votes?.length || 0);
      if (sortBy === 'comments') return (b.comments?.length || 0) - (a.comments?.length || 0);
      return 0;
    });

  if (!auth.currentUser) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
        <div className="bg-white p-8 rounded-xl shadow-sm border border-gray-100 max-w-md w-full text-center">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Sign In Required</h2>
          <p className="text-gray-600">Please sign in to view and submit feature requests.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-6 sm:py-12">
      <div className="max-w-5xl mx-auto px-4">
        <div className="space-y-6">
          {/* Header Section */}
          <div>
            <h1 className="text-2xl sm:text-3xl font-bold text-gray-900">Feature Requests</h1>
            <p className="mt-2 text-gray-600">Help us improve by sharing your ideas and feedback.</p>
          </div>

          {/* New Suggestion Form */}
          <form onSubmit={handleSubmitSuggestion} className="bg-white rounded-xl shadow-sm border border-gray-100 p-4 sm:p-6">
            <textarea
              value={newSuggestion}
              onChange={(e) => setNewSuggestion(e.target.value)}
              placeholder="Share your suggestion for improvement..."
              className="w-full min-h-[120px] p-4 border rounded-lg resize-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
            <div className="mt-4 flex flex-col sm:flex-row items-start sm:items-center justify-between gap-2">
              <p className="text-sm text-gray-500">
                Minimum 10 characters required
              </p>
              <button
                type="submit"
                disabled={isLoading || !newSuggestion.trim() || newSuggestion.length < 10}
                className="w-full sm:w-auto px-6 py-2 bg-blue-600 text-white rounded-lg font-medium hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center gap-2"
              >
                <Plus className="w-4 h-4" />
                Submit Suggestion
              </button>
            </div>
          </form>

          {/* Filters and Search */}
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
              <input
                type="text"
                placeholder="Search suggestions..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
            
            <div className="relative">
              <Filter className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
              <select
                value={filterStatus}
                onChange={(e) => setFilterStatus(e.target.value)}
                className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent appearance-none bg-white"
              >
                <option value="all">All Status</option>
                <option value="new">New</option>
                <option value="inProgress">In Progress</option>
                <option value="completed">Completed</option>
                <option value="declined">Declined</option>
              </select>
            </div>
            
            <div className="relative">
              <SortAsc className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
              <select
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value)}
                className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent appearance-none bg-white"
              >
                <option value="latest">Latest First</option>
                <option value="votes">Most Votes</option>
                <option value="comments">Most Comments</option>
              </select>
            </div>
          </div>

          {/* Stats Overview */}
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
            {[
              { label: 'Total Suggestions', value: suggestions.length, icon: Tag },
              { 
                label: 'Open Suggestions', 
                value: suggestions.filter(s => s.status === 'new').length,
                icon: MessageCircle 
              },
              { 
                label: 'In Progress', 
                value: suggestions.filter(s => s.status === 'inProgress').length,
                icon: Loader2 
              },
              { 
                label: 'Completed', 
                value: suggestions.filter(s => s.status === 'completed').length,
                icon: ThumbsUp 
              }
            ].map((stat, index) => (
              <div key={index} className="bg-white rounded-xl shadow-sm border border-gray-100 p-4">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm text-gray-500">{stat.label}</p>
                    <p className="text-xl sm:text-2xl font-semibold text-gray-900 mt-1">{stat.value}</p>
                  </div>
                  <stat.icon className="w-6 h-6 sm:w-8 sm:h-8 text-blue-500 opacity-75" />
                </div>
              </div>
            ))}
          </div>

          {/* Error Message */}
          {error && (
            <div className="bg-red-50 border-l-4 border-red-400 p-4 rounded-lg">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                  </svg>
                </div>
                <div className="ml-3">
                  <p className="text-sm text-red-700">{error}</p>
                </div>
                <button
                  onClick={() => setError(null)}
                  className="ml-auto pl-3 text-red-400 hover:text-red-500"
                >
                  <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </button>
              </div>
            </div>
          )}

          {/* Suggestions List */}
          <div className="space-y-4">
            {isLoading && !suggestions.length ? (
              <div className="flex flex-col items-center justify-center py-12">
                <Loader2 className="w-12 h-12 animate-spin text-blue-500" />
                <p className="mt-4 text-gray-600">Loading suggestions...</p>
              </div>
            ) : filteredSuggestions.length === 0 ? (
              <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-8 sm:p-12 text-center">
                <div className="mx-auto max-w-sm">
                  <MessageCircle className="mx-auto h-12 w-12 text-gray-400" />
                  <h3 className="mt-4 text-lg font-semibold text-gray-900">No suggestions found</h3>
                  <p className="mt-2 text-gray-600">
                    {searchTerm || filterStatus !== 'all' 
                      ? "Try adjusting your filters or search terms"
                      : "Be the first to suggest a feature!"}
                  </p>
                </div>
              </div>
            ) : (
              filteredSuggestions.map((suggestion) => (
                <SuggestionCard
                  key={suggestion.id}
                  suggestion={suggestion}
                  currentUserId={auth.currentUser?.uid}
                  newComment={newComments[suggestion.id] || ''}
                  onNewCommentChange={(value) => setNewComments(prev => ({
                    ...prev,
                    [suggestion.id]: value
                  }))}
                  onVote={handleVote}
                  onAddComment={handleAddComment}
                  onDelete={handleDeleteSuggestion}
                  onToggleComments={() => setSelectedSuggestion(
                    suggestion.id === selectedSuggestion ? null : suggestion.id
                  )}
                  isSelected={selectedSuggestion === suggestion.id}
                />
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureRequests;
