import React, { useState, useEffect } from 'react';
import { collection, addDoc, doc, updateDoc, Timestamp, query, where, getDocs, orderBy, limit } from 'firebase/firestore';
import { db, auth } from '../../../../../firebase';
import { 
    Scale, 
    X, 
    Save, 
    ChevronUp, 
    ChevronDown, 
    Info, 
    TrendingDown, 
    TrendingUp, 
    Trophy,
    Target,
    Star,
    Award,
    Crown,
    Zap,
    Check,
    Loader2,
    ArrowLeft,
    ArrowRight
} from 'lucide-react';
import { startOfDay, endOfDay, format, differenceInDays, isToday } from 'date-fns';

const MOTIVATION_MESSAGES = {
    streak: [
        { threshold: 3, message: "Three days in a row! You're building momentum! 🌱", icon: Star },
        { threshold: 7, message: "A week of dedication! Great habit building! 🌟", icon: Star },
        { threshold: 14, message: "Two weeks strong! Making it a lifestyle! 🎯", icon: Target },
        { threshold: 30, message: "One month milestone! You're a champion! 🏆", icon: Trophy },
        { threshold: 60, message: "60 days! Setting new standards! 👑", icon: Crown },
        { threshold: 90, message: "90 days! You're a master! 🌈", icon: Award }
    ],
    progress: {
        loss: {
            message: "Making steady progress! Keep it up! 💪",
            threshold: -0.5
        },
        maintain: {
            message: "Maintaining stability! That's a win too! ⭐",
            threshold: 0.1
        },
        gain: {
            message: "Remember, progress isn't always linear. Stay committed! 🎯",
            threshold: 0.5
        }
    }
};

// Utility Functions
const formatWeight = (weight, measurementPreference) => {
    if (weight === null || weight === undefined) return '0.0';
    const value = measurementPreference === 'imperial' ? weight * 2.20462 : weight;
    return `${value.toFixed(1)} ${measurementPreference === 'imperial' ? 'lbs' : 'kg'}`;
};

const convertWeight = (weight, fromPreference, toPreference) => {
    if (fromPreference === toPreference) return weight;
    return fromPreference === 'imperial' ? weight / 2.20462 : weight * 2.20462;
};

const getSliderRange = (currentWeight, measurementPreference) => {
    const range = measurementPreference === 'metric' ? 10 : 22; // Doubled range for better distribution
    const minWeight = measurementPreference === 'metric' ? 30 : 66;
    const maxWeight = measurementPreference === 'metric' ? 1000 : 2204.62;

    let centerWeight = currentWeight || (measurementPreference === 'metric' ? 70 : 154);
    let minSliderWeight = centerWeight - (range / 2);
    let maxSliderWeight = centerWeight + (range / 2);

    // Ensure we don't exceed global limits
    minSliderWeight = Math.max(minSliderWeight, minWeight);
    maxSliderWeight = Math.min(maxSliderWeight, maxWeight);

    return {
        min: minSliderWeight,
        max: maxSliderWeight,
        center: centerWeight,
        range: range
    };
};

const getWeightChangeCategory = (change) => {
    if (change < -0.5) return { color: 'text-green-600', bgColor: 'bg-green-50' };
    if (change > 0.5) return { color: 'text-red-600', bgColor: 'bg-red-50' };
    return { color: 'text-blue-600', bgColor: 'bg-blue-50' };
};

const LoadingSpinner = () => (
    <Loader2 className="w-4 h-4 animate-spin" />
);

const ActionButton = ({ 
    onClick, 
    disabled, 
    variant = 'primary', 
    icon: Icon,
    loading,
    children 
}) => {
    const baseStyles = "w-full sm:flex-1 py-4 sm:py-3.5 px-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50 transition-all duration-200 font-medium flex items-center justify-center gap-2 touch-manipulation text-sm sm:text-base";
    
    const variants = {
        primary: `${baseStyles} bg-blue-500 hover:bg-blue-600 active:bg-blue-700 text-white focus:ring-blue-500
            disabled:hover:bg-blue-500 transform active:scale-[0.98] shadow-sm hover:shadow
            ${loading ? 'bg-blue-400 cursor-not-allowed' : ''}`,
        secondary: `${baseStyles} bg-white hover:bg-gray-50 active:bg-gray-100 text-gray-700 border border-gray-200
            focus:ring-gray-500 transform active:scale-[0.98] hover:border-gray-300
            ${loading ? 'bg-gray-50 cursor-not-allowed' : ''}`
    };

    return (
        <button
            onClick={onClick}
            disabled={disabled || loading}
            className={variants[variant]}
        >
            {loading ? (
                <LoadingSpinner />
            ) : Icon ? (
                <Icon className="w-4 h-4" />
            ) : null}
            {children}
        </button>
    );
};
const WeightEntryForm = ({ initialData, onSuccess, onCancel, measurementPreference = 'metric' }) => {
    const [weight, setWeight] = useState(initialData?.weight?.toString() || '');
    const [notes, setNotes] = useState(initialData?.notes || '');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [showTips, setShowTips] = useState(false);
    const [recentEntries, setRecentEntries] = useState([]);
    const [streak, setStreak] = useState(0);
    const [lastEntry, setLastEntry] = useState(null);
    const [motivationMessage, setMotivationMessage] = useState('');
    const [submitStatus, setSubmitStatus] = useState('idle');
    const [sliderValue, setSliderValue] = useState(0); // Track relative change from center

    const weightStep = measurementPreference === 'metric' ? 0.1 : 0.2;
    const maxWeight = measurementPreference === 'metric' ? 1000 : 2204.62;
    const minWeight = measurementPreference === 'metric' ? 30 : 66;

    useEffect(() => {
        const fetchUserData = async () => {
            if (!auth.currentUser) return;

            const weightHistoryRef = collection(db, 'users', auth.currentUser.uid, 'weightHistory');
            const recentQuery = query(weightHistoryRef, orderBy('date', 'desc'), limit(10));

            try {
                const snapshot = await getDocs(recentQuery);
                const entries = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                    date: doc.data().date.toDate()
                }));

                setRecentEntries(entries);

                if (entries.length > 0) {
                    const latestEntry = entries[0];
                    setLastEntry(latestEntry);
                    // Convert weight to current preference and set as initial value
                    const convertedWeight = convertWeight(
                        latestEntry.weight,
                        'metric',
                        measurementPreference
                    );
                    setWeight(convertedWeight.toFixed(1));
                    setSliderValue(0); // Center the slider
                    calculateStreak(entries);
                }
            } catch (error) {
                console.error('Error fetching weight entries:', error);
                setError('Failed to load recent entries');
            }
        };

        fetchUserData();
    }, [measurementPreference]);

    const calculateStreak = (entries) => {
        let currentStreak = 0;
        let prevDate = new Date();
        
        for (const entry of entries) {
            const dayDiff = differenceInDays(prevDate, entry.date);
            if (dayDiff <= 1) {
                currentStreak++;
                prevDate = entry.date;
            } else {
                break;
            }
        }
        setStreak(currentStreak);

        const streakMessage = MOTIVATION_MESSAGES.streak
            .slice()
            .reverse()
            .find(m => currentStreak >= m.threshold);

        if (streakMessage) {
            setMotivationMessage(streakMessage.message);
        }
    };

    const handleSliderChange = (e) => {
        const newValue = parseFloat(e.target.value);
        setSliderValue(newValue);
        
        if (lastEntry) {
            const baseWeight = convertWeight(lastEntry.weight, 'metric', measurementPreference);
            const newWeight = (baseWeight + newValue).toFixed(1);
            handleWeightChange(newWeight);
        }
    };

    const handleWeightChange = (value) => {
        const numValue = parseFloat(value);
        if (!isNaN(numValue)) {
            if ('vibrate' in navigator) {
                navigator.vibrate(10);
            }
        }
        setWeight(value);

        // Update slider value based on the difference from base weight
        if (lastEntry) {
            const baseWeight = convertWeight(lastEntry.weight, 'metric', measurementPreference);
            setSliderValue(numValue - baseWeight);
        }

        // Set progress-based motivation message
        if (lastEntry) {
            const baseWeight = convertWeight(lastEntry.weight, 'metric', measurementPreference);
            const change = numValue - baseWeight;
            let message = '';
            
            if (change < MOTIVATION_MESSAGES.progress.loss.threshold) {
                message = MOTIVATION_MESSAGES.progress.loss.message;
            } else if (change > MOTIVATION_MESSAGES.progress.gain.threshold) {
                message = MOTIVATION_MESSAGES.progress.gain.message;
            } else {
                message = MOTIVATION_MESSAGES.progress.maintain.message;
            }
            
            setMotivationMessage(message);
        }
    };

    const handleWeightIncrement = () => {
        const currentWeight = parseFloat(weight) || 0;
        handleWeightChange((currentWeight + weightStep).toFixed(1));
    };

    const handleWeightDecrement = () => {
        const currentWeight = parseFloat(weight) || 0;
        if (currentWeight > minWeight) {
            handleWeightChange((currentWeight - weightStep).toFixed(1));
        }
    };
    const findExistingEntryForToday = async (userId) => {
        const today = new Date();
        const startDay = startOfDay(today);
        const endDay = endOfDay(today);

        const weightHistoryRef = collection(db, 'users', userId, 'weightHistory');
        const q = query(
            weightHistoryRef,
            where('date', '>=', Timestamp.fromDate(startDay)),
            where('date', '<=', Timestamp.fromDate(endDay))
        );

        const querySnapshot = await getDocs(q);
        return querySnapshot.docs[0];
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSubmitStatus('loading');

        try {
            const user = auth.currentUser;
            if (!user) throw new Error('User not authenticated');

            if (!weight || isNaN(weight) || weight <= 0) {
                throw new Error('Please enter a valid weight');
            }

            // Convert to metric for storage
            let weightInKg = parseFloat(weight);
            if (measurementPreference === 'imperial') {
                weightInKg = convertWeight(weightInKg, 'imperial', 'metric');
            }

            if (weightInKg <= 0 || weightInKg > 1000) {
                throw new Error('Weight must be between 0 and 1000 kg');
            }

            const now = Timestamp.now();
            let existingEntry;

            if (initialData?.id) {
                existingEntry = { id: initialData.id };
            } else {
                existingEntry = await findExistingEntryForToday(user.uid);
            }

            if (existingEntry) {
                const entryRef = doc(db, 'users', user.uid, 'weightHistory', existingEntry.id);
                await updateDoc(entryRef, {
                    weight: weightInKg,
                    notes: notes.trim(),
                    updatedAt: now,
                    date: now
                });
            } else {
                const weightHistoryRef = collection(db, 'users', user.uid, 'weightHistory');
                await addDoc(weightHistoryRef, {
                    weight: weightInKg,
                    date: now,
                    notes: notes.trim(),
                    createdAt: now,
                    updatedAt: now
                });
            }

            const userRef = doc(db, 'users', user.uid);
            await updateDoc(userRef, {
                weight: weightInKg,
                updatedAt: now
            });

            setSubmitStatus('success');
            setTimeout(() => {
                onSuccess?.();
            }, 500);

        } catch (error) {
            console.error('Weight entry error:', error);
            setError(error.message || 'Failed to save weight entry');
            setSubmitStatus('error');
        }
    };

    const handleCancel = () => {
        setSubmitStatus('idle');
        onCancel?.();
    };

    const getProgressIndicator = () => {
        if (!lastEntry || !weight) return null;
        
        const baseWeight = convertWeight(lastEntry.weight, 'metric', measurementPreference);
        const change = parseFloat(weight) - baseWeight;
        
        if (Math.abs(change) < 0.1) return {
            icon: Star,
            message: "Maintaining steady!",
            color: "text-blue-600"
        };
        
        if (change < 0) return {
            icon: TrendingDown,
            message: "On track!",
            color: "text-green-600"
        };
        
        return {
            icon: TrendingUp,
            message: "Keep pushing!",
            color: "text-red-600"
        };
    };

    const getStreakIcon = () => {
        const streakLevel = MOTIVATION_MESSAGES.streak
            .slice()
            .reverse()
            .find(m => streak >= m.threshold);
            
        return streakLevel?.icon || Star;
    };

    const getWeightChangeClass = () => {
        if (!lastEntry || !weight) return 'text-gray-600';
        
        const baseWeight = convertWeight(lastEntry.weight, 'metric', measurementPreference);
        const change = parseFloat(weight) - baseWeight;
        
        return change < 0 
            ? 'text-green-600' 
            : change > 0 
                ? 'text-red-600' 
                : 'text-blue-600';
    };

    const getWeightDifference = () => {
        if (!lastEntry || !weight) return null;
        
        const baseWeight = convertWeight(lastEntry.weight, 'metric', measurementPreference);
        const difference = (parseFloat(weight) - baseWeight).toFixed(1);
        const unit = measurementPreference === 'imperial' ? 'lbs' : 'kg';
        
        if (difference === '0.0') return 'No change';
        return `${difference > 0 ? '+' : ''}${difference} ${unit}`;
    };

    const getSliderBackgroundStyle = () => {
        const range = getSliderRange(convertWeight(lastEntry?.weight, 'metric', measurementPreference), measurementPreference);
        const percentage = ((parseFloat(weight) - range.min) / (range.max - range.min)) * 100;
        
        return {
            background: `linear-gradient(to right, 
                rgb(34 197 94 / 0.2) 0%, 
                rgb(34 197 94 / 0.2) ${percentage}%, 
                rgb(239 68 68 / 0.2) ${percentage}%, 
                rgb(239 68 68 / 0.2) 100%)`
        };
    };
    return (
        <div className="bg-gradient-to-br from-blue-50 to-white p-4 sm:p-6 max-w-2xl mx-auto">
            {/* Header */}
            <div className="flex items-center justify-between mb-4 sm:mb-6">
                <div className="flex items-center gap-2">
                    <div className="p-1.5 sm:p-2 bg-blue-100 rounded-lg">
                        <Scale className="w-5 h-5 sm:w-6 sm:h-6 text-blue-500" />
                    </div>
                    <div>
                        <h2 className="text-lg sm:text-xl font-semibold text-gray-900">
                            Record Today's Weight
                        </h2>
                        <p className="text-xs sm:text-sm text-gray-500">
                            {format(new Date(), 'EEEE, MMMM d, yyyy')}
                        </p>
                    </div>
                </div>
                <button
                    onClick={handleCancel}
                    className="p-2 hover:bg-blue-100 rounded-full transition-colors"
                >
                    <X className="w-5 h-5 text-gray-500" />
                </button>
            </div>

            <form onSubmit={handleSubmit} className="space-y-4 sm:space-y-6">
                {error && (
                    <div className="bg-red-50 border-l-4 border-red-500 p-4 rounded animate-shake">
                        <p className="text-red-700">{error}</p>
                    </div>
                )}

                {/* Streak Section */}
                {streak > 0 && (
                    <div className="bg-gradient-to-r from-blue-50 to-indigo-50 p-4 rounded-lg flex items-center gap-3">
                        {React.createElement(getStreakIcon(), {
                            className: "w-5 h-5 text-blue-500"
                        })}
                        <div>
                            <div className="text-sm font-medium text-gray-800">
                                {streak} day{streak !== 1 ? 's' : ''} streak!
                            </div>
                            {motivationMessage && (
                                <div className="text-sm text-gray-600">{motivationMessage}</div>
                            )}
                        </div>
                    </div>
                )}

                {/* Weight Input Section */}
                <div className="bg-white p-4 sm:p-6 rounded-xl shadow-sm">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                        Weight ({measurementPreference === 'metric' ? 'kg' : 'lbs'})
                    </label>
                    
                    {/* Numeric Input */}
                    <div className="relative flex items-center mb-6">
                        <input
                            type="number"
                            value={weight}
                            onChange={(e) => handleWeightChange(e.target.value)}
                            className="block w-full px-4 py-3 text-2xl font-semibold text-center rounded-lg border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                            placeholder="0.0"
                            step={weightStep}
                            min={minWeight}
                            max={maxWeight}
                            required
                            disabled={submitStatus === 'loading'}
                            inputMode="decimal"
                        />
                        <div className="absolute right-2 flex flex-col gap-1">
                            <button
                                type="button"
                                onClick={handleWeightIncrement}
                                className="p-1.5 hover:bg-gray-100 rounded transition-colors"
                            >
                                <ChevronUp className="w-5 h-5 text-gray-500" />
                            </button>
                            <button
                                type="button"
                                onClick={handleWeightDecrement}
                                className="p-1.5 hover:bg-gray-100 rounded transition-colors"
                            >
                                <ChevronDown className="w-5 h-5 text-gray-500" />
                            </button>
                        </div>
                    </div>

                    {/* Centered Slider */}
                    {lastEntry && (
                        <div className="space-y-4">
                            <div className="relative pt-6 pb-2">
                                {/* Slider Track Background */}
                                <div className="absolute w-full h-2 bg-gray-200 rounded-lg overflow-hidden">
                                    {/* Left side (loss) */}
                                    <div className="absolute left-0 w-1/2 h-full bg-gradient-to-r from-green-100 to-blue-100" />
                                    {/* Right side (gain) */}
                                    <div className="absolute right-0 w-1/2 h-full bg-gradient-to-l from-red-100 to-blue-100" />
                                </div>

                                {/* Center Marker */}
                                <div className="absolute h-8 w-0.5 bg-blue-500 top-0 left-1/2 transform -translate-x-1/2" />

                                {/* Slider Input */}
                                <input
                                    type="range"
                                    min={-5}
                                    max={5}
                                    step={weightStep}
                                    value={sliderValue}
                                    onChange={handleSliderChange}
                                    className="relative w-full h-2 appearance-none bg-transparent cursor-pointer z-10 touch-manipulation"
                                    style={{
                                        '--thumb-size': '24px',
                                        '--thumb-color': '#3B82F6',
                                    }}
                                />
                            </div>

                            {/* Weight Range Labels */}
                            <div className="flex justify-between items-center text-sm px-2">
                                <div className="flex items-center gap-1">
                                    <ArrowLeft className="w-4 h-4 text-green-600" />
                                    <span className="text-green-600 font-medium">Weight Loss</span>
                                </div>
                                <div className="text-center">
                                    <span className="text-blue-600 font-medium">Current</span>
                                    <div className="text-sm text-gray-600">
                                        {formatWeight(lastEntry.weight, measurementPreference)}
                                    </div>
                                </div>
                                <div className="flex items-center gap-1">
                                    <span className="text-red-600 font-medium">Weight Gain</span>
                                    <ArrowRight className="w-4 h-4 text-red-600" />
                                </div>
                            </div>

                            {/* Weight Change Display */}
                            <div className="mt-4 p-3 bg-gray-50 rounded-lg flex justify-between items-center">
                                <span className="text-sm text-gray-600">Change from current weight:</span>
                                <span className={`text-sm font-medium ${getWeightChangeClass()}`}>
                                    {getWeightDifference()}
                                </span>
                            </div>
                        </div>
                    )}
                </div>

                {/* Notes Section */}
                <div className="bg-white p-4 sm:p-6 rounded-xl shadow-sm">
                    <div className="flex items-center justify-between mb-2">
                        <label className="block text-sm font-medium text-gray-700">
                            Notes (optional)
                        </label>
                        <button
                            type="button"
                            onClick={() => setShowTips(!showTips)}
                            className="flex items-center gap-1 text-sm text-blue-500 hover:text-blue-600"
                        >
                            <Info className="w-4 h-4" />
                            Tips
                        </button>
                    </div>
                    
                    {showTips && (
                        <div className="mb-3 p-3 bg-blue-50 rounded-lg text-sm text-blue-700">
                            <p>Helpful things to note:</p>
                            <ul className="list-disc ml-4 mt-1 space-y-1">
                                <li>Time of day when you weighed yourself</li>
                                <li>Recent meals or water intake</li>
                                <li>Exercise or physical activity</li>
                                <li>Sleep quality</li>
                                <li>Any factors that might affect your weight</li>
                            </ul>
                        </div>
                    )}
                    
                    <textarea
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                        className="block w-full px-4 py-3 rounded-lg border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        placeholder="Add any notes about this weight entry..."
                        rows="3"
                        disabled={submitStatus === 'loading'}
                    />
                </div>

                {/* Action Buttons */}
                <div className="flex flex-col sm:flex-row gap-3">
                    <ActionButton
                        onClick={handleSubmit}
                        disabled={submitStatus === 'loading' || !weight}
                        variant="primary"
                        loading={submitStatus === 'loading'}
                        icon={submitStatus === 'success' ? Check : Save}
                    >
                        {submitStatus === 'loading' ? 'Saving...' : 
                         submitStatus === 'success' ? 'Saved!' : 
                         'Save Weight Entry'}
                    </ActionButton>
                    
                    <ActionButton
                        onClick={handleCancel}
                        disabled={submitStatus === 'loading'}
                        variant="secondary"
                        icon={X}
                    >
                        Cancel
                    </ActionButton>
                </div>
            </form>

            <style >{`
                input[type="range"] {
                    height: 32px;
                    margin: -16px 0;
                }
                input[type="range"]::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    width: var(--thumb-size);
                    height: var(--thumb-size);
                    border-radius: 50%;
                    background: var(--thumb-color);
                    border: 2px solid white;
                    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
                    cursor: pointer;
                    margin-top: -11px;
                }
                input[type="range"]::-moz-range-thumb {
                    width: var(--thumb-size);
                    height: var(--thumb-size);
                    border-radius: 50%;
                    background: var(--thumb-color);
                    border: 2px solid white;
                    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
                    cursor: pointer;
                }
            `}</style>
        </div>
    );
};

export default WeightEntryForm;