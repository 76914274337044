import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
    Shield, 
    Lock,
    Mail,
    Eye,
    EyeOff,
    ArrowRight,
    X,
    CheckCircle2,
    Smartphone,
    Activity,
    LockKeyhole,
    Heart
} from 'lucide-react';
import { auth, db } from '../../../firebase';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { initializeUserData } from '../../../utils/auth';
import WatchMyHealthLogo from '../../ui/WatchMyHealthLogo';

const AuthPage = () => {
    const [activeTab, setActiveTab] = useState('signup');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const returnUrl = searchParams.get('returnUrl') || '/dashboard';

    const handleSignIn = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);
    
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const userId = userCredential.user.uid;
    
            const adminDoc = await getDoc(doc(db, 'admins', userId));
            if (adminDoc.exists()) {
                navigate('/admin/dashboard');
                return;
            }
    
            const userDoc = await getDoc(doc(db, 'users', userId));
            if (!userDoc.exists() || !userDoc.data().profileCompleted) {
                navigate('/profile-completion');
            } else {
                const decodedReturnUrl = returnUrl ? decodeURIComponent(returnUrl) : '/dashboard';
                navigate(decodedReturnUrl);
            }
        } catch (err) {
            console.error('Login error:', err);
            setError(getAuthErrorMessage(err.code));
        } finally {
            setLoading(false);
        }
    };

    const handleSignUp = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);
    
        if (password !== confirmPassword) {
            setError("Passwords don't match");
            setLoading(false);
            return;
        }
    
        if (password.length < 6) {
            setError('Password should be at least 6 characters');
            setLoading(false);
            return;
        }
    
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
    
            await initializeUserData(user.uid, email);
            navigate('/profile-completion');
        } catch (error) {
            console.error('SignUp error:', error);
            setError(getAuthErrorMessage(error.code));
        } finally {
            setLoading(false);
        }
    };

    const getAuthErrorMessage = (errorCode) => {
        const errorMessages = {
            'auth/email-already-in-use': 'This email is already registered',
            'auth/invalid-email': 'Invalid email format',
            'auth/operation-not-allowed': 'Email/password accounts are not enabled',
            'auth/weak-password': 'Password should be at least 6 characters',
            'auth/user-not-found': 'No account found with this email',
            'auth/wrong-password': 'Incorrect password',
            'auth/too-many-requests': 'Too many failed attempts. Please try again later',
            'auth/network-request-failed': 'Network error. Please check your connection',
            'permission-denied': 'Failed to create user profile'
        };
        return errorMessages[errorCode] || 'An unexpected error occurred';
    };

    const validateEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const checkPasswordStrength = (password) => {
        let strength = 0;
        if (password.length >= 8) strength++;
        if (/[A-Z]/.test(password)) strength++;
        if (/[0-9]/.test(password)) strength++;
        if (/[^A-Za-z0-9]/.test(password)) strength++;
        return strength;
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-gray-50 to-blue-50/30">
            {/* Header */}
            <header className="fixed top-0 inset-x-0 h-16 bg-white/80 backdrop-blur-sm border-b border-gray-100 flex items-center justify-between px-4 lg:px-8 z-50">
                <div className="flex items-center gap-2">
                    <WatchMyHealthLogo width={32} height={32} />
                    <span className="text-xl font-bold bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent">
                        WatchMy.Health
                    </span>
                </div>
            </header>

            {/* Main Content */}
            <main className="pt-16 min-h-screen">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
                    <div className="grid lg:grid-cols-2 gap-12 items-start">
                        {/* Left Side - Features */}
                        <div className="hidden lg:block sticky top-24">
                            <div className="relative">
                                {/* Animated Background Blobs */}
                                <div className="absolute -top-12 -left-12 w-64 h-64 bg-blue-400/10 rounded-full mix-blend-multiply filter blur-2xl animate-blob"></div>
                                <div className="absolute -bottom-8 -right-8 w-64 h-64 bg-indigo-400/10 rounded-full mix-blend-multiply filter blur-2xl animate-blob animation-delay-2000"></div>
                                
                                {/* Content Card */}
                                <div className="relative bg-white/80 backdrop-blur-sm rounded-3xl shadow-xl p-8 space-y-8">
                                    <div className="flex items-center gap-4">
                                        <div className="p-3 bg-blue-50 rounded-xl">
                                            <Heart className="w-8 h-8 text-blue-600" />
                                        </div>
                                        <div>
                                            <h2 className="text-2xl font-bold text-gray-900">
                                                Transform Your Health Journey
                                            </h2>
                                            <p className="text-gray-600 mt-1">
                                                Join thousands tracking their wellness with WatchMy.Health
                                            </p>
                                        </div>
                                    </div>

                                    {/* Feature List */}
                                    <div className="space-y-6">
                                        {[
                                            {
                                                icon: <Activity className="w-6 h-6 text-blue-600" />,
                                                title: 'Smart Health Tracking',
                                                description: 'Personalized insights based on your unique health profile'
                                            },
                                            {
                                                icon: <Smartphone className="w-6 h-6 text-blue-600" />,
                                                title: 'Seamless Mobile Experience',
                                                description: 'Track your progress anytime, anywhere with our mobile app'
                                            },
                                            {
                                                icon: <LockKeyhole className="w-6 h-6 text-blue-600" />,
                                                title: 'Bank-Grade Security',
                                                description: 'Your health data is encrypted and protected'
                                            }
                                        ].map((feature, index) => (
                                            <div key={index} className="flex items-start gap-4 p-4 rounded-xl hover:bg-blue-50 transition-colors">
                                                <div className="flex-shrink-0">
                                                    {feature.icon}
                                                </div>
                                                <div>
                                                    <h3 className="font-semibold text-gray-900">
                                                        {feature.title}
                                                    </h3>
                                                    <p className="text-gray-600 mt-1">
                                                        {feature.description}
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    {/* Stats */}
                                    <div className="grid grid-cols-2 gap-6 pt-6 border-t border-gray-100">
                                        <div className="text-center p-4 bg-blue-50 rounded-xl">
                                            <div className="text-3xl font-bold text-blue-600">15K+</div>
                                            <div className="text-sm text-gray-600 mt-1">Active Users</div>
                                        </div>
                                        <div className="text-center p-4 bg-indigo-50 rounded-xl">
                                            <div className="text-3xl font-bold text-indigo-600">98%</div>
                                            <div className="text-sm text-gray-600 mt-1">Satisfaction</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Right Side - Auth Form */}
                        <div className="lg:pl-12">
                            <div className="max-w-md mx-auto">
                                {/* Auth Tabs */}
                                <div className="bg-white rounded-2xl shadow-sm border border-gray-100 p-1 mb-8">
                                    <div className="grid grid-cols-2 gap-1">
                                        <button
                                            onClick={() => setActiveTab('signup')}
                                            className={`py-3 px-6 rounded-xl font-medium transition-all ${
                                                activeTab === 'signup'
                                                    ? 'bg-gradient-to-r from-blue-600 to-indigo-600 text-white'
                                                    : 'text-gray-600 hover:text-gray-900'
                                            }`}
                                        >
                                            Create Account
                                        </button>
                                        <button
                                            onClick={() => setActiveTab('signin')}
                                            className={`py-3 px-6 rounded-xl font-medium transition-all ${
                                                activeTab === 'signin'
                                                    ? 'bg-gradient-to-r from-blue-600 to-indigo-600 text-white'
                                                    : 'text-gray-600 hover:text-gray-900'
                                            }`}
                                        >
                                            Sign In
                                        </button>
                                    </div>
                                </div>

                                {/* Title */}
                                <div className="text-center mb-8">
                                    <h1 className="text-3xl font-bold text-gray-900">
                                        {activeTab === 'signin' ? 'Welcome back' : 'Get started today'}
                                    </h1>
                                    <p className="mt-3 text-gray-600">
                                        {activeTab === 'signin' 
                                            ? 'Sign in to continue your health journey' 
                                            : 'Create your account in just a few steps'}
                                    </p>
                                </div>

                                {/* Error Message */}
                                {error && (
                                    <div className="mb-6 bg-red-50 border-l-4 border-red-500 p-4 rounded-lg flex items-center justify-between">
                                        <div className="flex items-center gap-3">
                                            <X className="w-5 h-5 text-red-500" />
                                            <p className="text-sm text-red-600">{error}</p>
                                        </div>
                                        <button 
                                            onClick={() => setError('')}
                                            className="text-red-400 hover:text-red-600"
                                        >
                                            <X className="w-4 h-4" />
                                        </button>
                                    </div>
                                )}

                                {/* Auth Form */}
                                <form 
                                    onSubmit={activeTab === 'signup' ? handleSignUp : handleSignIn}
                                    className="space-y-6"
                                >
                                    {/* Email Field */}
                                    <div className="space-y-2">
                                        <label className="text-sm font-medium text-gray-700">
                                            Email address
                                        </label>
                                        <div className="relative">
                                            <input
                                                type="email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                className="w-full pl-11 pr-4 py-3 rounded-xl border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors"
                                                placeholder="Enter your email"
                                                required
                                            />
                                            <Mail className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
                                        </div>
                                    </div>

                                    {/* Password Field */}
                                    <div className="space-y-2">
                                        <label className="text-sm font-medium text-gray-700">
                                            Password
                                        </label>
                                        <div className="relative">
                                            <input
                                                type={showPassword ? 'text' : 'password'}
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                className="w-full pl-11 pr-12 py-3 rounded-xl border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors"
                                                placeholder={activeTab === 'signup' ? 'Create a password' : 'Enter your password'}
                                                required
                                            />
                                            <Lock className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
                                            <button
                                                type="button"
                                                onClick={() => setShowPassword(!showPassword)}
                                                className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
                                            >
                                                {showPassword ? (
                                                    <EyeOff className="w-5 h-5" />
                                                ) : (
                                                    <Eye className="w-5 h-5" />
                                                )}
                                            </button>
                                        </div>
                                    </div>

                                    {/* Confirm Password - Sign Up Only */}
                                    {activeTab === 'signup' && (
                                        <div className="space-y-2">
                                            <label className="text-sm font-medium text-gray-700">
                                                Confirm password
                                            </label>
                                            <div className="relative">
                                                <input
                                                    type={showPassword ? 'text' : 'password'}
                                                    value={confirmPassword}
                                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                                    className="w-full pl-11 pr-4 py-3 rounded-xl border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors"
                                                    placeholder="Confirm your password"
                                                    required
                                                />
                                                <Shield className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
                                            </div>
                                        </div>
                                    )}

                                    {/* Additional Options */}
                                    {activeTab === 'signin' && (
                                        <div className="flex items-center justify-between text-sm">
                                            <label className="flex items-center gap-2">
                                                <input
                                                    type="checkbox"
                                                    className="w-4 h-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                                />
                                                <span className="text-gray-600">Remember me</span>
                                            </label>
                                            <button
                                                type="button"
                                                className="text-blue-600 hover:text-blue-500 font-medium"
                                            >
                                                Forgot password?
                                            </button>
                                        </div>
                                    )}

                                    {/* Submit Button */}
                                    <button
                                        type="submit"
                                        disabled={loading}
                                        className="w-full bg-gradient-to-r from-blue-600 to-indigo-600 text-white py-4 rounded-xl font-medium flex items-center justify-center gap-2 hover:from-blue-700 hover:to-indigo-700 transition-all focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed group"
                                    >
                                        {loading ? (
                                            <div className="flex items-center gap-2">
                                                <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                                                </svg>
                                                <span>Processing...</span>
                                            </div>
                                        ) : (
                                            <>
                                                <span>{activeTab === 'signin' ? 'Sign In' : 'Create Account'}</span>
                                                <ArrowRight className="w-5 h-5 transform group-hover:translate-x-1 transition-transform" />
                                            </>
                                        )}
                                    </button>

                                    {/* Terms - Sign Up Only */}
                                    {activeTab === 'signup' && (
                                        <p className="text-sm text-gray-600 text-center">
                                            By creating an account, you agree to our{' '}
                                            <a href="/terms" className="text-blue-600 hover:text-blue-500">
                                                Terms of Service
                                            </a>{' '}
                                            and{' '}
                                            <a href="/privacy" className="text-blue-600 hover:text-blue-500">
                                                Privacy Policy
                                            </a>
                                        </p>
                                    )}
                                </form>

                                {/* Toggle Auth Mode */}
                                <p className="mt-8 text-center text-sm text-gray-600">
                                    {activeTab === 'signin' ? (
                                        <>
                                            New to WatchMy.Health?{' '}
                                            <button
                                                onClick={() => setActiveTab('signup')}
                                                className="text-blue-600 font-medium hover:text-blue-500 focus:outline-none focus:underline transition-colors"
                                            >
                                                Create an account
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            Already have an account?{' '}
                                            <button
                                                onClick={() => setActiveTab('signin')}
                                                className="text-blue-600 font-medium hover:text-blue-500 focus:outline-none focus:underline transition-colors"
                                            >
                                                Sign in here
                                            </button>
                                        </>
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            {/* Footer */}
            <footer className="bg-white border-t border-gray-100 py-8 mt-12">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex flex-col md:flex-row justify-between items-center gap-6">
                        <div className="flex items-center gap-8">
                            <a href="/terms" className="text-sm text-gray-600 hover:text-gray-900 transition-colors">
                                Terms of Service
                            </a>
                            <a href="/privacy" className="text-sm text-gray-600 hover:text-gray-900 transition-colors">
                                Privacy Policy
                            </a>
                            <a href="/help" className="text-sm text-gray-600 hover:text-gray-900 transition-colors">
                                Help Center
                            </a>
                        </div>
                        <div className="flex items-center gap-2 text-sm text-gray-600">
                            <Shield className="w-4 h-4" />
                            Protected by enterprise-grade security
                        </div>
                    </div>
                </div>
            </footer>

            {/* Mobile App Banner */}
            <div className="lg:hidden fixed bottom-0 inset-x-0 bg-gradient-to-t from-gray-900 to-gray-900/90 text-white p-4 backdrop-blur-sm">
                <div className="flex items-center justify-between max-w-md mx-auto">
                    <div>
                        <h3 className="font-medium">Get our mobile app</h3>
                        <p className="text-sm text-gray-300">Track your health anywhere</p>
                    </div>
                    <button className="px-4 py-2 bg-white text-gray-900 rounded-lg font-medium hover:bg-gray-100 transition-colors">
                        Download
                    </button>
                </div>
            </div>
        </div>
    );
};

// Add these styles to your CSS or Tailwind config
const styles = `
@keyframes blob {
    0% {
        transform: translate(0px, 0px) scale(1);
    }
    33% {
        transform: translate(30px, -50px) scale(1.1);
    }
    66% {
        transform: translate(-20px, 20px) scale(0.9);
    }
    100% {
        transform: translate(0px, 0px) scale(1);
    }
}

.animate-blob {
    animation: blob 7s infinite;
}

.animation-delay-2000 {
    animation-delay: 2s;
}
`;

export default AuthPage;