import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  PlayCircle, Clock, Calendar, Moon, Sun, 
  Activity, Zap, Info, ChevronDown, AlertTriangle,
  Timer, Flame, sparkles
} from 'lucide-react';

const StartFast = ({ onStartFast, isLoading }) => {
  const [activeTab, setActiveTab] = useState('quick');
  const [showCustom, setShowCustom] = useState(false);
  const [customDuration, setCustomDuration] = useState({
    days: 0,
    hours: 16,
    minutes: 0
  });
  const [selectedPreset, setSelectedPreset] = useState(null);
  const [error, setError] = useState('');

  const presets = [
    { 
      id: '16-8',
      hours: 16,
      name: 'Intermittent',
      icon: Sun,
      color: 'orange',
      description: 'Perfect for beginners',
      benefits: ['Improved metabolism', 'Better sleep', 'Enhanced focus'],
      difficulty: 1
    },
    { 
      id: '18-6',
      hours: 18,
      name: 'Extended',
      icon: Moon,
      color: 'blue',
      description: 'Intermediate fasting',
      benefits: ['Fat adaptation', 'Increased autophagy', 'Mental clarity'],
      difficulty: 2
    },
    { 
      id: '20-4',
      hours: 20,
      name: 'Long',
      icon: Activity,
      color: 'purple',
      description: 'Advanced fasting',
      benefits: ['Deep ketosis', 'Cellular repair', 'Growth hormone boost'],
      difficulty: 3
    },
    { 
      id: '24-0',
      hours: 24,
      name: 'OMAD',
      icon: Zap,
      color: 'green',
      description: 'One meal a day',
      benefits: ['Maximum autophagy', 'Simplified eating', 'Metabolic reset'],
      difficulty: 4
    }
  ];

  const handleCustomDurationChange = (field, value) => {
    const numValue = Math.max(0, parseInt(value) || 0);
    
    let adjustedValue = numValue;
    switch (field) {
      case 'minutes':
        adjustedValue = Math.min(59, numValue);
        break;
      case 'hours':
        adjustedValue = Math.min(23, numValue);
        break;
      case 'days':
        adjustedValue = Math.min(30, numValue);
        break;
    }

    setCustomDuration(prev => ({ ...prev, [field]: adjustedValue }));
    setError('');
  };

  const getTotalHours = () => {
    return (customDuration.days * 24) + 
           customDuration.hours + 
           (customDuration.minutes / 60);
  };

  const handleStartCustomFast = () => {
    const totalHours = getTotalHours();
    if (totalHours <= 0) {
      setError('Please set a duration greater than 0');
      return;
    }
    onStartFast(totalHours);
  };

  const renderDifficultyDots = (difficulty) => {
    return (
      <div className="flex gap-1">
        {[...Array(4)].map((_, i) => (
          <div
            key={i}
            className={`w-1.5 h-1.5 rounded-full ${
              i < difficulty
                ? 'bg-blue-500'
                : 'bg-gray-200'
            }`}
          />
        ))}
      </div>
    );
  };

  const renderWarning = () => {
    const totalHours = getTotalHours();
    
    if (totalHours >= 72) {
      return (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="p-4 bg-red-50 rounded-xl flex items-start gap-3"
        >
          <AlertTriangle className="w-5 h-5 text-red-500 mt-0.5" />
          <div>
            <h4 className="font-medium text-red-700">Extended Fast Warning</h4>
            <p className="text-sm text-red-600 mt-1">
              Fasts longer than 72 hours should only be undertaken with medical supervision.
              Please consult healthcare professionals before proceeding.
            </p>
          </div>
        </motion.div>
      );
    }
    
    if (totalHours >= 48) {
      return (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="p-4 bg-yellow-50 rounded-xl flex items-start gap-3"
        >
          <AlertTriangle className="w-5 h-5 text-yellow-500 mt-0.5" />
          <div>
            <h4 className="font-medium text-yellow-700">Extended Fast Notice</h4>
            <p className="text-sm text-yellow-600 mt-1">
              Extended fasting requires proper preparation. Make sure you're well-hydrated
              and have experience with shorter fasts first.
            </p>
          </div>
        </motion.div>
      );
    }

    return null;
  };

  return (
    <div className="max-w-2xl mx-auto">
      <div className="backdrop-blur-md bg-white/90 rounded-3xl border border-white/20 shadow-2xl p-8">
        <div className="relative space-y-8">
          <div className="text-center">
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              className="inline-block p-2 rounded-full bg-blue-50 mb-4"
            >
              <Timer className="w-6 h-6 text-blue-500" />
            </motion.div>
            <h2 className="text-3xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
              Start Your Journey
            </h2>
            <p className="text-gray-600 mt-2">Choose your fasting experience</p>
          </div>

          {/* Tab Navigation */}
          <div className="flex rounded-xl bg-gray-100 p-1">
            {['quick', 'preset', 'custom'].map((tab) => (
              <motion.button
                key={tab}
                whileTap={{ scale: 0.95 }}
                onClick={() => setActiveTab(tab)}
                className={`flex-1 py-2 px-4 rounded-lg font-medium transition-all ${
                  activeTab === tab
                    ? 'bg-white text-blue-600 shadow-sm'
                    : 'text-gray-600 hover:text-gray-900'
                }`}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </motion.button>
            ))}
          </div>

          <AnimatePresence mode="wait">
            {activeTab === 'quick' && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="space-y-6"
              >
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => onStartFast(16)}
                  disabled={isLoading}
                  className="w-full py-6 bg-gradient-to-r from-blue-500 to-purple-500 
                           text-white rounded-2xl shadow-lg flex flex-col items-center justify-center gap-3
                           hover:from-blue-600 hover:to-purple-600 transition-all
                           disabled:opacity-50 disabled:cursor-not-allowed group"
                >
                  <div className="relative">
                    <PlayCircle className="w-12 h-12 group-hover:scale-110 transition-transform" />
                    <motion.div
                      className="absolute inset-0"
                      animate={{ scale: [1, 1.2, 1] }}
                      transition={{ duration: 2, repeat: Infinity }}
                    >
                      <div className="w-12 h-12 rounded-full bg-white/20" />
                    </motion.div>
                  </div>
                  <div className="text-center">
                    <div className="font-medium text-lg">Start 16:8 Fast</div>
                    <div className="text-sm text-white/80">Most popular method</div>
                  </div>
                </motion.button>

                {/* Quick Tips Card */}
                <div className="bg-gradient-to-br from-blue-50 to-purple-50 p-6 rounded-2xl">
                  <div className="flex items-start gap-4">
                    <div className="bg-white p-2 rounded-xl shadow-sm">
                      <Info className="w-6 h-6 text-blue-500" />
                    </div>
                    <div>
                      <h4 className="font-medium text-gray-900">Quick Tips</h4>
                      <ul className="mt-2 space-y-2">
                        <li className="flex items-center gap-2 text-sm text-gray-600">
                          <div className="w-1 h-1 rounded-full bg-blue-500" />
                          Start with 16:8 if you're new to fasting
                        </li>
                        <li className="flex items-center gap-2 text-sm text-gray-600">
                          <div className="w-1 h-1 rounded-full bg-blue-500" />
                          Stay hydrated throughout your fast
                        </li>
                        <li className="flex items-center gap-2 text-sm text-gray-600">
                          <div className="w-1 h-1 rounded-full bg-blue-500" />
                          Track your progress and adjust as needed
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </motion.div>
            )}

            {activeTab === 'preset' && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="grid grid-cols-1 gap-4"
              >
                {presets.map((preset, index) => (
                  <motion.button
                    key={preset.id}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ 
                      opacity: 1,
                      y: 0,
                      transition: { delay: index * 0.1 }
                    }}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={() => {
                      setSelectedPreset(preset.id);
                      onStartFast(preset.hours);
                    }}
                    disabled={isLoading}
                    className={`p-6 rounded-xl border-2 transition-all
                      ${selectedPreset === preset.id
                        ? 'border-blue-500 bg-blue-50'
                        : 'border-gray-200 hover:border-blue-200 hover:bg-gray-50'
                      } disabled:opacity-50 disabled:cursor-not-allowed`}
                  >
                    <div className="flex items-start gap-4">
                      <div className={`p-3 rounded-xl bg-${preset.color}-100`}>
                        <preset.icon className={`w-6 h-6 text-${preset.color}-500`} />
                      </div>
                      <div className="flex-1 text-left">
                        <div className="flex items-center justify-between">
                          <p className="font-medium text-gray-900">{preset.name}</p>
                          {renderDifficultyDots(preset.difficulty)}
                        </div>
                        <p className="text-sm text-gray-500 mt-1">{preset.description}</p>
                        <div className="mt-3 flex flex-wrap gap-2">
                          {preset.benefits.map((benefit, i) => (
                            <span
                              key={i}
                              className="px-2 py-1 bg-white rounded-full text-xs text-gray-600"
                            >
                              {benefit}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  </motion.button>
                ))}
              </motion.div>
            )}

            {activeTab === 'custom' && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="space-y-6"
              >
                <div className="grid grid-cols-3 gap-4">
                  {[
                    { label: 'Days', field: 'days', max: 30 },
                    { label: 'Hours', field: 'hours', max: 23 },
                    { label: 'Minutes', field: 'minutes', max: 59 }
                  ].map(({ label, field, max }) => (
                    <div key={field}>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        {label}
                      </label>
                      <div className="relative">
                        <input
                          type="number"
                          min="0"
                          max={max}
                          value={customDuration[field]}
                          onChange={(e) => handleCustomDurationChange(field, e.target.value)}
                          className="w-full px-4 py-3 border-2 rounded-xl focus:ring-2 focus:ring-blue-500
                                   focus:border-blue-500 transition-all"
                          disabled={isLoading}
                        />
                        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                          <span className="text-gray-400">{label.toLowerCase().slice(0, -1)}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                {error && (
                  <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="text-red-500 text-sm"
                  >
                    {error}
                  </motion.div>
                )}

                <div className="bg-gradient-to-r from-gray-50 to-blue-50 rounded-xl p-6">
                  <div className="flex justify-between items-center">
                    <div className="space-y-1">
                      <span className="text-sm text-gray-600">Total Duration</span>
                      <div className="font-semibold text-2xl bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
                        {getTotalHours().toFixed(1)} hours
                      </div>
                    </div>
                    <div className="h-12 w-12 rounded-full bg-gradient-to-r from-blue-500 to-purple-500 flex items-center justify-center">
                      <Clock className="w-6 h-6 text-white" />
                    </div>
                  </div>
                </div>

                {renderWarning()}

                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={handleStartCustomFast}
                  disabled={isLoading}
                  className="w-full py-4 bg-gradient-to-r from-blue-500 to-purple-500
                           text-white rounded-xl shadow-lg hover:shadow-xl
                           flex items-center justify-center gap-3
                           disabled:opacity-50 disabled:cursor-not-allowed
                           transition-all duration-300"
                >
                  <PlayCircle className="w-6 h-6" />
                  <span className="font-medium">Begin Custom Fast</span>
                </motion.button>

                {/* Advanced Tips */}
                <div className="bg-gradient-to-br from-purple-50 to-blue-50 rounded-xl p-6">
                  <h4 className="font-medium text-gray-900 flex items-center gap-2">
                    <Flame className="w-5 h-5 text-orange-500" />
                    Pro Tips
                  </h4>
                  <div className="mt-4 space-y-3">
                    <div className="flex items-start gap-3">
                      <div className="w-6 h-6 rounded-full bg-white flex items-center justify-center flex-shrink-0">
                        <div className="w-2 h-2 rounded-full bg-blue-500" />
                      </div>
                      <p className="text-sm text-gray-600">
                        For fasts over 24 hours, ensure you're well-hydrated and have electrolytes ready.
                      </p>
                    </div>
                    <div className="flex items-start gap-3">
                      <div className="w-6 h-6 rounded-full bg-white flex items-center justify-center flex-shrink-0">
                        <div className="w-2 h-2 rounded-full bg-purple-500" />
                      </div>
                      <p className="text-sm text-gray-600">
                        Consider breaking extended fasts with easily digestible foods.
                      </p>
                    </div>
                    <div className="flex items-start gap-3">
                      <div className="w-6 h-6 rounded-full bg-white flex items-center justify-center flex-shrink-0">
                        <div className="w-2 h-2 rounded-full bg-orange-500" />
                      </div>
                      <p className="text-sm text-gray-600">
                        Monitor your energy levels and listen to your body throughout the fast.
                      </p>
                    </div>
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>

          {/* Progress Indicator */}
          {isLoading && (
            <div className="absolute inset-0 bg-white/80 backdrop-blur-sm rounded-3xl flex items-center justify-center">
              <motion.div
                animate={{ rotate: 360 }}
                transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
                className="w-8 h-8 border-2 border-blue-500 border-t-transparent rounded-full"
              />
            </div>
          )}
        </div>
      </div>
      
      {/* Additional Resources Card */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        className="mt-6 bg-white/90 backdrop-blur-md rounded-2xl border border-white/20 shadow-lg p-6"
      >
        <div className="flex items-center justify-between">
          <h3 className="font-medium text-gray-900">Need Help?</h3>
          <Info className="w-5 h-5 text-blue-500" />
        </div>
        <div className="mt-4 grid grid-cols-2 gap-4">
          <button className="p-4 rounded-xl bg-gray-50 hover:bg-gray-100 transition-colors text-left">
            <h4 className="font-medium text-gray-900">Fasting Guide</h4>
            <p className="text-sm text-gray-600 mt-1">Learn about different fasting methods</p>
          </button>
          <button className="p-4 rounded-xl bg-gray-50 hover:bg-gray-100 transition-colors text-left">
            <h4 className="font-medium text-gray-900">FAQ</h4>
            <p className="text-sm text-gray-600 mt-1">Common questions and answers</p>
          </button>
        </div>
      </motion.div>
    </div>
  );
};

StartFast.propTypes = {
  onStartFast: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

export default StartFast;