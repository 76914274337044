// src/components/user/Authorization/AuthPromptOverlay.js
import React, { createContext, useContext, useCallback, useRef, useEffect } from 'react';
import { X, UserPlus, LogIn, Shield, ChevronRight, Star } from 'lucide-react';

const AuthPromptContext = createContext();

const FeatureHighlight = ({ icon: Icon, title, description }) => (
  <div className="flex items-start space-x-3 p-3 rounded-lg hover:bg-slate-50 transition-colors">
    <div className="flex-shrink-0">
      <Icon className="w-5 h-5 text-blue-500" />
    </div>
    <div>
      <h4 className="text-sm font-medium text-slate-900">{title}</h4>
      <p className="text-xs text-slate-500 mt-0.5">{description}</p>
    </div>
  </div>
);

export const AuthPromptProvider = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [feature, setFeature] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [onLogin, setOnLogin] = React.useState(() => () => {});
  const [onRegister, setOnRegister] = React.useState(() => () => {});
  const [animationState, setAnimationState] = React.useState('entering');
  const modalRef = useRef(null);

  const showAuthPrompt = useCallback((featureName, loginCallback, registerCallback, customMessage) => {
    setFeature(featureName);
    setMessage(customMessage || 'Create an account to access all features and start tracking your health journey today.');
    setOnLogin(() => loginCallback);
    setOnRegister(() => registerCallback);
    setIsOpen(true);
    setAnimationState('entering');
  }, []);

  const hideAuthPrompt = useCallback(() => {
    setAnimationState('leaving');
    setTimeout(() => {
      setIsOpen(false);
      setAnimationState('entering');
      setTimeout(() => {
        setFeature('');
        setMessage('');
        setOnLogin(() => () => {});
        setOnRegister(() => () => {});
      }, 200);
    }, 200);
  }, []);

  // Handle click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        hideAuthPrompt();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, hideAuthPrompt]);

  // Handle escape key
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        hideAuthPrompt();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen, hideAuthPrompt]);

  const premiumFeatures = [
    {
      icon: Shield,
      title: 'Enhanced Security',
      description: 'Your data is encrypted and securely stored'
    },
    {
      icon: Star,
      title: 'Premium Features',
      description: 'Access to advanced health tracking tools'
    }
  ];

  return (
    <AuthPromptContext.Provider value={{ showAuthPrompt, hideAuthPrompt }}>
      {children}
      {isOpen && (
        <div 
          className={`fixed inset-0 z-50 flex items-center justify-center p-4 ${
            animationState === 'entering' 
              ? 'animate-fadeIn' 
              : 'animate-fadeOut'
          }`}
        >
          {/* Backdrop */}
          <div 
            className={`absolute inset-0 bg-black/50 backdrop-blur-sm ${
              animationState === 'entering' 
                ? 'animate-backdropIn' 
                : 'animate-backdropOut'
            }`}
          />
          
          {/* Modal */}
          <div 
            ref={modalRef}
            className={`bg-white rounded-2xl max-w-lg w-full shadow-2xl relative ${
              animationState === 'entering' 
                ? 'animate-modalIn' 
                : 'animate-modalOut'
            }`}
          >
            {/* Close button */}
            <button
              onClick={hideAuthPrompt}
              className="absolute right-4 top-4 p-2 rounded-lg hover:bg-slate-100 text-slate-500 transition-colors"
              aria-label="Close"
            >
              <X className="w-5 h-5" />
            </button>
            
            <div className="p-6">
              {/* Header */}
              <div className="text-center mb-6">
                <div className="w-16 h-16 rounded-full bg-gradient-to-tr from-blue-100 to-indigo-100 flex items-center justify-center mx-auto mb-4">
                  <div className="w-8 h-8 rounded-full bg-gradient-to-r from-blue-500 to-indigo-500 animate-pulse" />
                </div>
                <h3 className="text-xl font-semibold text-slate-900 mb-2">
                  {feature ? `Access ${feature}` : 'Join WatchMy.Health'}
                </h3>
                <p className="text-slate-600 max-w-sm mx-auto">
                  {message}
                </p>
              </div>

              {/* Features Section */}
              <div className="bg-slate-50 rounded-xl p-4 mb-6">
                <h4 className="text-sm font-medium text-slate-900 mb-3 px-3">
                  Included with your account:
                </h4>
                <div className="space-y-2">
                  {premiumFeatures.map((feature, index) => (
                    <FeatureHighlight key={index} {...feature} />
                  ))}
                </div>
              </div>
              
              {/* Action Buttons */}
              <div className="space-y-3">
                <button
                  onClick={() => {
                    onRegister();
                    hideAuthPrompt();
                  }}
                  className="w-full py-3 px-4 rounded-xl bg-gradient-to-r from-blue-600 to-indigo-600 text-white font-medium hover:shadow-lg transition-all duration-300 flex items-center justify-center space-x-2 group"
                >
                  <UserPlus className="w-5 h-5" />
                  <span>Create Account</span>
                  <ChevronRight className="w-4 h-4 opacity-0 group-hover:opacity-100 transform translate-x-[-10px] group-hover:translate-x-0 transition-all" />
                </button>
                
                <button
                  onClick={() => {
                    onLogin();
                    hideAuthPrompt();
                  }}
                  className="w-full py-3 px-4 rounded-xl border border-slate-200 text-slate-700 font-medium hover:bg-slate-50 transition-all duration-300 flex items-center justify-center space-x-2 group"
                >
                  <LogIn className="w-5 h-5" />
                  <span>Sign In</span>
                  <ChevronRight className="w-4 h-4 opacity-0 group-hover:opacity-100 transform translate-x-[-10px] group-hover:translate-x-0 transition-all" />
                </button>
              </div>
              
              {/* Footer */}
              <p className="text-xs text-slate-500 text-center mt-6">
                By joining, you agree to our{' '}
                <a href="/terms" className="text-blue-600 hover:underline">Terms of Service</a>
                {' '}and{' '}
                <a href="/privacy" className="text-blue-600 hover:underline">Privacy Policy</a>
              </p>
            </div>
          </div>
        </div>
      )}
    </AuthPromptContext.Provider>
  );
};

export const useAuthPrompt = () => {
  const context = useContext(AuthPromptContext);
  if (!context) {
    throw new Error('useAuthPrompt must be used within an AuthPromptProvider');
  }
  return context;
};