import React, { useState, useEffect } from 'react';
import { doc, updateDoc, collection, getDocs, getDoc } from 'firebase/firestore';
import { auth, db } from '../../../firebase';
import { useNavigate } from 'react-router-dom';
import { 
  Calendar, 
  Weight, 
  Target, 
  AlertTriangle, 
  ArrowLeft, 
  Save, 
  Check,
  User,
  Info,
  Activity,
  Scale
} from 'lucide-react';

// Constants
const MEASUREMENT_SYSTEMS = {
  METRIC: 'metric',
  IMPERIAL: 'imperial'
};

const GENDERS = {
  MALE: 'male',
  FEMALE: 'female'
};

const ACTIVITY_LEVELS = {
  SEDENTARY: 'sedentary',
  LIGHTLY_ACTIVE: 'lightly_active',
  MODERATELY_ACTIVE: 'moderately_active',
  VERY_ACTIVE: 'very_active',
  EXTREMELY_ACTIVE: 'extremely_active'
};

const ACTIVITY_LEVEL_DETAILS = {
  [ACTIVITY_LEVELS.SEDENTARY]: {
    title: 'Sedentary',
    description: 'Little or no exercise, desk job',
    examples: 'Office work, driving, watching TV',
    multiplier: 1.2
  },
  [ACTIVITY_LEVELS.LIGHTLY_ACTIVE]: {
    title: 'Lightly Active',
    description: 'Light exercise 1-3 days/week',
    examples: 'Walking, light housework, golf',
    multiplier: 1.375
  },
  [ACTIVITY_LEVELS.MODERATELY_ACTIVE]: {
    title: 'Moderately Active',
    description: 'Moderate exercise 3-5 days/week',
    examples: 'Jogging, light cycling, recreational sports',
    multiplier: 1.55
  },
  [ACTIVITY_LEVELS.VERY_ACTIVE]: {
    title: 'Very Active',
    description: 'Hard exercise 6-7 days/week',
    examples: 'Running, swimming, intense cycling',
    multiplier: 1.725
  },
  [ACTIVITY_LEVELS.EXTREMELY_ACTIVE]: {
    title: 'Extremely Active',
    description: 'Very hard exercise & physical job or training twice per day',
    examples: 'Professional athlete, construction worker with additional training',
    multiplier: 1.9
  }
};

// Card Components
const Card = ({ children, className = '' }) => (
  <div className={`bg-white rounded-xl shadow-lg ${className}`}>
    {children}
  </div>
);

const CardHeader = ({ children }) => (
  <div className="p-4 sm:p-6 pb-2 sm:pb-3">{children}</div>
);

const CardTitle = ({ children, className = '' }) => (
  <h3 className={`text-lg sm:text-xl font-semibold ${className}`}>{children}</h3>
);

const CardContent = ({ children }) => (
  <div className="p-4 sm:p-6 pt-2 sm:pt-3">{children}</div>
);
// Form Field Components
const InputField = ({ label, required, error, icon: Icon, ...props }) => (
  <div>
    <label className="block text-sm font-medium text-gray-700 mb-1.5 sm:mb-2">
      {label}
      {required && <span className="text-red-500 ml-1">*</span>}
    </label>
    <div className="relative">
      {Icon && (
        <div className="absolute left-2.5 sm:left-3 top-1/2 -translate-y-1/2 text-gray-400">
          <Icon className="w-4 h-4 sm:w-5 sm:h-5" />
        </div>
      )}
      <input
        className={`w-full p-2.5 sm:p-3 ${Icon ? 'pl-8 sm:pl-10' : ''} border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent text-sm sm:text-base ${
          error ? 'border-red-500' : 'border-gray-300'
        }`}
        {...props}
      />
    </div>
    {error && <p className="mt-1 text-xs sm:text-sm text-red-500">{error}</p>}
  </div>
);

const SelectField = ({ label, required, error, options, icon: Icon, ...props }) => (
  <div>
    <label className="block text-sm font-medium text-gray-700 mb-1.5 sm:mb-2">
      {label}
      {required && <span className="text-red-500 ml-1">*</span>}
    </label>
    <div className="relative">
      {Icon && (
        <div className="absolute left-2.5 sm:left-3 top-1/2 -translate-y-1/2 text-gray-400">
          <Icon className="w-4 h-4 sm:w-5 sm:h-5" />
        </div>
      )}
      <select
        className={`w-full p-2.5 sm:p-3 ${Icon ? 'pl-8 sm:pl-10' : ''} border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent text-sm sm:text-base ${
          error ? 'border-red-500' : 'border-gray-300'
        }`}
        {...props}
      >
        <option value="">Select...</option>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
    {error && <p className="mt-1 text-xs sm:text-sm text-red-500">{error}</p>}
  </div>
);

// Enhanced Radio Card for Activity Level
const ActivityLevelCard = ({ level, value, selected, onChange }) => {
  const details = ACTIVITY_LEVEL_DETAILS[level];
  
  return (
    <label
      className={`block relative rounded-lg border-2 p-3 sm:p-4 cursor-pointer transition-all ${
        selected 
          ? 'border-blue-500 bg-blue-50' 
          : 'border-gray-200 hover:border-blue-200 hover:bg-gray-50'
      }`}
    >
      <input
        type="radio"
        className="sr-only"
        name="activityLevel"
        value={value}
        checked={selected}
        onChange={onChange}
      />
      <div className="flex items-start gap-2 sm:gap-3">
        <div className={`mt-1 w-4 h-4 sm:w-5 sm:h-5 rounded-full border-2 flex items-center justify-center ${
          selected ? 'border-blue-500 bg-blue-500' : 'border-gray-300'
        }`}>
          {selected && <Check className="w-2.5 h-2.5 sm:w-3 sm:h-3 text-white" />}
        </div>
        <div>
          <div className="font-medium text-gray-900 text-sm sm:text-base">{details.title}</div>
          <p className="text-xs sm:text-sm text-gray-600 mt-0.5 sm:mt-1">{details.description}</p>
          <p className="text-xs text-gray-500 mt-0.5 sm:mt-1">
            Examples: {details.examples}
          </p>
        </div>
      </div>
    </label>
  );
};

// Enhanced Checkbox Component
const Checkbox = ({ label, checked, onChange, className = '' }) => (
  <label 
    className={`flex items-center p-2.5 sm:p-3 rounded-lg transition-all cursor-pointer border ${
      checked 
        ? 'bg-blue-50 border-blue-200 shadow-sm' 
        : 'hover:bg-gray-50 border-transparent hover:border-gray-200'
    } ${className}`}
  >
    <div className="relative flex items-center">
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className="peer absolute opacity-0 w-0 h-0"
      />
      <div className={`w-4 h-4 sm:w-5 sm:h-5 border-2 rounded flex items-center justify-center transition-all ${
        checked ? 'bg-blue-500 border-blue-500' : 'border-gray-300'
      }`}>
        {checked && <Check className="w-3 h-3 sm:w-4 sm:h-4 text-white" />}
      </div>
      <span className={`ml-2.5 sm:ml-3 text-sm sm:text-base transition-colors ${
        checked ? 'text-blue-900' : 'text-gray-700'
      }`}>
        {label}
      </span>
    </div>
  </label>
);
const UpdateProfile = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  
  // Enhanced form state with activity level
  const [formState, setFormState] = useState({
    name: '',
    gender: '',
    dateOfBirth: '',
    measurementSystem: MEASUREMENT_SYSTEMS.METRIC,
    activityLevel: '',
    height: { feet: '', inches: '', cm: '' },
    weight: { lb: '', kg: '' },
    selectedRiskFactors: [],
    selectedHealthGoals: [],
  });

  const [riskFactors, setRiskFactors] = useState([]);
  const [healthGoals, setHealthGoals] = useState([]);

  // Data fetching logic
  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          navigate('/auth');
          return;
        }

        // Fetch user data
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (!userDoc.exists()) {
          throw new Error('User data not found');
        }

        const userData = userDoc.data();
        
        // Convert measurements
        const height = userData.height;
        const weight = userData.weight;
        const measurementSystem = userData.measurementPreference || MEASUREMENT_SYSTEMS.METRIC;

        let heightFormatted = { cm: '', feet: '', inches: '' };
        let weightFormatted = { kg: '', lb: '' };

        if (measurementSystem === MEASUREMENT_SYSTEMS.METRIC) {
          heightFormatted.cm = height?.toString() || '';
          weightFormatted.kg = weight?.toString() || '';
        } else {
          const totalInches = height ? height / 2.54 : 0;
          const feet = Math.floor(totalInches / 12);
          const inches = Math.round(totalInches % 12);
          heightFormatted.feet = feet.toString();
          heightFormatted.inches = inches.toString();
          
          weightFormatted.lb = weight ? (weight * 2.20462).toFixed(1) : '';
        }

        // Fetch risk factors and health goals
        const [riskFactorsSnap, healthGoalsSnap] = await Promise.all([
          getDocs(collection(db, 'riskFactors')),
          getDocs(collection(db, 'healthGoals'))
        ]);

        setRiskFactors(riskFactorsSnap.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })));
        
        setHealthGoals(healthGoalsSnap.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })));

        // Set form state
        setFormState({
          name: userData.name || '',
          gender: userData.gender || '',
          dateOfBirth: userData.dateOfBirth || '',
          measurementSystem,
          activityLevel: userData.activityLevel || ACTIVITY_LEVELS.SEDENTARY,
          height: heightFormatted,
          weight: weightFormatted,
          selectedRiskFactors: userData.selectedRiskFactors || [],
          selectedHealthGoals: userData.selectedHealthGoals || [],
        });

        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  // Field validation functions
  const validateField = (name, value) => {
    switch (name) {
      case 'name':
        if (!value.trim()) return 'Name is required';
        if (value.trim().length < 2) return 'Name must be at least 2 characters';
        if (value.trim().length > 50) return 'Name must be less than 50 characters';
        return '';
      case 'gender':
        if (!value) return 'Gender is required';
        if (!Object.values(GENDERS).includes(value)) return 'Invalid gender selection';
        return '';
      case 'dateOfBirth':
        if (!value) return 'Date of birth is required';
        const date = new Date(value);
        const now = new Date();
        if (date > now) return 'Date of birth cannot be in the future';
        if (date < new Date('1900-01-01')) return 'Invalid date of birth';
        return '';
      case 'activityLevel':
        if (!value) return 'Activity level is required';
        if (!Object.values(ACTIVITY_LEVELS).includes(value)) return 'Invalid activity level';
        return '';
      default:
        return '';
    }
  };

  const validateMeasurements = (measurements, system) => {
    const errors = {};
    
    if (system === MEASUREMENT_SYSTEMS.METRIC) {
      if (!measurements.height.cm) {
        errors.height = 'Height is required';
      } else if (measurements.height.cm < 50 || measurements.height.cm > 300) {
        errors.height = 'Please enter a valid height (50-300 cm)';
      }
      
      if (!measurements.weight.kg) {
        errors.weight = 'Weight is required';
      } else if (measurements.weight.kg < 20 || measurements.weight.kg > 500) {
        errors.weight = 'Please enter a valid weight (20-500 kg)';
      }
    } else {
      if (!measurements.height.feet || !measurements.height.inches) {
        errors.height = 'Height is required';
      } else {
        const totalInches = (parseInt(measurements.height.feet) * 12) + parseInt(measurements.height.inches);
        if (totalInches < 20 || totalInches > 118) {
          errors.height = 'Please enter a valid height (2ft - 9ft 10in)';
        }
      }
      
      if (!measurements.weight.lb) {
        errors.weight = 'Weight is required';
      } else if (measurements.weight.lb < 44 || measurements.weight.lb > 1100) {
        errors.weight = 'Please enter a valid weight (44-1100 lbs)';
      }
    }
    
    return errors;
  };
// Continue inside UpdateProfile component
const handleSubmit = async (e) => {
  e.preventDefault();
  setError('');
  setValidationErrors({});

  try {
    const user = auth.currentUser;
    if (!user) throw new Error('Authentication required');

    // Validate all fields
    let errors = {};
    ['name', 'gender', 'dateOfBirth', 'activityLevel'].forEach(field => {
      const error = validateField(field, formState[field]);
      if (error) errors[field] = error;
    });

    // Validate measurements
    const measurementErrors = validateMeasurements(
      { height: formState.height, weight: formState.weight },
      formState.measurementSystem
    );
    errors = { ...errors, ...measurementErrors };

    // Check for validation errors
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      throw new Error('Please correct the errors before submitting');
    }

    // Calculate final measurements
    const finalHeight = formState.measurementSystem === MEASUREMENT_SYSTEMS.METRIC 
      ? parseFloat(formState.height.cm)
      : parseFloat((parseInt(formState.height.feet) * 30.48 + parseInt(formState.height.inches) * 2.54).toFixed(1));

    const finalWeight = formState.measurementSystem === MEASUREMENT_SYSTEMS.METRIC
      ? parseFloat(formState.weight.kg)
      : parseFloat((parseFloat(formState.weight.lb) * 0.453592).toFixed(1));

    // Prepare update data
    const updateData = {
      name: formState.name.trim(),
      gender: formState.gender,
      dateOfBirth: formState.dateOfBirth,
      activityLevel: formState.activityLevel,
      height: finalHeight,
      weight: finalWeight,
      selectedRiskFactors: formState.selectedRiskFactors,
      selectedHealthGoals: formState.selectedHealthGoals,
      measurementPreference: formState.measurementSystem,
      updatedAt: new Date(),
      profileCompleted: true
    };

    // Update user profile in Firestore
    const userRef = doc(db, 'users', user.uid);
    await updateDoc(userRef, updateData);

    navigate('/dashboard');
  } catch (err) {
    console.error('Profile update error:', err);
    if (!err.message.includes('Please correct the errors')) {
      setError(err.message);
    }
    // Scroll to the top if there are errors
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
};

if (loading) {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-50 to-purple-50 px-4">
      <div className="text-center">
        <div className="animate-spin rounded-full h-10 w-10 sm:h-12 sm:w-12 border-b-2 border-blue-500 mx-auto"></div>
        <p className="mt-4 text-sm sm:text-base text-gray-600">Loading your profile...</p>
      </div>
    </div>
  );
}

return (
  <div className="min-h-screen bg-gradient-to-br from-blue-50 to-purple-50 px-4 py-6 sm:p-6">
    <div className="max-w-5xl mx-auto">
      {/* Header Section */}
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-6 sm:mb-8 gap-4">
        <div>
          <h1 className="text-2xl sm:text-3xl font-bold text-gray-800">Update Your Profile</h1>
          <p className="text-sm sm:text-base text-gray-600 flex items-center gap-2 mt-2">
            <Info className="w-4 h-4" />
            Keep your health information up to date
          </p>
        </div>
        <button 
          onClick={() => navigate('/dashboard')}
          className="flex items-center justify-center gap-2 px-4 py-2 rounded-lg bg-white shadow-sm hover:shadow-md transition-all text-gray-700 border border-gray-200 text-sm sm:text-base"
        >
          <ArrowLeft className="w-4 h-4" />
          Back to Dashboard
        </button>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4 sm:space-y-6">
        {error && (
          <div className="p-3 sm:p-4 bg-red-50 border-l-4 border-red-500 text-red-700 rounded-lg">
            <div className="flex items-center gap-2">
              <AlertTriangle className="w-4 h-4 sm:w-5 sm:h-5" />
              <p className="font-medium text-sm sm:text-base">Error</p>
            </div>
            <p className="mt-1 text-sm">{error}</p>
          </div>
        )}
{/* Basic Information and Measurements Grid */}
<div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6">
          {/* Basic Information Card */}
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <User className="w-4 h-4 sm:w-5 sm:h-5 text-blue-500" />
                Basic Information
              </CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              <InputField
                label="Full Name"
                type="text"
                value={formState.name}
                onChange={(e) => setFormState(prev => ({ ...prev, name: e.target.value }))}
                placeholder="Enter your full name"
                required
                error={validationErrors.name}
                icon={User}
              />

              {/* Gender Selection */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1.5 sm:mb-2">
                  Gender
                  <span className="text-red-500 ml-1">*</span>
                </label>
                <div className="grid grid-cols-2 gap-2 sm:gap-4">
                  {Object.values(GENDERS).map((gender) => (
                    <label
                      key={gender}
                      className={`flex items-center justify-center p-2.5 sm:p-3 rounded-lg border transition-all cursor-pointer text-sm sm:text-base ${
                        formState.gender === gender
                          ? 'bg-blue-50 border-blue-200 text-blue-900 shadow-sm'
                          : 'border-gray-200 hover:bg-gray-50'
                      }`}
                    >
                      <input
                        type="radio"
                        name="gender"
                        value={gender}
                        checked={formState.gender === gender}
                        onChange={(e) => setFormState(prev => ({ ...prev, gender: e.target.value }))}
                        className="sr-only"
                        required
                      />
                      <span className="capitalize">{gender}</span>
                    </label>
                  ))}
                </div>
                {validationErrors.gender && (
                  <p className="mt-1 text-xs sm:text-sm text-red-500">{validationErrors.gender}</p>
                )}
              </div>

              <InputField
                label="Date of Birth"
                type="date"
                value={formState.dateOfBirth}
                onChange={(e) => setFormState(prev => ({ ...prev, dateOfBirth: e.target.value }))}
                max={new Date().toISOString().split('T')[0]}
                required
                error={validationErrors.dateOfBirth}
                icon={Calendar}
              />
            </CardContent>
          </Card>

          {/* Body Measurements Card */}
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <Weight className="w-4 h-4 sm:w-5 sm:h-5 text-blue-500" />
                Body Measurements
              </CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              {/* Measurement System Selection */}
              <SelectField
                label="Measurement System"
                value={formState.measurementSystem}
                onChange={(e) => setFormState(prev => ({ 
                  ...prev, 
                  measurementSystem: e.target.value,
                  height: { feet: '', inches: '', cm: '' },
                  weight: { lb: '', kg: '' }
                }))}
                options={[
                  { value: MEASUREMENT_SYSTEMS.METRIC, label: 'Metric (cm/kg)' },
                  { value: MEASUREMENT_SYSTEMS.IMPERIAL, label: 'Imperial (ft/in/lb)' }
                ]}
                icon={Scale}
              />

              {/* Height Input */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1.5 sm:mb-2">
                  Height
                  <span className="text-red-500 ml-1">*</span>
                </label>
                {formState.measurementSystem === MEASUREMENT_SYSTEMS.METRIC ? (
                  <div className="relative">
                    <input
                      type="number"
                      placeholder="Height in centimeters"
                      value={formState.height.cm}
                      onChange={(e) => setFormState(prev => ({
                        ...prev,
                        height: { ...prev.height, cm: e.target.value }
                      }))}
                      className={`w-full p-2.5 sm:p-3 pl-8 sm:pl-10 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent text-sm sm:text-base ${
                        validationErrors.height ? 'border-red-500' : 'border-gray-300'
                      }`}
                      min="50"
                      max="300"
                      step="0.1"
                      required
                    />
                    <div className="absolute left-2.5 sm:left-3 top-1/2 -translate-y-1/2 text-gray-400">
                      <Activity className="w-4 h-4 sm:w-5 sm:h-5" />
                    </div>
                    <span className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 text-sm sm:text-base">cm</span>
                  </div>
                ) : (
                  <div className="grid grid-cols-2 gap-2 sm:gap-4">
                    <div className="relative">
                      <input
                        type="number"
                        placeholder="Feet"
                        value={formState.height.feet}
                        onChange={(e) => setFormState(prev => ({
                          ...prev,
                          height: { ...prev.height, feet: e.target.value }
                        }))}
                        className={`w-full p-2.5 sm:p-3 pl-8 sm:pl-10 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent text-sm sm:text-base ${
                          validationErrors.height ? 'border-red-500' : 'border-gray-300'
                        }`}
                        min="2"
                        max="9"
                        required
                      />
                      <div className="absolute left-2.5 sm:left-3 top-1/2 -translate-y-1/2 text-gray-400">
                        <Activity className="w-4 h-4 sm:w-5 sm:h-5" />
                      </div>
                      <span className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 text-sm sm:text-base">ft</span>
                    </div>
                    <div className="relative">
                      <input
                        type="number"
                        placeholder="Inches"
                        value={formState.height.inches}
                        onChange={(e) => setFormState(prev => ({
                          ...prev,
                          height: { ...prev.height, inches: e.target.value }
                        }))}
                        className={`w-full p-2.5 sm:p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent text-sm sm:text-base ${
                          validationErrors.height ? 'border-red-500' : 'border-gray-300'
                        }`}
                        min="0"
                        max="11"
                        required
                      />
                      <span className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 text-sm sm:text-base">in</span>
                    </div>
                  </div>
                )}
                {validationErrors.height && (
                  <p className="mt-1 text-xs sm:text-sm text-red-500">{validationErrors.height}</p>
                )}
              </div>

              {/* Weight Input */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1.5 sm:mb-2">
                  Weight
                  <span className="text-red-500 ml-1">*</span>
                </label>
                <div className="relative">
                  <input
                    type="number"
                    placeholder={`Weight in ${formState.measurementSystem === MEASUREMENT_SYSTEMS.METRIC ? 'kilograms' : 'pounds'}`}
                    value={formState.measurementSystem === MEASUREMENT_SYSTEMS.METRIC ? formState.weight.kg : formState.weight.lb}
                    onChange={(e) => setFormState(prev => ({
                      ...prev,
                      weight: {
                        ...prev.weight,
                        [formState.measurementSystem === MEASUREMENT_SYSTEMS.METRIC ? 'kg' : 'lb']: e.target.value
                      }
                    }))}
                    className={`w-full p-2.5 sm:p-3 pl-8 sm:pl-10 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent text-sm sm:text-base ${
                      validationErrors.weight ? 'border-red-500' : 'border-gray-300'
                    }`}
                    min={formState.measurementSystem === MEASUREMENT_SYSTEMS.METRIC ? "20" : "44"}
                    max={formState.measurementSystem === MEASUREMENT_SYSTEMS.METRIC ? "500" : "1100"}
                    step="0.1"
                    required
                  />
                  <div className="absolute left-2.5 sm:left-3 top-1/2 -translate-y-1/2 text-gray-400">
                    <Weight className="w-4 h-4 sm:w-5 sm:h-5" />
                  </div>
                  <span className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 text-sm sm:text-base">
                    {formState.measurementSystem === MEASUREMENT_SYSTEMS.METRIC ? 'kg' : 'lb'}
                  </span>
                </div>
                {validationErrors.weight && (
                  <p className="mt-1 text-xs sm:text-sm text-red-500">{validationErrors.weight}</p>
                )}
              </div>
            </CardContent>
          </Card>
        </div>
{/* Activity Level Card */}
<Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <Activity className="w-4 h-4 sm:w-5 sm:h-5 text-blue-500" />
                Activity Level
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-3 sm:space-y-4">
                <p className="text-xs sm:text-sm text-gray-600">
                  Select your typical activity level to help us calculate your daily energy needs:
                </p>
                <div className="grid gap-3 sm:gap-4">
                  {Object.entries(ACTIVITY_LEVELS).map(([key, value]) => (
                    <ActivityLevelCard
                      key={key}
                      level={value}
                      value={value}
                      selected={formState.activityLevel === value}
                      onChange={(e) => setFormState(prev => ({
                        ...prev,
                        activityLevel: e.target.value
                      }))}
                    />
                  ))}
                </div>
                {validationErrors.activityLevel && (
                  <p className="mt-1 text-xs sm:text-sm text-red-500">{validationErrors.activityLevel}</p>
                )}
              </div>
            </CardContent>
          </Card>

          {/* Health Factors Section */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6">
            {/* Risk Factors Card */}
            <Card>
              <CardHeader>
                <CardTitle className="flex items-center gap-2">
                  <AlertTriangle className="w-4 h-4 sm:w-5 sm:h-5 text-amber-500" />
                  Risk Factors
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="space-y-2 max-h-[250px] sm:max-h-[300px] overflow-y-auto custom-scrollbar pr-2">
                  <p className="text-xs sm:text-sm text-gray-500 mb-3 sm:mb-4">
                    Select any health conditions or risk factors that apply to you:
                  </p>
                  {riskFactors.length === 0 ? (
                    <p className="text-xs sm:text-sm text-gray-500 italic">No risk factors available</p>
                  ) : (
                    riskFactors.map((factor) => (
                      <Checkbox
                        key={factor.id}
                        label={factor.name}
                        checked={formState.selectedRiskFactors.includes(factor.id)}
                        onChange={(e) => {
                          setFormState(prev => ({
                            ...prev,
                            selectedRiskFactors: e.target.checked
                              ? [...prev.selectedRiskFactors, factor.id]
                              : prev.selectedRiskFactors.filter(id => id !== factor.id)
                          }));
                        }}
                      />
                    ))
                  )}
                </div>
              </CardContent>
            </Card>

            {/* Health Goals Card */}
            <Card>
              <CardHeader>
                <CardTitle className="flex items-center gap-2">
                  <Target className="w-4 h-4 sm:w-5 sm:h-5 text-green-500" />
                  Health Goals
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="space-y-2 max-h-[250px] sm:max-h-[300px] overflow-y-auto custom-scrollbar pr-2">
                  <p className="text-xs sm:text-sm text-gray-500 mb-3 sm:mb-4">
                    Choose the health goals you'd like to achieve:
                  </p>
                  {healthGoals.length === 0 ? (
                    <p className="text-xs sm:text-sm text-gray-500 italic">No health goals available</p>
                  ) : (
                    healthGoals.map((goal) => (
                      <Checkbox
                        key={goal.id}
                        label={goal.name}
                        checked={formState.selectedHealthGoals.includes(goal.id)}
                        onChange={(e) => {
                          setFormState(prev => ({
                            ...prev,
                            selectedHealthGoals: e.target.checked
                              ? [...prev.selectedHealthGoals, goal.id]
                              : prev.selectedHealthGoals.filter(id => id !== goal.id)
                          }));
                        }}
                      />
                    ))
                  )}
                </div>
              </CardContent>
            </Card>
          </div>

          {/* Action Buttons */}
          <div className="flex flex-col sm:flex-row gap-3 sm:gap-4 pt-4">
            <button
              type="button"
              onClick={() => navigate('/dashboard')}
              className="flex-1 px-4 py-2.5 sm:py-3 border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors flex items-center justify-center gap-2 text-gray-700 text-sm sm:text-base"
            >
              <ArrowLeft className="w-4 h-4" />
              Cancel
            </button>
            <button
              type="submit"
              className="flex-1 bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700 text-white px-4 py-2.5 sm:py-3 rounded-lg transition-colors flex items-center justify-center gap-2 text-sm sm:text-base"
            >
              <Save className="w-4 h-4" />
              Save Changes
            </button>
          </div>
        </form>
      </div>

      <style>
    {`
      .custom-scrollbar {
        scrollbar-width: thin;
        scrollbar-color: rgba(156, 163, 175, 0.5) transparent;
      }
      .custom-scrollbar::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }
      .custom-scrollbar::-webkit-scrollbar-track {
        background: transparent;
      }
      .custom-scrollbar::-webkit-scrollbar-thumb {
        background-color: rgba(156, 163, 175, 0.5);
        border-radius: 2px;
      }
    `}
  </style>

    </div>
  );
};

export default UpdateProfile;