// src/components/comments/components/VirtualizedCommentList.js
import React, { memo, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import Comment from './Comment';

const COMMENTS_PER_PAGE = 10;

const VirtualizedCommentList = ({ 
  comments = [],
  currentUser,
  isAuthor,
  editingComment,
  setEditingComment,
  showReplies,
  setShowReplies,
  onCommentAction
}) => {
  // Process comments to ensure proper date format
  const processedComments = comments.map(comment => ({
    ...comment,
    createdAt: comment.createdAt instanceof Date 
      ? comment.createdAt.toISOString()
      : typeof comment.createdAt === 'object' && comment.createdAt?.toDate
      ? comment.createdAt.toDate().toISOString()
      : comment.createdAt || new Date().toISOString()
  }));

  const [visibleRange, setVisibleRange] = useState({
    start: 0,
    end: Math.min(COMMENTS_PER_PAGE, processedComments.length)
  });

  const observerRef = useRef(null);
  const loadMoreRef = useRef(null);

  useEffect(() => {
    // Reset visible range when comments change
    setVisibleRange({
      start: 0,
      end: Math.min(COMMENTS_PER_PAGE, processedComments.length)
    });
  }, [processedComments.length]);

  useEffect(() => {
    if (!loadMoreRef.current) return;

    observerRef.current = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.isIntersecting) {
          setVisibleRange(prev => ({
            start: prev.start,
            end: Math.min(prev.end + COMMENTS_PER_PAGE, processedComments.length)
          }));
        }
      },
      { threshold: 0.1 }
    );

    observerRef.current.observe(loadMoreRef.current);

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [processedComments.length]);

  const visibleComments = processedComments.slice(0, visibleRange.end);
  const hasMore = visibleRange.end < processedComments.length;

  if (!processedComments.length) {
    return (
      <div className="text-center py-8 text-gray-500">
        No comments yet. Be the first to comment!
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <AnimatePresence mode="popLayout">
        {visibleComments.map((comment) => (
          <motion.div
            key={comment.id}
            layout
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
          >
            <Comment
              comment={comment}
              currentUser={currentUser}
              isAuthor={isAuthor}
              onAction={onCommentAction}
              isEditing={editingComment?.id === comment.id}
              setEditingComment={setEditingComment}
              showReplies={showReplies[comment.id]}
              onToggleReplies={() => {
                setShowReplies(prev => ({
                  ...prev,
                  [comment.id]: !prev[comment.id]
                }));
              }}
            />
          </motion.div>
        ))}
      </AnimatePresence>

      {hasMore && (
        <div ref={loadMoreRef} className="py-4 text-center">
          <div className="inline-block p-2 rounded-full bg-gray-100">
            <div className="w-6 h-6 border-2 border-gray-300 border-t-blue-600 
              rounded-full animate-spin"
            />
          </div>
        </div>
      )}
    </div>
  );
};

VirtualizedCommentList.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    authorId: PropTypes.string.isRequired,
    authorEmail: PropTypes.string.isRequired,
    createdAt: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.number
    ]).isRequired,
    likes: PropTypes.number,
    dislikes: PropTypes.number,
    likedBy: PropTypes.arrayOf(PropTypes.string),
    dislikedBy: PropTypes.arrayOf(PropTypes.string),
    replies: PropTypes.array
  })),
  currentUser: PropTypes.object,
  isAuthor: PropTypes.bool,
  editingComment: PropTypes.object,
  setEditingComment: PropTypes.func,
  showReplies: PropTypes.objectOf(PropTypes.bool).isRequired,
  setShowReplies: PropTypes.func.isRequired,
  onCommentAction: PropTypes.func
};

export default memo(VirtualizedCommentList);