// PrivacyPolicy.js
import React from 'react';
import LegalLayout from './LegalLayout';

const PrivacyPolicy = () => {
  return (
    <LegalLayout title="Privacy Policy">
      <div className="prose max-w-none">
        <div className="space-y-12">
          {/* Introduction Section */}
          <section className="rounded-2xl bg-gray-50 p-6 hover:bg-gray-100 transition-colors" id="introduction">
            <h2 className="text-2xl font-semibold mb-4 text-gray-900">1. Introduction</h2>
            <p className="text-gray-600 mb-4">
              Welcome to WatchMy.health. We respect your privacy and are committed to protecting your personal data. 
              This privacy policy explains how we handle your information when you use our website and services.
            </p>
          </section>

          {/* Information Collection Section */}
          <section className="rounded-2xl bg-gray-50 p-6 hover:bg-gray-100 transition-colors" id="information-collection">
            <h2 className="text-2xl font-semibold mb-4 text-gray-900">2. Information We Collect</h2>
            
            <h3 className="text-xl font-medium mb-3 text-gray-900">2.1 Personal Information</h3>
            <ul className="list-disc pl-6 mb-4 text-gray-600 space-y-2">
              <li>Name and contact information</li>
              <li>Account credentials</li>
              <li>Health and fitness data</li>
              <li>Weight and body measurements</li>
              <li>Fasting schedules and logs</li>
              <li>Device information and usage data</li>
            </ul>

            <h3 className="text-xl font-medium mb-3 text-gray-900">2.2 Automatically Collected Information</h3>
            <ul className="list-disc pl-6 mb-4 text-gray-600 space-y-2">
              <li>IP address and location data</li>
              <li>Browser type and version</li>
              <li>Device identifiers</li>
              <li>Usage patterns and preferences</li>
              <li>Cookies and similar tracking technologies</li>
            </ul>
          </section>

          {/* Continue with other sections following the same pattern... */}

          <div className="mt-12 pt-8 border-t border-gray-200">
            <p className="text-sm text-gray-500">
              This privacy policy was last updated on November 5, 2024. By continuing to use our services, 
              you agree to the terms of this privacy policy.
            </p>
          </div>
        </div>
      </div>
    </LegalLayout>
  );
};

export default PrivacyPolicy;
