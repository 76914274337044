import React from 'react';
import { 
  ArrowLeft, 
  Heart, 
  Share2, 
  Bookmark, 
  MoreHorizontal, 
  Edit3, 
  Trash2, 
  Flag 
} from 'lucide-react';

export const PostHeader = React.forwardRef(({
  post,
  onBack,
  onLike,
  onShare,
  onBookmark,
  onEdit,
  onDelete,
  onReport,
  currentUser,
  isBookmarked,
  isAuthor,
  showOptionsMenu,
  onToggleOptionsMenu
}, ref) => (
  <header ref={ref} className="sticky top-0 z-40 bg-white/80 backdrop-blur-md border-b border-gray-200">
    <div className="max-w-[800px] mx-auto px-4">
      <div className="h-16 flex items-center justify-between">
        <button
          onClick={onBack}
          className="flex items-center gap-2 text-gray-600 hover:text-gray-900 transition-colors"
        >
          <ArrowLeft className="w-5 h-5" />
          <span className="hidden sm:inline">Back to Community</span>
        </button>
        
        <div className="flex items-center gap-4">
          {/* Like Button */}
          <button 
            onClick={onLike}
            className={`p-2 rounded-full transition-colors flex items-center gap-2 ${
              post.likedBy?.includes(currentUser?.uid)
                ? 'text-pink-600 bg-pink-50'
                : 'text-gray-600 hover:text-pink-600 hover:bg-pink-50'
            }`}
          >
            <Heart className={`w-5 h-5 ${
              post.likedBy?.includes(currentUser?.uid) ? 'fill-current' : ''
            }`} />
            <span className="text-sm font-medium">{post.likes || 0}</span>
          </button>

          {/* Share Button */}
          <button
            onClick={onShare}
            className="p-2 text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-full transition-colors"
          >
            <Share2 className="w-5 h-5" />
          </button>

          {/* Bookmark Button */}
          <button
            onClick={onBookmark}
            className={`p-2 rounded-full transition-colors ${
              isBookmarked
                ? 'text-blue-600 bg-blue-50'
                : 'text-gray-600 hover:text-blue-600 hover:bg-blue-50'
            }`}
          >
            <Bookmark className={`w-5 h-5 ${isBookmarked ? 'fill-current' : ''}`} />
          </button>

          {/* Options Menu */}
          <div className="relative">
            <button
              onClick={onToggleOptionsMenu}
              className="p-2 text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-full transition-colors"
            >
              <MoreHorizontal className="w-5 h-5" />
            </button>

            {showOptionsMenu && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg border border-gray-200 py-1 z-50">
                {isAuthor ? (
                  <>
                    <button
                      onClick={onEdit}
                      className="w-full px-4 py-2 text-left text-sm hover:bg-gray-50 flex items-center gap-2"
                    >
                      <Edit3 className="w-4 h-4" />
                      Edit post
                    </button>
                    <button
                      onClick={onDelete}
                      className="w-full px-4 py-2 text-left text-sm hover:bg-gray-50 flex items-center gap-2 text-red-600"
                    >
                      <Trash2 className="w-4 h-4" />
                      Delete post
                    </button>
                  </>
                ) : (
                  <button
                    onClick={onReport}
                    className="w-full px-4 py-2 text-left text-sm hover:bg-gray-50 flex items-center gap-2 text-amber-600"
                  >
                    <Flag className="w-4 h-4" />
                    Report post
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  </header>
));

PostHeader.displayName = 'PostHeader';

export default PostHeader;