// src/components/user/Posts/comments/hooks/useComments.js
import { useState, useMemo, useCallback, useEffect } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../../firebase';
import { NOTIFICATION_DURATION } from '../constants';

export const useComments = ({ 
  initialComments = [],
  postId,
  currentUser,
  onSuccess = () => {},
  onError = () => {}
}) => {
  const [localComments, setLocalComments] = useState(initialComments);
  const [replyingTo, setReplyingTo] = useState(null);
  const [editingComment, setEditingComment] = useState(null);
  const [commentText, setCommentText] = useState('');
  const [sortBy, setSortBy] = useState('newest');
  const [commentFilter, setCommentFilter] = useState('all');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  
  // Initialize showReplies state with a map of comment IDs to boolean values
  const [showReplies, setShowReplies] = useState(() => {
    const repliesMap = {};
    const initializeReplies = (comments) => {
      comments.forEach(comment => {
        repliesMap[comment.id] = false;
        if (comment.replies?.length > 0) {
          initializeReplies(comment.replies);
        }
      });
    };
    initializeReplies(initialComments);
    return repliesMap;
  });

  // Update showReplies when comments change
  useEffect(() => {
    setShowReplies(prev => {
      const newReplies = { ...prev };
      const updateReplies = (comments) => {
        comments.forEach(comment => {
          if (!newReplies.hasOwnProperty(comment.id)) {
            newReplies[comment.id] = false;
          }
          if (comment.replies?.length > 0) {
            updateReplies(comment.replies);
          }
        });
      };
      updateReplies(initialComments);
      return newReplies;
    });
  }, [initialComments]);

  // Sync with Firebase
  const syncToFirebase = async (comments) => {
    if (!postId) return;
    
    try {
      const postRef = doc(db, 'posts', postId);
      await updateDoc(postRef, {
        comments: comments
      });
    } catch (error) {
      console.error('Error syncing comments:', error);
      throw error;
    }
  };

  const showNotificationMessage = useCallback((message) => {
    setNotificationMessage(message);
    setShowNotification(true);
    setTimeout(() => setShowNotification(false), NOTIFICATION_DURATION);
  }, []);

  const handleInputChange = useCallback((e) => {
    setCommentText(e.target.value);
  }, []);

  // Helper function to add a reply at any nesting level
  const addReplyToComment = useCallback((comments, parentId, newReply) => {
    return comments.map(comment => {
      if (comment.id === parentId) {
        return {
          ...comment,
          replies: [...(comment.replies || []), newReply]
        };
      }
      if (comment.replies?.length > 0) {
        return {
          ...comment,
          replies: addReplyToComment(comment.replies, parentId, newReply)
        };
      }
      return comment;
    });
  }, []);

  const handleSubmitComment = useCallback(async (e) => {
    e.preventDefault();
    
    if (!commentText.trim() || isSubmitting) return;
    if (!postId) {
      showNotificationMessage('Unable to add comment at this time');
      return;
    }

    setIsSubmitting(true);

    try {
      if (!currentUser) {
        throw new Error('Authentication required to comment');
      }

      const newComment = {
        id: `comment_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`,
        text: commentText.trim(),
        authorId: currentUser.uid,
        authorEmail: currentUser.email,
        createdAt: new Date().toISOString(),
        likes: 0,
        dislikes: 0,
        likedBy: [],
        dislikedBy: [],
        parentId: replyingTo || null,
        replies: []
      };

      let updatedComments;
      if (!replyingTo) {
        updatedComments = [newComment, ...localComments];
      } else {
        updatedComments = addReplyToComment([...localComments], replyingTo, newComment);
      }

      // Save to Firebase first
      await syncToFirebase(updatedComments);

      // Then update local state
      setLocalComments(updatedComments);
      setShowReplies(prev => ({
        ...prev,
        [replyingTo]: true, // Show parent's replies
        [newComment.id]: false // Initialize new comment's replies as hidden
      }));

      setCommentText('');
      setReplyingTo(null);
      showNotificationMessage('Comment posted successfully!');
      onSuccess('Comment added successfully');

    } catch (error) {
      console.error('Error posting comment:', error);
      showNotificationMessage(
        error.message.includes('Authentication')
          ? 'Please sign in to comment'
          : 'Failed to post comment. Please try again.'
      );
      onError(error);
    } finally {
      setIsSubmitting(false);
    }
  }, [postId, commentText, replyingTo, isSubmitting, currentUser, localComments, addReplyToComment, showNotificationMessage, onSuccess, onError]);

  const deleteCommentFromTree = useCallback((comments, targetId) => {
    return comments.filter(comment => {
      if (comment.id === targetId) {
        return false;
      }
      if (comment.replies?.length > 0) {
        comment.replies = deleteCommentFromTree(comment.replies, targetId);
      }
      return true;
    });
  }, []);

  const handleDeleteComment = useCallback(async (commentId) => {
    if (!currentUser || !postId) return;

    try {
      const updatedComments = deleteCommentFromTree([...localComments], commentId);
      
      // Save to Firebase first
      await syncToFirebase(updatedComments);

      // Then update local state
      setLocalComments(updatedComments);
      setShowReplies(prev => {
        const updated = { ...prev };
        delete updated[commentId];
        return updated;
      });

      showNotificationMessage('Comment deleted successfully');
      onSuccess('Comment deleted successfully');
    } catch (error) {
      console.error('Error deleting comment:', error);
      showNotificationMessage('Failed to delete comment');
      onError(error);
    }
  }, [currentUser, postId, localComments, deleteCommentFromTree, showNotificationMessage, onSuccess, onError]);

  const updateCommentInTree = useCallback((comments, targetId, updateFn) => {
    return comments.map(comment => {
      if (comment.id === targetId) {
        return updateFn(comment);
      }
      if (comment.replies?.length > 0) {
        return {
          ...comment,
          replies: updateCommentInTree(comment.replies, targetId, updateFn)
        };
      }
      return comment;
    });
  }, []);

  const handleLikeComment = useCallback(async (commentId) => {
    if (!currentUser || !postId) return;

    try {
      const updatedComments = updateCommentInTree([...localComments], commentId, (comment) => {
        const isLiked = comment.likedBy?.includes(currentUser.uid);
        return {
          ...comment,
          likes: isLiked ? (comment.likes || 1) - 1 : (comment.likes || 0) + 1,
          likedBy: isLiked 
            ? (comment.likedBy || []).filter(id => id !== currentUser.uid)
            : [...(comment.likedBy || []), currentUser.uid]
        };
      });

      await syncToFirebase(updatedComments);
      setLocalComments(updatedComments);
    } catch (error) {
      console.error('Error liking comment:', error);
      showNotificationMessage('Failed to update like status');
      onError(error);
    }
  }, [currentUser, postId, localComments, updateCommentInTree, showNotificationMessage, onError]);

  const handleDislikeComment = useCallback(async (commentId) => {
    if (!currentUser || !postId) return;

    try {
      const updatedComments = updateCommentInTree([...localComments], commentId, (comment) => {
        const isDisliked = comment.dislikedBy?.includes(currentUser.uid);
        return {
          ...comment,
          dislikes: isDisliked ? (comment.dislikes || 1) - 1 : (comment.dislikes || 0) + 1,
          dislikedBy: isDisliked
            ? (comment.dislikedBy || []).filter(id => id !== currentUser.uid)
            : [...(comment.dislikedBy || []), currentUser.uid]
        };
      });

      await syncToFirebase(updatedComments);
      setLocalComments(updatedComments);
    } catch (error) {
      console.error('Error disliking comment:', error);
      showNotificationMessage('Failed to update dislike status');
      onError(error);
    }
  }, [currentUser, postId, localComments, updateCommentInTree, showNotificationMessage, onError]);

  const handleReportComment = useCallback(async (commentId, reason) => {
    if (!currentUser || !reason?.trim()) return;

    try {
      const report = {
        userId: currentUser.uid,
        reason: reason.trim(),
        timestamp: new Date().toISOString()
      };

      const updatedComments = updateCommentInTree([...localComments], commentId, (comment) => ({
        ...comment,
        reports: [...(comment.reports || []), report]
      }));

      await syncToFirebase(updatedComments);
      setLocalComments(updatedComments);
      
      showNotificationMessage('Comment reported successfully');
      onSuccess('Comment reported successfully');
    } catch (error) {
      console.error('Error reporting comment:', error);
      showNotificationMessage('Failed to report comment');
      onError(error);
    }
  }, [currentUser, localComments, updateCommentInTree, showNotificationMessage, onSuccess, onError]);

  const sortedAndFilteredComments = useMemo(() => {
    let filteredComments = [...localComments];

    switch (commentFilter) {
      case 'my_comments':
        filteredComments = filteredComments.filter(
          comment => comment.authorId === currentUser?.uid
        );
        break;
      case 'liked':
        filteredComments = filteredComments.filter(
          comment => comment.likedBy?.includes(currentUser?.uid)
        );
        break;
      default:
        break;
    }

    return filteredComments.sort((a, b) => {
      switch (sortBy) {
        case 'oldest':
          return new Date(a.createdAt) - new Date(b.createdAt);
        case 'most_liked':
          return (b.likes || 0) - (a.likes || 0);
        case 'most_discussed':
          return ((b.replies?.length || 0) + (b.likes || 0)) - 
                 ((a.replies?.length || 0) + (a.likes || 0));
        case 'newest':
        default:
          return new Date(b.createdAt) - new Date(a.createdAt);
      }
    });
  }, [localComments, sortBy, commentFilter, currentUser?.uid]);

  return {
    localComments,
    replyingTo,
    setReplyingTo,
    editingComment,
    setEditingComment,
    commentText,
    setCommentText,
    showReplies,
    setShowReplies,
    sortBy,
    setSortBy,
    commentFilter,
    setCommentFilter,
    isSubmitting,
    showNotification,
    notificationMessage,
    sortedAndFilteredComments,
    handleSubmitComment,
    handleDeleteComment,
    handleLikeComment,
    handleDislikeComment,
    handleReportComment,
    handleInputChange
  };
};

export default useComments;