import React, { useState, useEffect } from 'react';
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { auth, db } from '../../firebase';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import {
  Users,
  Activity,
  Shield,
  Menu,
  X,
  LogOut,
  ShieldAlert,
  Target,
  Clipboard,
  AlertCircle
} from 'lucide-react';

// Utility Components
const StatCard = ({ icon: Icon, label, value, trend }) => (
  <div className="bg-white p-4 rounded-xl border border-gray-200 hover:border-blue-200 transition-all">
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-3">
        <div className="p-2 bg-blue-50 rounded-lg">
          <Icon className="w-5 h-5 text-blue-500" />
        </div>
        <div>
          <p className="text-sm text-gray-500">{label}</p>
          <p className="text-lg font-semibold text-gray-900">{value}</p>
        </div>
      </div>
      {trend && (
        <div className={`text-sm px-2 py-1 rounded ${
          trend > 0 ? 'bg-green-50 text-green-600' : 'bg-red-50 text-red-600'
        }`}>
          {trend > 0 ? '+' : ''}{trend}%
        </div>
      )}
    </div>
  </div>
);

const AdminDashboard = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [stats, setStats] = useState({
    totalUsers: 0,
    activeUsers: 0,
    totalRiskFactors: 0,
    totalHealthGoals: 0
  });
  const navigate = useNavigate();
  const location = useLocation();

  // Check admin status
  useEffect(() => {
    const checkAdminStatus = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          navigate('/admin');
          return;
        }

        const adminDoc = await getDoc(doc(db, 'admins', user.uid));
        if (!adminDoc.exists()) {
          auth.signOut();
          navigate('/admin');
          return;
        }

        await loadDashboardStats();
        setIsAdmin(true);
      } catch (error) {
        console.error('Error checking admin status:', error);
        navigate('/admin');
      } finally {
        setLoading(false);
      }
    };

    checkAdminStatus();
  }, [navigate]);

  // Close mobile menu on route change
  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [location]);

  const loadDashboardStats = async () => {
    try {
      const [riskFactorsSnap, healthGoalsSnap] = await Promise.all([
        getDocs(collection(db, 'riskFactors')),
        getDocs(collection(db, 'healthGoals'))
      ]);

      setStats({
        totalUsers: 2547, // Example static data
        activeUsers: 1823,
        totalRiskFactors: riskFactorsSnap.size,
        totalHealthGoals: healthGoalsSnap.size
      });
    } catch (err) {
      console.error('Failed to load dashboard statistics:', err);
    }
  };

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      navigate('/admin');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const isActiveLink = (path) => {
    return location.pathname.includes(path);
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-50 to-purple-50">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
          <p className="mt-4 text-gray-600">Loading dashboard...</p>
        </div>
      </div>
    );
  }

  if (!isAdmin) {
    return null;
  }

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Navigation Header */}
      <nav className="bg-white shadow-lg">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between h-16">
            <div className="flex">
              <div className="flex-shrink-0 flex items-center">
                <Shield className="w-8 h-8 text-blue-500" />
                <h1 className="ml-2 text-xl font-bold text-gray-800">BioGuard Admin</h1>
              </div>
              
              {/* Desktop Navigation */}
              <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                <Link
                  to="/admin/risk-factors"
                  className={`inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium ${
                    isActiveLink('risk-factors')
                      ? 'border-blue-500 text-gray-900'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                  }`}
                >
                  <ShieldAlert className="w-4 h-4 mr-2" />
                  Risk Factors
                </Link>
                <Link
                  to="/admin/health-goals"
                  className={`inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium ${
                    isActiveLink('health-goals')
                      ? 'border-blue-500 text-gray-900'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                  }`}
                >
                  <Target className="w-4 h-4 mr-2" />
                  Health Goals
                </Link>
                <Link
                  to="/admin/recommendations"
                  className={`inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium ${
                    isActiveLink('recommendations')
                      ? 'border-blue-500 text-gray-900'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                  }`}
                >
                  <Clipboard className="w-4 h-4 mr-2" />
                  Recommendations
                </Link>
              </div>
            </div>

            {/* Mobile menu button */}
            <div className="sm:hidden flex items-center">
              <button
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100"
              >
                <span className="sr-only">Open main menu</span>
                {isMobileMenuOpen ? (
                  <X className="block h-6 w-6" />
                ) : (
                  <Menu className="block h-6 w-6" />
                )}
              </button>
            </div>

            {/* Desktop Sign Out */}
            <div className="hidden sm:flex items-center">
              <button
                onClick={handleSignOut}
                className="flex items-center gap-2 ml-4 px-4 py-2 text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                <LogOut className="w-4 h-4" />
                Sign Out
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Navigation Menu */}
        {isMobileMenuOpen && (
          <div className="sm:hidden">
            <div className="pt-2 pb-3 space-y-1">
              <Link
                to="/admin/risk-factors"
                className={`flex items-center pl-3 pr-4 py-2 border-l-4 text-base font-medium ${
                  isActiveLink('risk-factors')
                    ? 'border-blue-500 text-blue-700 bg-blue-50'
                    : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700'
                }`}
              >
                <ShieldAlert className="w-4 h-4 mr-2" />
                Risk Factors
              </Link>
              <Link
                to="/admin/health-goals"
                className={`flex items-center pl-3 pr-4 py-2 border-l-4 text-base font-medium ${
                  isActiveLink('health-goals')
                    ? 'border-blue-500 text-blue-700 bg-blue-50'
                    : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700'
                }`}
              >
                <Target className="w-4 h-4 mr-2" />
                Health Goals
              </Link>
              <Link
                to="/admin/recommendations"
                className={`flex items-center pl-3 pr-4 py-2 border-l-4 text-base font-medium ${
                  isActiveLink('recommendations')
                    ? 'border-blue-500 text-blue-700 bg-blue-50'
                    : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700'
                }`}
              >
                <Clipboard className="w-4 h-4 mr-2" />
                Recommendations
              </Link>
              <button
                onClick={handleSignOut}
                className="w-full flex items-center pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700"
              >
                <LogOut className="w-4 h-4 mr-2" />
                Sign Out
              </button>
            </div>
          </div>
        )}
      </nav>

      {/* Main Content */}
      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        {/* Stats Overview */}
        {location.pathname === '/admin' && (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
            <StatCard
              icon={Users}
              label="Total Users"
              value={stats.totalUsers.toLocaleString()}
              trend={12.5}
            />
            <StatCard
              icon={Activity}
              label="Active Users"
              value={stats.activeUsers.toLocaleString()}
            />
            <StatCard
              icon={Target}
              label="Health Goals"
              value={stats.totalHealthGoals}
            />
            <StatCard
              icon={ShieldAlert}
              label="Risk Factors"
              value={stats.totalRiskFactors}
            />
          </div>
        )}
        
        {/* Router Outlet for Nested Routes */}
        <Outlet />
      </main>
    </div>
  );
};

export default AdminDashboard;