import React, { useState, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { format } from 'date-fns';
import { 
  StopCircle, 
  Clock, 
  Edit2,
  Target,
  Scale,
  Flame,
  ChevronDown,
  ChevronUp,
  AlertCircle,
  Timer,
  Activity,
  Sparkles,
  Brain,
  Heart,
  Battery,
  TrendingUp,
  X,
  Zap
} from 'lucide-react';

import { TimerRing } from './TimerRing';
import { TimePickerModal } from './TimePickerModal';
import { FastingStageCard } from './FastingStageCard';
import { calculateCaloriesBurned } from './calculations';
import { fastingStages } from './fastingStages';
import { ElectrolyteTracker } from './Electrolytes/ElectrolyteTracker';

// Helper Components
const PhaseIndicator = ({ phase, intensity }) => {
  const colors = {
    low: 'bg-blue-50 border-blue-200 text-blue-700',
    medium: 'bg-purple-50 border-purple-200 text-purple-700',
    high: 'bg-orange-50 border-orange-200 text-orange-700'
  };

  return (
    <div className={`px-2 py-1 text-xs md:px-3 md:py-1.5 md:text-sm rounded-full border ${colors[intensity]} font-medium`}>
      {phase}
    </div>
  );
};

const MetricCard = ({ 
  icon: Icon, 
  label, 
  value, 
  unit, 
  trend, 
  color = "blue",
  subValue,
  onClick,
  isExpandable = false
}) => (
  <motion.div
    whileHover={{ scale: 1.02 }}
    whileTap={onClick ? { scale: 0.98 } : {}}
    onClick={onClick}
    className={`relative bg-white rounded-xl p-3 md:p-4 shadow-sm hover:shadow-md transition-all 
               ${onClick ? 'cursor-pointer' : ''}`}
  >
    <div className="space-y-2 md:space-y-3">
      <div className={`w-8 h-8 md:w-10 md:h-10 rounded-lg bg-${color}-50 flex items-center justify-center`}>
        <Icon className={`w-4 h-4 md:w-5 md:h-5 text-${color}-500`} />
      </div>
      <div>
        <div className="text-xs md:text-sm text-gray-500 mb-0.5 md:mb-1">{label}</div>
        <div className="flex items-baseline gap-1">
          <span className="text-lg md:text-2xl font-bold bg-gradient-to-r from-gray-900 to-gray-600 bg-clip-text text-transparent">
            {value}
          </span>
          {unit && <span className="text-xs md:text-sm text-gray-500">{unit}</span>}
        </div>
        {subValue && (
          <div className="text-xs text-gray-500 mt-0.5 md:mt-1">{subValue}</div>
        )}
      </div>
    </div>
    {trend && (
      <div className={`absolute top-2 md:top-4 right-2 md:right-4 text-xs md:text-sm font-medium 
                      ${trend >= 0 ? 'text-green-500' : 'text-red-500'}`}>
        {trend > 0 ? '↑' : '↓'} {Math.abs(trend)}%
      </div>
    )}
  </motion.div>
);

const BenefitBadge = ({ active, icon: Icon, label, timeToUnlock }) => (
  <div className={`flex items-center gap-1.5 md:gap-2 px-2 md:px-3 py-1.5 md:py-2 rounded-xl ${
    active ? 'bg-green-50 text-green-700' : 'bg-gray-50 text-gray-500'
  }`}>
    <Icon className="w-3.5 h-3.5 md:w-4 md:h-4" />
    <span className="text-xs md:text-sm font-medium">{label}</span>
    {!active && timeToUnlock && (
      <span className="text-xs text-gray-400">
        {timeToUnlock}h
      </span>
    )}
  </div>
);
// Main Component
const CurrentFastProgress = ({ 
  activeFast,
  elapsedTime = 0,
  targetHours = 16,
  remainingTime = 0,
  onStopFast,
  onAdjustTime,
  userWeight = 70,
  measurementSystem = 'metric',
  userHeight = 170,
  userAge = 30,
  userGender = 'male',
  userActivityLevel = 'moderately_active'
}) => {
  // State management
  const [showDetails, setShowDetails] = useState(false);
  const [showTimeModal, setShowTimeModal] = useState(false);
  const [showConfirmEnd, setShowConfirmEnd] = useState(false);
  const [timeAdjustmentError, setTimeAdjustmentError] = useState('');

  // Calculate current hours and progress
  const currentHours = elapsedTime / (60 * 60 * 1000);
  const progress = Math.min((elapsedTime / (targetHours * 60 * 60 * 1000)) * 100, 100);

  // Get current fasting stage and phase
  const currentStage = fastingStages.find(stage => currentHours >= stage.hour) || fastingStages[0];
  const nextStage = fastingStages.find(stage => stage.hour > currentHours);

  // Calculate metrics
  const caloriesBurned = calculateCaloriesBurned(
    elapsedTime,
    userWeight,
    userHeight,
    userAge,
    userGender,
    userActivityLevel,
    measurementSystem
  );
  
  const fatLoss = ((caloriesBurned / 3500) * (measurementSystem === 'metric' ? 0.453592 : 1)).toFixed(2);

  // Get current phase based on hours
  const getPhase = () => {
    if (currentHours >= 72) return { name: 'Extended', intensity: 'high' };
    if (currentHours >= 48) return { name: 'Deep Ketosis', intensity: 'high' };
    if (currentHours >= 24) return { name: 'Fat-Adapted', intensity: 'medium' };
    if (currentHours >= 12) return { name: 'Ketosis', intensity: 'medium' };
    return { name: 'Glycogen Depletion', intensity: 'low' };
  };

  const currentPhase = getPhase();

  // Time adjustment handler
  const handleTimeAdjustment = useCallback((newDateTime) => {
    try {
      const selectedDate = new Date(newDateTime);
      const now = new Date();

      if (selectedDate > now) {
        setTimeAdjustmentError('Start time cannot be in the future');
        return;
      }

      if (onAdjustTime) {
        onAdjustTime(selectedDate.toISOString());
        setTimeAdjustmentError('');
        setShowTimeModal(false);
      }
    } catch (error) {
      setTimeAdjustmentError('Invalid date format');
      console.error('Time adjustment error:', error);
    }
  }, [onAdjustTime]);

  // Handle fast end confirmation
  const handleEndFast = useCallback(() => {
    onStopFast();
    setShowConfirmEnd(false);
  }, [onStopFast]);

  // Benefits list with activation status
  const benefits = [
    { 
      icon: Battery,
      label: 'Glycogen Depletion',
      active: currentHours >= 12,
      timeToUnlock: currentHours < 12 ? Math.ceil(12 - currentHours) : null
    },
    { 
      icon: Flame,
      label: 'Fat Burning',
      active: currentHours >= 16,
      timeToUnlock: currentHours < 16 ? Math.ceil(16 - currentHours) : null
    },
    { 
      icon: Brain,
      label: 'Mental Clarity',
      active: currentHours >= 18,
      timeToUnlock: currentHours < 18 ? Math.ceil(18 - currentHours) : null
    },
    { 
      icon: Sparkles,
      label: 'Autophagy',
      active: currentHours >= 24,
      timeToUnlock: currentHours < 24 ? Math.ceil(24 - currentHours) : null
    },
    { 
      icon: Heart,
      label: 'Cell Repair',
      active: currentHours >= 48,
      timeToUnlock: currentHours < 48 ? Math.ceil(48 - currentHours) : null
    }
  ];
  return (
    <div className="space-y-4 md:space-y-6 px-4 md:px-6">
      {/* Main Timer Card */}
      <div className="bg-gradient-to-br from-white via-blue-50 to-purple-50 rounded-xl p-4 md:p-6 shadow-lg">
        {/* Header Section */}
        <div className="flex flex-col md:flex-row md:items-center justify-between mb-4 md:mb-6 space-y-2 md:space-y-0">
          <div className="space-y-1">
            <h2 className="text-xl md:text-2xl font-bold bg-gradient-to-r from-gray-900 to-gray-600 bg-clip-text text-transparent">
              Current Fast
            </h2>
            <div className="flex flex-col md:flex-row md:items-center gap-2 md:gap-4">
              <div className="flex items-center gap-2">
                <Clock className="w-4 h-4 text-gray-500" />
                <span className="text-xs md:text-sm text-gray-600">
                  Started {format(new Date(activeFast?.startTime), 'MMM d, h:mm a')}
                </span>
              </div>
              <PhaseIndicator phase={currentPhase.name} intensity={currentPhase.intensity} />
            </div>
          </div>
          <button
            onClick={() => setShowTimeModal(true)}
            className="p-2 hover:bg-white/50 rounded-lg transition-colors self-end md:self-auto"
          >
            <Edit2 className="w-4 h-4 md:w-5 md:h-5 text-blue-500" />
          </button>
        </div>
  
        {/* Timer Display */}
        <div className="relative flex justify-center mb-6 md:mb-8">
          <div className="w-[250px] md:w-[300px]">
            <TimerRing 
              progress={progress}
              targetHours={targetHours}
              elapsedHours={currentHours}
              remainingHours={remainingTime / (60 * 60 * 1000)}
              size={250}
              strokeWidth={16}
            />
          </div>
        </div>
  
        {/* Quick Stats Grid */}
        <div className="grid grid-cols-2 md:grid-cols-3 gap-3 md:gap-4 mb-4 md:mb-6">
          <MetricCard 
            icon={Target}
            label="Progress"
            value={`${progress.toFixed(1)}%`}
            subValue={`${targetHours}h target`}
            color="blue"
          />
          <MetricCard 
            icon={Flame}
            label="Calories Burned"
            value={caloriesBurned}
            unit="kcal"
            subValue="Based on BMR"
            color="orange"
          />
          <div className="col-span-2 md:col-span-1">
            <MetricCard 
              icon={Scale}
              label="Est. Fat Loss"
              value={fatLoss}
              unit={measurementSystem === 'metric' ? 'kg' : 'lbs'}
              subValue="From calorie deficit"
              color="green"
            />
          </div>
        </div>
  
        {/* Action Buttons */}
        <div className="grid grid-cols-1 gap-3 md:gap-4">
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => setShowConfirmEnd(true)}
            className="w-full py-3 md:py-4 bg-gradient-to-r from-red-500 to-pink-500 
                     text-white rounded-xl shadow-lg hover:shadow-xl 
                     transition-all flex items-center justify-center gap-2"
          >
            <StopCircle className="w-4 h-4 md:w-5 md:h-5" />
            <span className="font-medium text-sm md:text-base">End Fast</span>
          </motion.button>
        </div>
{/* Active Benefits Section */}
<div className="mt-6 md:mt-8 space-y-4 md:space-y-6">
        <div className="space-y-2">
          <div className="flex items-center gap-2 md:gap-3">
            <div className="p-1.5 md:p-2 bg-blue-50 rounded-lg">
              <Sparkles className="w-4 h-4 md:w-5 md:h-5 text-blue-500" />
            </div>
            <h2 className="text-lg md:text-2xl font-bold bg-gradient-to-r from-gray-900 to-gray-600 bg-clip-text text-transparent">
              Active Benefits
            </h2>
          </div>
          <div className="flex items-center gap-2">
            {currentHours >= 48 ? (
              <div className="px-2 md:px-3 py-1 bg-orange-50 rounded-full">
                <span className="text-xs md:text-sm text-orange-600 font-medium">
                  Extended Fast (48h+) - Maximum benefits activated
                </span>
              </div>
            ) : currentHours >= 24 ? (
              <div className="px-2 md:px-3 py-1 bg-purple-50 rounded-full">
                <span className="text-xs md:text-sm text-purple-600 font-medium">
                  Deep Fast (24-48h) - Enhanced cellular benefits
                </span>
              </div>
            ) : (
              <div className="px-2 md:px-3 py-1 bg-blue-50 rounded-full">
                <span className="text-xs md:text-sm text-blue-600 font-medium">
                  Regular Fast (0-24h) - Initial metabolic benefits
                </span>
              </div>
            )}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-3">
          {benefits.map((benefit, index) => (
            <BenefitBadge key={index} {...benefit} />
          ))}
        </div>
      </div>

      {/* Expand/Collapse Button */}
      <motion.button
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        onClick={() => setShowDetails(!showDetails)}
        className="w-full mt-4 md:mt-6 py-2 md:py-3 px-4 bg-white/50 hover:bg-white/80 rounded-xl 
                 transition-all flex items-center justify-center gap-2 
                 text-xs md:text-sm font-medium text-gray-600"
      >
        {showDetails ? 'Show Less' : 'Show More Details'}
        {showDetails ? <ChevronUp className="w-3 h-3 md:w-4 md:h-4" /> : <ChevronDown className="w-3 h-3 md:w-4 md:h-4" />}
      </motion.button>
    </div>

    {/* Expandable Details Section */}
    <AnimatePresence>
      {showDetails && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0, height: 0 }}
          className="space-y-4 md:space-y-6 overflow-hidden"
        >
          {/* Fasting Journey Card */}
          <div className="bg-gradient-to-br from-white to-blue-50 rounded-xl p-4 md:p-6 shadow-lg">
            <div className="flex items-center justify-between mb-4 md:mb-6">
              <h3 className="text-base md:text-lg font-semibold text-gray-900">Your Fasting Journey</h3>
              <div className="flex items-center gap-2 text-xs md:text-sm text-blue-600">
                <Clock className="w-3 h-3 md:w-4 md:h-4" />
                <span>{Math.floor(currentHours)}h milestone</span>
              </div>
            </div>

            {/* Timeline */}
            <div className="relative pb-4 md:pb-6">
              <div className="absolute left-3 md:left-4 top-0 bottom-0 w-0.5 bg-gradient-to-b from-blue-500 to-purple-500" />
              <div className="space-y-4 md:space-y-6">
                {fastingStages.map((stage, index) => {
                  const isComplete = currentHours >= stage.hour;
                  const isActive = stage === currentStage;
                  
                  return (
                    <motion.div
                      key={index}
                      initial={false}
                      animate={{ opacity: isComplete || isActive ? 1 : 0.5 }}
                      className={`relative pl-8 md:pl-12 ${isActive ? 'bg-blue-50 -mx-4 md:-mx-6 px-4 md:px-6 py-3 md:py-4 rounded-xl' : ''}`}
                    >
                      {/* Stage content */}
                      <div className={`absolute left-1.5 md:left-2 w-4 md:w-6 h-4 md:h-6 rounded-full border-2 
                        ${isComplete ? 'bg-green-500 border-green-500' : 
                          isActive ? 'bg-blue-500 border-blue-500' :
                          'bg-white border-gray-300'}
                        flex items-center justify-center`}
                      >
                        {isComplete && (
                          <motion.svg 
                            className="w-3 h-3 md:w-4 md:h-4 text-white"
                            initial={{ scale: 0 }}
                            animate={{ scale: 1 }}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="3"
                          >
                            <polyline points="20 6 9 17 4 12" />
                          </motion.svg>
                        )}
                      </div>
                      <div className="space-y-1 md:space-y-2">
                        <div className="flex items-center justify-between">
                          <h4 className={`text-sm md:text-base font-medium ${isActive ? 'text-blue-700' : 'text-gray-900'}`}>
                            {stage.label}
                          </h4>
                          <span className="text-xs md:text-sm text-gray-500">{stage.hour}h</span>
                        </div>
                        <p className={`text-xs md:text-sm ${isActive ? 'text-blue-600' : 'text-gray-600'}`}>
                          {stage.benefit}
                        </p>
                        
                        {isActive && stage.detailedBenefits && (
                          <motion.div
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            className="mt-2 md:mt-3 space-y-1 md:space-y-2"
                          >
                            {stage.detailedBenefits.map((benefit, idx) => (
                              <div key={idx} className="flex items-center gap-2 text-xs md:text-sm text-blue-600">
                                <div className="w-1 h-1 md:w-1.5 md:h-1.5 rounded-full bg-blue-500" />
                                {benefit}
                              </div>
                            ))}
                          </motion.div>
                        )}
                      </div>
                    </motion.div>
                  );
                })}
              </div>
            </div>
          </div>

          {/* Metabolic Insights Card */}
          <div className="bg-gradient-to-br from-white to-purple-50 rounded-xl p-4 md:p-6 shadow-lg space-y-4 md:space-y-6">
            <h3 className="text-base md:text-lg font-semibold text-gray-900">Metabolic Insights</h3>

            <div className="grid grid-cols-2 md:grid-cols-3 gap-3 md:gap-4">
              <MetricCard
                icon={Battery}
                label="Glycogen Stores"
                value={`${Math.max(0, Math.min(100, (1 - (currentHours / 24)) * 100)).toFixed(0)}%`}
                subValue={currentHours < 24 ? 'Depleting' : 'Depleted'}
                color="blue"
              />
              <MetricCard
                icon={Flame}
                label="Fat Burning"
                value={`${Math.min(100, (currentHours / 24) * 100).toFixed(0)}%`}
                subValue={currentHours >= 24 ? 'Optimal' : 'Increasing'}
                color="orange"
              />
              <div className="col-span-2 md:col-span-1">
                <MetricCard
                  icon={Activity}
                  label="Ketones"
                  value={`${Math.min(100, (currentHours / 36) * 100).toFixed(0)}%`}
                  subValue={currentHours >= 36 ? 'Peak' : 'Building'}
                  color="green"
                />
              </div>
            </div>

            {/* Autophagy Section */}
            {currentHours >= 16 && (
              <div className="bg-white/80 rounded-xl p-3 md:p-4">
                <div className="flex items-center justify-between mb-3 md:mb-4">
                  <div className="flex items-center gap-2">
                    <div className="p-1.5 md:p-2 bg-violet-100 rounded-lg">
                      <Sparkles className="w-3 h-3 md:w-4 md:h-4 text-violet-600" />
                    </div>
                    <span className="text-sm md:text-base font-medium text-gray-700">Cellular Renewal</span>
                  </div>
                  <span className="text-xs md:text-sm bg-violet-100 text-violet-600 px-2 md:px-3 py-0.5 md:py-1 rounded-full">
                    {currentHours >= 48 ? 'Peak' : currentHours >= 24 ? 'High' : 'Initiating'}
                  </span>
                </div>
                <div className="space-y-3 md:space-y-4">
                  {[
                    { 
                      label: 'Autophagy',
                      progress: Math.min(100, (currentHours / 48) * 100),
                      colorClass: 'violet'
                    },
                    { 
                      label: 'Cellular Repair',
                      progress: Math.min(100, (currentHours / 72) * 100),
                      colorClass: 'indigo'
                    },
                    { 
                      label: 'Immune Response',
                      progress: Math.min(100, (currentHours / 36) * 100),
                      colorClass: 'blue'
                    }
                  ].map((process, index) => (
                    <div key={index} className="space-y-1.5 md:space-y-2">
                      <div className="flex justify-between text-xs md:text-sm">
                        <span className="text-gray-600">{process.label}</span>
                        <span className={`text-${process.colorClass}-600 font-medium`}>
                          {Math.round(process.progress)}%
                        </span>
                      </div>
                      <div className={`relative h-1.5 md:h-2 bg-${process.colorClass}-100 rounded-full overflow-hidden`}>
                        <motion.div
                          initial={{ width: 0 }}
                          animate={{ width: `${process.progress}%` }}
                          className={`absolute inset-y-0 left-0 bg-${process.colorClass}-500 rounded-full`}
                          transition={{ duration: 1, ease: "easeOut" }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* Electrolyte Management */}
          {currentHours >= 24 && (
            <div className="bg-gradient-to-br from-white to-green-50 rounded-xl p-4 md:p-6 shadow-lg">
              <div className="flex items-center justify-between mb-4 md:mb-6">
                <h3 className="text-base md:text-lg font-semibold text-gray-900">Electrolyte Balance</h3>
                <div className="text-xs md:text-sm text-green-600 bg-green-100 px-2 md:px-3 py-0.5 md:py-1 rounded-full">
                  Extended Fast Mode
                </div>
              </div>
              <ElectrolyteTracker
                fastingHours={currentHours}
                activityLevel={userActivityLevel}
              />
            </div>
          )}
        </motion.div>
      )}
    </AnimatePresence>

    {/* Time Picker Modal */}
    <TimePickerModal
      isOpen={showTimeModal}
      onClose={() => {
        setShowTimeModal(false);
        setTimeAdjustmentError('');
      }}
      onSave={handleTimeAdjustment}
      currentTime={activeFast?.startTime}
      error={timeAdjustmentError}
    />

    {/* Error Display */}
    {timeAdjustmentError && (
      <motion.div 
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        className="fixed top-4 right-4 bg-red-50 text-red-600 px-3 md:px-4 py-2 rounded-lg 
                 shadow-lg flex items-center gap-2"
      >
        <AlertCircle className="w-3 h-3 md:w-4 md:h-4" />
        <span className="text-xs md:text-sm">{timeAdjustmentError}</span>
      </motion.div>
    )}
{/* End Fast Confirmation Modal */}
<AnimatePresence>
      {showConfirmEnd && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black/50 backdrop-blur-sm z-[9999] 
                   flex items-center justify-center p-4"
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              setShowConfirmEnd(false);
            }
          }}
        >
          <motion.div
            initial={{ scale: 0.95, opacity: 0, y: 20 }}
            animate={{ scale: 1, opacity: 1, y: 0 }}
            exit={{ scale: 0.95, opacity: 0, y: 20 }}
            className="bg-white rounded-xl p-4 md:p-6 max-w-sm w-full shadow-xl 
                     relative mx-auto my-auto"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex items-start justify-between mb-4">
              <div className="flex items-center gap-2 md:gap-3">
                <div className="p-1.5 md:p-2 bg-red-100 rounded-lg">
                  <StopCircle className="w-4 h-4 md:w-5 md:h-5 text-red-500" />
                </div>
                <h3 className="text-lg md:text-xl font-bold text-gray-900">End Fast?</h3>
              </div>
              <button
                onClick={() => setShowConfirmEnd(false)}
                className="p-1 hover:bg-gray-100 rounded-lg transition-colors"
              >
                <X className="w-4 h-4 md:w-5 md:h-5 text-gray-500" />
              </button>
            </div>
            
            <div className="space-y-4 md:space-y-6">
              <div className="bg-red-50 rounded-xl p-3 md:p-4">
                <p className="text-sm md:text-base text-gray-600">
                  You've completed <span className="font-semibold text-gray-900">
                    {Math.floor(currentHours)}h {Math.floor((currentHours % 1) * 60)}m
                  </span> of your fast. 
                </p>
                <p className="text-xs md:text-sm text-red-600 mt-2">
                  Are you sure you want to end it now?
                </p>
              </div>

              <div className="flex gap-2 md:gap-3">
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => setShowConfirmEnd(false)}
                  className="flex-1 px-3 md:px-4 py-2 md:py-3 bg-gray-100 hover:bg-gray-200 
                           rounded-xl text-gray-700 font-medium transition-colors text-sm md:text-base"
                >
                  Continue Fasting
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={handleEndFast}
                  className="flex-1 px-3 md:px-4 py-2 md:py-3 bg-gradient-to-r from-red-500 to-pink-500 
                           hover:from-red-600 hover:to-pink-600
                           text-white rounded-xl font-medium transition-colors text-sm md:text-base
                           flex items-center justify-center gap-2"
                >
                  <StopCircle className="w-3 h-3 md:w-4 md:h-4" />
                  End Fast
                </motion.button>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  </div>
);
};

export default CurrentFastProgress;