// SupplementationGuide.js
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { 
  Clock, 
  AlertTriangle, 
  Shield, 
  Check, 
  X,
  Info,
  Calendar
} from 'lucide-react';
import { SUPPLEMENTATION_PROTOCOLS, ELECTROLYTE_DATA } from './ElectrolyteData';

const getSupplementationPlan = (fastingHours, activityLevel = 'moderate') => {
  const getPhase = (hours) => {
    if (hours <= 24) return 'early';
    if (hours <= 48) return 'moderate';
    return 'extended';
  };

  const phase = getPhase(fastingHours);
  const plan = {};

  Object.entries(SUPPLEMENTATION_PROTOCOLS.electrolytes).forEach(([electrolyte, data]) => {
    plan[electrolyte] = {
      ...data.phases[phase],
      contraindications: data.contraindications,
      timing: SUPPLEMENTATION_PROTOCOLS.supplementTiming.optimalTimes[electrolyte]
    };
  });

  return plan;
};

export const SupplementationGuide = ({ 
  fastingHours, 
  activityLevel,
  onLog 
}) => {
  const [showSchedule, setShowSchedule] = useState(false);
  const plan = getSupplementationPlan(fastingHours, activityLevel);

  const SupplementCard = ({ name, data }) => (
    <div className="bg-white rounded-xl shadow-sm p-4 space-y-4">
      <div className="flex items-center justify-between">
        <h3 className="font-medium capitalize">{name}</h3>
        <span className={`px-3 py-1 rounded-full text-sm
          ${fastingHours > 48 ? 'bg-orange-100 text-orange-800' : 
           fastingHours > 24 ? 'bg-yellow-100 text-yellow-800' : 
           'bg-blue-100 text-blue-800'}`}>
          {data.timing}
        </span>
      </div>

      {/* Dosage Information */}
      <div className="bg-gray-50 rounded-lg p-3">
        <h4 className="font-medium text-sm mb-2">Recommended Dosage</h4>
        {typeof data.dosage === 'object' ? (
          <div className="space-y-2">
            <div className="flex items-center justify-between text-sm">
              <span className="text-gray-600">Minimal:</span>
              <span className="font-medium">{data.dosage.minimal}</span>
            </div>
            <div className="flex items-center justify-between text-sm">
              <span className="text-gray-600">Standard:</span>
              <span className="font-medium">{data.dosage.standard}</span>
            </div>
            <div className="flex items-center justify-between text-sm">
              <span className="text-gray-600">Active:</span>
              <span className="font-medium">{data.dosage.active}</span>
            </div>
          </div>
        ) : (
          <p className="text-sm">{data.dosage}</p>
        )}
      </div>

      {/* Schedule */}
      {data.schedule && (
        <div className="space-y-2">
          <h4 className="font-medium text-sm">Daily Schedule</h4>
          {data.schedule.map((timing, idx) => (
            <div key={idx} className="flex items-center justify-between bg-blue-50 p-2 rounded">
              <span className="text-sm text-blue-800">{timing.time}</span>
              <span className="text-sm font-medium text-blue-800">{timing.amount}</span>
            </div>
          ))}
        </div>
      )}

      {/* Contraindications */}
      {data.contraindications && (
        <div className="bg-red-50 rounded-lg p-3">
          <h4 className="font-medium text-sm text-red-800 mb-2">Contraindications</h4>
          <ul className="space-y-1">
            {data.contraindications.map((item, idx) => (
              <li key={idx} className="flex items-center gap-2 text-sm text-red-700">
                <X className="w-4 h-4" />
                {item}
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Notes */}
      {data.notes && (
        <div className="flex items-start gap-2 text-sm text-gray-600">
          <Info className="w-4 h-4 mt-0.5 flex-shrink-0" />
          <p>{data.notes}</p>
        </div>
      )}
    </div>
  );

  return (
    <div className="space-y-6">
      {/* Header with safety warning */}
      <div className="bg-yellow-50 p-4 rounded-lg">
        <div className="flex items-start gap-3">
          <AlertTriangle className="w-5 h-5 text-yellow-800" />
          <div>
            <h3 className="font-medium text-yellow-800">Important Safety Notice</h3>
            <p className="text-sm text-yellow-700 mt-1">
              This supplementation guide is for informational purposes only. 
              Always consult with a healthcare provider before starting any 
              supplementation routine, especially during extended fasting.
            </p>
          </div>
        </div>
      </div>

      {/* Current Phase Information */}
      <div className="bg-blue-50 p-4 rounded-lg">
        <div className="flex items-center gap-3">
          <Clock className="w-5 h-5 text-blue-800" />
          <div>
            <h3 className="font-medium text-blue-800">
              Current Fasting Phase: {fastingHours <= 24 ? 'Early' : 
                                    fastingHours <= 48 ? 'Moderate' : 
                                    'Extended'} Phase
            </h3>
            <p className="text-sm text-blue-700 mt-1">
              {fastingHours <= 24 ? 'Light supplementation may be needed' :
               fastingHours <= 48 ? 'Regular supplementation recommended' :
               'Careful monitoring and supplementation required'}
            </p>
          </div>
        </div>
      </div>

      {/* Supplement Cards */}
      <div className="grid gap-4">
        {Object.entries(plan).map(([name, data]) => (
          <SupplementCard key={name} name={name} data={data} />
        ))}
      </div>

      {/* Daily Schedule Toggle */}
      <button
        onClick={() => setShowSchedule(!showSchedule)}
        className="w-full py-2 px-4 bg-white border border-gray-200 rounded-lg
                 flex items-center justify-center gap-2 hover:bg-gray-50 transition-colors"
      >
        <Calendar className="w-5 h-5" />
        <span>{showSchedule ? 'Hide' : 'Show'} Daily Schedule</span>
      </button>

      {/* Detailed Daily Schedule */}
      {showSchedule && (
        <div className="bg-white rounded-xl shadow-sm p-4">
          <h3 className="font-medium mb-4">24-Hour Supplement Schedule</h3>
          <div className="space-y-4">
            {['Morning', 'Midday', 'Afternoon', 'Evening'].map((timeSlot) => (
              <div key={timeSlot} className="border-l-4 border-blue-500 pl-4">
                <h4 className="font-medium text-sm text-gray-900">{timeSlot}</h4>
                <div className="mt-2 space-y-2">
                  {Object.entries(plan).map(([name, data]) => {
                    const schedule = data.schedule?.find(s => 
                      s.time.toLowerCase().includes(timeSlot.toLowerCase())
                    );
                    if (!schedule) return null;
                    return (
                      <div key={name} className="flex items-center justify-between text-sm">
                        <span className="text-gray-600 capitalize">{name}:</span>
                        <span className="font-medium">{schedule.amount}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

SupplementationGuide.propTypes = {
  fastingHours: PropTypes.number.isRequired,
  activityLevel: PropTypes.string,
  onLog: PropTypes.func
};

SupplementationGuide.defaultProps = {
  activityLevel: 'moderate',
  onLog: () => {}
};