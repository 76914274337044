// src/components/user/Posts/components/PostForm/components/StatusBar.js
import React from 'react';

const StatusBar = ({ wordCount = 0, charCount = 0, readTime = '1 min read' }) => {
  return (
    <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-white rounded-full shadow-lg px-6 py-2 flex items-center gap-6 text-sm text-gray-600 border border-gray-100 z-20">
      <div className="flex items-center gap-2">
        <span className="font-medium">{wordCount.toLocaleString()}</span>
        <span className="text-gray-400">words</span>
      </div>
      
      <div className="w-px h-4 bg-gray-200" />
      
      <div className="flex items-center gap-2">
        <span className="font-medium">{charCount.toLocaleString()}</span>
        <span className="text-gray-400">characters</span>
      </div>
      
      <div className="w-px h-4 bg-gray-200" />
      
      <div className="flex items-center gap-2">
        <span className="font-medium">{readTime}</span>
      </div>
    </div>
  );
};

export default StatusBar;