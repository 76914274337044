// src/components/user/Posts/components/PostView/components/MobileEngagementBar.js
import React from 'react';
import { Heart, Share2, Bookmark, MessageSquare } from 'lucide-react';

export const MobileEngagementBar = ({ 
  post, 
  currentUser, 
  isBookmarked, 
  onLike, 
  onShare, 
  onBookmark 
}) => (
  <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 p-3 flex justify-around items-center lg:hidden z-40">
    <div className="flex flex-col items-center">
      <button 
        onClick={onLike}
        className={`p-2 rounded-full transition-all ${
          post.likedBy?.includes(currentUser?.uid)
            ? 'text-red-500'
            : 'text-gray-400 hover:text-red-500'
        }`}
      >
        <Heart className={`w-6 h-6 ${post.likedBy?.includes(currentUser?.uid) ? 'fill-current' : ''}`} />
      </button>
      <span className="text-xs text-gray-500">{post.likes || 0}</span>
    </div>

    <div className="flex flex-col items-center">
      <button 
        onClick={() => document.getElementById('comments').scrollIntoView({ behavior: 'smooth' })}
        className="p-2 text-gray-400 hover:text-gray-600 rounded-full"
      >
        <MessageSquare className="w-6 h-6" />
      </button>
      <span className="text-xs text-gray-500">{post.comments?.length || 0}</span>
    </div>

    <button
      onClick={onShare}
      className="p-2 text-gray-400 hover:text-gray-600 rounded-full"
    >
      <Share2 className="w-6 h-6" />
    </button>

    <button
      onClick={onBookmark}
      className={`p-2 rounded-full ${
        isBookmarked
          ? 'text-emerald-500'
          : 'text-gray-400 hover:text-emerald-500'
      }`}
    >
      <Bookmark className={`w-6 h-6 ${isBookmarked ? 'fill-current' : ''}`} />
    </button>
  </div>
);