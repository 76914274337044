// src/components/user/Posts/constants/categories.js
import {
  Activity,
  Sparkles,
  BookOpen,
  Brain,
  Apple,
  Dumbbell,
  Laptop,
  Users,
  GraduationCap
} from 'lucide-react';

export const categories = {
  'health-tips': {
    label: 'Health Tips',
    icon: Activity,
  },
  'medical-research': {
    label: 'Medical Research',
    icon: Sparkles,
  },
  'wellness': {
    label: 'Wellness',
    icon: BookOpen,
  },
  'mental-health': {
    label: 'Mental Health',
    icon: Brain,
  },
  'nutrition': {
    label: 'Nutrition',
    icon: Apple,
  },
  'fitness': {
    label: 'Fitness',
    icon: Dumbbell,
  },
  'healthcare-tech': {
    label: 'Healthcare Tech',
    icon: Laptop,
  },
  'patient-care': {
    label: 'Patient Care',
    icon: Users,
  },
  'medical-education': {
    label: 'Medical Education',
    icon: GraduationCap,
  },
};