import React, { useState, useEffect } from 'react';
import { usePostView } from './hooks/usePostView';
import { PostHeader } from './components/PostHeader';
import { PostContent } from './components/PostContent';
import { LoadingSpinner } from './components/LoadingSpinner';
import { ErrorView } from './components/ErrorView';
import { ShareMenu } from './components/ShareMenu';
import { ConfirmDialog } from './components/ConfirmDialog';
import { Toast } from './components/Toast';
import { MobileEngagementBar } from './components/MobileEngagementBar';
import { DesktopSidebar } from './components/DesktopSidebar';
import CommentSection from '../../comments/CommentSection';
import PostForm from '../PostForm/PostForm';
import { ChevronUp, ImageOff } from 'lucide-react';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { app } from '../../../../../firebase';
import DOMPurify from 'dompurify';

const PostView = () => {
  const {
    state,
    updateState,
    handlers,
    refs,
    post,
    currentUser,
    isAuthor
  } = usePostView();

  const [coverImageUrl, setCoverImageUrl] = useState(null);
  const [imageError, setImageError] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);
  const [processedContent, setProcessedContent] = useState('');

  // Process and format the content
  const processContent = (content) => {
    if (!content) return '';

    try {
      // Sanitize the content first
      let sanitizedContent = DOMPurify.sanitize(content);

      // Remove empty paragraphs
      sanitizedContent = sanitizedContent.replace(/<p>\s*<\/p>/g, '');

      // Convert placeholder text
      sanitizedContent = sanitizedContent.replace(
        /<p>Tell your story...[^<]*<\/p>/g,
        ''
      );

      // Add proper spacing between paragraphs
      sanitizedContent = sanitizedContent.replace(
        /<\/p><p>/g,
        '</p>\n<p>'
      );

      // Process code blocks if any
      sanitizedContent = sanitizedContent.replace(
        /<pre><code>(.*?)<\/code><\/pre>/g,
        (match, p1) => {
          return `<pre class="bg-gray-100 p-4 rounded-lg overflow-x-auto"><code>${p1}</code></pre>`;
        }
      );

      // Process links
      sanitizedContent = sanitizedContent.replace(
        /<a([^>]*)>/g,
        '<a$1 class="text-blue-600 hover:text-blue-800 underline">'
      );

      return sanitizedContent;
    } catch (error) {
      console.error('Error processing content:', error);
      return content; // Return original content if processing fails
    }
  };

  // Custom hook for format detection
  const useContentFormat = (content) => {
    useEffect(() => {
      if (!content) {
        setProcessedContent('');
        return;
      }

      const processed = processContent(content);
      setProcessedContent(processed);
    }, [content]);
  };

  // Use the custom hook
  useContentFormat(post?.content);

  // Process Firebase Storage URLs with enhanced error handling
  const processStorageUrl = async (imageData) => {
    try {
      if (!imageData) return null;

      if (typeof imageData === 'string') {
        if (imageData.startsWith('http')) {
          if (window.location.hostname === 'localhost' && 
              imageData.includes('firebasestorage.googleapis.com')) {
            return imageData
              .replace('https://firebasestorage.googleapis.com', 'http://localhost:9199')
              .split('?')[0] + '?alt=media';
          }
          return imageData;
        }
        
        const storage = getStorage(app);
        const imageRef = ref(storage, imageData);
        return await getDownloadURL(imageRef);
      }

      if (typeof imageData === 'object') {
        if (imageData.url && typeof imageData.url === 'string') {
          return imageData.url;
        }
        
        if (imageData.path && typeof imageData.path === 'string') {
          const storage = getStorage(app);
          const imageRef = ref(storage, imageData.path);
          return await getDownloadURL(imageRef);
        }
      }

      return null;
    } catch (error) {
      console.error('Error processing storage URL:', error);
      return null;
    }
  };

  useEffect(() => {
    let isMounted = true;

    const processCoverImage = async () => {
      if (isMounted) {
        setImageLoading(true);
        setImageError(false);
        setCoverImageUrl(null);
      }

      if (!post?.coverImage) {
        if (isMounted) {
          setImageLoading(false);
        }
        return;
      }

      try {
        const processedUrl = await processStorageUrl(post.coverImage);
        
        if (isMounted) {
          if (processedUrl) {
            setCoverImageUrl(processedUrl);
            setImageError(false);
          } else {
            setImageError(true);
          }
          setImageLoading(false);
        }
      } catch (error) {
        console.error('Error in cover image processing:', error);
        if (isMounted) {
          setImageError(true);
          setImageLoading(false);
        }
      }
    };

    processCoverImage();

    return () => {
      isMounted = false;
    };
  }, [post?.coverImage]);

  if (state.loading) {
    return <LoadingSpinner message="Loading article..." />;
  }

  if (state.error || !post) {
    return <ErrorView error={state.error || 'Post not found'} />;
  }

  const formatDate = (date) => {
    if (!date) return 'Unknown date';
    
    try {
      return new Date(date).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      });
    } catch (error) {
      console.error('Date formatting error:', error);
      return 'Invalid date';
    }
  };

  const calculateReadTime = (content) => {
    if (!content || typeof content !== 'string') return 1;
    
    const wordsPerMinute = 200;
    const text = content.replace(/<[^>]*>/g, ''); // Remove HTML tags
    const wordCount = text.trim().split(/\s+/).length;
    return Math.max(1, Math.ceil(wordCount / wordsPerMinute));
  };

  const ImagePlaceholder = ({ error }) => (
    <div className="w-full aspect-[2/1] bg-gray-100 rounded-lg flex items-center justify-center">
      <div className="text-center">
        <ImageOff className="w-12 h-12 text-gray-400 mx-auto mb-2" />
        <p className="text-gray-500">
          {error ? 'Failed to load image' : 'No image available'}
        </p>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-white overflow-hidden">
      <div 
        className="fixed top-0 left-0 h-0.5 bg-emerald-500 transition-all duration-300 z-50"
        style={{ width: `${state.readingProgress}%` }}
      />

      <PostHeader
        ref={refs.headerRef}
        post={post}
        onBack={handlers.handleBack}
        onLike={handlers.handleLike}
        onShare={() => updateState({ showShareMenu: true })}
        onBookmark={handlers.handleBookmark}
        onEdit={() => updateState({ isEditing: true, showOptionsMenu: false })}
        onDelete={handlers.handleDelete}
        onReport={handlers.handleReport}
        currentUser={currentUser}
        isBookmarked={state.isBookmarked}
        isAuthor={isAuthor}
        showOptionsMenu={state.showOptionsMenu}
        onToggleOptionsMenu={() => updateState({ showOptionsMenu: !state.showOptionsMenu })}
      />

      <main className="pt-4 pb-16">
        <header className="max-w-screen-md mx-auto px-4 mb-12">
          <div className="flex items-center gap-4 mb-8">
            <div className="flex-shrink-0 w-12 h-12 rounded-full bg-gradient-to-br from-emerald-400 to-emerald-600 
              flex items-center justify-center text-white text-xl font-medium">
              {post.authorEmail?.[0]?.toUpperCase() || 'A'}
            </div>
            <div className="min-w-0 flex-1">
              <div className="font-semibold text-gray-900 break-words">
                {post.authorEmail?.split('@')[0] || 'Anonymous'}
              </div>
              <div className="text-sm text-gray-500 break-words">
                {formatDate(post.createdAt)} · {calculateReadTime(post.content)} min read
              </div>
            </div>
          </div>
          
          <h1 className="font-serif text-4xl md:text-5xl font-bold text-gray-900 tracking-tight mb-6 break-words hyphens-auto">
            {post.title || 'Untitled Post'}
          </h1>
          
          {post.subtitle && (
            <p className="text-xl md:text-2xl text-gray-600 font-serif leading-relaxed break-words hyphens-auto">
              {post.subtitle}
            </p>
          )}
        </header>

        {/* Cover Image */}
        <div className="w-full max-w-screen-lg mx-auto mb-16 px-4">
          {imageLoading ? (
            <div className="w-full aspect-[2/1] bg-gray-100 rounded-lg flex items-center justify-center">
              <LoadingSpinner message="Loading image..." />
            </div>
          ) : !imageError && coverImageUrl ? (
            <figure className="w-full aspect-[2/1] relative">
              <img
                src={coverImageUrl}
                alt={post.title || 'Article cover'}
                className="w-full h-full object-cover rounded-lg shadow-2xl"
                loading="lazy"
                onError={(e) => {
                  console.error('Image load error:', e.target.src);
                  setImageError(true);
                }}
              />
            </figure>
          ) : (
            <ImagePlaceholder error={imageError} />
          )}
        </div>

        {/* Article Content with Improved Formatting */}
        <article ref={refs.articleRef} className="max-w-screen-md mx-auto px-4">
          <div 
            className="prose prose-lg prose-gray max-w-none break-words"
            dangerouslySetInnerHTML={{ __html: processedContent }}
          />

          {post.tags?.length > 0 && (
            <div className="mt-12 pt-8 border-t flex flex-wrap gap-3">
              {post.tags.map(tag => (
                <span 
                  key={tag}
                  className="px-4 py-1.5 bg-gray-100 text-gray-600 rounded-full text-sm hover:bg-gray-200 
                    transition-colors cursor-pointer break-words max-w-full"
                >
                  #{tag}
                </span>
              ))}
            </div>
          )}
        </article>

        <section id="comments" className="max-w-screen-md mx-auto px-4 mt-16 mb-20 lg:mb-0">
        <CommentSection 
  post={post}
  comments={post.comments?.map(comment => ({
    ...comment,
    id: comment.id || crypto.randomUUID(), // Ensure unique ID
    createdAt: comment.createdAt instanceof Date 
      ? comment.createdAt.toISOString()
      : typeof comment.createdAt === 'object' && comment.createdAt?.toDate
      ? comment.createdAt.toDate().toISOString()
      : comment.createdAt || new Date().toISOString()
  })) || []}
  currentUser={currentUser}
  onAddComment={handlers.handleAddComment}
  onEditComment={handlers.handleEditComment}
  onDeleteComment={handlers.handleDeleteComment}
  onReportComment={handlers.handleReportComment}
  onLikeComment={handlers.handleLikeComment}
  onDislikeComment={handlers.handleDislikeComment}
  isAuthor={isAuthor}
/>

</section>
      </main>

      <MobileEngagementBar 
        post={post}
        currentUser={currentUser}
        isBookmarked={state.isBookmarked}
        onLike={handlers.handleLike}
        onShare={() => updateState({ showShareMenu: true })}
        onBookmark={handlers.handleBookmark}
      />

      <DesktopSidebar 
        post={post}
        currentUser={currentUser}
        isBookmarked={state.isBookmarked}
        onLike={handlers.handleLike}
        onShare={() => updateState({ showShareMenu: true })}
        onBookmark={handlers.handleBookmark}
      />

      {state.showShareMenu && (
        <ShareMenu
          onShare={handlers.handleShare}
          onClose={() => updateState({ showShareMenu: false })}
        />
      )}

      {state.isEditing && (
        <div className="fixed inset-0 z-50">
          <PostForm
            onClose={() => updateState({ isEditing: false })}
            onSubmit={handlers.handleSave}
            initialData={post}
          />
        </div>
      )}

      {state.showConfirmDialog && (
        <ConfirmDialog
          message={state.confirmMessage}
          onConfirm={() => {
            state.confirmAction?.();
            updateState({ 
              showConfirmDialog: false, 
              confirmMessage: '', 
              confirmAction: null 
            });
          }}
          onCancel={() => updateState({ 
            showConfirmDialog: false, 
            confirmMessage: '', 
            confirmAction: null 
          })}
        />
      )}

      {state.toast.show && (
        <Toast
          message={state.toast.message}
          type={state.toast.type}
          onClose={() => updateState({
            toast: { ...state.toast, show: false }
          })}
        />
      )}

      {state.showScrollTop && (
        <button
          onClick={handlers.scrollToTop}
          className="fixed bottom-24 right-8 p-3 bg-white rounded-full shadow-lg hover:shadow-xl 
            border border-gray-200 text-gray-600 hover:text-gray-900 transition-all duration-300 z-40 lg:bottom-8"
          aria-label="Scroll to top"
        >
          <ChevronUp className="w-6 h-6" />
        </button>
      )}
    </div>
  );
};

export default PostView;