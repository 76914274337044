export const CATEGORIES = [
    'Health Tips',
    'Medical Research',
    'Wellness',
    'Disease Prevention',
    'Mental Health',
    'Nutrition',
    'Fitness',
    'Healthcare Technology',
    'Patient Care',
    'Medical Education'
  ];
  
  export const PlaceholderContent = {
    title: 'Write your title...',
    subtitle: 'Add a subtitle that describes your story...',
    content: '<p>Tell your story...</p>'
  };
  
  export const defaultFormData = {
    title: '',
    subtitle: '',
    content: '<p>Tell your story...</p>',
    category: '',
    coverImage: null,
    tags: [],
    readTime: '3 min read',
    publishStatus: 'draft',
    lastSaved: null,
    authorId: '',
    authorEmail: '',
    createdAt: null,
    updatedAt: null
  };
  
  export const sanitizeConfig = {
    ALLOWED_TAGS: [
      'p', 'br', 'strong', 'em', 'u', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
      'ul', 'ol', 'li', 'blockquote', 'a', 'img', 'pre', 'code',
      'div', 'span', 'table', 'thead', 'tbody', 'tr', 'td', 'th'
    ],
    ALLOWED_ATTR: [
      'href', 'src', 'alt', 'title', 'style', 'target', 'class',
      'width', 'height', 'data-*'
    ],
    ALLOWED_STYLES: [
      'color', 'background-color', 'text-align', 'font-size', 'font-family',
      'margin', 'padding', 'text-decoration', 'font-weight', 'font-style'
    ]
  };
  