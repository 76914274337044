// bioguard\src\components\user\StructureDashboard\DashboardHeader.js
import React from 'react';
import PropTypes from 'prop-types';

const DashboardHeader = ({ 
  title, 
  description, 
  action, 
  children 
}) => {
  return (
    <div className="flex items-center justify-between">
      <div>
        <h1 className="text-2xl font-bold text-gray-800">{title}</h1>
        <p className="text-gray-500 mt-1">{description}</p>
      </div>
      
      {(action || children) && (
        <div className="flex items-center space-x-4">
          {action}
          {children}
        </div>
      )}
    </div>
  );
};

DashboardHeader.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  action: PropTypes.node,
  children: PropTypes.node
};

DashboardHeader.defaultProps = {
  description: '',
};

export default DashboardHeader;