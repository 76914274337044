// LegalLayout.js
import React, { useState, useEffect } from 'react';
import { ArrowLeft, Search, Clock, Share2, Printer, Download, ArrowUpCircle } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';

// Simple Alert Component
const SimpleAlert = ({ children }) => (
  <div className="bg-gray-50 border rounded-lg p-4" role="alert">
    <div className="flex items-center">
      {children}
    </div>
  </div>
);

// Table of Contents Component
const TableOfContents = () => {
  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('section[id]');
      let currentSection = '';
      
      sections.forEach(section => {
        const rect = section.getBoundingClientRect();
        if (rect.top <= 100) {
          currentSection = section.id;
        }
      });
      
      setActiveSection(currentSection);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleClick = (e, id) => {
    e.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const sections = Array.from(document.querySelectorAll('section[id]')).map(section => ({
    id: section.id,
    title: section.querySelector('h2')?.textContent || ''
  }));

  return (
    <div className="space-y-2">
      {sections.map(({ id, title }) => (
        <a
          key={id}
          href={`#${id}`}
          onClick={(e) => handleClick(e, id)}
          className={`block px-3 py-2 rounded-lg transition-colors ${
            activeSection === id
              ? 'bg-blue-50 text-blue-600 font-medium'
              : 'text-gray-600 hover:bg-gray-50'
          }`}
        >
          {title}
        </a>
      ))}
    </div>
  );
};

const LegalLayout = ({ title, children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const isAuthenticated = !!localStorage.getItem('token');

  // Store previous path when component mounts
  useEffect(() => {
    if (isAuthenticated) {
      const storedPath = window.location.pathname;
      sessionStorage.setItem('previousLegalPath', storedPath);
    }
  }, [isAuthenticated]);

  const handleBack = () => {
    if (!isAuthenticated) {
      navigate('/');
      return;
    }

    // Define valid dashboard paths
    const validPaths = {
      '/dashboard/weight': true,
      '/dashboard/fasting': true,
      '/dashboard/feature-requests': true,
      '/recommendations': true,
      '/settings/profile': true
    };

    // Try to get previous path from sessionStorage
    const previousPath = sessionStorage.getItem('previousLegalPath');

    // First try to navigate to referrer
    const referrer = document.referrer;
    if (referrer) {
      try {
        const url = new URL(referrer);
        if (url.origin === window.location.origin) {
          const path = url.pathname;
          if (validPaths[path]) {
            navigate(path);
            sessionStorage.removeItem('previousLegalPath');
            return;
          }
        }
      } catch (error) {
        console.error('Error parsing referrer:', error);
      }
    }

    // If no valid referrer, default to weight dashboard
    navigate('/dashboard/weight');
    sessionStorage.removeItem('previousLegalPath');
  };

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 400);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleShare = async () => {
    try {
      await navigator.share({
        title: document.title,
        url: window.location.href
      });
    } catch (err) {
      console.log('Error sharing:', err);
    }
  };

  const handlePrint = () => {
    window.print();
  };

  const handleDownload = () => {
    // Implementation for download functionality
    console.log('Download functionality to be implemented');
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    // Implement search functionality here
    console.log('Search:', e.target.value);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 via-purple-50 to-pink-50">
      {/* Sticky Header */}
      <div className="sticky top-0 z-10 backdrop-blur-md bg-white/80 border-b">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex flex-col sm:flex-row items-center gap-4">
            <div className="flex items-center gap-4">
              <button 
                onClick={handleBack}
                className="p-2 hover:bg-gray-100 rounded-full transition-colors group"
                aria-label={`Go back to ${isAuthenticated ? 'dashboard' : 'home'}`}
              >
                <ArrowLeft className="w-5 h-5 group-hover:text-blue-600" />
              </button>
              <h1 className="text-2xl font-semibold text-gray-900">{title}</h1>
            </div>
            
            {/* Only show search for authenticated users */}
            {isAuthenticated && (
              <div className="flex-1 relative">
                <input
                  type="search"
                  placeholder="Search in document..."
                  className="w-full px-4 py-2 pl-10 rounded-lg border focus:ring-2 focus:ring-blue-500 outline-none"
                  value={searchQuery}
                  onChange={handleSearch}
                />
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
              </div>
            )}

            {/* Only show additional actions for authenticated users */}
            {isAuthenticated && (
              <div className="flex items-center gap-2">
                <button 
                  onClick={handleShare}
                  className="p-2 hover:bg-gray-100 rounded-full transition-colors" 
                  aria-label="Share"
                >
                  <Share2 className="w-5 h-5" />
                </button>
                <button 
                  onClick={handlePrint}
                  className="p-2 hover:bg-gray-100 rounded-full transition-colors" 
                  aria-label="Print"
                >
                  <Printer className="w-5 h-5" />
                </button>
                <button 
                  onClick={handleDownload}
                  className="p-2 hover:bg-gray-100 rounded-full transition-colors" 
                  aria-label="Download"
                >
                  <Download className="w-5 h-5" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
          {/* Table of Contents Sidebar - only shown to authenticated users */}
          {isAuthenticated && (
            <div className="lg:col-span-1 hidden lg:block">
              <div className="sticky top-24 bg-white rounded-2xl shadow-lg p-6">
                <h3 className="text-lg font-semibold mb-4">Contents</h3>
                <nav>
                  <TableOfContents />
                </nav>
              </div>
            </div>
          )}

          {/* Main Content Area - adjusts width based on authentication */}
          <div className={`${isAuthenticated ? 'lg:col-span-3' : 'lg:col-span-4'}`}>
            <div className="bg-white rounded-3xl shadow-xl">
              <div className="p-6 sm:p-8 lg:p-12">
                <SimpleAlert>
                  <Clock className="w-4 h-4" />
                  <p className="ml-2 text-sm text-gray-600">
                    Last updated: November 5, 2024
                  </p>
                </SimpleAlert>
                <div className="mt-6">
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Scroll to Top Button */}
      {showScrollTop && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-8 right-8 bg-blue-500 text-white p-3 rounded-full shadow-lg hover:bg-blue-600 transition-colors"
          aria-label="Scroll to top"
        >
          <ArrowUpCircle className="w-6 h-6" />
        </button>
      )}
    </div>
  );
};

export default LegalLayout;