// src/components/user/Posts/components/PostForm/components/RichTextEditor/context/EditorContext.js
import React, { createContext, useContext, useReducer, useCallback } from 'react';

const EditorContext = createContext();

const initialState = {
  history: {
    past: [],
    present: '',
    future: []
  },
  selection: {
    range: null,
    styles: new Set()
  },
  activeElement: null,
  stats: {
    wordCount: 0,
    charCount: 0,
    readTime: '1 min read'
  },
  isUnsaved: false,
  tableState: {
    selectedCell: null,
    isEditing: false
  }
};

const MAX_HISTORY_LENGTH = 100;

function editorReducer(state, action) {
  switch (action.type) {
    case 'UPDATE_CONTENT':
      const past = [...state.history.past, state.history.present].slice(-MAX_HISTORY_LENGTH);
      return {
        ...state,
        history: {
          past,
          present: action.payload,
          future: []
        },
        isUnsaved: true
      };

    case 'UNDO':
      if (state.history.past.length === 0) return state;
      const previous = state.history.past[state.history.past.length - 1];
      const newPast = state.history.past.slice(0, -1);
      return {
        ...state,
        history: {
          past: newPast,
          present: previous,
          future: [state.history.present, ...state.history.future]
        },
        isUnsaved: true
      };

    case 'REDO':
      if (state.history.future.length === 0) return state;
      const next = state.history.future[0];
      return {
        ...state,
        history: {
          past: [...state.history.past, state.history.present],
          present: next,
          future: state.history.future.slice(1)
        },
        isUnsaved: true
      };

    case 'UPDATE_SELECTION':
      return {
        ...state,
        selection: action.payload
      };

    case 'UPDATE_STATS':
      return {
        ...state,
        stats: action.payload
      };

    case 'UPDATE_TABLE_STATE':
      return {
        ...state,
        tableState: {
          ...state.tableState,
          ...action.payload
        }
      };

    case 'MARK_SAVED':
      return {
        ...state,
        isUnsaved: false
      };

    case 'RESET':
      return {
        ...initialState,
        history: {
          past: [],
          present: action.payload || '',
          future: []
        }
      };

    default:
      return state;
  }
}

export function EditorProvider({ children }) {
  const [state, dispatch] = useReducer(editorReducer, initialState);

  const value = {
    state,
    canUndo: state.history.past.length > 0,
    canRedo: state.history.future.length > 0,
    
    undo: useCallback(() => {
      dispatch({ type: 'UNDO' });
    }, []),
    
    redo: useCallback(() => {
      dispatch({ type: 'REDO' });
    }, []),
    
    updateContent: useCallback((content) => {
      dispatch({ type: 'UPDATE_CONTENT', payload: content });
    }, []),
    
    updateSelection: useCallback((selection) => {
      dispatch({ type: 'UPDATE_SELECTION', payload: selection });
    }, []),
    
    updateStats: useCallback((stats) => {
      dispatch({ type: 'UPDATE_STATS', payload: stats });
    }, []),
    
    updateTableState: useCallback((tableState) => {
      dispatch({ type: 'UPDATE_TABLE_STATE', payload: tableState });
    }, []),
    
    markSaved: useCallback(() => {
      dispatch({ type: 'MARK_SAVED' });
    }, []),
    
    reset: useCallback((initialContent) => {
      dispatch({ type: 'RESET', payload: initialContent });
    }, [])
  };

  return (
    <EditorContext.Provider value={value}>
      {children}
    </EditorContext.Provider>
  );
}

export const useEditor = () => {
  const context = useContext(EditorContext);
  if (!context) {
    throw new Error('useEditor must be used within an EditorProvider');
  }
  return context;
};