// src/components/user/Posts/comments/constants.js
import { 
  MessageCircle, 
  SortDesc, 
  SortAsc, 
  Heart, 
  MessageSquare, 
  Star, 
  Reply 
} from 'lucide-react';

export const MAX_COMMENT_LENGTH = 10000;
export const NOTIFICATION_DURATION = 3000;
export const MAX_NESTING_DEPTH = 5;
export const COMMENTS_PER_PAGE = 20;

export const SORT_OPTIONS = [
  { value: 'newest', label: 'Newest First', icon: SortDesc },
  { value: 'oldest', label: 'Oldest First', icon: SortAsc },
  { value: 'most_liked', label: 'Most Liked', icon: Heart },
  { value: 'most_discussed', label: 'Most Discussed', icon: MessageSquare }
];

export const FILTER_OPTIONS = [
  { value: 'all', label: 'All Comments', icon: MessageCircle },
  { value: 'my_comments', label: 'My Comments', icon: Star },
  { value: 'liked', label: 'Liked Comments', icon: Heart },
  { value: 'replies', label: 'Replies', icon: Reply }
];