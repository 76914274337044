import React, { useState, useRef, useEffect } from 'react';
import { Pencil, ChevronRight, X } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const ResponsiveCategoryNav = ({
  categories,
  selectedCategory,
  onSelectCategory,
  onCreatePost,
  postCounts = {}
}) => {
  const [activeTabWidth, setActiveTabWidth] = useState(0);
  const [activeTabLeft, setActiveTabLeft] = useState(0);
  const [activeTabTop, setActiveTabTop] = useState(0);
  const tabsRef = useRef(new Map());
  const containerRef = useRef(null);
  const [showMoreModal, setShowMoreModal] = useState(false);
  const [visibleCategories, setVisibleCategories] = useState([]);
  const [overflowCategories, setOverflowCategories] = useState([]);

  // Calculate how many categories to show based on screen size
  useEffect(() => {
    const allCategories = [
      { key: 'all', label: 'All Topics', count: Object.values(postCounts).reduce((a, b) => a + b, 0) },
      ...Object.entries(categories).map(([key, { label, icon: Icon }]) => ({
        key,
        label,
        Icon,
        count: postCounts[key] || 0
      }))
    ];

    const updateVisibleCategories = () => {
      const width = window.innerWidth;
      let visibleCount;
      
      if (width < 640) { // sm
        visibleCount = 4; // 2 rows of 2
      } else if (width < 768) { // md
        visibleCount = 6; // 2 rows of 3
      } else if (width < 1024) { // lg
        visibleCount = 8; // 2 rows of 4
      } else {
        visibleCount = 10; // 2 rows of 5
      }

      setVisibleCategories(allCategories.slice(0, visibleCount));
      setOverflowCategories(allCategories.slice(visibleCount));
    };

    updateVisibleCategories();
    window.addEventListener('resize', updateVisibleCategories);
    return () => window.removeEventListener('resize', updateVisibleCategories);
  }, [categories, postCounts]);

  // Update active tab indicator position
  useEffect(() => {
    const updateTabPosition = () => {
      const activeTab = tabsRef.current.get(selectedCategory);
      if (activeTab) {
        const { offsetWidth, offsetLeft, offsetTop } = activeTab;
        setActiveTabWidth(offsetWidth);
        setActiveTabLeft(offsetLeft);
        setActiveTabTop(offsetTop);
      }
    };

    updateTabPosition();
    window.addEventListener('resize', updateTabPosition);
    return () => window.removeEventListener('resize', updateTabPosition);
  }, [selectedCategory]);

  return (
    <div className="bg-white">
      <div className="max-w-screen-2xl mx-auto px-4 sm:px-6 lg:px-8 py-6 border-b border-gray-100">
        {/* Header */}
        <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4 mb-6">
          <div>
            <h1 className="text-2xl sm:text-3xl font-bold text-gray-900">Community Insights</h1>
            <p className="text-sm sm:text-base text-gray-600 mt-1">Join the conversation with healthcare professionals</p>
          </div>
          
          <button
            onClick={onCreatePost}
            className="inline-flex items-center justify-center gap-2 px-6 py-2.5
              bg-blue-600 hover:bg-blue-700 text-white rounded-full
              transition-all duration-200 text-sm font-medium
              shadow-sm hover:shadow-md w-full sm:w-auto"
          >
            <Pencil className="w-4 h-4" />
            Write
          </button>
        </div>

        {/* Category Grid */}
        <div className="relative" ref={containerRef}>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2">
            {visibleCategories.map(({ key, label, Icon, count }) => (
              <button
                key={key}
                ref={el => tabsRef.current.set(key, el)}
                onClick={() => onSelectCategory(key)}
                className={`
                  relative px-3 py-2 text-sm rounded-lg transition-all
                  hover:bg-gray-50 flex items-center gap-2
                  ${selectedCategory === key
                    ? 'text-blue-600 bg-blue-50 font-medium'
                    : 'text-gray-600'
                  }
                `}
              >
                {Icon && <Icon className="w-4 h-4 flex-shrink-0" />}
                <span className="truncate">{label}</span>
                {count > 0 && (
                  <span className={`
                    text-xs px-1.5 py-0.5 rounded-full ml-auto flex-shrink-0
                    ${selectedCategory === key
                      ? 'bg-blue-100 text-blue-600'
                      : 'bg-gray-100 text-gray-600'
                    }
                  `}>
                    {count > 999 ? '999+' : count}
                  </span>
                )}
              </button>
            ))}

            {overflowCategories.length > 0 && (
              <button
                onClick={() => setShowMoreModal(true)}
                className="px-3 py-2 text-sm text-gray-600 hover:text-gray-900
                  hover:bg-gray-50 rounded-lg flex items-center gap-2"
              >
                More
                <ChevronRight className="w-4 h-4" />
              </button>
            )}
          </div>
        </div>
      </div>

      {/* More Categories Modal */}
      <AnimatePresence>
        {showMoreModal && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/50 z-50 flex items-end sm:items-center justify-center p-4"
            onClick={() => setShowMoreModal(false)}
          >
            <motion.div
              initial={{ y: 50 }}
              animate={{ y: 0 }}
              exit={{ y: 50 }}
              onClick={e => e.stopPropagation()}
              className="bg-white rounded-t-2xl sm:rounded-2xl shadow-xl w-full max-w-lg max-h-[80vh] overflow-hidden"
            >
              <div className="p-4 border-b border-gray-100 flex items-center justify-between">
                <h3 className="font-semibold text-gray-900">All Categories</h3>
                <button
                  onClick={() => setShowMoreModal(false)}
                  className="p-1 hover:bg-gray-100 rounded-lg transition-colors"
                >
                  <X className="w-5 h-5" />
                </button>
              </div>
              <div className="p-4 overflow-y-auto max-h-[60vh]">
                <div className="grid grid-cols-2 gap-2">
                  {overflowCategories.map(({ key, label, Icon, count }) => (
                    <button
                      key={key}
                      onClick={() => {
                        onSelectCategory(key);
                        setShowMoreModal(false);
                      }}
                      className={`
                        px-3 py-2 text-sm rounded-lg transition-colors
                        flex items-center gap-2
                        ${selectedCategory === key
                          ? 'bg-blue-50 text-blue-600 font-medium'
                          : 'text-gray-600 hover:bg-gray-50'
                        }
                      `}
                    >
                      {Icon && <Icon className="w-4 h-4" />}
                      <span className="truncate">{label}</span>
                      {count > 0 && (
                        <span className={`
                          text-xs px-1.5 py-0.5 rounded-full ml-auto
                          ${selectedCategory === key
                            ? 'bg-blue-100 text-blue-600'
                            : 'bg-gray-100 text-gray-600'
                          }
                        `}>
                          {count > 999 ? '999+' : count}
                        </span>
                      )}
                    </button>
                  ))}
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ResponsiveCategoryNav;