import React, { useState } from 'react';
import {
  Bold, Italic, Strikethrough, Code, Type,
  AlignLeft, AlignCenter, AlignRight,
  Palette, TableIcon, ImageIcon, ChevronDown
} from 'lucide-react';

export const MenuBar = ({ editor }) => {
  const [showTextStyles, setShowTextStyles] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);

  if (!editor) {
    return null;
  }

  const colorOptions = [
    { name: 'Default', color: '#374151' },
    { name: 'Purple', color: '#7C3AED' },
    { name: 'Red', color: '#DC2626' },
    { name: 'Yellow', color: '#D97706' },
    { name: 'Blue', color: '#2563EB' },
    { name: 'Green', color: '#059669' },
  ];

  const textStyles = [
    { label: 'Normal text', command: () => editor.chain().focus().setParagraph().run() },
    { label: 'Heading 1', command: () => editor.chain().focus().toggleHeading({ level: 1 }).run() },
    { label: 'Heading 2', command: () => editor.chain().focus().toggleHeading({ level: 2 }).run() },
    { label: 'Bullet list', command: () => editor.chain().focus().toggleBulletList().run() },
    { label: 'Numbered list', command: () => editor.chain().focus().toggleOrderedList().run() },
  ];

  const getCurrentTextStyle = () => {
    if (editor.isActive('heading', { level: 1 })) return 'Heading 1';
    if (editor.isActive('heading', { level: 2 })) return 'Heading 2';
    if (editor.isActive('bulletList')) return 'Bullet list';
    if (editor.isActive('orderedList')) return 'Numbered list';
    return 'Normal text';
  };

  return (
    <div className="border-b border-gray-200 bg-white">
      <div className="px-4 py-2 flex flex-wrap items-center gap-2">
        {/* Text Style Dropdown */}
        <div className="relative">
          <button
            onClick={() => setShowTextStyles(!showTextStyles)}
            className="flex items-center gap-2 px-3 py-1.5 rounded-md hover:bg-gray-100 text-gray-700 text-sm font-medium"
          >
            <Type className="w-4 h-4" />
            <span>{getCurrentTextStyle()}</span>
            <ChevronDown className="w-4 h-4" />
          </button>
          {showTextStyles && (
            <div 
              className="absolute left-0 top-full mt-1 w-48 rounded-lg bg-white shadow-lg border border-gray-200 py-1 z-10"
              onMouseLeave={() => setShowTextStyles(false)}
            >
              {textStyles.map((style) => (
                <button
                  key={style.label}
                  onClick={() => {
                    style.command();
                    setShowTextStyles(false);
                  }}
                  className="w-full px-4 py-2 text-sm text-left hover:bg-gray-50 text-gray-700"
                >
                  {style.label}
                </button>
              ))}
            </div>
          )}
        </div>

        <div className="w-px h-6 bg-gray-200" />

        {/* Formatting Options */}
        <div className="flex items-center gap-0.5 bg-gray-100 rounded-lg p-1">
          <button
            onClick={() => editor.chain().focus().toggleBold().run()}
            className={`p-1.5 rounded-md transition-colors ${
              editor.isActive('bold')
                ? 'bg-white shadow-sm text-blue-600'
                : 'hover:bg-white/60 text-gray-600'
            }`}
            title="Bold (Ctrl+B)"
          >
            <Bold className="w-4 h-4" />
          </button>
          <button
            onClick={() => editor.chain().focus().toggleItalic().run()}
            className={`p-1.5 rounded-md transition-colors ${
              editor.isActive('italic')
                ? 'bg-white shadow-sm text-blue-600'
                : 'hover:bg-white/60 text-gray-600'
            }`}
            title="Italic (Ctrl+I)"
          >
            <Italic className="w-4 h-4" />
          </button>
          <button
            onClick={() => editor.chain().focus().toggleStrike().run()}
            className={`p-1.5 rounded-md transition-colors ${
              editor.isActive('strike')
                ? 'bg-white shadow-sm text-blue-600'
                : 'hover:bg-white/60 text-gray-600'
            }`}
          >
            <Strikethrough className="w-4 h-4" />
          </button>
          <button
            onClick={() => editor.chain().focus().toggleCode().run()}
            className={`p-1.5 rounded-md transition-colors ${
              editor.isActive('code')
                ? 'bg-white shadow-sm text-blue-600'
                : 'hover:bg-white/60 text-gray-600'
            }`}
          >
            <Code className="w-4 h-4" />
          </button>
        </div>

        <div className="w-px h-6 bg-gray-200" />

        {/* Alignment Options */}
        <div className="flex items-center gap-0.5 bg-gray-100 rounded-lg p-1">
          <button
            onClick={() => editor.chain().focus().setTextAlign('left').run()}
            className={`p-1.5 rounded-md transition-colors ${
              editor.isActive({ textAlign: 'left' })
                ? 'bg-white shadow-sm text-blue-600'
                : 'hover:bg-white/60 text-gray-600'
            }`}
          >
            <AlignLeft className="w-4 h-4" />
          </button>
          <button
            onClick={() => editor.chain().focus().setTextAlign('center').run()}
            className={`p-1.5 rounded-md transition-colors ${
              editor.isActive({ textAlign: 'center' })
                ? 'bg-white shadow-sm text-blue-600'
                : 'hover:bg-white/60 text-gray-600'
            }`}
          >
            <AlignCenter className="w-4 h-4" />
          </button>
          <button
            onClick={() => editor.chain().focus().setTextAlign('right').run()}
            className={`p-1.5 rounded-md transition-colors ${
              editor.isActive({ textAlign: 'right' })
                ? 'bg-white shadow-sm text-blue-600'
                : 'hover:bg-white/60 text-gray-600'
            }`}
          >
            <AlignRight className="w-4 h-4" />
          </button>
        </div>

        <div className="w-px h-6 bg-gray-200" />

        {/* Color Picker */}
        <div className="relative">
          <button
            onClick={() => setShowColorPicker(!showColorPicker)}
            className={`p-1.5 rounded-md hover:bg-gray-100 text-gray-600`}
          >
            <Palette className="w-4 h-4" />
          </button>
          {showColorPicker && (
            <div 
              className="absolute left-0 top-full mt-1 p-2 rounded-lg bg-white shadow-lg border border-gray-200 z-10"
              onMouseLeave={() => setShowColorPicker(false)}
            >
              <div className="grid grid-cols-3 gap-1">
                {colorOptions.map((option) => (
                  <button
                    key={option.name}
                    onClick={() => {
                      editor.chain().focus().setColor(option.color).run();
                      setShowColorPicker(false);
                    }}
                    className="w-8 h-8 rounded-full border border-gray-200 hover:scale-110 transition-transform"
                    style={{ backgroundColor: option.color }}
                    title={option.name}
                  />
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Insert Options */}
        <button
          onClick={() => {
            editor.chain().focus().insertTable({
              rows: 3,
              cols: 3,
              withHeaderRow: true
            }).run();
          }}
          className="p-1.5 rounded-md hover:bg-gray-100 text-gray-600"
        >
          <TableIcon className="w-4 h-4" />
        </button>

        <button
          onClick={() => {
            const url = window.prompt('Enter image URL:');
            if (url) editor.chain().focus().setImage({ src: url }).run();
          }}
          className="p-1.5 rounded-md hover:bg-gray-100 text-gray-600"
        >
          <ImageIcon className="w-4 h-4" />
        </button>
      </div>
    </div>
  );
};