import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import PostList from './components/PostList/PostList';
import PostForm from './components/PostForm/PostForm';
import { useAuthPrompt } from '../Authorization/AuthPromptOverlay';
import PostsService from './PostsService';
import { categories } from './constants/categories';
import { 
  ChevronUp,
  AlertCircle,
  X
} from 'lucide-react';

const SortOptions = {
  LATEST: 'latest',
  TRENDING: 'trending',
  MOST_LIKED: 'most_liked',
  OLDEST: 'oldest'
};

const FilterOptions = {
  ALL: 'all',
  MY_POSTS: 'my_posts',
  LIKED: 'liked'
};

const Community = ({ isPreview = false }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const basePath = isPreview ? '/preview' : '/dashboard';
  
  // State Management
  const { currentUser, loading: authLoading } = useAuth();
  const { showAuthPrompt } = useAuthPrompt();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showPostForm, setShowPostForm] = useState(false);
  const [editingPost, setEditingPost] = useState(null);
  const [showScrollTop, setShowScrollTop] = useState(false);
  
  const unsubscribers = useRef([]);

  // Handle return URL after authentication
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const action = params.get('action');
    
    if (currentUser && action) {
      switch (action) {
        case 'create-post':
          setShowPostForm(true);
          break;
        case 'edit-post':
          const postId = params.get('postId');
          const post = posts.find(p => p.id === postId);
          if (post) {
            setEditingPost(post);
            setShowPostForm(true);
          }
          break;
        case 'like-post':
          const likePostId = params.get('postId');
          if (likePostId) {
            handleLikePost(likePostId);
          }
          break;
      }
      
      // Clean up the URL
      const newUrl = window.location.pathname;
      window.history.replaceState({}, '', newUrl);
    }
  }, [currentUser, posts, location.search]);

  // Scroll to top button visibility
  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 500);
    };
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Initial data fetch
  useEffect(() => {
    if (currentUser) {
      checkAdminStatus();
    }
    fetchPosts();

    return () => {
      unsubscribers.current.forEach(unsubscribe => {
        if (typeof unsubscribe === 'function') {
          unsubscribe();
        }
      });
      unsubscribers.current = [];
    };
  }, [currentUser]);

  const checkAdminStatus = async () => {
    if (!currentUser) return;
    try {
      const isUserAdmin = await PostsService.isUserAdmin(currentUser.uid);
      setIsAdmin(isUserAdmin);
    } catch (error) {
      console.error('Error checking admin status:', error);
    }
  };

  const fetchPosts = async () => {
    try {
      setLoading(true);
      setError(null);

      const { posts: fetchedPosts } = await PostsService.getPosts({
        sortBy: SortOptions.LATEST,
        filterBy: FilterOptions.ALL
      });

      // Only set up subscriptions if user is authenticated
      if (currentUser) {
        fetchedPosts.forEach(post => {
          const unsubscribe = PostsService.subscribeToPost(post.id, (updatedPost) => {
            setPosts(currentPosts => 
              currentPosts.map(p => p.id === updatedPost.id ? updatedPost : p)
            );
          });
          unsubscribers.current.push(unsubscribe);
        });
      }

      setPosts(fetchedPosts);
    } catch (error) {
      console.error('Error fetching posts:', error);
      setError('Failed to fetch posts');
    } finally {
      setLoading(false);
    }
  };

  const handleCreatePost = () => {
    if (!currentUser) {
      const returnUrl = encodeURIComponent(`${basePath}/community?action=create-post`);
      showAuthPrompt(
        'Post Creation',
        () => navigate(`/auth?mode=login&returnUrl=${returnUrl}`),
        () => navigate(`/auth?mode=register&returnUrl=${returnUrl}`),
        'Create an account to share your knowledge and connect with the community.'
      );
      return;
    }
    setEditingPost(null);
    setShowPostForm(true);
  };

  const handleEditPost = (post) => {
    if (!currentUser) {
      const returnUrl = encodeURIComponent(`${basePath}/community?action=edit-post&postId=${post.id}`);
      showAuthPrompt(
        'Post Editing',
        () => navigate(`/auth?mode=login&returnUrl=${returnUrl}`),
        () => navigate(`/auth?mode=register&returnUrl=${returnUrl}`),
        'Sign in to edit your posts and manage your content.'
      );
      return;
    }
    setEditingPost(post);
    setShowPostForm(true);
  };

  const handleDeletePost = async (postId) => {
    if (!currentUser) {
      const returnUrl = encodeURIComponent(`${basePath}/community`);
      showAuthPrompt(
        'Post Deletion',
        () => navigate(`/auth?mode=login&returnUrl=${returnUrl}`),
        () => navigate(`/auth?mode=register&returnUrl=${returnUrl}`),
        'Sign in to manage your posts.'
      );
      return;
    }
    
    if (!window.confirm('Are you sure you want to delete this post?')) return;
    
    try {
      await PostsService.deletePost(postId);
      await fetchPosts();
    } catch (error) {
      console.error('Error deleting post:', error);
      setError('Failed to delete post: ' + error.message);
    }
  };

  const handleLikePost = async (postId) => {
    if (!currentUser) {
      const returnUrl = encodeURIComponent(`${basePath}/community?action=like-post&postId=${postId}`);
      showAuthPrompt(
        'Post Interaction',
        () => navigate(`/auth?mode=login&returnUrl=${returnUrl}`),
        () => navigate(`/auth?mode=register&returnUrl=${returnUrl}`),
        'Create an account to like posts and interact with the community.'
      );
      return;
    }

    try {
      await PostsService.toggleLike(postId, currentUser.uid);
    } catch (error) {
      console.error('Error liking post:', error);
      setError('Failed to update like status: ' + error.message);
    }
  };

  const handleReportPost = async (postId) => {
    if (!currentUser) {
      const returnUrl = encodeURIComponent(`${basePath}/community?action=report-post&postId=${postId}`);
      showAuthPrompt(
        'Post Reporting',
        () => navigate(`/auth?mode=login&returnUrl=${returnUrl}`),
        () => navigate(`/auth?mode=register&returnUrl=${returnUrl}`),
        'Sign in to report inappropriate content and help maintain community standards.'
      );
      return;
    }

    try {
      const reason = prompt('Please provide a reason for reporting this post:');
      if (!reason) return;
      
      await PostsService.reportPost(postId, currentUser.uid, reason);
      alert('Post reported successfully');
    } catch (error) {
      console.error('Error reporting post:', error);
      setError('Failed to report post: ' + error.message);
    }
  };

  const handleSubmitPost = async (postData) => {
    if (!currentUser) {
      const returnUrl = encodeURIComponent(`${basePath}/community`);
      showAuthPrompt(
        'Post Submission',
        () => navigate(`/auth?mode=login&returnUrl=${returnUrl}`),
        () => navigate(`/auth?mode=register&returnUrl=${returnUrl}`),
        'Sign in to create and share posts with the community.'
      );
      return;
    }

    try {
      if (editingPost) {
        await PostsService.updatePost(editingPost.id, postData);
      } else {
        await PostsService.createPost(postData, currentUser.uid, currentUser.email);
      }
      setShowPostForm(false);
      setEditingPost(null);
      await fetchPosts();
    } catch (error) {
      console.error('Error saving post:', error);
      throw error;
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {error && (
          <div className="mb-8 bg-red-50 text-red-600 p-4 rounded-lg flex items-center justify-between">
            <div className="flex items-center gap-2">
              <AlertCircle className="w-5 h-5" />
              {error}
            </div>
            <button
              onClick={() => setError(null)}
              className="text-red-400 hover:text-red-600"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
        )}

        {loading ? (
          <div className="flex justify-center items-center min-h-[400px]">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500" />
          </div>
        ) : (
          <div className="space-y-6">
            <PostList
              posts={posts}
              categories={categories}
              currentUser={currentUser}
              isAdmin={isAdmin}
              onDelete={handleDeletePost}
              onLike={handleLikePost}
              onEdit={handleEditPost}
              onReport={handleReportPost}
              onCreatePost={handleCreatePost}
              isPreview={isPreview}
            />
          </div>
        )}
      </main>

      {showPostForm && (
        <div className="fixed inset-0 z-50 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4">
          <PostForm
            onClose={() => {
              setShowPostForm(false);
              setEditingPost(null);
            }}
            onSubmit={handleSubmitPost}
            initialData={editingPost}
            currentUser={currentUser}
          />
        </div>
      )}

      {showScrollTop && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-8 right-8 p-3 bg-white rounded-full shadow-lg hover:shadow-xl 
            border border-gray-200 text-gray-600 hover:text-gray-900 
            transition-all duration-300 z-40"
        >
          <ChevronUp className="w-6 h-6" />
        </button>
      )}
    </div>
  );
};

export default Community;