import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { auth } from '../../../../firebase';
import TimerSection from './Components/FastingComponents';
import FastingService from './Components/FastingService';
import DashboardHeader from '../../../user/StructureDashboard/DashboardHeader';

const Fasting = () => {
  const { userData } = useOutletContext();
  const [fasts, setFasts] = useState([]);
  const [activeFast, setActiveFast] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [fastingStats, setFastingStats] = useState(null);
  const [error, setError] = useState(null);
  const [lastUpdate, setLastUpdate] = useState(Date.now());

  const calculateTimes = () => {
    if (!activeFast) return { elapsedTime: 0, remainingTime: 0 };

    const now = new Date();
    const startTime = new Date(activeFast.startTime);
    const targetEnd = new Date(startTime.getTime() + (activeFast.targetHours * 60 * 60 * 1000));
    
    const elapsedMs = now - startTime;
    const remainingMs = targetEnd - now;

    return {
      elapsedTime: Math.max(0, elapsedMs),
      remainingTime: Math.max(0, remainingMs)
    };
  };

  const fetchFastingData = async () => {
    try {
      const userId = auth.currentUser?.uid;
      if (!userId) {
        setError('User not authenticated');
        return;
      }

      setIsLoading(true);
      setError(null);
      
      const active = await FastingService.getActiveFast(userId);
      setActiveFast(active);

      const history = await FastingService.getFastingHistory(userId);
      setFasts(history);

      const stats = await FastingService.getFastingStats(userId);
      setFastingStats(stats);
    } catch (error) {
      console.error('Error fetching fasting data:', error);
      setError(error.message || 'Failed to fetch fasting data');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchFastingData();
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    let intervalId;
    
    if (activeFast) {
      intervalId = setInterval(() => {
        setLastUpdate(Date.now());
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [activeFast]);

  const handleStartFast = async (hours) => {
    try {
      setIsLoading(true);
      setError(null);
      const userId = auth.currentUser?.uid;
      
      if (!userId) throw new Error('User not authenticated');

      const fastData = {
        targetHours: hours,
        userWeight: userData?.weight || 70,
        measurementSystem: userData?.measurementPreference || 'metric',
        type: 'standard'
      };
      
      const newFast = await FastingService.startFast(userId, fastData);
      setActiveFast(newFast);
      await fetchFastingData();
    } catch (error) {
      console.error('Error starting fast:', error);
      setError(error.message || 'Failed to start fast');
    } finally {
      setIsLoading(false);
    }
  };

  const handleStopFast = async () => {
    if (!activeFast) return;
    try {
      setIsLoading(true);
      setError(null);
      const userId = auth.currentUser?.uid;
      await FastingService.endFast(userId, activeFast.id);
      await fetchFastingData();
    } catch (error) {
      console.error('Error stopping fast:', error);
      setError(error.message || 'Failed to stop fast');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCustomDuration = async (duration) => {
    await handleStartFast(duration);
  };

  const handleDeleteFast = async (fastId) => {
    try {
      setError(null);
      const userId = auth.currentUser?.uid;
      await FastingService.deleteFast(userId, fastId);
      await fetchFastingData();
    } catch (error) {
      console.error('Error deleting fast:', error);
      setError(error.message || 'Failed to delete fast');
    }
  };

  const handleEditFast = async (fastId, updatedData) => {
    try {
      setError(null);
      const userId = auth.currentUser?.uid;
      await FastingService.updateFastTimes(userId, fastId, updatedData);
      await fetchFastingData();
    } catch (error) {
      console.error('Error editing fast:', error);
      setError(error.message || 'Failed to edit fast');
    }
  };

  const handleAdjustTime = async (dateTime, elapsedHours) => {
    if (!activeFast) return;
    try {
      setIsLoading(true);
      const userId = auth.currentUser?.uid;
      await FastingService.updateFastTimes(userId, activeFast.id, {
        startTime: dateTime
      });
      await fetchFastingData();
    } catch (error) {
      console.error('Error adjusting time:', error);
      setError(error.message || 'Failed to adjust time');
    } finally {
      setIsLoading(false);
    }
  };

  const { elapsedTime, remainingTime } = calculateTimes();

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

        {/* Error Message */}
        {error && (
          <div className="fixed top-0 left-0 right-0 z-50 m-4">
            <div className="bg-red-50 border-l-4 border-red-400 p-4 rounded-md shadow-lg">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                  </svg>
                </div>
                <div className="ml-3">
                  <p className="text-sm text-red-700">{error}</p>
                </div>
                <div className="ml-auto pl-3">
                  <button
                    onClick={() => setError(null)}
                    className="inline-flex text-red-400 focus:outline-none focus:text-red-500"
                  >
                    <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Main Content */}
        <div>
          <TimerSection
            activeFast={activeFast}
            elapsedTime={elapsedTime}
            targetHours={activeFast?.targetHours || 16}
            remainingTime={remainingTime}
            isLoading={isLoading}
            onStartFast={handleStartFast}
            onStopFast={handleStopFast}
            onCustomDuration={handleCustomDuration}
            onDeleteFast={handleDeleteFast}
            onEditFast={handleEditFast}
            onAdjustTime={handleAdjustTime}
            userWeight={userData?.weight || 70}
            measurementSystem={userData?.measurementPreference || 'metric'}
            fastingHistory={fasts}
            fastingStats={fastingStats}
            isMobileView={window.innerWidth < 1024}
          />
        </div>
      </div>
    </div>
  );
};

export default Fasting;