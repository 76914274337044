import React from 'react';

const WatchMyHealthLogo = ({ width = 56, height = 56, className = '' }) => {
  const uniqueId = React.useId();
  const eyeGradientId = `eyeGradient-${uniqueId}`;
  const irisGradientId = `irisGradient-${uniqueId}`;
  const networkGradientId = `networkGradient-${uniqueId}`;
  const glowId = `aiGlow-${uniqueId}`;
  const scanLineId = `scanLine-${uniqueId}`;
  const pulseGradientId = `pulseGradient-${uniqueId}`;

  return (
    <div className={`relative group ${className}`}>
      <svg 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 80 80"
        width={width}
        height={height}
        className="transform transition-transform duration-300 group-hover:scale-105"
      >
        <defs>
          <linearGradient id={eyeGradientId} x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" style={{ stopColor: '#60A5FA', stopOpacity: 1 }}>
              <animate 
                attributeName="stop-color" 
                values="#60A5FA; #3B82F6; #60A5FA" 
                dur="3s" 
                repeatCount="indefinite" 
              />
            </stop>
            <stop offset="100%" style={{ stopColor: '#3B82F6', stopOpacity: 1 }}>
              <animate 
                attributeName="stop-color" 
                values="#3B82F6; #2563EB; #3B82F6" 
                dur="3s" 
                repeatCount="indefinite" 
              />
            </stop>
          </linearGradient>
          
          <linearGradient id={irisGradientId} x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" style={{ stopColor: '#2563EB', stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: '#1D4ED8', stopOpacity: 1 }} />
          </linearGradient>
          
          <linearGradient id={networkGradientId} x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={{ stopColor: '#93C5FD', stopOpacity: 0.6 }} />
            <stop offset="50%" style={{ stopColor: '#3B82F6', stopOpacity: 0.8 }} />
            <stop offset="100%" style={{ stopColor: '#1D4ED8', stopOpacity: 0.6 }} />
          </linearGradient>
          
          <filter id={glowId} x="-50%" y="-50%" width="200%" height="200%">
            <feGaussianBlur stdDeviation="1.5" result="blur" />
            <feComposite in="SourceGraphic" in2="blur" operator="over" />
          </filter>
          
          <linearGradient id={scanLineId} x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" style={{ stopColor: 'white', stopOpacity: 0 }} />
            <stop offset="50%" style={{ stopColor: 'white', stopOpacity: 0.7 }} />
            <stop offset="100%" style={{ stopColor: 'white', stopOpacity: 0 }} />
          </linearGradient>

          <radialGradient id={pulseGradientId} cx="50%" cy="50%" r="50%">
            <stop offset="0%" style={{ stopColor: '#3B82F6', stopOpacity: 0.2 }} />
            <stop offset="100%" style={{ stopColor: '#3B82F6', stopOpacity: 0 }} />
          </radialGradient>
        </defs>

        {/* Pulse Animation Background */}
        <circle cx="40" cy="40" r="38" fill={`url(#${pulseGradientId})`} className="group-hover:animate-pulse" />

        {/* Main Eye Symbol */}
        <g transform="translate(40, 40)">
          <circle cx="0" cy="0" r="35" 
                  stroke="#BFDBFE" 
                  strokeWidth="0.5" 
                  fill="none" 
                  strokeDasharray="3 3" 
                  className="group-hover:animate-spin"
                  style={{ animationDuration: '8s' }} />
          
          <g transform="rotate(45)" className="group-hover:animate-pulse">
            <circle cx="38" cy="0" r="2" fill="#3B82F6" opacity="0.6" />
            <circle cx="-38" cy="0" r="2" fill="#3B82F6" opacity="0.6" />
          </g>
          <g transform="rotate(-45)" className="group-hover:animate-pulse">
            <circle cx="38" cy="0" r="2" fill="#3B82F6" opacity="0.6" />
            <circle cx="-38" cy="0" r="2" fill="#3B82F6" opacity="0.6" />
          </g>
          
          <path d="M-30 -10 Q-15 -25, 0 -20 Q15 -15, 30 -10" 
                stroke={`url(#${networkGradientId})`} 
                strokeWidth="0.5" 
                fill="none" 
                opacity="0.6" />
          <path d="M-30 10 Q-15 25, 0 20 Q15 15, 30 10" 
                stroke={`url(#${networkGradientId})`} 
                strokeWidth="0.5" 
                fill="none" 
                opacity="0.6" />
                
          <path d="M-30 0 Q-15 -30, 0 -30 Q15 -30, 30 0 Q15 30, 0 30 Q-15 30, -30 0" 
                fill={`url(#${eyeGradientId})`} 
                filter={`url(#${glowId})`} />
                
          <circle cx="0" cy="0" r="15" 
                  fill={`url(#${irisGradientId})`} />
                  
          <circle cx="0" cy="0" r="8" 
                  fill="#111827" />
                  
          <circle cx="0" cy="0" r="12" 
                  stroke="#60A5FA" 
                  strokeWidth="0.5" 
                  fill="none" 
                  strokeDasharray="4 4" 
                  className="group-hover:animate-spin"
                  style={{ animationDuration: '3s' }} />
                  
          <rect x="-30" y="-2" width="60" height="4" 
                fill={`url(#${scanLineId})`} 
                opacity="0.3">
            <animateTransform
              attributeName="transform"
              type="translate"
              from="0 -30"
              to="0 30"
              dur="2s"
              repeatCount="indefinite" />
          </rect>
          
          <path d="M-25 0 L-15 0 L-10 -15 L0 15 L10 -5 L15 0 L25 0" 
                stroke="#60A5FA" 
                strokeWidth="2" 
                fill="none" 
                opacity="0.8" 
                strokeLinecap="round" />
        </g>
      </svg>
    </div>
  );
};

export default WatchMyHealthLogo;