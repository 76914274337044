// src/components/user/Posts/components/PostView/components/ErrorView.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertTriangle } from 'lucide-react';

export const ErrorView = ({ error }) => {
  const navigate = useNavigate(); // Add useNavigate hook

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-gray-50 to-white p-4">
      <AlertTriangle className="w-16 h-16 text-red-500 mb-6" />
      <h2 className="text-2xl font-bold text-gray-900 mb-2">Oops!</h2>
      <p className="text-red-500 text-lg mb-6">{error || 'Post not found'}</p>
      <button
        onClick={() => navigate('/dashboard/community')}
        className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
      >
        Return to Community
      </button>
    </div>
  );
};