import React, { useState, useRef, useCallback } from 'react';
import { 
  Settings, Globe, X, Camera, Layout,
  ChevronDown, Search, Tag, Plus, AlertTriangle,
  Save
} from 'lucide-react';
import { useAuth } from '../../../../../contexts/AuthContext';
import useImageUpload from '../../../../../hooks/useImageUpload';
import RichTextEditor from './components/RichTextEditor/RichTextEditor';
import StatusBar from './components/StatusBar';
import ImageUploadModal from './components/ImageUploadModal';
import CloseConfirmDialog from './components/CloseConfirmDialog';
import SettingsSidebar from './components/SettingsSidebar';
import { EditorProvider } from './components/RichTextEditor/context/EditorContext';
import { CATEGORIES } from './Constants/postConfig';

const PostForm = ({ initialData = null, onSubmit, onClose }) => {
  const { currentUser } = useAuth();
  const editorRef = useRef(null);

  const { 
    uploadImage, 
    isUploading, 
    progress, 
    error: uploadError,
    resetUploadState 
  } = useImageUpload(currentUser?.uid);

  // Form State
  const [formData, setFormData] = useState({
    title: initialData?.title || '',
    subtitle: initialData?.subtitle || '',
    content: initialData?.content || '<p>Tell your story...</p>',
    category: initialData?.category || '',
    tags: initialData?.tags || [],
    coverImage: initialData?.coverImage ? {
      url: typeof initialData.coverImage === 'string' ? initialData.coverImage : initialData.coverImage.url,
      path: typeof initialData.coverImage === 'string' ? null : initialData.coverImage.path
    } : null
  });

  // UI State
  const [ui, setUi] = useState({
    showSettings: false,
    showImageUpload: false,
    showCloseConfirm: false,
    showCategoryDropdown: false,
    categorySearch: '',
    showTagInput: false,
    tagInput: '',
    unsavedChanges: false,
    isSubmitting: false,
    error: null,
    stats: { wordCount: 0, charCount: 0, readTime: '1 min read' }
  });

  // Handlers
  const handleFormUpdate = useCallback((field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value,
      updatedAt: new Date().toISOString()
    }));
    setUi(prev => ({ ...prev, unsavedChanges: true }));
  }, []);

  const handleContentChange = useCallback((newContent) => {
    handleFormUpdate('content', newContent);
  }, [handleFormUpdate]);

  const handleAddTag = useCallback((tag) => {
    if (tag && !formData.tags.includes(tag)) {
      handleFormUpdate('tags', [...formData.tags, tag]);
      setUi(prev => ({ ...prev, tagInput: '', showTagInput: false }));
    }
  }, [formData.tags, handleFormUpdate]);

  const handleRemoveTag = useCallback((tagToRemove) => {
    handleFormUpdate('tags', formData.tags.filter(tag => tag !== tagToRemove));
  }, [formData.tags, handleFormUpdate]);

  const handleCoverImageUpload = async (file) => {
    try {
      const uploadResult = await uploadImage(file);
      
      if (!uploadResult?.url) {
        throw new Error('Upload failed: No URL received');
      }

      handleFormUpdate('coverImage', {
        url: uploadResult.url,
        path: uploadResult.path || `posts/${currentUser.uid}/${file.name}`,
        metadata: uploadResult.metadata
      });
      
      setUi(prev => ({ ...prev, showImageUpload: false }));
    } catch (error) {
      console.error('Cover image upload error:', error);
      setUi(prev => ({ 
        ...prev, 
        error: error.message || 'Failed to upload image',
        showImageUpload: false 
      }));
    }
  };

  const handleContentImageUpload = async (file) => {
    try {
      const uploadResult = await uploadImage(file);
      return uploadResult?.url || null;
    } catch (error) {
      setUi(prev => ({ ...prev, error: error.message }));
      return null;
    }
  };

  const handleSave = async (status) => {
    if (ui.isSubmitting) return;

    try {
      setUi(prev => ({ ...prev, isSubmitting: true }));
      
      await onSubmit({
        ...formData,
        publishStatus: status,
        updatedAt: new Date().toISOString(),
        authorId: currentUser.uid,
        authorEmail: currentUser.email
      });

      setUi(prev => ({ 
        ...prev, 
        unsavedChanges: false, 
        isSubmitting: false 
      }));

      if (status === 'published') {
        onClose();
      }
    } catch (error) {
      setUi(prev => ({
        ...prev,
        isSubmitting: false,
        error: error.message
      }));
    }
  };

  const renderCoverImage = () => {
    const imageUrl = formData.coverImage?.url || formData.coverImage;
    
    if (imageUrl) {
      return (
        <div className="relative group h-64">
          <img
            src={imageUrl}
            alt="Cover"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-all flex items-center justify-center opacity-0 group-hover:opacity-100">
            <div className="flex gap-2">
              <button
                onClick={() => setUi(prev => ({ ...prev, showImageUpload: true }))}
                className="p-3 bg-white rounded-full hover:bg-slate-50"
              >
                <Camera className="w-5 h-5" />
              </button>
              <button
                onClick={() => handleFormUpdate('coverImage', null)}
                className="p-3 bg-white rounded-full hover:bg-slate-50"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>
      );
    }

    return (
      <button
        onClick={() => setUi(prev => ({ ...prev, showImageUpload: true }))}
        className="w-full h-64 flex flex-col items-center justify-center gap-4 bg-slate-50 hover:bg-slate-100 transition-colors"
      >
        <div className="p-4 bg-white rounded-full shadow-sm">
          <Camera className="w-8 h-8 text-slate-400" />
        </div>
        <p className="text-slate-500">Add cover image</p>
      </button>
    );
  };

  return (
    <div className="min-h-screen bg-slate-50">
      {/* Top Navigation */}
      <div className="fixed top-0 left-0 right-0 bg-white border-b border-slate-200 z-50">
        <div className="max-w-screen-xl mx-auto px-4">
          <div className="h-16 flex items-center justify-between">
            {/* Left Section */}
            <div className="flex items-center gap-4">
              <button
                onClick={() => ui.unsavedChanges ? 
                  setUi(prev => ({ ...prev, showCloseConfirm: true })) : 
                  onClose()
                }
                className="p-2 text-slate-600 hover:bg-slate-100 rounded-full"
              >
                <X className="w-5 h-5" />
              </button>
              {ui.unsavedChanges && (
                <span className="text-sm text-amber-600 bg-amber-50 px-3 py-1 rounded-full flex items-center gap-2">
                  <span className="w-1.5 h-1.5 bg-amber-500 rounded-full animate-pulse" />
                  Unsaved changes
                </span>
              )}
            </div>

            {/* Right Section */}
            <div className="flex items-center gap-3">
              {/* Category Selector */}
              <div className="relative">
                <button
                  onClick={() => setUi(prev => ({ 
                    ...prev, 
                    showCategoryDropdown: !prev.showCategoryDropdown 
                  }))}
                  className="flex items-center gap-2 px-4 py-2 text-slate-700 hover:bg-slate-100 rounded-full transition-colors"
                >
                  <Layout className="w-4 h-4" />
                  {formData.category || 'Select Category'}
                  <ChevronDown className="w-4 h-4" />
                </button>

                {ui.showCategoryDropdown && (
                  <div className="absolute right-0 mt-2 w-64 bg-white rounded-lg shadow-xl border border-slate-200 py-2">
                    <div className="px-3 pb-2">
                      <div className="relative">
                        <Search className="w-4 h-4 absolute left-3 top-2.5 text-slate-400" />
                        <input
                          type="text"
                          value={ui.categorySearch}
                          onChange={(e) => setUi(prev => ({ 
                            ...prev, 
                            categorySearch: e.target.value 
                          }))}
                          placeholder="Search categories..."
                          className="w-full pl-9 pr-3 py-2 text-sm border border-slate-200 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                      </div>
                    </div>
                    <div className="max-h-64 overflow-y-auto">
                      {CATEGORIES
                        .filter(cat => cat.toLowerCase().includes(ui.categorySearch.toLowerCase()))
                        .map(category => (
                          <button
                            key={category}
                            onClick={() => {
                              handleFormUpdate('category', category);
                              setUi(prev => ({ 
                                ...prev, 
                                showCategoryDropdown: false 
                              }));
                            }}
                            className="w-full px-4 py-2 text-left text-sm text-slate-700 hover:bg-slate-50 flex items-center gap-2"
                          >
                            {category === formData.category && (
                              <span className="w-1.5 h-1.5 bg-blue-500 rounded-full" />
                            )}
                            {category}
                          </button>
                        ))
                      }
                    </div>
                  </div>
                )}
              </div>

              <button
                onClick={() => handleSave('draft')}
                disabled={ui.isSubmitting}
                className="px-4 py-2 text-slate-600 hover:text-slate-900 flex items-center gap-2"
              >
                <Save className="w-4 h-4" />
                Save draft
              </button>

              <button
                onClick={() => handleSave('published')}
                disabled={ui.isSubmitting}
                className="px-6 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-full flex items-center gap-2"
              >
                <Globe className="w-4 h-4" />
                {ui.isSubmitting ? 'Publishing...' : 'Publish'}
              </button>

              <button
                onClick={() => setUi(prev => ({ 
                  ...prev, 
                  showSettings: !prev.showSettings 
                }))}
                className="p-2 text-slate-400 hover:bg-slate-100 rounded-full"
              >
                <Settings className="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <main className="max-w-4xl mx-auto pt-24 px-4 pb-24">
        <div className="bg-white rounded-xl shadow-sm overflow-hidden">
          {/* Cover Image Section */}
          {renderCoverImage()}

          <div className="p-8">
            {/* Title Input */}
            <input
              type="text"
              value={formData.title}
              onChange={(e) => handleFormUpdate('title', e.target.value)}
              placeholder="Write your title..."
              className="w-full text-4xl font-serif font-bold mb-4 border-none bg-transparent focus:outline-none focus:ring-0 placeholder-slate-300"
            />

            {/* Subtitle Input */}
            <input
              type="text"
              value={formData.subtitle}
              onChange={(e) => handleFormUpdate('subtitle', e.target.value)}
              placeholder="Add a subtitle that describes your story..."
              className="w-full text-xl text-slate-600 mb-8 border-none bg-transparent focus:outline-none focus:ring-0 placeholder-slate-300"
            />

            {/* Tags Section */}
            <div className="mb-8 flex flex-wrap items-center gap-2">
              {formData.tags.map(tag => (
                <span
                  key={tag}
                  className="px-3 py-1 bg-slate-100 rounded-full text-sm flex items-center gap-2 group"
                >
                  <Tag className="w-3 h-3 text-slate-500" />
                  {tag}
                  <button
                    onClick={() => handleRemoveTag(tag)}
                    className="text-slate-400 hover:text-slate-600 opacity-0 group-hover:opacity-100 transition-opacity"
                  >
                    <X className="w-3 h-3" />
                  </button>
                </span>
              ))}
              {ui.showTagInput ? (
                <input
                  type="text"
                  value={ui.tagInput}
                  onChange={(e) => setUi(prev => ({ ...prev, tagInput: e.target.value }))}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleAddTag(ui.tagInput);
                    }
                  }}
                  onBlur={() => {
                    handleAddTag(ui.tagInput);
                    setUi(prev => ({ ...prev, showTagInput: false }));
                  }}
                  placeholder="Add tag..."
                  autoFocus
                  className="px-3 py-1 text-sm bg-transparent border-none focus:outline-none focus:ring-0"
                />
              ) : (
                <button
                  onClick={() => setUi(prev => ({ ...prev, showTagInput: true }))}
                  className="px-3 py-1 text-sm text-slate-500 hover:bg-slate-100 rounded-full flex items-center gap-2"
                >
                  <Plus className="w-3 h-3" />
                  Add tag
                </button>
              )}
            </div>

            {/* Rich Text Editor */}
            <EditorProvider>
              <RichTextEditor
                ref={editorRef}
                content={formData.content}
                onChange={handleContentChange}
                onImageUpload={handleContentImageUpload}
                placeholder="Tell your story..."
              />
            </EditorProvider>
          </div>
        </div>
      </main>

      {/* Status Bar */}
      <StatusBar {...ui.stats} />

      {/* Settings Sidebar */}
      <SettingsSidebar
        show={ui.showSettings}
        onClose={() => setUi(prev => ({ ...prev, showSettings: false }))}
        formData={formData}
        onUpdateFormData={handleFormUpdate}
        lastSaved={formData.lastSaved}
      />

      {/* Modals */}
      <ImageUploadModal
        show={ui.showImageUpload}
        onClose={() => {
          setUi(prev => ({ ...prev, showImageUpload: false }));
          resetUploadState();
        }}
        onUpload={handleCoverImageUpload}
        isUploading={isUploading}
        progress={progress}
        error={uploadError}
      />

      <CloseConfirmDialog
        show={ui.showCloseConfirm}
        onClose={() => setUi(prev => ({ ...prev, showCloseConfirm: false }))}
        onConfirm={() => {
          setUi(prev => ({ ...prev, showCloseConfirm: false }));
          onClose();
        }}
      />

      {/* Error Toast */}
      {ui.error && (
        <div className="fixed bottom-4 right-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-lg flex items-center gap-2 z-50">
          <AlertTriangle className="w-4 h-4" />
          <span>{ui.error}</span>
          <button
            onClick={() => setUi(prev => ({ ...prev, error: null }))}
            className="p-1 hover:bg-red-200 rounded-full"
          >
            <X className="w-4 h-4" />
          </button>
        </div>
      )}

      {/* Upload Progress Toast */}
      {isUploading && (
        <div className="fixed bottom-4 left-4 bg-white rounded-lg shadow-lg border border-slate-200 p-4 z-50">
          <div className="flex items-center gap-3">
            <Camera className="w-5 h-5 text-blue-500 animate-pulse" />
            <div className="flex-1 min-w-[200px]">
              <div className="h-2 bg-slate-100 rounded-full overflow-hidden">
                <div 
                  className="h-full bg-blue-500 transition-all duration-300"
                  style={{ width: `${progress}%` }}
                />
              </div>
            </div>
            <span className="text-sm text-slate-600">
              {Math.round(progress)}%
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default PostForm;