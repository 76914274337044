import { 
    collection,
    doc,
    getDoc,
    getDocs,
    addDoc,
    updateDoc,
    deleteDoc,
    query,
    where,
    orderBy,
    Timestamp,
    serverTimestamp 
  } from 'firebase/firestore';
  import { db } from '../../../../../firebase';
  
  class FastingService {
    constructor() {
      // Update the collection path structure
      this.getCollectionRef = (userId) => collection(db, 'users', userId, 'fasts');
      this.getDocRef = (userId, fastId) => doc(db, 'users', userId, 'fasts', fastId);
    }
  
    transformFast = (doc) => {
      const data = doc.data();
      return {
        id: doc.id,
        startTime: data.startTime.toDate().toISOString(),
        endTime: data.endTime?.toDate().toISOString() || null,
        targetHours: data.targetHours,
        userWeight: data.userWeight,
        measurementSystem: data.measurementSystem,
        status: data.status,
        type: data.type || 'standard', // Added to match rules
        createdAt: data.createdAt?.toDate().toISOString(),
        updatedAt: data.updatedAt?.toDate().toISOString(),
      };
    };
  
    getActiveFast = async (userId) => {
      try {
        const fastsRef = this.getCollectionRef(userId);
        const q = query(
          fastsRef,
          where('status', '==', 'active'),
          orderBy('startTime', 'desc')
        );
  
        const snapshot = await getDocs(q);
        if (snapshot.empty) return null;
  
        return this.transformFast(snapshot.docs[0]);
      } catch (error) {
        console.error('Error getting active fast:', error);
        throw new Error('Failed to get active fast');
      }
    };
  
    getFastingHistory = async (userId) => {
      try {
        const fastsRef = this.getCollectionRef(userId);
        const q = query(
          fastsRef,
          where('status', '==', 'completed'),
          orderBy('startTime', 'desc')
        );
  
        const snapshot = await getDocs(q);
        return snapshot.docs.map(this.transformFast);
      } catch (error) {
        console.error('Error getting fasting history:', error);
        throw new Error('Failed to get fasting history');
      }
    };
  
    startFast = async (userId, fastData) => {
      try {
        const activeFast = await this.getActiveFast(userId);
        if (activeFast) {
          throw new Error('You already have an active fast');
        }
  
        const fastsRef = this.getCollectionRef(userId);
        const newFast = {
          startTime: Timestamp.now(),
          targetHours: fastData.targetHours,
          userWeight: fastData.userWeight,
          measurementSystem: fastData.measurementSystem,
          status: 'active',
          type: fastData.type || 'standard', // Added to match rules
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        };
  
        const docRef = await addDoc(fastsRef, newFast);
        const docSnap = await getDoc(docRef);
        
        return this.transformFast(docSnap);
      } catch (error) {
        console.error('Error starting fast:', error);
        throw new Error(error.message || 'Failed to start fast');
      }
    };
  
    endFast = async (userId, fastId) => {
      try {
        const fastRef = this.getDocRef(userId, fastId);
        const fastDoc = await getDoc(fastRef);
  
        if (!fastDoc.exists()) {
          throw new Error('Fast not found');
        }
  
        await updateDoc(fastRef, {
          endTime: Timestamp.now(),
          status: 'completed',
          updatedAt: serverTimestamp(),
        });
      } catch (error) {
        console.error('Error ending fast:', error);
        throw new Error('Failed to end fast');
      }
    };
  
    updateFastTimes = async (userId, fastId, timeData) => {
      try {
        const fastRef = this.getDocRef(userId, fastId);
        const fastDoc = await getDoc(fastRef);
  
        if (!fastDoc.exists()) {
          throw new Error('Fast not found');
        }
  
        const updates = {
          updatedAt: serverTimestamp(),
        };
  
        if (timeData.startTime) {
          updates.startTime = Timestamp.fromDate(new Date(timeData.startTime));
        }
  
        if (timeData.endTime) {
          updates.endTime = Timestamp.fromDate(new Date(timeData.endTime));
          updates.status = 'completed';
        }
  
        await updateDoc(fastRef, updates);
  
        const updatedDoc = await getDoc(fastRef);
        return this.transformFast(updatedDoc);
      } catch (error) {
        console.error('Error updating fast times:', error);
        throw new Error('Failed to update fast times');
      }
    };
  
    deleteFast = async (userId, fastId) => {
      try {
        const fastRef = this.getDocRef(userId, fastId);
        const fastDoc = await getDoc(fastRef);
  
        if (!fastDoc.exists()) {
          throw new Error('Fast not found');
        }
  
        await deleteDoc(fastRef);
      } catch (error) {
        console.error('Error deleting fast:', error);
        throw new Error('Failed to delete fast');
      }
    };
  
    getFastingStats = async (userId) => {
      try {
        const fasts = await this.getFastingHistory(userId);
        
        const stats = fasts.reduce((acc, fast) => {
          const duration = (new Date(fast.endTime) - new Date(fast.startTime)) / (1000 * 60 * 60);
          const isSuccessful = duration >= fast.targetHours;
  
          return {
            totalFasts: acc.totalFasts + 1,
            successfulFasts: acc.successfulFasts + (isSuccessful ? 1 : 0),
            totalHours: acc.totalHours + duration,
            longestFast: Math.max(acc.longestFast, duration),
          };
        }, {
          totalFasts: 0,
          successfulFasts: 0,
          totalHours: 0,
          longestFast: 0,
        });
  
        return {
          ...stats,
          averageHours: stats.totalFasts ? stats.totalHours / stats.totalFasts : 0,
          successRate: stats.totalFasts ? (stats.successfulFasts / stats.totalFasts) * 100 : 0,
        };
      } catch (error) {
        console.error('Error getting fasting stats:', error);
        throw new Error('Failed to get fasting stats');
      }
    };
  }
  
  export default new FastingService();