// File: src/components/community/posts/PostView/components/Toast.jsx
export const Toast = ({ message, type = 'info' }) => (
    <div className={`fixed bottom-20 left-1/2 transform -translate-x-1/2 px-6 py-3 rounded-lg 
      shadow-lg z-50 transition-all duration-300 ${
        type === 'error' ? 'bg-red-500' : 
        type === 'success' ? 'bg-green-500' : 
        type === 'warning' ? 'bg-yellow-500' : 
        'bg-gray-800'
      } text-white`}
    >
      {message}
    </div>
  );
  