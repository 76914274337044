// src/components/comments/components/Comment.js
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { 
  MoreVertical, 
  ThumbsUp, 
  ThumbsDown, 
  Reply, 
  Flag,
  Trash2,
  Edit2,
  ChevronDown,
  ChevronRight
} from 'lucide-react';
import { MAX_NESTING_DEPTH } from '../constants';

const Comment = ({
  comment,
  currentUser,
  isAuthor,
  onAction,
  isEditing,
  setEditingComment,
  depth = 0,
  showReplies = false,
  onToggleReplies,
  parentId = null
}) => {
  const isOwnComment = currentUser?.uid === comment.authorId;
  const canModerate = isOwnComment || isAuthor;
  const hasReplies = comment.replies && comment.replies.length > 0;
  const canReply = depth < MAX_NESTING_DEPTH;

  // Format the date safely
  const formatDate = (dateString) => {
    try {
      if (!dateString) return 'Some time ago';
      
      const date = typeof dateString === 'string' ? parseISO(dateString) : new Date(dateString);
      
      if (isNaN(date.getTime())) {
        return 'Invalid date';
      }
      
      return formatDistanceToNow(date, { addSuffix: true });
    } catch (error) {
      console.error('Date formatting error:', error);
      return 'Some time ago';
    }
  };

  const handleAction = (action, data = null) => {
    if (onAction) {
      onAction(action, comment.id, { ...data, parentId });
    }
  };

  return (
    <div className={`relative ${depth > 0 ? 'ml-4 md:ml-8' : ''}`}>
      {depth > 0 && (
        <div 
          className="absolute left-0 top-0 bottom-0 w-px bg-gray-200"
          style={{ left: '-1rem' }}
        />
      )}

      <div className={`bg-white rounded-lg p-4 border border-gray-200 
        ${depth > 0 ? 'border-l-2 border-l-blue-500' : ''}`}>
        <div className="flex items-start justify-between gap-4">
          <div className="flex items-center gap-3">
            <div className="w-10 h-10 rounded-full bg-gradient-to-br from-blue-400 to-blue-600 
              flex items-center justify-center text-white font-medium">
              {comment.authorEmail?.[0]?.toUpperCase() || '?'}
            </div>
            <div>
              <div className="font-medium">
                {comment.authorEmail?.split('@')[0] || 'Anonymous'}
              </div>
              <div className="text-sm text-gray-500">
                {formatDate(comment.createdAt)}
              </div>
            </div>
          </div>

          {canModerate && (
            <div className="flex items-center gap-2">
              <button
                onClick={() => setEditingComment?.(isEditing ? null : comment)}
                className="p-1 text-gray-400 hover:text-gray-600 rounded"
              >
                <Edit2 className="w-4 h-4" />
              </button>
              <button
                onClick={() => handleAction('delete')}
                className="p-1 text-gray-400 hover:text-red-600 rounded"
              >
                <Trash2 className="w-4 h-4" />
              </button>
            </div>
          )}
        </div>

        <div className="mt-3 text-gray-700 whitespace-pre-wrap">
          {comment.text}
        </div>

        <div className="mt-4 flex items-center gap-4">
          <button
            onClick={() => handleAction('like')}
            className={`flex items-center gap-1 ${
              comment.likedBy?.includes(currentUser?.uid)
                ? 'text-blue-600'
                : 'text-gray-500 hover:text-blue-600'
            }`}
          >
            <ThumbsUp className="w-4 h-4" />
            <span className="text-sm">{comment.likes || 0}</span>
          </button>

          <button
            onClick={() => handleAction('dislike')}
            className={`flex items-center gap-1 ${
              comment.dislikedBy?.includes(currentUser?.uid)
                ? 'text-red-600'
                : 'text-gray-500 hover:text-red-600'
            }`}
          >
            <ThumbsDown className="w-4 h-4" />
            <span className="text-sm">{comment.dislikes || 0}</span>
          </button>

          {canReply && (
            <button
              onClick={() => handleAction('reply')}
              className="flex items-center gap-1 text-gray-500 hover:text-gray-700"
            >
              <Reply className="w-4 h-4" />
              <span className="text-sm">Reply</span>
            </button>
          )}

          {!isOwnComment && (
            <button
              onClick={() => handleAction('report')}
              className="flex items-center gap-1 text-gray-500 hover:text-yellow-600"
            >
              <Flag className="w-4 h-4" />
              <span className="text-sm">Report</span>
            </button>
          )}
        </div>

        {hasReplies && (
          <div className="mt-4">
            <button
              onClick={() => onToggleReplies?.(comment.id)}
              className="flex items-center gap-2 text-sm text-gray-500 hover:text-gray-700"
            >
              {showReplies ? (
                <ChevronDown className="w-4 h-4" />
              ) : (
                <ChevronRight className="w-4 h-4" />
              )}
              {comment.replies.length} {comment.replies.length === 1 ? 'reply' : 'replies'}
            </button>
          </div>
        )}
      </div>

      {/* Nested replies */}
      {hasReplies && showReplies && (
        <div className="mt-4 space-y-4">
          {comment.replies.map((reply) => (
            <Comment
              key={reply.id}
              comment={reply}
              currentUser={currentUser}
              isAuthor={isAuthor}
              onAction={onAction}
              isEditing={isEditing}
              setEditingComment={setEditingComment}
              depth={depth + 1}
              showReplies={showReplies}
              onToggleReplies={onToggleReplies}
              parentId={comment.id}
            />
          ))}
        </div>
      )}
    </div>
  );
};

Comment.propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    authorId: PropTypes.string,
    authorEmail: PropTypes.string,
    createdAt: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.number
    ]),
    likes: PropTypes.number,
    dislikes: PropTypes.number,
    likedBy: PropTypes.arrayOf(PropTypes.string),
    dislikedBy: PropTypes.arrayOf(PropTypes.string),
    replies: PropTypes.array,
    parentId: PropTypes.string
  }).isRequired,
  currentUser: PropTypes.object,
  isAuthor: PropTypes.bool,
  onAction: PropTypes.func,
  isEditing: PropTypes.bool,
  setEditingComment: PropTypes.func,
  depth: PropTypes.number,
  showReplies: PropTypes.bool,
  onToggleReplies: PropTypes.func,
  parentId: PropTypes.string
};

export default memo(Comment);