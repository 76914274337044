// src/components/user/Posts/comments/components/CommentHeader.js
import React from 'react';
import { MessageCircle } from 'lucide-react';
import { FILTER_OPTIONS, SORT_OPTIONS } from '../constants';

const CommentHeader = ({
  commentCount,
  commentFilter,
  setCommentFilter,
  sortBy,
  setSortBy
}) => (
  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-8">
    <h2 className="text-2xl font-bold flex items-center gap-2">
      <MessageCircle className="w-6 h-6" />
      Comments ({commentCount})
    </h2>

    <div className="flex flex-wrap items-center gap-4">
      <select
        value={commentFilter}
        onChange={(e) => setCommentFilter(e.target.value)}
        className="px-4 py-2 bg-white border border-gray-200 rounded-lg text-sm
          focus:ring-2 focus:ring-blue-500 focus:border-transparent cursor-pointer
          transition-colors hover:bg-gray-50"
        aria-label="Filter comments"
      >
        {FILTER_OPTIONS.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>

      <select
        value={sortBy}
        onChange={(e) => setSortBy(e.target.value)}
        className="px-4 py-2 bg-white border border-gray-200 rounded-lg text-sm
          focus:ring-2 focus:ring-blue-500 focus:border-transparent cursor-pointer
          transition-colors hover:bg-gray-50"
        aria-label="Sort comments"
      >
        {SORT_OPTIONS.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  </div>
);

export default CommentHeader;