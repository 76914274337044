// src/components/user/Posts/comments/components/NotificationToast.js
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const NotificationToast = ({ message, show }) => (
  <AnimatePresence>
    {show && (
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        className="fixed bottom-8 left-1/2 transform -translate-x-1/2 px-6 py-3 
          bg-gray-800 text-white rounded-lg shadow-lg z-50"
      >
        {message}
      </motion.div>
    )}
  </AnimatePresence>
);

export default NotificationToast;