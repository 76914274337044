import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useLocation, Outlet, Link } from 'react-router-dom';
import { 
  Scale, 
  Timer,
  Sparkles,
  MessageSquare,
  Menu,
  X,
  LogIn,
  UserPlus,
  Heart,
  AlertCircle,
  ArrowRight,
  Users,
  Activity
} from 'lucide-react';
import { useAuthPrompt } from '../Authorization/AuthPromptOverlay';
import WatchMyHealthLogo from '../../ui/WatchMyHealthLogo';

// Utility function for class names
const cn = (...classes) => classes.filter(Boolean).join(' ');

// Basic Alert Components
const Alert = React.forwardRef(({ className, variant = 'default', children, ...props }, ref) => {
  return (
    <div
      ref={ref}
      role="alert"
      className={cn(
        'relative w-full rounded-lg border p-4',
        variant === 'default' && 'bg-white text-slate-950 border-slate-200',
        variant === 'destructive' && 'border-red-500/50 text-red-600 bg-red-50',
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
});

const AlertDescription = React.forwardRef(({ className, children, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('text-sm [&_p]:leading-relaxed', className)}
    {...props}
  >
    {children}
  </div>
));

// Basic ScrollArea Component
const ScrollArea = React.forwardRef(({ className, children, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn('relative overflow-auto', className)}
      {...props}
    >
      {children}
    </div>
  );
});

const NonRegisteredDashboardLayout = () => {
  // State Management
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [error, setError] = useState(null);
  const [loadingSection, setLoadingSection] = useState(null);

  // Hooks
  const navigate = useNavigate();
  const location = useLocation();
  const { showAuthPrompt } = useAuthPrompt();

  // Navigation Configuration
  const navigationItems = useMemo(() => [
    {
      id: 'community',
      path: '/preview/community',
      label: 'Community',
      icon: MessageSquare,
      description: 'Join the discussion',
      requiresAuth: false,
      primary: true
    },
    {
      id: 'weight',
      path: '/preview/weight',
      label: 'Weight Analytics',
      icon: Scale,
      description: 'Track your progress',
      requiresAuth: true,
      authMessage: 'Weight tracking requires basic information about you to provide accurate analytics and personalized recommendations.',
      features: ['Custom goal setting', 'Progress visualization', 'Smart insights']
    },
    {
      id: 'fasting',
      path: '/preview/fasting',
      label: 'Fasting Timer',
      icon: Timer,
      description: 'Manage fasting periods',
      requiresAuth: true,
      authMessage: 'Fasting tracking requires information about your health goals and current metrics to provide safe recommendations.',
      features: ['Multiple fasting protocols', 'Real-time tracking', 'Health insights']
    },
    {
      id: 'health',
      path: '/preview/recommendations',
      label: 'Health Insights',
      icon: Sparkles,
      description: 'Personalized recommendations',
      requiresAuth: true,
      authMessage: 'Personalized health insights require information about your health profile to provide relevant recommendations.',
      features: ['AI-powered analysis', 'Custom health plans', 'Progress tracking']
    }
  ], []);

  // Feature highlights
  const featureHighlights = useMemo(() => [
    {
      icon: Heart,
      title: 'Track Your Progress',
      description: 'Monitor your health journey with detailed analytics and insights.',
      color: 'text-rose-500'
    },
    {
      icon: Users,
      title: 'Community Support',
      description: 'Connect with others on similar health journeys.',
      color: 'text-blue-500'
    },
    {
      icon: Activity,
      title: 'Smart Recommendations',
      description: 'Get personalized health insights based on your data.',
      color: 'text-emerald-500'
    }
  ], []);

  // URL pattern helpers
  const isCommunityPath = useCallback((path) => {
    return path.includes('/preview/community');
  }, []);

  // Scroll handler
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Navigation handlers
  const handleNavigation = useCallback(async (item) => {
    try {
      setLoadingSection(item.id);
      
      if (isCommunityPath(item.path)) {
        await navigate(item.path);
      } else if (item.requiresAuth) {
        const returnUrl = encodeURIComponent(item.path);
        const loginCallback = () => navigate(`/auth?mode=login&returnUrl=${returnUrl}`);
        const registerCallback = () => navigate(`/auth?mode=register&returnUrl=${returnUrl}`);
        
        showAuthPrompt(
          item.label,
          loginCallback,
          registerCallback,
          item.authMessage || 'Create an account to access premium features and unlock your full health potential.'
        );
      } else {
        await navigate(item.path);
      }
    } catch (err) {
      setError('Navigation failed. Please try again.');
      console.error('Navigation error:', err);
    } finally {
      setLoadingSection(null);
      setIsMobileMenuOpen(false);
    }
  }, [navigate, showAuthPrompt, isCommunityPath]);
  
  // Usage in community post creation:
  const handleCreatePost = useCallback(() => {
    const returnUrl = encodeURIComponent('/community/new-post');
    const loginCallback = () => navigate(`/auth?mode=login&returnUrl=${returnUrl}`);
    const registerCallback = () => navigate(`/auth?mode=register&returnUrl=${returnUrl}`);
    
    showAuthPrompt(
      'Post Creation',
      loginCallback,
      registerCallback,
      'Join our community to share your knowledge and experiences with others on their health journey.'
    );
  }, [navigate, showAuthPrompt]);
  

  const handleAuth = useCallback((mode) => {
    navigate(`/auth?mode=${mode}`);
    setIsMobileMenuOpen(false);
  }, [navigate]);

  // Current section detection
  const getCurrentSection = useCallback(() => {
    const path = location.pathname;
    if (isCommunityPath(path)) return 'community';
    if (path.includes('/preview/weight')) return 'weight';
    if (path.includes('/preview/fasting')) return 'fasting';
    if (path.includes('/preview/recommendations')) return 'health';
    return 'community';
  }, [location.pathname, isCommunityPath]);

  const currentSection = getCurrentSection();

  // Render helpers
  const renderNavigationItem = useCallback(({ item, isActive, isMobile = false }) => {
    const ItemIcon = item.icon;
    
    return (
      <button
        key={item.id}
        onClick={() => handleNavigation(item)}
        disabled={loadingSection === item.id}
        className={cn(
          'w-full p-3 flex items-center gap-3 rounded-xl transition-all duration-300',
          isActive 
            ? 'bg-gradient-to-r from-blue-50 to-indigo-50 text-blue-700'
            : 'hover:bg-slate-50 text-slate-600',
          loadingSection === item.id && 'opacity-70 cursor-wait'
        )}
        aria-current={isActive ? 'page' : undefined}
      >
        <ItemIcon className={cn(
          'w-5 h-5',
          isActive ? 'text-blue-500' : 'text-slate-400'
        )} />
        <div className="flex flex-col items-start">
          <span className="font-medium text-sm">{item.label}</span>
          <span className="text-xs text-slate-500">{item.description}</span>
        </div>
        {item.requiresAuth && (
          <span className="ml-auto text-xs font-medium text-blue-500 flex items-center gap-1">
            Register
            <ArrowRight className="w-3 h-3" />
          </span>
        )}
        {isActive && !isMobile && (
          <div className="absolute left-0 w-1 h-8 bg-blue-500 rounded-r-full" />
        )}
      </button>
    );
  }, [handleNavigation, loadingSection]);

  const renderAuthButtons = useCallback((isMobile = false) => (
    <div className={cn(
      'space-y-2',
      isMobile ? 'p-4 border-t border-slate-200/50' : 'p-4'
    )}>
      <button
        onClick={() => handleAuth('register')}
        className="w-full p-3 rounded-xl bg-gradient-to-r from-blue-600 to-indigo-600 text-white font-medium hover:from-blue-700 hover:to-indigo-700 transition-colors duration-200"
      >
        <div className="flex items-center justify-center gap-2">
          <UserPlus className="w-5 h-5" />
          <span>Register Now</span>
        </div>
      </button>
      <button
        onClick={() => handleAuth('login')}
        className="w-full p-3 rounded-xl border border-slate-200 text-slate-700 font-medium hover:bg-slate-50 transition-colors duration-200"
      >
        <div className="flex items-center justify-center gap-2">
          <LogIn className="w-5 h-5" />
          <span>Sign In</span>
        </div>
      </button>
    </div>
  ), [handleAuth]);

  return (
    <div className="min-h-screen bg-slate-100">
      {/* Fixed Banner */}
      <div className={cn(
        'fixed top-0 left-0 w-full bg-gradient-to-r from-blue-600 to-indigo-600 text-white py-2 text-center z-50',
        isScrolled && 'shadow-md'
      )}>
        <p className="text-sm">
          You're in preview mode. 
          <button 
            onClick={() => handleAuth('register')}
            className="ml-2 font-medium underline hover:text-blue-200"
          >
            Register now
          </button>
          {" "}to unlock all features!
        </p>
      </div>

      {/* Error Alert */}
      {error && (
        <Alert variant="destructive" className="fixed top-12 left-1/2 transform -translate-x-1/2 z-50 w-96">
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>{error}</AlertDescription>
          <button 
            onClick={() => setError(null)}
            className="absolute right-2 top-2 p-1 rounded-full hover:bg-red-100"
          >
            <X className="w-4 h-4" />
          </button>
        </Alert>
      )}

      {/* Side Navigation - Desktop */}
      <div className="fixed inset-y-0 left-0 w-64 bg-white border-r border-slate-200/50 shadow-sm z-40 hidden lg:block pt-10">
        <div className="flex flex-col h-full">
          {/* Logo Section */}
          <div className="p-6">
            <Link 
              to="/" 
              className="flex items-center gap-3 group"
            >
              <WatchMyHealthLogo 
                width={40} 
                height={40} 
                className="transition-transform duration-300 group-hover:scale-105" 
              />
              <div>
                <h1 className="text-lg font-semibold bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent">
                  WatchMy.Health
                </h1>
                <span className="text-xs text-slate-500">Preview Mode</span>
              </div>
            </Link>
          </div>

          {/* Navigation Items */}
          <ScrollArea className="flex-1 px-3 space-y-1">
            {navigationItems.map((item) => renderNavigationItem({
              item,
              isActive: currentSection === item.id
            }))}
          </ScrollArea>

          {/* Auth Buttons */}
          {renderAuthButtons()}
        </div>
      </div>

      {/* Mobile Menu Button */}
      <button
        onClick={() => setIsMobileMenuOpen(true)}
        className="fixed top-14 left-4 p-2 rounded-lg bg-white shadow-sm border border-slate-200/50 lg:hidden z-40"
      >
        <Menu className="w-6 h-6 text-slate-600" />
      </button>

      {/* Mobile Navigation Menu */}
      {isMobileMenuOpen && (
        <div className="lg:hidden fixed inset-0 z-50">
          <div 
            className="fixed inset-0 bg-slate-900/50 backdrop-blur-sm"
            onClick={() => setIsMobileMenuOpen(false)}
          />
          <div className="fixed inset-y-0 left-0 w-full max-w-sm bg-white shadow-2xl">
            <div className="flex flex-col h-full pt-10">
              <div className="p-4 flex items-center justify-between border-b border-slate-200/50">
                <div className="flex items-center gap-3">
                  <WatchMyHealthLogo width={32} height={32} />
                  <h1 className="text-lg font-semibold">WatchMy.Health</h1>
                </div>
                <button
                  onClick={() => setIsMobileMenuOpen(false)}
                  className="p-2 rounded-lg hover:bg-slate-100 text-slate-600"
                >
                  <X className="w-6 h-6" />
                </button>
              </div>

              <ScrollArea className="flex-1 p-4">
                <div className="space-y-2">
                  {navigationItems.map((item) => renderNavigationItem({
                    item,
                    isActive: currentSection === item.id,
                    isMobile: true
                  }))}
                </div>
              </ScrollArea>

              {/* Mobile Auth Buttons */}
              {renderAuthButtons(true)}
            </div>
          </div>
        </div>
      )}

      {/* Main Content Area */}
      <main className="lg:pl-64 pt-20">
        <div className="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8">
          <div className="bg-white rounded-2xl shadow-sm border border-slate-200/50 min-h-[calc(100vh-8rem)]">
            <Outlet context={{ 
              isPreview: true,
              onAuthPrompt: showAuthPrompt,
              requiredFeatures: navigationItems.reduce((acc, item) => ({
                ...acc,
                [item.id]: item.requiresAuth
              }), {})
            }} />
          </div>

          {/* Feature Highlights */}
          <div className="mt-8 grid grid-cols-1 md:grid-cols-3 gap-6">
            {featureHighlights.map((feature, index) => (
              <div 
                key={index}
                className="bg-white p-6 rounded-xl shadow-sm border border-slate-200/50"
              >
                <feature.icon className={cn('w-8 h-8 mb-4', feature.color)} />
                <h3 className="text-lg font-semibold mb-2">{feature.title}</h3>
                <p className="text-slate-600 text-sm">{feature.description}</p>
              </div>
            ))}
          </div>

          {/* Feature Preview Section */}
          <div className="mt-12 bg-white rounded-2xl shadow-sm border border-slate-200/50 p-8">
            <h2 className="text-2xl font-semibold mb-6">Explore Premium Features</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {navigationItems
                .filter(item => item.requiresAuth && item.features)
                .map((item, index) => (
                  <div 
                    key={index}
                    className="relative group"
                  >
                    <div className="p-6 rounded-xl border border-slate-200 bg-gradient-to-br from-slate-50 to-white">
                      <item.icon className="w-8 h-8 text-blue-500 mb-4" />
                      <h3 className="text-lg font-semibold mb-3">{item.label}</h3>
                      <ul className="space-y-2">
                        {item.features.map((feature, featureIndex) => (
                          <li 
                            key={featureIndex}
                            className="flex items-center text-sm text-slate-600"
                          >
                            <ArrowRight className="w-4 h-4 text-blue-500 mr-2" />
                            {feature}
                          </li>
                        ))}
                      </ul>
                      <button
                        onClick={() => handleNavigation(item)}
                        className="mt-4 w-full p-2 rounded-lg bg-blue-50 text-blue-600 font-medium hover:bg-blue-100 transition-colors"
                      >
                        Try Now
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default NonRegisteredDashboardLayout;