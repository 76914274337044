// src/App.js
import React from 'react';
import { createBrowserRouter, RouterProvider, createRoutesFromElements, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { RecommendationsProvider } from './contexts/RecommendationsContext';
import { AuthPromptProvider } from './components/user/Authorization/AuthPromptOverlay';
import UserRoutes from './routes/UserRoutes'; // Changed this line
import AdminRoutes from './routes/AdminRoutes';
import AuthPage from './components/user/Authorization/AuthPage';
import PrivacyPolicy from './components/Legal/PrivacyPolicy';
import TermsOfService from './components/Legal/TermsOfService';

// Shared Components
export const LoadingSpinner = () => (
  <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-50 to-purple-50">
    <div className="text-center">
      <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
      <p className="mt-4 text-gray-600">Loading BioGuard...</p>
    </div>
  </div>
);

export const NotFound = () => (
  <div className="min-h-screen flex items-center justify-center bg-gray-50">
    <div className="text-center p-8">
      <h2 className="text-2xl font-bold text-gray-800 mb-4">Page Not Found</h2>
      <p className="text-gray-600 mb-4">The page you're looking for doesn't exist.</p>
      <button
        onClick={() => window.history.back()}
        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
      >
        Go Back
      </button>
    </div>
  </div>
);

// Create router with routes
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      {/* Public Routes */}
      <Route path="/auth" element={<AuthPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />
      
      {/* Admin Routes */}
      <Route path="/admin/*" element={<AdminRoutes />} />
      
      {/* Dashboard Routes - using UserRoutes directly */}
      <Route path="/*" element={<UserRoutes />} />
      
      {/* 404 Route */}
      <Route path="*" element={<NotFound />} />
    </Route>
  ),
  {
    future: {
      v7_startTransition: true,
      v7_relativeSplatPath: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_skipActionErrorRevalidation: true
    }
  }
);

// Root component that wraps RouterProvider with other providers
const Root = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <AuthPromptProvider>
        <RouterProvider router={router} />
      </AuthPromptProvider>
    </div>
  );
};

// App component that provides outer context
const App = () => {
  return (
    <AuthProvider>
      <RecommendationsProvider>
        <Root />
      </RecommendationsProvider>
    </AuthProvider>
  );
};

export default App;