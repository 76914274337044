// File: src/components/community/posts/PostView/components/ShareMenu.jsx
import { Twitter, Facebook, Linkedin, Link as LinkIcon } from 'lucide-react';

export const ShareMenu = ({ onShare, onClose }) => (
  <div className="fixed inset-0 z-50" onClick={onClose}>
    <div 
      className="absolute right-4 top-20 w-48 bg-white rounded-lg shadow-lg border border-gray-200 py-2"
      onClick={e => e.stopPropagation()}
    >
      <button
        onClick={() => onShare('twitter')}
        className="w-full px-4 py-2 text-left text-sm hover:bg-gray-50 flex items-center gap-3"
      >
        <Twitter className="w-4 h-4 text-[#1DA1F2]" />
        Share on Twitter
      </button>
      <button
        onClick={() => onShare('linkedin')}
        className="w-full px-4 py-2 text-left text-sm hover:bg-gray-50 flex items-center gap-3"
      >
        <Linkedin className="w-4 h-4 text-[#0A66C2]" />
        Share on LinkedIn
      </button>
      <button
        onClick={() => onShare('facebook')}
        className="w-full px-4 py-2 text-left text-sm hover:bg-gray-50 flex items-center gap-3"
      >
        <Facebook className="w-4 h-4 text-[#1877F2]" />
        Share on Facebook
      </button>
      <hr className="my-1 border-gray-200" />
      <button
        onClick={() => onShare('copy')}
        className="w-full px-4 py-2 text-left text-sm hover:bg-gray-50 flex items-center gap-3"
      >
        <LinkIcon className="w-4 h-4" />
        Copy link
      </button>
    </div>
  </div>
);