import DOMPurify from 'dompurify';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { app } from '../../../../../firebase';

export const processContent = (content) => {
  if (!content) return '';

  try {
    // Sanitize HTML
    let processedContent = DOMPurify.sanitize(content);

    // Remove the placeholder text
    processedContent = processedContent.replace(/<p>Tell your story...(.*?)<\/p>/, '');

    // Remove empty paragraphs
    processedContent = processedContent.replace(/<p>\s*<\/p>/g, '');

    // Convert newlines to proper paragraphs
    processedContent = processedContent
      .split(/\n\s*\n/)
      .map(para => para.trim())
      .filter(para => para.length > 0)
      .map(para => `<p>${para}</p>`)
      .join('\n');

    // Add proper styling to paragraphs
    processedContent = processedContent.replace(
      /<p>/g,
      '<p class="mb-4 text-gray-700 leading-relaxed">'
    );

    return processedContent;
  } catch (error) {
    console.error('Error processing content:', error);
    return content;
  }
};

export const extractPreviewText = (content, maxLength = 150) => {
  if (!content) return '';

  // Remove HTML tags and get plain text
  const plainText = content.replace(/<[^>]*>/g, '');
  
  // Remove placeholder text
  const cleanText = plainText.replace(/Tell your story.../, '').trim();

  // Truncate to maxLength
  if (cleanText.length <= maxLength) return cleanText;
  
  // Find the last complete word within maxLength
  const truncated = cleanText.substr(0, maxLength);
  const lastSpace = truncated.lastIndexOf(' ');
  
  return truncated.substr(0, lastSpace) + '...';
};

export const processImageUrl = async (imageData) => {
  try {
    if (!imageData) return null;

    // If imageData is an object with url property
    if (typeof imageData === 'object' && imageData.url) {
      if (imageData.url.startsWith('http')) {
        return imageData.url;
      }
    }

    // If imageData is already a URL string
    if (typeof imageData === 'string' && imageData.startsWith('http')) {
      return imageData;
    }

    // If it's a Firebase storage path, process it
    const path = typeof imageData === 'string' ? imageData : 
                 (imageData.path || (typeof imageData === 'object' && imageData.url) || null);
                 
    if (path) {
      const storage = getStorage(app);
      const imageRef = ref(storage, path);
      try {
        const url = await getDownloadURL(imageRef);
        return url;
      } catch (error) {
        console.error('Error getting download URL:', error);
        return null;
      }
    }

    return null;
  } catch (error) {
    console.error('Error processing image URL:', error);
    return null;
  }
};