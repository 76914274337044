import React, { useCallback } from 'react';
import { BubbleMenu, FloatingMenu } from '@tiptap/react';
import {
  Bold, Italic, LinkIcon, Highlighter,
  Heading1, Heading2, List, ListOrdered, CheckSquare,
  TableIcon, ImageIcon, Code, Plus, X, Quote
} from 'lucide-react';

export const FloatingFormatBar = ({ editor }) => {
  const handleLinkClick = useCallback(() => {
    if (!editor) return;

    const previousUrl = editor.getAttributes('link').href;
    const url = window.prompt('Enter link URL:', previousUrl);
    
    if (url === null) return;
    
    if (url === '') {
      editor.chain().focus().unsetLink().run();
      return;
    }
    
    editor.chain().focus().setLink({ href: url }).run();
  }, [editor]);

  if (!editor) {
    return null;
  }

  return (
    <BubbleMenu
      editor={editor}
      tippyOptions={{ 
        duration: 100,
        placement: 'top',
        offset: [0, 10],
        appendTo: document.body
      }}
      className="flex items-center gap-1 px-1.5 py-1 bg-gray-800 rounded-lg shadow-lg border border-gray-700"
    >
      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={`p-1.5 rounded-md transition-colors ${
          editor.isActive('bold')
            ? 'bg-gray-700 text-white'
            : 'text-gray-300 hover:bg-gray-700/50 hover:text-white'
        }`}
      >
        <Bold className="w-3.5 h-3.5" />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className={`p-1.5 rounded-md transition-colors ${
          editor.isActive('italic')
            ? 'bg-gray-700 text-white'
            : 'text-gray-300 hover:bg-gray-700/50 hover:text-white'
        }`}
      >
        <Italic className="w-3.5 h-3.5" />
      </button>
      <button
        onClick={handleLinkClick}
        className={`p-1.5 rounded-md transition-colors ${
          editor.isActive('link')
            ? 'bg-gray-700 text-white'
            : 'text-gray-300 hover:bg-gray-700/50 hover:text-white'
        }`}
      >
        <LinkIcon className="w-3.5 h-3.5" />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHighlight().run()}
        className={`p-1.5 rounded-md transition-colors ${
          editor.isActive('highlight')
            ? 'bg-gray-700 text-white'
            : 'text-gray-300 hover:bg-gray-700/50 hover:text-white'
        }`}
      >
        <Highlighter className="w-3.5 h-3.5" />
      </button>
    </BubbleMenu>
  );
};

export const InsertMenu = ({ editor }) => {
  if (!editor) {
    return null;
  }

  return (
    <FloatingMenu
      editor={editor}
      tippyOptions={{ 
        duration: 100,
        placement: 'left',
        offset: [0, 10],
        appendTo: document.body
      }}
      className="bg-white rounded-lg shadow-lg border border-gray-200 py-1 overflow-hidden w-48"
    >
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        className="flex items-center gap-2 w-full px-3 py-1.5 text-sm text-gray-700 hover:bg-gray-50"
      >
        <Heading1 className="w-4 h-4 text-gray-500" />
        <span>Heading 1</span>
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        className="flex items-center gap-2 w-full px-3 py-1.5 text-sm text-gray-700 hover:bg-gray-50"
      >
        <Heading2 className="w-4 h-4 text-gray-500" />
        <span>Heading 2</span>
      </button>
      <div className="w-full h-px bg-gray-200 my-1" />
      <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className="flex items-center gap-2 w-full px-3 py-1.5 text-sm text-gray-700 hover:bg-gray-50"
      >
        <List className="w-4 h-4 text-gray-500" />
        <span>Bullet List</span>
      </button>
      <button
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className="flex items-center gap-2 w-full px-3 py-1.5 text-sm text-gray-700 hover:bg-gray-50"
      >
        <ListOrdered className="w-4 h-4 text-gray-500" />
        <span>Numbered List</span>
      </button>
      <button
        onClick={() => editor.chain().focus().toggleTaskList().run()}
        className="flex items-center gap-2 w-full px-3 py-1.5 text-sm text-gray-700 hover:bg-gray-50"
      >
        <CheckSquare className="w-4 h-4 text-gray-500" />
        <span>Task List</span>
      </button>
      <div className="w-full h-px bg-gray-200 my-1" />
      <button
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        className="flex items-center gap-2 w-full px-3 py-1.5 text-sm text-gray-700 hover:bg-gray-50"
      >
        <Quote className="w-4 h-4 text-gray-500" />
        <span>Quote</span>
      </button>
      <button
        onClick={() => {
          editor.chain().focus().insertTable({
            rows: 3,
            cols: 3,
            withHeaderRow: true
          }).run();
        }}
        className="flex items-center gap-2 w-full px-3 py-1.5 text-sm text-gray-700 hover:bg-gray-50"
      >
        <TableIcon className="w-4 h-4 text-gray-500" />
        <span>Insert Table</span>
      </button>
      <button
        onClick={() => {
          const url = window.prompt('Enter image URL:');
          if (url) editor.chain().focus().setImage({ src: url }).run();
        }}
        className="flex items-center gap-2 w-full px-3 py-1.5 text-sm text-gray-700 hover:bg-gray-50"
      >
        <ImageIcon className="w-4 h-4 text-gray-500" />
        <span>Insert Image</span>
      </button>
      <button
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        className="flex items-center gap-2 w-full px-3 py-1.5 text-sm text-gray-700 hover:bg-gray-50"
      >
        <Code className="w-4 h-4 text-gray-500" />
        <span>Code Block</span>
      </button>
    </FloatingMenu>
  );
};

export const TableMenu = ({ editor }) => {
  if (!editor || !editor.isActive('table')) {
    return null;
  }

  const buttons = [
    {
      onClick: () => editor.chain().focus().addColumnBefore().run(),
      label: 'Add Column Before',
      icon: Plus,
    },
    {
      onClick: () => editor.chain().focus().addColumnAfter().run(),
      label: 'Add Column After',
      icon: Plus,
    },
    {
      onClick: () => editor.chain().focus().deleteColumn().run(),
      label: 'Delete Column',
      icon: X,
      variant: 'danger',
    },
    { type: 'separator' },
    {
      onClick: () => editor.chain().focus().addRowBefore().run(),
      label: 'Add Row Before',
      icon: Plus,
    },
    {
      onClick: () => editor.chain().focus().addRowAfter().run(),
      label: 'Add Row After',
      icon: Plus,
    },
    {
      onClick: () => editor.chain().focus().deleteRow().run(),
      label: 'Delete Row',
      icon: X,
      variant: 'danger',
    },
    { type: 'separator' },
    {
      onClick: () => editor.chain().focus().deleteTable().run(),
      label: 'Delete Table',
      icon: X,
      variant: 'danger',
    },
  ];

  return (
    <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 flex items-center gap-1 p-1.5 bg-white rounded-lg shadow-lg border border-gray-200">
      {buttons.map((button, index) => 
        button.type === 'separator' ? (
          <div key={index} className="w-px h-5 bg-gray-200 mx-1" />
        ) : (
          <button
            key={index}
            onClick={button.onClick}
            className={`
              px-3 py-1.5 rounded text-sm transition-colors flex items-center gap-1.5
              ${button.variant === 'danger'
                ? 'text-red-600 hover:bg-red-50'
                : 'text-gray-700 hover:bg-gray-50'
              }
            `}
          >
            {button.icon && <button.icon className="w-3.5 h-3.5" />}
            {button.label}
          </button>
        )
      )}
    </div>
  );
};