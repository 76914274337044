// src/components/user/GoalsDashboard/Fasting/Components/CurrentFastProgress/calculations.js
export const getActivityMultiplier = (activityLevel) => {
    const multiplierMap = {
      'sedentary': 1.2,
      'lightly_active': 1.375,
      'moderately_active': 1.55,
      'very_active': 1.725,
      'extremely_active': 1.9
    };
    return multiplierMap[activityLevel] || 1.2;
  };
  
  export const calculateBMR = (
    userWeight,
    userHeight,
    userAge,
    userGender,
    measurementSystem
  ) => {
    const weightInKg = measurementSystem === 'imperial' ? userWeight * 0.453592 : userWeight;
    const heightInCm = measurementSystem === 'imperial' ? userHeight * 2.54 : userHeight;
    
    let bmr;
    if (userGender === 'male') {
      bmr = (10 * weightInKg) + (6.25 * heightInCm) - (5 * userAge) + 5;
    } else {
      bmr = (10 * weightInKg) + (6.25 * heightInCm) - (5 * userAge) - 161;
    }
    
    return bmr;
  };
  
  export const calculateCaloriesBurned = (
    elapsedTime,
    userWeight,
    userHeight,
    userAge,
    userGender,
    userActivityLevel,
    measurementSystem
  ) => {
    const bmr = calculateBMR(userWeight, userHeight, userAge, userGender, measurementSystem);
    const activityMultiplier = getActivityMultiplier(userActivityLevel);
    const tdee = bmr * activityMultiplier;
    const hourlyBurn = tdee / 24;
    const fastHours = elapsedTime / (60 * 60 * 1000);
    
    // Updated calorie calculation based on research
    const calculatePhasedBurn = () => {
      let totalBurn = 0;
      
      // First 12 hours: Normal TDEE-based burn with slight reduction
      const initialHours = Math.min(fastHours, 12);
      totalBurn += hourlyBurn * 0.95 * initialHours; // Slight metabolic slowdown
      
      if (fastHours > 12) {
        // 12-18 hours: Early fasting adaptation
        const adaptationHours = Math.min(fastHours - 12, 6);
        totalBurn += (hourlyBurn * 0.93) * adaptationHours; // Metabolic adaptation
        
        if (fastHours > 18) {
          // 18-24 hours: Early ketosis phase
          const earlyKetosisHours = Math.min(fastHours - 18, 6);
          totalBurn += (hourlyBurn * 0.90) * earlyKetosisHours; // Further adaptation
          
          if (fastHours > 24) {
            // Beyond 24 hours: Established ketosis
            const ketosisHours = fastHours - 24;
            // Research shows ~10% reduction in metabolic rate during extended fasting
            totalBurn += (hourlyBurn * 0.90) * ketosisHours;
          }
        }
      }
      
      return totalBurn;
    };
  
    return Math.round(calculatePhasedBurn());
  };
  
  // Utility function to format durations
  export const formatDuration = (ms) => {
    const hours = Math.floor(ms / (60 * 60 * 1000));
    const minutes = Math.floor((ms % (60 * 60 * 1000)) / (60 * 1000));
    return {
      hours,
      minutes,
      formatted: `${hours}h ${minutes}m`
    };
  };