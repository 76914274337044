import React, { useState, useEffect } from 'react';
import { 
  collection, 
  getDocs, 
  addDoc, 
  updateDoc, 
  deleteDoc, 
  doc 
} from 'firebase/firestore';
import { db } from '../../firebase';
import {
  Clipboard,
  Plus,
  Edit2,
  Trash2,
  AlertCircle,
  X,
  Save,
  Search,
  Target,
  ShieldAlert
} from 'lucide-react';

const RecommendationForm = () => {
  // State declarations
  const [riskFactors, setRiskFactors] = useState([]);
  const [healthGoals, setHealthGoals] = useState([]);
  const [recommendations, setRecommendations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  
  const [formData, setFormData] = useState({
    gender: '',
    minAge: '18',
    maxAge: '99',
    selectedRiskFactors: [],
    selectedHealthGoals: [],
    frequencyNumber: '',
    frequencyPeriod: 'day',
    actionType: '',
    actionDetails: '',
    instruction: '',
    explanation: ''
  });

  // Data loading effect
  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      setLoading(true);
      const [riskFactorsSnapshot, healthGoalsSnapshot, recommendationsSnapshot] = await Promise.all([
        getDocs(collection(db, 'riskFactors')),
        getDocs(collection(db, 'healthGoals')),
        getDocs(collection(db, 'recommendations'))
      ]);
      
      setRiskFactors(riskFactorsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })));
      
      setHealthGoals(healthGoalsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })));

      setRecommendations(recommendationsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate()
      })));
    } catch (err) {
      setError('Failed to load data');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
// Form handling functions
const handleRiskFactorToggle = (factorId) => {
    setFormData(prev => ({
      ...prev,
      selectedRiskFactors: prev.selectedRiskFactors.includes(factorId)
        ? prev.selectedRiskFactors.filter(id => id !== factorId)
        : [...prev.selectedRiskFactors, factorId]
    }));
  };

  const handleHealthGoalToggle = (goalId) => {
    setFormData(prev => ({
      ...prev,
      selectedHealthGoals: prev.selectedHealthGoals.includes(goalId)
        ? prev.selectedHealthGoals.filter(id => id !== goalId)
        : [...prev.selectedHealthGoals, goalId]
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const frequency = `${formData.frequencyNumber} ${formData.frequencyPeriod}`;
      const recommendationData = {
        ...formData,
        frequency,
        updatedAt: new Date()
      };

      if (editMode && formData.id) {
        await updateDoc(doc(db, 'recommendations', formData.id), recommendationData);
      } else {
        await addDoc(collection(db, 'recommendations'), {
          ...recommendationData,
          createdAt: new Date()
        });
      }

      clearForm();
      await loadData();
    } catch (err) {
      setError(`Failed to ${editMode ? 'update' : 'save'} recommendation`);
      console.error(err);
    }
  };

  const handleEdit = (recommendation) => {
    setFormData({
      ...recommendation,
      frequencyNumber: recommendation.frequency.split(' ')[0],
      frequencyPeriod: recommendation.frequency.split(' ')[1],
    });
    setEditMode(true);
    setShowForm(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this recommendation?')) {
      try {
        await deleteDoc(doc(db, 'recommendations', id));
        await loadData();
      } catch (err) {
        setError('Failed to delete recommendation');
        console.error(err);
      }
    }
  };

  const clearForm = () => {
    setFormData({
      gender: '',
      minAge: '18',
      maxAge: '99',
      selectedRiskFactors: [],
      selectedHealthGoals: [],
      frequencyNumber: '',
      frequencyPeriod: 'day',
      actionType: '',
      actionDetails: '',
      instruction: '',
      explanation: ''
    });
    setEditMode(false);
    setShowForm(false);
    setError('');
  };

  // Filter and search functions
  const filteredRecommendations = recommendations.filter(rec => {
    const searchString = searchTerm.toLowerCase();
    return (
      rec.actionDetails?.toLowerCase().includes(searchString) ||
      rec.instruction?.toLowerCase().includes(searchString) ||
      rec.explanation?.toLowerCase().includes(searchString) ||
      rec.actionType.toLowerCase().includes(searchString)
    );
  });

  // Loading state
  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
          <p className="mt-4 text-gray-600">Loading recommendations...</p>
        </div>
      </div>
    );
  }
  return (
    <div className="space-y-6">
      {/* Header Section */}
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-2xl font-bold text-gray-800 flex items-center gap-2">
            <Clipboard className="w-6 h-6 text-blue-500" />
            Recommendations
          </h2>
          <p className="text-gray-600 mt-1">Manage health recommendations and guidelines for users</p>
        </div>
        <button
          onClick={() => setShowForm(true)}
          className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-all"
        >
          <Plus className="w-4 h-4" />
          Add Recommendation
        </button>
      </div>

      {/* Error Alert */}
      {error && (
        <div className="flex items-center gap-2 bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-lg">
          <AlertCircle className="w-5 h-5" />
          {error}
        </div>
      )}

      {/* Search Bar */}
      <div className="relative">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
        <input
          type="text"
          placeholder="Search recommendations..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      {/* Recommendations List */}
      <div className="grid grid-cols-1 gap-4">
        {filteredRecommendations.map((recommendation) => (
          <div
            key={recommendation.id}
            className="bg-white rounded-lg border border-gray-200 hover:border-blue-200 transition-all p-6"
          >
            <div className="flex justify-between items-start">
              <div className="space-y-3 flex-grow">
                <div className="flex items-center gap-2">
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                    {recommendation.actionType.replace('_', ' ').replace(/\b\w/g, l => l.toUpperCase())}
                  </span>
                  <span className="text-sm text-gray-500">
                    {recommendation.gender.charAt(0).toUpperCase() + recommendation.gender.slice(1)} |
                    Ages {recommendation.minAge}-{recommendation.maxAge} |
                    Every {recommendation.frequency}
                  </span>
                </div>
                
                <div>
                  <h3 className="text-lg font-semibold text-gray-900">{recommendation.actionDetails}</h3>
                  <p className="text-gray-600 mt-1">{recommendation.instruction}</p>
                  {recommendation.explanation && (
                    <p className="text-sm text-gray-500 mt-2">{recommendation.explanation}</p>
                  )}
                </div>

                {recommendation.selectedRiskFactors?.length > 0 && (
                  <div className="flex items-center gap-2">
                    <ShieldAlert className="w-4 h-4 text-blue-500" />
                    <div className="flex flex-wrap gap-1">
                      {recommendation.selectedRiskFactors.map(factorId => {
                        const factor = riskFactors.find(f => f.id === factorId);
                        return factor ? (
                          <span key={factorId} className="text-xs bg-gray-100 text-gray-600 px-2 py-1 rounded">
                            {factor.name}
                          </span>
                        ) : null;
                      })}
                    </div>
                  </div>
                )}

                {recommendation.selectedHealthGoals?.length > 0 && (
                  <div className="flex items-center gap-2">
                    <Target className="w-4 h-4 text-blue-500" />
                    <div className="flex flex-wrap gap-1">
                      {recommendation.selectedHealthGoals.map(goalId => {
                        const goal = healthGoals.find(g => g.id === goalId);
                        return goal ? (
                          <span key={goalId} className="text-xs bg-gray-100 text-gray-600 px-2 py-1 rounded">
                            {goal.name}
                          </span>
                        ) : null;
                      })}
                    </div>
                  </div>
                )}

                {recommendation.createdAt && (
                  <p className="text-sm text-gray-400">
                    Created: {recommendation.createdAt.toLocaleDateString()}
                  </p>
                )}
              </div>
              
              <div className="flex items-center gap-2 ml-4">
                <button
                  onClick={() => handleEdit(recommendation)}
                  className="p-2 text-blue-600 hover:bg-blue-50 rounded-full transition-all"
                >
                  <Edit2 className="w-4 h-4" />
                </button>
                <button
                  onClick={() => handleDelete(recommendation.id)}
                  className="p-2 text-red-600 hover:bg-red-50 rounded-full transition-all"
                >
                  <Trash2 className="w-4 h-4" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Form Modal */}
      {showForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-xl shadow-xl max-w-4xl w-full max-h-[90vh] overflow-y-auto">
            <div className="p-6">
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-xl font-bold text-gray-800">
                  {editMode ? 'Edit Recommendation' : 'Add Recommendation'}
                </h3>
                <button
                  onClick={clearForm}
                  className="text-gray-400 hover:text-gray-600"
                >
                  <X className="w-5 h-5" />
                </button>
              </div>

              <form onSubmit={handleSubmit} className="space-y-6">
                {/* Basic Information */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Gender</label>
                    <select
                      value={formData.gender}
                      onChange={(e) => setFormData({...formData, gender: e.target.value})}
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                      required
                    >
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="all">All</option>
                    </select>
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">Min Age</label>
                      <input
                        type="number"
                        value={formData.minAge}
                        onChange={(e) => setFormData({...formData, minAge: e.target.value})}
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        min="0"
                        max="120"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">Max Age</label>
                      <input
                        type="number"
                        value={formData.maxAge}
                        onChange={(e) => setFormData({...formData, maxAge: e.target.value})}
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        min="0"
                        max="120"
                        required
                      />
                    </div>
                  </div>
                </div>

                {/* Risk Factors and Health Goals */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      <div className="flex items-center gap-2">
                        <ShieldAlert className="w-4 h-4 text-blue-500" />
                        Risk Factors
                      </div>
                    </label>
                    <div className="space-y-2 max-h-48 overflow-y-auto p-4 bg-gray-50 rounded-lg">
                      {riskFactors.map(factor => (
                        <label key={factor.id} className="flex items-center space-x-3 p-2 hover:bg-white rounded">
                          <input
                            type="checkbox"
                            checked={formData.selectedRiskFactors.includes(factor.id)}
                            onChange={() => handleRiskFactorToggle(factor.id)}
                            className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                          />
                          <span className="text-sm">{factor.name}</span>
                        </label>
                      ))}
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      <div className="flex items-center gap-2">
                        <Target className="w-4 h-4 text-blue-500" />
                        Health Goals
                      </div>
                    </label>
                    <div className="space-y-2 max-h-48 overflow-y-auto p-4 bg-gray-50 rounded-lg">
                      {healthGoals.map(goal => (
                        <label key={goal.id} className="flex items-center space-x-3 p-2 hover:bg-white rounded">
                          <input
                            type="checkbox"
                            checked={formData.selectedHealthGoals.includes(goal.id)}
                            onChange={() => handleHealthGoalToggle(goal.id)}
                            className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                          />
                          <span className="text-sm">{goal.name}</span>
                        </label>
                      ))}
                    </div>
                  </div>
                </div>

                {/* Frequency and Action Type */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Frequency</label>
                    <input
                      type="number"
                      value={formData.frequencyNumber}
                      onChange={(e) => setFormData({...formData, frequencyNumber: e.target.value})}
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                      min="1"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Period</label>
                    <select
                      value={formData.frequencyPeriod}
                      onChange={(e) => setFormData({...formData, frequencyPeriod: e.target.value})}
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                      required
                    >
                      <option value="day">Day</option>
                      <option value="week">Week</option>
                      <option value="month">Month</option>
                      <option value="year">Year</option>
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Action Type</label>
                    <select
                      value={formData.actionType}
                      onChange={(e) => setFormData({...formData, actionType: e.target.value})}
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                      required
                    >
                      <option value="">Select Type</option>
                      <option value="physician_visit">Physician Visit</option>
                      <option value="lab_test">Lab Test</option>
                      <option value="imaging">Imaging</option>
                      <option value="vaccination">Vaccination</option>
                      <option value="supplement">Supplement</option>
                    </select>
                  </div>
                </div>

                {/* Details and Instructions */}
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Action Details</label>
                    <textarea
                      value={formData.actionDetails}
                      onChange={(e) => setFormData({...formData, actionDetails: e.target.value})}
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                      rows="2"
                      required
                      placeholder="Specify the exact procedure, test, or action required..."
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Instructions</label>
                    <textarea
                      value={formData.instruction}
                      onChange={(e) => setFormData({...formData, instruction: e.target.value})}
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                      rows="3"
                      required
                      placeholder="Enter detailed instructions for the recommendation..."
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Explanation</label>
                    <textarea
                      value={formData.explanation}
                      onChange={(e) => setFormData({...formData, explanation: e.target.value})}
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                      rows="3"
                      required
                      placeholder="Enter explanation of why this recommendation is important..."
                    />
                  </div>
                </div>

                {/* Form Actions */}
                <div className="flex justify-end gap-3 pt-4">
                  <button
                    type="button"
                    onClick={clearForm}
                    className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-all"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-all"
                  >
                    <Save className="w-4 h-4" />
                    {editMode ? 'Update' : 'Save'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RecommendationForm;


