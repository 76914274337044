// src/components/user/Posts/components/PostView/components/DesktopSidebar.js
import React from 'react';
import { Heart, Share2, Bookmark, MessageSquare } from 'lucide-react';

export const DesktopSidebar = ({ 
  post, 
  currentUser, 
  isBookmarked, 
  onLike, 
  onShare, 
  onBookmark 
}) => (
  <aside className="fixed left-8 top-1/2 -translate-y-1/2 hidden lg:flex flex-col items-center gap-6">
    <div className="flex flex-col items-center gap-2">
      <button 
        onClick={onLike}
        className={`p-2 rounded-full transition-all hover:scale-110 ${
          post.likedBy?.includes(currentUser?.uid)
            ? 'text-red-500'
            : 'text-gray-400 hover:text-red-500'
        }`}
      >
        <Heart className={`w-6 h-6 ${post.likedBy?.includes(currentUser?.uid) ? 'fill-current' : ''}`} />
      </button>
      <span className="text-sm text-gray-500 font-medium">{post.likes || 0}</span>
    </div>

    <div className="flex flex-col items-center gap-2">
      <button 
        onClick={() => document.getElementById('comments').scrollIntoView({ behavior: 'smooth' })}
        className="p-2 text-gray-400 hover:text-gray-600 rounded-full transition-all hover:scale-110"
      >
        <MessageSquare className="w-6 h-6" />
      </button>
      <span className="text-sm text-gray-500 font-medium">{post.comments?.length || 0}</span>
    </div>

    <button
      onClick={onShare}
      className="p-2 text-gray-400 hover:text-gray-600 rounded-full transition-all hover:scale-110"
    >
      <Share2 className="w-6 h-6" />
    </button>

    <button
      onClick={onBookmark}
      className={`p-2 rounded-full transition-all hover:scale-110 ${
        isBookmarked
          ? 'text-emerald-500'
          : 'text-gray-400 hover:text-emerald-500'
      }`}
    >
      <Bookmark className={`w-6 h-6 ${isBookmarked ? 'fill-current' : ''}`} />
    </button>
  </aside>
);