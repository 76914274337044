// src/components/user/Posts/comments/components/CommentInput.js
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Smile, ImageIcon, AtSign, Send, AlertCircle, X } from 'lucide-react';
import { MAX_COMMENT_LENGTH } from '../constants';

const CommentInput = ({ 
  commentText = '',
  handleInputChange,
  handleSubmitComment,
  isSubmitting = false,
  currentUser = null,
  replyingTo = null,
  setReplyingTo,
  showEmojiPicker = false,
  setShowEmojiPicker,
  placeholder = "Write a comment...",
  maxLength = MAX_COMMENT_LENGTH
}) => {
  const inputId = `comment-input-${replyingTo?.id || 'main'}`;

  if (!currentUser) {
    return (
      <div className="w-full bg-blue-50 rounded-lg p-6">
        <div className="flex flex-col items-center">
          <AlertCircle className="w-12 h-12 text-blue-500 mb-4" />
          <h3 className="text-lg font-semibold text-blue-900 mb-2">
            Join the conversation
          </h3>
          <p className="text-blue-600 text-center mb-4">
            Sign in to post comments and interact with other members
          </p>
          <button 
            onClick={() => window.location.href = '/login'}
            className="px-8 py-3 bg-blue-600 text-white rounded-full text-sm font-medium
              hover:bg-blue-700 transition-colors"
          >
            Sign in
          </button>
        </div>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmitComment} className="w-full space-y-4">
      {replyingTo && (
        <div className="flex items-center gap-2 text-sm text-gray-600 bg-gray-50 p-2 rounded-lg">
          <span>Replying to {replyingTo.authorEmail?.split('@')[0]}</span>
          <button
            type="button"
            onClick={() => setReplyingTo(null)}
            className="p-1 hover:bg-gray-200 rounded-full"
          >
            <X className="w-4 h-4" />
          </button>
        </div>
      )}

      <div className="relative">
        <textarea
          id={inputId}
          value={commentText}
          onChange={handleInputChange}
          placeholder={replyingTo ? `Reply to ${replyingTo.authorEmail?.split('@')[0]}...` : placeholder}
          maxLength={maxLength}
          disabled={isSubmitting}
          className="w-full min-h-[100px] p-4 bg-white border border-gray-200 rounded-lg resize-y
            focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors
            disabled:opacity-50 disabled:cursor-not-allowed"
          aria-label="Comment text"
        />
        
        <div className="absolute right-4 bottom-4 flex items-center gap-2">
          <button
            type="button"
            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
            className="p-2 text-gray-400 hover:text-gray-600 hover:bg-gray-100 rounded-full transition-colors"
            aria-label="Add emoji"
          >
            <Smile className="w-5 h-5" />
          </button>
          
          <button
            type="button"
            className="p-2 text-gray-400 hover:text-gray-600 hover:bg-gray-100 rounded-full transition-colors"
            aria-label="Add image"
          >
            <ImageIcon className="w-5 h-5" />
          </button>
          
          <button
            type="button"
            className="p-2 text-gray-400 hover:text-gray-600 hover:bg-gray-100 rounded-full transition-colors"
            aria-label="Mention user"
          >
            <AtSign className="w-5 h-5" />
          </button>
        </div>
      </div>

      <div className="flex items-center justify-between">
        <div className="text-sm text-gray-500">
          {maxLength - commentText.length} characters remaining
        </div>

        <div className="flex items-center gap-4">
          {replyingTo && (
            <button
              type="button"
              onClick={() => setReplyingTo(null)}
              className="px-4 py-2 text-gray-500 hover:text-gray-700 transition-colors"
            >
              Cancel
            </button>
          )}
          
          <button
            type="submit"
            disabled={isSubmitting || !commentText.trim()}
            className={`px-6 py-2 rounded-full flex items-center gap-2 ${
              isSubmitting || !commentText.trim()
                ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                : 'bg-blue-600 text-white hover:bg-blue-700'
            } transition-colors`}
            aria-disabled={isSubmitting || !commentText.trim()}
          >
            <Send className="w-4 h-4" />
            {isSubmitting ? 'Posting...' : 'Post'}
          </button>
        </div>
      </div>
    </form>
  );
};

CommentInput.propTypes = {
  commentText: PropTypes.string,
  handleInputChange: PropTypes.func.isRequired,
  handleSubmitComment: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  currentUser: PropTypes.shape({
    uid: PropTypes.string,
    email: PropTypes.string
  }),
  replyingTo: PropTypes.shape({
    id: PropTypes.string,
    authorEmail: PropTypes.string
  }),
  setReplyingTo: PropTypes.func.isRequired,
  showEmojiPicker: PropTypes.bool,
  setShowEmojiPicker: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number
};

export default memo(CommentInput);