import React, { useState, useEffect } from 'react';
import { 
  Sparkles, 
  Scale,
  Timer,
  LogOut,
  UserCog,
  Calendar,
  User2,
  ChevronDown,
  Ruler,
  Activity,
  HeartPulse,
  Menu,
  X,
  Heart,
  MessageSquare,
  Lightbulb,
  Settings,
  Command,
  Search,
  Bell,
  BarChart,
  TrendingUp,
  Home,
  Layout,
  PlusCircle
} from 'lucide-react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { Timestamp } from 'firebase/firestore';
import { auth } from '../../../firebase';
import WatchMyHealthLogo from '../../ui/WatchMyHealthLogo';

const calculateAge = (dateOfBirth) => {
  if (!dateOfBirth) return 'N/A';
  const dob = dateOfBirth instanceof Timestamp ? 
    dateOfBirth.toDate() : 
    new Date(dateOfBirth);
  
  const today = new Date();
  let age = today.getFullYear() - dob.getFullYear();
  const monthDiff = today.getMonth() - dob.getMonth();
  
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
    age--;
  }
  
  return age;
};

const formatGender = (gender) => {
  if (!gender) return 'Not set';
  return gender.charAt(0).toUpperCase() + gender.slice(1).toLowerCase();
};

const calculateBMI = (weight, height) => {
  if (!weight || !height) return null;
  const heightInMeters = height / 100;
  return weight / (heightInMeters * heightInMeters);
};

const RegisteredDashboardLayout = ({ profileStatus }) => {
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [showCommandMenu, setShowCommandMenu] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [activeTab, setActiveTab] = useState('overview');
  
  const dropdownRef = React.useRef(null);
  const searchRef = React.useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  
  const userData = profileStatus?.data;

  useEffect(() => {
    const handleKeyPress = (e) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 'k') {
        e.preventDefault();
        setShowCommandMenu(true);
      }
      if (e.key === 'Escape') {
        setShowCommandMenu(false);
        setIsSearchOpen(false);
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, []);

  const navigationItems = [
    {
      id: 'community',
      path: '/dashboard/community',
      label: 'Community',
      icon: <MessageSquare className="w-5 h-5" />,
      description: 'Join the discussion'
    },
    {
      id: 'weight',
      path: '/dashboard/weight',
      label: 'Weight Analytics',
      icon: <Scale className="w-5 h-5" />,
      description: 'Track your progress'
    },
    {
      id: 'fasting',
      path: '/dashboard/fasting',
      label: 'Fasting Timer',
      icon: <Timer className="w-5 h-5" />,
      description: 'Manage fasting periods'
    },
    {
      id: 'health',
      path: '/dashboard/recommendations',
      label: 'Health Insights',
      icon: <Sparkles className="w-5 h-5" />,
      description: 'Personalized recommendations'
    }
];


  const handleDonation = () => {
    window.open('https://donate.stripe.com/8wM4jnaAJ9R6f9m4gg', '_blank');
  };

  const handleNavigation = (path) => {
    navigate(path);
    setIsProfileMenuOpen(false);
    setIsMobileMenuOpen(false);
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/auth');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const formatMetric = (value, unit, preference = userData?.measurementPreference) => {
    if (!value) return 'Not set';
    
    if (preference === 'imperial' && unit === 'kg') {
      return `${(value * 2.20462).toFixed(1)} lbs`;
    }
    if (preference === 'imperial' && unit === 'cm') {
      const inches = value / 2.54;
      const feet = Math.floor(inches / 12);
      const remainingInches = Math.round(inches % 12);
      return `${feet}'${remainingInches}"`;
    }
    
    return `${value} ${unit}`;
  };

  const getCurrentSection = () => {
    const path = location.pathname;
    if (path.includes('/community')) return 'community';
    if (path.includes('/weight')) return 'weight';
    if (path.includes('/fasting')) return 'fasting';
    if (path.includes('/recommendations')) return 'health';
    return 'community'; // Default to community
};


  const currentSection = getCurrentSection();
  const bmi = calculateBMI(userData?.weight, userData?.height);
  const age = calculateAge(userData?.dateOfBirth);

  return (
    <div className="min-h-screen bg-slate-100">
      {/* Modern Command Menu */}
      {showCommandMenu && (
        <div className="fixed inset-0 z-[60] bg-black/50 backdrop-blur-sm">
          <div className="fixed top-[20%] left-1/2 transform -translate-x-1/2 w-full max-w-2xl">
            <div className="mx-4 bg-white rounded-2xl shadow-2xl border border-slate-200/50">
              <div className="p-4 border-b border-slate-200/50">
                <div className="flex items-center gap-2 px-3 py-2 bg-slate-50 rounded-lg">
                  <Command className="w-5 h-5 text-slate-400" />
                  <input 
                    type="text"
                    placeholder="Search commands..."
                    className="flex-1 bg-transparent outline-none text-slate-900 placeholder-slate-400"
                    autoFocus
                  />
                  <kbd className="px-2 py-1 text-xs font-mono bg-white rounded border border-slate-200 text-slate-500">
                    ESC
                  </kbd>
                </div>
              </div>
              <div className="p-2 max-h-[60vh] overflow-y-auto">
                {navigationItems.map((item) => (
                  <button
                    key={item.id}
                    onClick={() => {
                      handleNavigation(item.path);
                      setShowCommandMenu(false);
                    }}
                    className="w-full p-3 flex items-center gap-3 rounded-lg hover:bg-slate-50 text-slate-600 transition-colors duration-200"
                  >
                    {item.icon}
                    <div className="flex flex-col items-start">
                      <span className="font-medium text-slate-900">{item.label}</span>
                      <span className="text-xs text-slate-500">{item.description}</span>
                    </div>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modern Side Navigation */}
      <div className="fixed inset-y-0 left-0 w-64 bg-white border-r border-slate-200/50 shadow-sm z-50 hidden lg:block">
        <div className="flex flex-col h-full">
          {/* Logo Section */}
          <div className="p-6 flex items-center gap-3">
            <WatchMyHealthLogo 
              width={40} 
              height={40} 
              className="transition-transform duration-300 hover:scale-105" 
            />
            <div>
              <h1 className="text-lg font-semibold bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent">
                WatchMy.Health
              </h1>
              <span className="text-xs text-slate-500">Health Analytics</span>
            </div>
          </div>


          {/* Navigation Items */}
          <nav className="flex-1 px-3 space-y-1">
            {navigationItems.map((item) => {
              const isActive = currentSection === item.id;
              return (
                <button
                  key={item.id}
                  onClick={() => handleNavigation(item.path)}
                  className={`
                    w-full p-3 flex items-center gap-3 rounded-xl transition-all duration-300
                    ${isActive 
                      ? 'bg-gradient-to-r from-blue-50 to-indigo-50 text-blue-700' 
                      : 'hover:bg-slate-50 text-slate-600'
                    }
                  `}
                >
                  {React.cloneElement(item.icon, {
                    className: `w-5 h-5 ${isActive ? 'text-blue-500' : 'text-slate-400'}`
                  })}
                  <div className="flex flex-col items-start">
                    <span className="font-medium text-sm">{item.label}</span>
                    <span className="text-xs text-slate-500">{item.description}</span>
                  </div>
                  {isActive && (
                    <div className="absolute left-0 w-1 h-8 bg-blue-500 rounded-r-full" />
                  )}
                </button>
              );
            })}
          </nav>

          {/* Bottom Section */}
          <div className="p-4 mt-auto">
            <button
              onClick={handleDonation}
              className="w-full p-3 rounded-xl bg-gradient-to-br from-blue-500/10 to-indigo-500/10 hover:from-blue-500/20 hover:to-indigo-500/20 transition-all duration-300"
            >
              <div className="flex items-center gap-3">
                <Heart className="w-5 h-5 text-blue-500" />
                <div className="flex flex-col items-start">
                  <span className="text-sm font-medium text-slate-900">Support Us</span>
                  <span className="text-xs text-slate-500">Help us grow</span>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
{/* Top Navigation Bar */}
<header className="fixed top-0 right-0 w-full lg:w-[calc(100%-16rem)] h-16 bg-white/80 backdrop-blur-md border-b border-slate-200/50 z-40">
        <div className="h-full px-4 flex items-center justify-between">
          {/* Left Section - Mobile Menu & Search */}
          <div className="flex items-center gap-3">
            <button
              onClick={() => setIsMobileMenuOpen(true)}
              className="lg:hidden p-2 rounded-lg hover:bg-slate-100 text-slate-600 transition-colors duration-200"
            >
              <Menu className="w-6 h-6" />
            </button>

            {/* Global Search */}
            <div className="hidden sm:block relative">
              <button
                onClick={() => setShowCommandMenu(true)}
                className="flex items-center gap-2 px-4 py-2 bg-slate-50 hover:bg-slate-100 rounded-lg text-slate-600 transition-colors duration-200"
              >
                <Search className="w-4 h-4" />
                <span className="text-sm">Quick search...</span>
                <div className="flex items-center gap-1 px-2 py-1 bg-white rounded border border-slate-200">
                  <Command className="w-3 h-3" />
                  <span className="text-xs">K</span>
                </div>
              </button>
            </div>
          </div>

          {/* Center Section - Feedback Button */}
          <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <button
              onClick={() => handleNavigation('/dashboard/feature-requests')}
              className="group flex items-center gap-2 px-4 py-2 rounded-xl bg-gradient-to-r from-blue-500 to-indigo-500 text-white font-medium shadow-sm hover:shadow-md transition-all duration-300 hover:-translate-y-0.5"
            >
              <div className="relative">
                <Lightbulb className="w-5 h-5" />
                <span className="absolute -top-1 -right-1 w-2 h-2 bg-yellow-300 rounded-full animate-pulse" />
              </div>
              <span className="relative">
                Share Feedback
              </span>
              <span className="absolute inset-0 rounded-xl bg-white opacity-0 group-hover:opacity-10 transition-all duration-300" />
            </button>
          </div>

          {/* Right Section - Actions & Profile */}
          <div className="flex items-center gap-3">


            {/* Notifications */}
            <div className="relative">
              <button
                onClick={() => setShowNotifications(!showNotifications)}
                className="p-2 rounded-lg hover:bg-slate-100 text-slate-600 transition-colors duration-200 relative"
              >
                <Bell className="w-5 h-5" />
                <span className="absolute top-1 right-1 w-2 h-2 bg-blue-500 rounded-full" />
              </button>

              {showNotifications && (
                <div className="absolute right-0 mt-2 w-80 bg-white rounded-xl shadow-xl border border-slate-200/50 p-4">
                  <div className="flex items-center justify-between mb-4">
                    <h3 className="font-semibold text-slate-900">Notifications</h3>
                    <button className="text-xs text-blue-500 hover:text-blue-600">Mark all as read</button>
                  </div>
                  <div className="space-y-3">
                    {/* Feedback-related notification */}
                    <div className="flex items-start gap-3 p-2 rounded-lg hover:bg-slate-50">
                      <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center">
                        <Lightbulb className="w-4 h-4 text-blue-500" />
                      </div>
                      <div>
                        <p className="text-sm text-slate-900">Your feedback helps us improve!</p>
                        <span className="text-xs text-slate-500">Share your thoughts with us</span>
                      </div>
                    </div>
                    {/* Other notifications */}
                    <div className="flex items-start gap-3 p-2 rounded-lg hover:bg-slate-50">
                      <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center">
                        <TrendingUp className="w-4 h-4 text-blue-500" />
                      </div>
                      <div>
                        <p className="text-sm text-slate-900">Weekly health report ready</p>
                        <span className="text-xs text-slate-500">2 hours ago</span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Profile Menu */}
            <div className="relative" ref={dropdownRef}>
              <button
                onClick={() => setIsProfileMenuOpen(!isProfileMenuOpen)}
                className="flex items-center gap-2 p-1.5 rounded-lg hover:bg-slate-100 transition-colors duration-200"
              >
                <div className="w-8 h-8 rounded-lg bg-gradient-to-br from-blue-500 to-indigo-500 flex items-center justify-center text-white text-sm font-medium">
                  {userData?.email?.[0]?.toUpperCase() || 'U'}
                </div>
                <ChevronDown className={`w-4 h-4 text-slate-400 transition-transform duration-200 ${isProfileMenuOpen ? 'rotate-180' : ''}`} />
              </button>

              {/* Enhanced Profile Dropdown */}
              {isProfileMenuOpen && (
                <div className="absolute right-0 mt-2 w-80 bg-white rounded-xl shadow-xl border border-slate-200/50 p-4">
                  {/* User Info Section */}
                  <div className="flex items-center gap-3 pb-4 border-b border-slate-200/50">
                    <div className="w-12 h-12 rounded-xl bg-gradient-to-br from-blue-500 to-indigo-500 flex items-center justify-center text-white text-lg font-medium">
                      {userData?.email?.[0]?.toUpperCase() || 'U'}
                    </div>
                    <div>
                      <div className="font-medium text-slate-900">{userData?.email?.split('@')[0]}</div>
                      <div className="text-sm text-slate-500">{userData?.email}</div>
                    </div>
                  </div>

                  {/* Stats Grid */}
                  <div className="grid grid-cols-2 gap-3 py-4">
                    <div className="p-3 rounded-lg bg-slate-50">
                      <div className="text-sm text-slate-500">Height</div>
                      <div className="text-lg font-medium text-slate-900">{formatMetric(userData?.height, 'cm')}</div>
                    </div>
                    <div className="p-3 rounded-lg bg-slate-50">
                      <div className="text-sm text-slate-500">Weight</div>
                      <div className="text-lg font-medium text-slate-900">{formatMetric(userData?.weight, 'kg')}</div>
                    </div>
                    <div className="p-3 rounded-lg bg-slate-50">
                      <div className="text-sm text-slate-500">BMI</div>
                      <div className="text-lg font-medium text-slate-900">{bmi ? bmi.toFixed(1) : 'Not set'}</div>
                    </div>
                    <div className="p-3 rounded-lg bg-slate-50">
                      <div className="text-sm text-slate-500">Age</div>
                      <div className="text-lg font-medium text-slate-900">{age} years</div>
                    </div>
                  </div>

                  {/* Actions */}
                  <div className="space-y-1 pt-2">
                    <button
                      onClick={() => handleNavigation('/settings/profile')}
                      className="w-full p-2 flex items-center gap-2 rounded-lg hover:bg-slate-50 text-slate-700 transition-colors duration-200"
                    >
                      <UserCog className="w-4 h-4 text-slate-400" />
                      <span className="text-sm">Settings</span>
                    </button>
                    <button
                      onClick={() => handleNavigation('/dashboard/feature-requests')}
                      className="w-full p-2 flex items-center gap-2 rounded-lg hover:bg-slate-50 text-slate-700 transition-colors duration-200"
                    >
                      <MessageSquare className="w-4 h-4 text-slate-400" />
                      <span className="text-sm">Feedback</span>
                    </button>
                    <button
                      onClick={handleLogout}
                      className="w-full p-2 flex items-center gap-2 rounded-lg hover:bg-red-50 text-red-600 transition-colors duration-200"
                    >
                      <LogOut className="w-4 h-4" />
                      <span className="text-sm">Logout</span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>

      {/* Mobile Navigation Menu */}
      {isMobileMenuOpen && (
        <div className="lg:hidden fixed inset-0 z-50">
          <div 
            className="fixed inset-0 bg-slate-900/50 backdrop-blur-sm"
            onClick={() => setIsMobileMenuOpen(false)}
          />
          <div className="fixed inset-y-0 left-0 w-full max-w-sm bg-white shadow-2xl">
            <div className="flex flex-col h-full">
              <div className="p-4 flex items-center justify-between border-b border-slate-200/50">
                <div className="flex items-center gap-3">
                  <WatchMyHealthLogo width={32} height={32} />
                  <h1 className="text-lg font-semibold">WatchMy.Health</h1>
                </div>
                <button
                  onClick={() => setIsMobileMenuOpen(false)}
                  className="p-2 rounded-lg hover:bg-slate-100 text-slate-600"
                >
                  <X className="w-6 h-6" />
                </button>
              </div>

              <nav className="flex-1 p-4 space-y-2 overflow-y-auto">
                {navigationItems.map((item) => {
                  const isActive = currentSection === item.id;
                  return (
                    <button
                      key={item.id}
                      onClick={() => handleNavigation(item.path)}
                      className={`
                        w-full p-3 flex items-center gap-3 rounded-xl transition-all duration-200
                        ${isActive 
                          ? 'bg-blue-50 text-blue-600' 
                          : 'hover:bg-slate-50 text-slate-600'
                        }
                      `}
                    >
                      {React.cloneElement(item.icon, {
                        className: `w-5 h-5 ${isActive ? 'text-blue-500' : 'text-slate-400'}`
                      })}
                      <div className="flex flex-col items-start">
                        <span className="font-medium text-sm">{item.label}</span>
                        <span className="text-xs text-slate-500">{item.description}</span>
                      </div>
                    </button>
                  );
                })}
              </nav>

              <div className="p-4 border-t border-slate-200/50">
                <button
                  onClick={handleDonation}
                  className="w-full p-3 rounded-xl bg-blue-50 text-blue-600 flex items-center gap-3"
                >
                  <Heart className="w-5 h-5" />
                  <span className="font-medium text-sm">Support Us</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Main Content Area */}
      <main className="lg:pl-64 pt-16">
        <div className="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8">
          <div className="bg-white rounded-2xl shadow-sm border border-slate-200/50 backdrop-blur-sm min-h-[calc(100vh-8rem)]">
            <div className="p-6">
              <Outlet context={{ userData: profileStatus?.data }} />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default RegisteredDashboardLayout;